import React, { Component } from 'react';
import AdmissionSidebar from '../../component/AdmissionSidebar';
import { bKashPayment } from '../../../static';

class ApplyOnlineContent extends Component {
    render() {
        return (
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr />
                    <p className='heading2 primaryHeading text-center'>
                        Apply Online
                    </p>
                    <hr />
                    <p className='heading5 primaryHeading'>
                        How to apply for online admission?
                    </p>
                    <p className='allText'>
                        Students seeking admission can download Admission Form from the link given bellow and can submit the same either online or send to admission@easternuni.edu.bd
                    </p>
                    <p className='heading5'>
                        Apply Online
                    </p>
                    <p className='heading5'>
                        Download Admission Form
                    </p>
                    <p className='heading5'>
                        Online submission
                    </p>
                    <p className='allText'>
                        <ul className='allText' tyle={{ marginLeft: "5rem" }}>
                            <li>Contact a bKash Agent,Pay Tk. 500 to bKash no. 01795711361, and collect a TrxID number.</li>
                            <li>Go to EU Website , go to admission, go to Online Admission and then go to New Admission</li>
                            <li>Fill- up the Admission Form</li>
                            <li>Insert the TrxID number.</li>
                            <li>Submit the form either online or send to admission@easternuni.edu.bd</li>
                                OR, if you have personal bKash account, transfer Tk.500 to bKash no. 01795711361 and collect a TrxID number.
                            <li>Go to EU Website , go to admission, go to Online Admission and then go to New Admission</li>
                            <li>Fill- up the Admission Form</li>
                            <li>Insert the TrxID number</li>
                            <li>Submit the formeither online or send to admission@easternuni.edu.bd</li>
                        </ul>
                    </p>

                </div>
                <AdmissionSidebar />
            </div>
        );
    }
}

export default ApplyOnlineContent;