import { useQuery } from "@tanstack/react-query";
import { getAlbumImages } from "../services/apis";

function useAlbumImages(id) {
  const {
    data: images,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["album-images", id],
    queryFn: () => getAlbumImages(id),
    retry: 3,
  });
  return { images, isLoading, error };
}

export default useAlbumImages;
