import { useQuery } from "@tanstack/react-query";
import { getEuFoundationMembers } from "../services/apis";

function useEuFoundation() {
  const {
    data: euFoundationMembers,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["eu-foundation"],
    queryFn: () => getEuFoundationMembers(),
  });

  return { euFoundationMembers, isLoading, error };
}

export default useEuFoundation;
