import React, { Component } from 'react';
import AdmissionSidebar from '../../component/AdmissionSidebar';
import {foreignStudentTutionFeeImage} from '../../../static';

class FeeStructureForForeignStudentsContent extends Component {
    render() {
        return (
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr />
                    <p className='heading2 primaryHeading text-center'>
                        Fee Structure For Foreign Student
                    </p>
                    <hr />
                    <img className='img-fluid' src={foreignStudentTutionFeeImage} alt='Tution fee Loading...'/>
                </div>
                <AdmissionSidebar />
            </div>
        );
    }
}

export default FeeStructureForForeignStudentsContent;