import React, { Component, useState } from 'react';
import { NavLink } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';

// Images

import { aboutImg, academicImg, admissionImg, contactImg, communityImg, researchImg, newsAndUpdateImg } from '../static'



function SubMenubar(props) {


    let menu = props.MENU;
    const [toggle, setToggle] = useState(false);
    const [toggleSubEng, setToggleSubEng] = useState(false);
    const [toggleSubBus, setToggleSubBus] = useState(false);
    const [toggleSubEt, setToggleSubEt] = useState(false);
    const [toggleSubLaw, setToggleSubLaw] = useState(false);

    if (menu == 'About') {
        return (
            <div className='hoverMe'>
                <div className='subMenu'>
                    <div className='leftSideSubMenu'>
                        <div className="card">
                            <ul className="list-group list-group-flush">
                                <li className="nav-item dropdown list-group-item">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                                        role="button" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        About us
                                            </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li className='list-group-item'>
                                            <NavLink exact to='/history'>History</NavLink>
                                        </li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/aboutEU'>Vission,Mission and Strategy</NavLink></li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/internationalAcademicCollaboration'>International Academic Collaboration</NavLink>
                                        </li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/accreditation'>Accreditation</NavLink>
                                        </li>
                                    </div>
                                </li>
                                <li className="nav-item dropdown list-group-item">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                                        role="button" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        Board of trustee
                                            </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li className='list-group-item'>
                                            <NavLink exact to='/messageFromChairman'>Message from Chairman BOT</NavLink></li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/boardOfTrustees'>Board of Trustees</NavLink></li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/membersofEUTrust'>Members of EU Trust</NavLink>
                                        </li>
                                    </div>
                                </li>
                                <li className="nav-item dropdown list-group-item">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                                        role="button" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        Execitve Leaders
                                            </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li className='list-group-item'>
                                            <NavLink exact to='/messageFromVC'>Message from VC</NavLink></li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/treasurer'>Treasurer</NavLink>
                                        </li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/deansChairperson'>Deans, Chairpersons</NavLink>
                                        </li>
                                    </div>
                                </li>
                                <li className="nav-item dropdown list-group-item">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                                        role="button" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        Authorities
                                            </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li className='list-group-item'>
                                            <NavLink exact to='/governance'>Governance</NavLink></li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/syndicate'>Syndicate</NavLink></li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/academicCouncil'>Academic Council</NavLink></li>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='centerSubMenu'>
                        <div className="card">
                            <ul className="list-group list-group-flush">


                                <li className="nav-item dropdown list-group-item">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                                        role="button" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        Administration
                                            </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li className='list-group-item'>
                                            <NavLink exact to='/registrar'>Registrar</NavLink></li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/proctor'>Proctor</NavLink></li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/examController' >Exam Controller</NavLink></li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/offices'>Offices</NavLink></li>
                                    </div>
                                </li>
                                <li className="nav-item dropdown list-group-item">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                                        role="button" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        Offices
                                            </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li className='list-group-item'>
                                            <NavLink exact to='/accountOffice' >Accounts</NavLink></li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/aaa' >All Other Office</NavLink>
                                        </li>

                                    </div>
                                </li>


                            </ul>
                        </div>
                    </div>
                    <div className='rightSideSubMenu'>
                        <img src={aboutImg} alt='About Us' className='img-fluid'></img>
                    </div>
                </div>
            </div>

        );
    }

    else if (menu == 'Academic') {
        return (

            <div className='hoverMe'>
                <div className='subMenu'>
                    <div className='leftSideSubMenu'>
                        <div className="card">
                            <ul className='list-group list-group-flush'>
                                <li className='nav-item dropdown list-group-item'
                                    onClick={() => setToggle(!toggle)}>
                                    Faculties
                                    <div className='dropdown-toggle'
                                        style={{ display: 'inline-block', paddingLeft: '.5rem' }}></div>
                                    {
                                        (toggle) && (
                                            <div className='dropdown-items'>
                                                <ul className='list-group'>
                                                    <li className='list-group-item'
                                                        onMouseOver={() => setToggleSubEng(!toggleSubEng)}>
                                                        Faculty of Arts
                                                        <div className='dropdown-toggle'
                                                            style={{ display: 'inline-block', paddingLeft: '.5rem' }}></div>

                                                        {(toggleSubEng) && (
                                                            <ul className='list-group'>
                                                                <li className='list-group-item'>Department of English</li>
                                                            </ul>
                                                        )
                                                        }
                                                    </li>
                                                    <li className='list-group-item'
                                                        onMouseOver={() => setToggleSubBus(!toggleSubBus)}>
                                                        Faculty of Business Administration
                                                        <div className='dropdown-toggle'
                                                            style={{ display: 'inline-block', paddingLeft: '.5rem' }}></div>

                                                        {(toggleSubBus) && (
                                                            <ul className='list-group'>
                                                                <li className='list-group-item'>Department of Business Administration</li>
                                                            </ul>
                                                        )
                                                        }
                                                    </li>
                                                    <li className='list-group-item'
                                                        onMouseOver={() => setToggleSubEt(!toggleSubEt)}>
                                                        Faculty of Engineering and Technology
                                                        <div className='dropdown-toggle'
                                                            style={{ display: 'inline-block', paddingLeft: '.5rem' }}></div>

                                                        {(toggleSubEt) && (
                                                            <ul className='list-group'>
                                                                <li className='list-group-item'>Deaprtment of Computer Science and Engineering</li>
                                                                <li className='list-group-item'>Department of Electrical and Electronics Engineering</li>
                                                            </ul>
                                                        )
                                                        }
                                                    </li>
                                                    <li className='list-group-item'
                                                        onMouseOver={() => setToggleSubLaw(!toggleSubLaw)}>
                                                        Faculty of Law
                                                        <div className='dropdown-toggle'
                                                            style={{ display: 'inline-block', paddingLeft: '.5rem' }}></div>

                                                        {(toggleSubLaw) && (
                                                            <ul className='list-group'>
                                                                <li className='list-group-item'>Department of Law</li>
                                                            </ul>
                                                        )
                                                        }
                                                    </li>
                                                </ul>
                                            </div>
                                        )
                                    }
                                </li>
                                <li className="nav-item dropdown list-group-item">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                                        role="button" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        Registration Procedure
                                            </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <NavLink exact to='/onlinePaymentProcedure' className="dropdown-item" href="../Academics/onlinePaymentProcedure.html">
                                            Online Payment Procedure</NavLink>

                                    </div>
                                </li>

                                <li className="nav-item dropdown list-group-item">
                                    <a className="nav-link" href="http://webportal.easternuni.edu.bd/Calender/_AcademicCalendarSearch.aspx" id="navbarDropdown" role="button"
                                    >
                                        Academic Calender
                                            </a>

                                </li>


                            </ul>
                        </div>

                    </div>
                    <div className='centerSubMenu'>
                        <div className="card">
                            <ul className="list-group list-group-flush">
                                <li className="nav-item dropdown list-group-item">
                                    <NavLink className="nav-link" exact to='/iqac'>
                                        IQAC
                                    </NavLink>

                                </li>
                                <li className="nav-item dropdown list-group-item">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                                        role="button" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        Academic Rules and Regulations
                                            </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li className='list-group-item'>
                                            <NavLink exact to='/academicRulesAndProcedures' className="dropdown-item">
                                                Academic Rules and Procedures</NavLink>
                                        </li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/codeOfConduct' className="dropdown-item">
                                                Code of Conduct</NavLink>
                                        </li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/academicStandingOfAStudent' className="dropdown-item">
                                                Academic Standing of a student</NavLink>
                                        </li>

                                        <li className='list-group-item'>
                                            <NavLink exact to='/testsAndExams' className="dropdown-item">
                                                Tests and Exams</NavLink>
                                        </li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/rulesOfClassAttendance' className="dropdown-item">
                                                Rules of Class Attendance</NavLink>
                                        </li>

                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div className='rightSideSubMenu'>
                        <img src={academicImg} alt={academicImg} className='img-fluid'></img>
                    </div>
                </div>
            </div >

        );
    }

    else if (menu == 'Admission') {
        return (

            <div className='hoverMe'>
                <div className='subMenu'>
                    <div className='leftSideSubMenu'>
                        <div className="card">
                            <ul className="list-group list-group-flush">
                                <li className="nav-item dropdown list-group-item">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                                        role="button" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        Undergraduate Program
                                            </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <a className="dropdown-item" href="../faculty/EnglishDept.html">Department of
                                                    English</a>
                                        <a className="dropdown-item" href="../faculty/CSEDept.html">Department of
                                                    Computer Science and Engineering</a>

                                        <a className="dropdown-item" href="../faculty/EEEDept.html">Department of
                                                    Electrical and Electronics Engineering</a>
                                        <a className="dropdown-item" href="../faculty/LawDept.html">Department of
                                                    Law</a>
                                    </div>
                                </li>
                                <li className="nav-item list-group-item">

                                    <NavLink exact to='/aaa'>Graduate Program</NavLink>

                                </li>

                                <li className="nav-item list-group-item">
                                    <NavLink exact to='/internationalStudents'>International Students</NavLink>
                                </li>
                                <li className="nav-item dropdown list-group-item">
                                    <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                                        role="button" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        How To Apply
                                            </a>
                                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                        <li className='list-group-item'>
                                            <NavLink exact to='/euAdmissionForm'>Fresh Students</NavLink></li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/transferStudents'>Transfer Student</NavLink></li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/creditTransferForm'>Credit Transfer Form</NavLink></li>
                                        <li className='list-group-item'>
                                            <NavLink exact to='/howToApply'>How To Apply</NavLink></li>
                                    </div>
                                </li>


                            </ul>
                        </div>

                    </div>
                    <div className='centerSubMenu'>
                        <div className="card">
                            <ul className="list-group list-group-flush">
                                <li className="nav-item list-group-item">
                                    <a className="nav-link " href="../Admission/onlineAdmissonForm.html" id="navbarDropdown" role="button"
                                        aria-haspopup="true" aria-expanded="false">
                                        Online Admission Form
                                            </a>

                                </li>

                                <li className="nav-item list-group-item">

                                    <NavLink exact to='/tutionFees'>Tution Fees</NavLink>

                                </li>
                                <li className="nav-item list-group-item">

                                    <NavLink exact to='/financialAidAndScholarship'>Financial Aid and Scholarship</NavLink>

                                </li>
                                <li className="nav-item list-group-item">

                                    <NavLink exact to='/admissionInformation'>Admission Office</NavLink>

                                </li>

                            </ul>
                        </div>

                    </div>
                    <div className='rightSideSubMenu'>
                        <img src={admissionImg} alt='About Us' className='img-fluid'></img>
                    </div>
                </div>
            </div>

        );
    }

    else if (menu == 'Research') {
        return (

            <div className='hoverMe'>
                <div className='subMenu'>
                    <div className='leftSideSubMenu'>
                        <div className="card">
                            <ul className="list-group list-group-flush">


                                <li className="nav-item list-group-item">
                                    <a className="nav-link " href="../faculty/CSEDept.html" id="navbarDropdown"
                                        role="button" aria-haspopup="true"
                                        aria-expanded="false">
                                        Department of Computer Science and Engineering
                                            </a>

                                </li>
                                <li className="nav-item list-group-item">
                                    <a className="nav-link " href="../faculty/EEEDept.html" id="navbarDropdown"
                                        role="button" aria-haspopup="true"
                                        aria-expanded="false">
                                        Department of Electrical and Electronics Engineering
                                            </a>

                                </li>
                                <li className="nav-item list-group-item">
                                    <a className="nav-link " href="../faculty/EnglishDept.html" id="navbarDropdown"
                                        role="button" aria-haspopup="true"
                                        aria-expanded="false">
                                        Department of English
                                            </a>

                                </li>
                                <li className="nav-item list-group-item">
                                    <a className="nav-link " href="../faculty/LawDept.html" id="navbarDropdown"
                                        role="button" aria-haspopup="true"
                                        aria-expanded="false">
                                        Department of Law
                                            </a>

                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='centerSubMenu'>
                        <div className="card mt-3">
                            <p>Eastern University is widely known for its quality education,
                            superior faculty composition, excellent academic environment,
                            sincere care for students, extensive co and extra- curricular
                            activities, successful internship and job placement, modern
                            digital library, good governance and administration and
                                        convenient location of the campus.</p>
                        </div>
                    </div>
                    <div className='rightSideSubMenu'>
                        <img src={researchImg} alt={researchImg} className='img-fluid'></img>
                    </div>
                </div>
            </div>

        );
    }

    else if (menu == 'CommunityAndServices') {
        return (
            <div className='hoverMe'>
                <div className='subMenu'>
                    <div className='leftSideSubMenu'>
                        <div className="card">
                            <ul className="list-group list-group-flush">


                                <li className="nav-item list-group-item">
                                    <a className="nav-link " href="#" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Club
                                            </a>

                                </li>
                                <li className="nav-item list-group-item">
                                    <a className="nav-link " href="#" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Library
                                            </a>

                                </li>
                                <li className="nav-item list-group-item">
                                    <a className="nav-link " href="#" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Online Activities
                                            </a>

                                </li>
                                <li className="nav-item list-group-item">
                                    <a className="nav-link " href="#" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Athletics
                                            </a>

                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='centerSubMenu'>
                        <div className="card">
                            <ul className="list-group list-group-flush">


                                <li className="nav-item list-group-item">
                                    <a className="nav-link " href="#" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Services
                                            </a>

                                </li>
                                <li className="nav-item list-group-item">
                                    <a className="nav-link " href="#" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        twitter
                                            </a>

                                </li>
                                <li className="nav-item list-group-item">
                                    <a className="nav-link " href="#" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        FaceBook Link
                                            </a>

                                </li>
                                <li className="nav-item list-group-item">
                                    <a className="nav-link " href="#" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Youtube Link
                                            </a>

                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='rightSideSubMenu'>
                        <img src={communityImg} alt={communityImg} className='img-fluid'></img>
                    </div>
                </div>
            </div>

        );
    }
    else if (menu == 'NewsUpdates') {
        return (

            <div className='hoverMe'>
                <div className='subMenu'>
                    <div className='leftSideSubMenu'>
                        <div className="card">
                            <ul className="list-group list-group-flush">


                                <li className="nav-item list-group-item">
                                    <a className="nav-link " href="#" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        News
                                            </a>

                                </li>
                                <li className="nav-item list-group-item">
                                    <a className="nav-link " href="#" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Upcoming Events
                                            </a>

                                </li>
                                <li className="nav-item list-group-item">
                                    <a className="nav-link " href="#" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Notices
                                            </a>

                                </li>

                            </ul>
                        </div>

                    </div>
                    <div className='centerSubMenu'>
                        <div className="card mt-3">
                            <p>Eastern University is widely known for its quality education,
                            superior faculty composition, excellent academic environment,
                            sincere care for students, extensive co and extra- curricular
                            activities, successful internship and job placement, modern
                            digital library, good governance and administration and
                                        convenient location of the campus.</p>
                        </div>

                    </div>
                    <div className='rightSideSubMenu'>
                        <img src={newsAndUpdateImg} alt={newsAndUpdateImg} className='img-fluid'></img>
                    </div>
                </div>
            </div>

        );
    }

    else if (menu == 'ContactDirectories') {
        return (

            <div className='hoverMe'>
                <div className='subMenu'>
                    <div className='leftSideSubMenu'>
                        <div className="card">
                            <ul className="list-group list-group-flush">

                                <li className="nav-item list-group-item">
                                    <NavLink className="nav-link" exact to='/chairmanOffice' id="navbarDropdown" role="button"
                                        aria-haspopup="true" aria-expanded="false">
                                        Chairman Office
                                            </NavLink>

                                </li>

                                <li className="nav-item list-group-item">
                                    <NavLink className="nav-link" exact to='/vcOffice' id="navbarDropdown" role="button"
                                        aria-haspopup="true" aria-expanded="false">
                                        Vice Chancellor Office
                                            </NavLink>

                                </li>
                                <li className="nav-item list-group-item">
                                    <NavLink className="nav-link " exact to='/registrarOffice' id="navbarDropdown" role="button"
                                        aria-haspopup="true" aria-expanded="false">
                                        Registrar Office
                                            </NavLink>

                                </li>
                                <li className="nav-item list-group-item">
                                    <NavLink className="nav-link" exact to='/examControllerOffice' id="navbarDropdown" role="button"
                                        aria-haspopup="true" aria-expanded="false">
                                        Exam Controller Office
                                            </NavLink>

                                </li>
                                <li className="nav-item list-group-item">
                                    <NavLink className="nav-link" exact to='/libraryOffice' id="navbarDropdown" role="button"
                                        aria-haspopup="true" aria-expanded="false">
                                        Library Office
                                            </NavLink>

                                </li>
                            </ul>
                        </div>

                    </div>
                    <div className='centerSubMenu'>
                        <div className="card">
                            <ul className="list-group list-group-flush">

                                <li className="nav-item list-group-item">
                                    <NavLink className="nav-link" exact to='/admissionOffice' id="navbarDropdown" role="button"
                                        aria-haspopup="true" aria-expanded="false">
                                        Admission Office
                                            </NavLink>

                                </li>

                                <li className="nav-item list-group-item">
                                    <NavLink className="nav-link" exact to='/publicRelationsOffice' id="navbarDropdown" role="button"
                                        aria-haspopup="true" aria-expanded="false">
                                        Public Relations Office
                                            </NavLink>

                                </li>
                                <li className="nav-item list-group-item">
                                    <NavLink className="nav-link " exact to='/careerServiceAndInternationalOffice' id="navbarDropdown" role="button"
                                        aria-haspopup="true" aria-expanded="false">
                                        Career Service and International Office
                                            </NavLink>

                                </li>
                                <li className="nav-item list-group-item">
                                    <NavLink className="nav-link" exact to='/accountOffice' id="navbarDropdown" role="button"
                                        aria-haspopup="true" aria-expanded="false">
                                        Account Office
                                            </NavLink>

                                </li>
                                <li className="nav-item list-group-item">
                                    <NavLink className="nav-link" exact to='/itOffice' id="navbarDropdown" role="button"
                                        aria-haspopup="true" aria-expanded="false">
                                        IT Office
                                            </NavLink>

                                </li>
                            </ul>
                        </div>

                    </div>
                    <div className='rightSideSubMenu'>
                        <div className="card">
                            <ul className="list-group list-group-flush">

                                <li className="nav-item list-group-item">
                                    <NavLink className="nav-link" exact to='/medicalServiceOffice' id="navbarDropdown" role="button"
                                        aria-haspopup="true" aria-expanded="false">
                                        Medical Service Office
                                            </NavLink>

                                </li>

                                <li className="nav-item list-group-item">
                                    <NavLink className="nav-link" exact to='/counsellorOffice' id="navbarDropdown" role="button"
                                        aria-haspopup="true" aria-expanded="false">
                                        Counsellor Office
                                            </NavLink>

                                </li>
                                <li className="nav-item list-group-item">
                                    <NavLink className="nav-link " exact to='/studentAffairsOffice' id="navbarDropdown" role="button"
                                        aria-haspopup="true" aria-expanded="false">
                                        Student Affairs Office
                                            </NavLink>

                                </li>
                                <li className="nav-item list-group-item">
                                    <NavLink className="nav-link" exact to='/internalAuditOffice' id="navbarDropdown" role="button"
                                        aria-haspopup="true" aria-expanded="false">
                                        Internal Audit Office
                                            </NavLink>

                                </li>
                                <li className="nav-item list-group-item">
                                    <NavLink className="nav-link" exact to='/logisticsOffice' id="navbarDropdown" role="button"
                                        aria-haspopup="true" aria-expanded="false">
                                        Logistics Office
                                            </NavLink>

                                </li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>

        );
    }



}

export default SubMenubar;