import React from 'react';
import Footer from '../../../component/Footer';
import CareerServiceContent from './content/CareerServieContent';

const careerServie = () => {
    return (
        <div>
            <CareerServiceContent />
            <Footer />
        </div>
    );
};

export default careerServie;