import React, { Component } from 'react';
import AdmissionSidebar from '../../component/AdmissionSidebar';
import { bKashPayment } from '../../../static';

class paymentProcedureContent extends Component {
    render() {
        return (
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr/>
                    <p className='heading2 primaryHeading text-center'>
                        Payment Procedure
                    </p>
                    <hr/>
                    <div className='card text-center'>
                        <img src={bKashPayment} alt = 'For Admission fee only'></img>                      
                    </div>
                </div>
                <AdmissionSidebar/>
            </div>
        );
    }
}

export default paymentProcedureContent;