import React from "react";
import IqacSideBar from "../../../component/IqacSideBar";

const IqacEventScheduleContent = () => {
  return (
    <div className="content">
      <div className="OtherPagecontent">
        <hr />
        <p className="heading2 primaryHeading text-center">Event Schedule</p>
        <hr />
        <p className="allText text-center">
          Upcoming evnets are not available.
        </p>
      </div>
      <IqacSideBar />
    </div>
  );
};

export default IqacEventScheduleContent;
