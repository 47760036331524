import React, { useState } from "react";
import LeftSideNavigationBusinessDepartment from "../../component/leftSideNavigationDepartmentofBusiness";
import FacultyName from "../../component/facultyName";
import { engFacultyTestImg } from "../../../../static";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import Clubs from '../../../campus/Clubs/Clubs'

const ClubInformationBusinessDepartment = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName
        name="Department of Business Administration"
        facultyImg={engFacultyTestImg}
      />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationBusinessDepartment />
        <div className="rightSideContent">
          <div className='departmentClubs'>
            <Clubs />
          </div>
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationBusinessDepartment />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </div>
  );
};

export default ClubInformationBusinessDepartment;
