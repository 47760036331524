import React from "react";
import IqacSideBar from "../../../component/IqacSideBar";

const IqacPictureGalleryContent = () => {
  return (
    <div className="content">
      <div className="OtherPagecontent">
        <hr />
        <p className="heading2 primaryHeading text-center">
          Picture Gallery of IQAC
        </p>
        <hr />
        <p className="allText">Work in progress..</p>
      </div>
      <IqacSideBar />
    </div>
  );
};

export default IqacPictureGalleryContent;
