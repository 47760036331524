import React, { Component } from 'react';
import RegistrarContent from './content/RegistrarContent';

import Footer from '../../component/Footer';

class registrar extends Component {
    render() {
        return (
            <div>
                <RegistrarContent/>
                <Footer/>
            </div>
        );
    }
}

export default registrar;