import React from 'react';
import { NavLink } from "react-router-dom";

const NewsAndUpdatesSideBar = () => {
    return (
        <div className="OtherpageSideBar">
            <ul className="list-group list-group-flush">
                <li className="nav-item dropdown list-group-item">
                    <NavLink className="nav-link" exact to="/newsPage">
                        News
              </NavLink>
                </li>
                <li className="nav-item dropdown list-group-item">
                    <NavLink className="nav-link" exact to="/upcomingEventsPage">
                        Upcoming Events
              </NavLink>
                </li>

                <li className="nav-item dropdown list-group-item">
                    <NavLink className="nav-link" exact to="/noticePage">
                        Notice
              </NavLink>
                </li>



            </ul>
        </div>
    );
};

export default NewsAndUpdatesSideBar;