import React from "react";
import { NavLink } from "react-router-dom";
import SocialIcon from "../component/SocialIcon";
import moment from "moment/moment";

const Footer = () => {
  return (
    <div>
      <footer id="footer">
        <div className="site-footer">
          <div className="footerFlex">
            <div className="footer-into" style={{ textAlign: "left" }}>
              <h1>Eastern University</h1>
              <p>
                <i className="fas fa-map-marker-alt"></i>
                Road 6, Block B, Ashulia Model Town<br></br>
                <p style={{ margin: "0 0 0 2px" }}>
                  Khagan, Birulia, Savar, Dhaka,Bangladesh.
                </p>
                <i className="fas fa-mobile-alt"></i> +8809602666651,
                +8809602666652<br></br>
                {/* <i className="fas fa-fax"></i>+8802967 5981<br></br> */}
                <i className="far fa-envelope"></i>info@easternuni.edu.bd,
                <br></br>
                ro@easternuni.edu.bd
                <br></br>
                For Admission : +8801844169651, +8801844169659, <br></br>
                +8801844169660, +8801741300002
                <br></br>
                Email: admission@easternuni.edu.bd
              </p>
            </div>
            <div className="footer-links">
              <h6>About EU</h6>
              <ul className="footer-links">
                <li>
                  <NavLink exact to="/aboutEU">
                    EU Profile
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/boardOfTrustees">
                    Board of Trustees
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/messageFromChairman">
                    Message from the Chairman
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/messageFromVC">
                    Message from the Vice-Chancellor
                  </NavLink>
                </li>
                {/*<li><NavLink exact to='/'>International Advisors</NavLink></li>*/}

                <li>
                  <NavLink exact to="/internationalAcademicCollaboration">
                    International Academic Collaboration
                  </NavLink>
                </li>
                {/*<li><NavLink exact to=''>Campus Map</NavLink></li>*/}

                <li>
                  <NavLink exact to="/contactDirectories">
                    Contact Us
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="footer-links">
              <h6>Admission</h6>
              <ul className="footer-links">
                <li>
                  <NavLink exact to="/admissionInformation">
                    Overview
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/whyEasternUni">
                    Why Study at EU
                  </NavLink>
                </li>
                {/*<li><NavLink exact to=''>Undergraduate Admission</NavLink>
                                </li>
                                <li><NavLink exact to=''>Graduate Admission</NavLink></li> */}
                <li>
                  <NavLink exact to="/tuitionFees">
                    Tuition and Fees
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/financialAidAndScholarship">
                    Financial Aid & Scholarship
                  </NavLink>
                </li>
              </ul>
            </div>
            <div className="footer-links">
              <h6>Academics</h6>
              <ul className="footer-links">
                <li>
                  <NavLink exact to="/academicRulesAndProcedures">
                    Academic Rules & Procedures
                  </NavLink>
                </li>
                {/*<li><NavLink exact to='/'>Registration Procedures</NavLink>
                                </li>*/}
                <li>
                  <NavLink exact to="/rulesOfClassAttendance">
                    Rules of Class Attendance
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/graduateAdmissionEligibility">
                    Graduate Admission
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/testsAndExams">
                    Tests and Exams
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/academicStandingOfAStudent">
                    Academic Standing of a Student
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/degreeRequirments">
                    Degree Requirements
                  </NavLink>
                </li>
                {/*<li><NavLink exact to=''>Undergraduate Programs</NavLink>
                                </li>
                                <li><NavLink exact to=''>Graduate Programs</NavLink></li>*/}
              </ul>
            </div>
            <div className="footer-links">
              <h6>Faculties</h6>
              <ul className="footer-links">
                <li>
                  <NavLink exact to="/facultyOfArts">
                    Faculty of Arts
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/facultyOfBusinessAdministration">
                    Faculty of Business Administration
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/facultyOfEngineering">
                    Faculty of Engineering and Technology
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/facultyOfLaw">
                    Faculty of Law
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/facultyOfLifeScience">
                    Faculty of Life Science
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>

          <hr />
          <div className="container">
            <div className="row">
              <div className="col-md-8 col-sm-6 col-xs-12">
                <p className="copyright-text">
                  Copyright &copy; {moment().format("YYYY")} All Rights Reserved
                  by
                  <NavLink exact to="/office/14">
                    {" "}
                    EUIT
                  </NavLink>
                  .
                </p>
                <NavLink className="pr-4" exact to="/privacypolicy">
                  Privacy Policy
                </NavLink>
                <NavLink exact to="/useofterms">
                  Use of Terms
                </NavLink>
              </div>

              <div className="col-md-4 col-sm-6 col-xs-12">
                <SocialIcon />
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
