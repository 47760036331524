import React from "react";

import Wrapper from "../../component/Wrapper";
import OtherpageSideBar from "../component/OtherpageSideBar";
import { NavLink } from "react-router-dom/cjs/react-router-dom";

function AboutEU() {
  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr></hr>
          <p className="heading2 text-center primaryHeading">
            Eastern University Profile
          </p>
          <hr></hr>
          <div className="history">
            <p className="heading4 primaryHeading">
              History, Vision and Mission
            </p>

            <p className="allText">
              Committed to an ideal of “quality higher education at an
              affordable cost”, a group of educational philanthropists
              comprising academics, industrialists, engineers and retired civil
              servants established Eastern University in 2003 under the Private
              University Act 1992, and later on approved under Private
              University Act of 2010. The university was set up by Eastern
              University Foundation - a non-profit, non-political and
              philanthropic organization. The Foundation has 30 members. The
              governance of Eastern University is carried out as per the Private
              Universities Act of 2010 by several bodies: Board of Trustees,
              Syndicate, Academic Council, Curriculum Committee, Finance
              Committee, Teacher Selection Committee and Disciplinary Committee.
            </p>
            <p className="allText">
              Eastern University is widely known for its quality education,
              superior faculty composition, excellent academic environment,
              sincere care for students, extensive co and extra- curricular
              activities, successful internship and job placement, modern
              digital library, good governance and administration and convenient
              location of the campus.
            </p>
            <p className="allText">
              The Ministry of Education has given Eastern University a
              certificate of permanent establishment and management on 22 March
              2023 in accordance with Section 10 of the Private University Act
              2010 after evaluating the overall issues including the education
              environment, quality standards, all the performance related to
              education and meeting all the conditions of the private
              university. More than 13,000 students have graduated from the
              university by 2023.
            </p>
            <p className="allText">
              The vision of Eastern University is to be a globally recognized
              university in its chosen fields of higher education and research
              in terms of quality. The mission of the University is to equip the
              students with knowledge and skills essential for leadership in
              private and public sector in the increasingly competitive and
              globalized environment.
            </p>

            <p className="heading4 primaryHeading">
              Governance and Administration
            </p>

            <p className="allText">
              His Excellency the President of Bangladesh is the Chancellor of
              Eastern University as per the Private University Act of 2010. The
              governance of the University is vested with a 21 member Board of
              Trustees (BOT)comprising 20 members drawn from the Eastern
              University Foundation and the Vice Chancellor as an ex-officio
              member. The Board is chaired by the Chairman of the Board elected
              by the Foundation. The board is assisted by the Syndicate,
              Academic Council, Finance Committee, Faculty Selection Committee,
              Staff Selection Committee and Institutional Quality Assurance
              Committee. The administration consists of the Vice Chancellor,
              Pro-Vice Chancellor, Treasurer, Registrar and Controller of
              Examinations.
            </p>

            <p className="heading4 primaryHeading">
              Curricula, Students and Faculty
            </p>

            <p className="allText">
              The OBE (Outcome Based Education) curricula of the academic
              programs combine and yet balance the broad-based and the in-depth
              learning, and emphasize “knowing, doing and being” of the
              students. The curricula are of international standard and are
              upgraded and updated regularly to make it relevant to the
              advancement of the discipline and the future job and professional
              requirements of the students. The teaching-learning methods used
              are participative, interactive, experiential, learner-centered and
              hands-on practice depending upon the nature of the course being
              taught.
            </p>
            <p className="allText">
              The students are carefully selected for admission on the basis of
              their excellent academic record, high aptitude and strong
              motivation to learn. They are transformed into knowledgeable,
              skilled and globally oriented human resources by the caring and
              committed faculty members of the University. They are groomed to
              be leaders, value-driven and socially responsive citizens through
              a variety of co-curricular and extra- curricular programs. The
              university has at present international students from SAARC
              countries and Africa. The university has plans to attract students
              from other countries also.
            </p>
            <p className="allText">
              Eastern University hires top-of-the className, talented graduates
              of highly reputed universities from home and abroad as junior
              faculty, experienced teachers and researchers as senior faculty
              andwell known academics aspart time faculty. In addition, the
              University appoints accomplished professionals and practitioners
              as adjunct faculty and invites university teachers from abroad as
              Visiting Faculty. The faculty members are chosen on the basis of
              their scholarly achievement, knack for research and commitment in
              teaching. The Faculty Development Program of the University trains
              junior faculty in pedagogy, research methods and in specific
              disciplines they teach.
            </p>
            <p className="allText">
              Eastern University hires talented graduates of highly reputed
              universities from home and abroad as junior faculty, experienced
              teachers and researchers as senior faculty and well-known
              academics as part-time faculty. In addition, the university
              appoints accomplished professionals and practitioners as adjunct
              faculty and invites university teachers from abroad as Visiting
              Faculty. The faculty members are chosen on the basis of their
              scholarly achievement, contribution on research and commitment in
              teaching. The Faculty Development Program of the university trains
              junior faculty in pedagogy, research methods and in specific
              disciplines they teach. The senior faculty members also
              participate in the program to disseminate their knowledge among
              the junior colleagues and hone their skills from time to time.{" "}
            </p>
            <p className="heading4 primaryHeading">Faculties and Programs</p>
            <p className="allText">
              The university has at present five faculties: Faculty of Arts,
              Faculty of Business Administration, Faculty of Engineering and
              Technology, Faculty of Law and Faculty of Life Science. Faculty of
              Social Science will commence its journey soon.
            </p>

            <p className="heading5 primaryHeading">
              <NavLink exact to="/facultyOfArts">
                Faculty of Arts
              </NavLink>
            </p>

            <p className="allText">
              The Faculty of Arts offers a four-year B.A. (Hons.) in English and
              a one-year M.A. in English Literature and a one-year M.A. in
              English Language Teaching (ELT). The programs follow a need-based
              multi syllabus approach combining well-balanced language and
              literature courses in order to cater to the job needs of its
              graduates. A well-earned degree in English opens innumerable job
              opportunities at home and abroad. The Faculty is well-known for
              the mixed batch of experienced and young teachers who not only
              have passion for teaching but also have skill to bring out the
              talents from the young minds. Thus, the environment of the Faculty
              is ideal for grooming and shaping up the future leaders of the
              4IR.
            </p>

            <p className="heading5 primaryHeading">
              <NavLink exact to="/facultyOfBusinessAdministration">
                Faculty of Business Administration
              </NavLink>
            </p>

            <p className="allText">
              The Faculty of Business Administration (FBA) delivers innovative
              and world class leadership education in business. Its highly
              qualified faculty members with extensive research experience and
              international exposure provide tailor-made support to enable
              learning and development of the student groups to become
              professional managers as well as good human beings.
            </p>
            <p className="allText">
              FBA offers a four-year Bachelor of Business Administration (BBA),
              a four-year Bachelor of Business Administration (BBA) in Islamic
              Finance, Banking and Insurance, a two-year Master of Business
              Administration (MBA), a one year Executive Master of Business
              Administration (EMBA) and a one-year Master of Business
              Administration (MBA) in Islamic Finance, Banking and Insurance.
              Graduates of FBA are readily employed and are occupying leading
              positions in major domestic and international companies providing
              excellent leadership as well as value addition to the society.
              There are also a good number of graduates who have become
              successful entrepreneurs and have contributed in the economy of
              the country and also in the job market as employers.
            </p>

            <p className="heading5 primaryHeading">
              <NavLink exact to="/facultyOfEngineering">
                Faculty of Engineering & Technology
              </NavLink>
            </p>

            <p className="allText">
              The Faculty of Engineering & Technology (E&T) offers four-year
              B.Sc. Programs in Electrical & Electronic Engineering (EEE), Civil
              Engineering (CE) and Computer Science & Engineering (CSE).
            </p>
            <p className="allText">
              The Faculty attracts talented and outstanding faculty members with
              foreign degrees to nurture young students in various fields of
              Engineering and Technology. Its laboratories are furnished with
              state-of-the-art equipment and software programs and provides an
              excellent learning environment. The Faculty is committed to
              ensuring quality education with a team of talented, dedicated and
              cordial faculty members as mentors of the students.
            </p>

            <p className="heading5 primaryHeading">
              <NavLink exact to="/facultyOfLaw">
                Faculty of Law
              </NavLink>
            </p>
            <p className="allText">
              The Faculty of Law of Eastern University is one of the premier law
              schools of the country. It has always attracted the best legal
              minds academically and ideologically, thereby creating an
              inspirational center of exchanging ideas. It is a public-spirited
              faculty that makes distinctive contributions to society in
              learning, teaching and engagement. EU’s law curricula reflect the
              changing requirement of the legal profession. Students are given
              extensive training in various forms of Legal Drafting and Trial
              Advocacy in the Moot Court Room. The Faculty offers a four-year
              LL.B (Hons.) and a one year LL.M Program.
            </p>
            <p className="heading5 primaryHeading">
              <NavLink exact to="/facultyOfLifeScience">
                Faculty of Life Science
              </NavLink>
            </p>
            {/* <p className="allText">
              Welcome to the Faculty of Life Sciences at Eastern University.
              Eastern University offers high- quality undergraduate and graduate
              education as well as cutting-edge research. We believe in
              producing competent graduates capable of achieving sustainable
              development for individuals, communities, and the world.
            </p> */}
            <p className="allText">
              {/* Currently, the Pharmacy department at the Faculty of Life Science
              offers a B. Pharm program. The Department of Pharmacy integrates
              the scientific, clinical, and professional aspects of relevant
              pharmaceutical disciplines to provide a comprehensive introduction
              to pharmaceutical knowledge. Our faculty members are highly
              qualified, holding Masters or PhD degrees from internationally
              renowned universities. All faculty members have cutting-edge
              teaching tools and extensive research experience to ensure that
              our students receive the best education possible. Modern
              laboratories are available at the Faculty of Life Sciences for
              conducting basic and translational research in a variety of areas
              related to the life sciences, including microbiology, physiology,
              pharmaceutical technology, and biopharmaceutics, as well as an
              animal house. By combining curricular, co-curricular, and
              extra-curricular activities, the Faculty of Life Science
              guarantees a vibrant life. Besides that, a calm environment is
              essential for proper student grooming. Therefore, we have a
              beautiful green campus in Ashulia Model Town, Savar, Dhaka. You
              are cordially invited to come and explore our campus. */}
              Faculty of Life Sciences starts its journey in 2023 with the
              four-year Bachelor of Pharmacy (Hons.) program that has received
              approval from the UGC and from the Pharmacy Council. Pharmacy is a
              multidisciplinary subject and this program has been designed to
              provide students with a comprehensive understanding of the
              principles and techniques of pharmaceutical sciences. The
              objective of the program is to equip candidates with the knowledge
              required for the identification, formulation, preparation,
              standardization, quality control, and utilization of drugs and
              medicines. Faculty members with extensive research exposure and
              outstanding teaching experience along with the modern laboratories
              for conducting basic and translational research in a variety of
              areas related to the life sciences, including microbiology,
              physiology, pharmaceutical technology, and biopharmaceutics are
              available. Additionally, it aims to promote research in various
              fields of pharmacy to develop improved techniques for production,
              quality control, and standardization of drugs and medicines.
            </p>

            <p className="heading5 primaryHeading">
              <NavLink exact to="/internationalAcademicCollaboration">
                International Collaboration
              </NavLink>
            </p>
            <p className="allText">
              Eastern University has academic partnership with over 20
              universities in UK, USA, Australia, New Zealand, Finland, Malaysia
              and few other countries. Eastern University students can pursue
              higher studies by credit transfer to these and other universities
              abroad. The Faculty of Business Administration (FBA) has credit
              transfer arrangements for undergraduate program with several
              universities in UK, USA, Malaysia and Australia for 2 years study
              at EU and 2 years study in the selected foreign university earning
              degree from the foreign university and even earning double degree.
              Credit transfer facility also exists for Master’s program.
            </p>

            <p className="heading5 primaryHeading">
              Opportunity for Study Abroad
            </p>
            <p className="allText">
              Because of international standard curricula, the courses, credits
              and degrees of Eastern University are readily accepted by
              universities in the USA, Europe, Australia and other countries and
              many EU students are studying abroad in Master’s and PhD Programs
              with the degrees conferred by Eastern University accepted by the
              foreign universities.
            </p>

            <p className="heading5 primaryHeading">Centres and Cells</p>
            <p className="allText">
              The University has four specialized centers: Center for Modern
              Languages, Center for Research and Development, Center for
              Innovation and Incubation, Center for Faculty and Staff
              Development, and two cells: General Education (GED) Cell and
              Institutional Quality Assurance Cell (IQAC).
            </p>
            <p className="allText">
              1. The Center for Modern Languages at present offers certificate
              and short courses on Chinese Language. The instructor is a
              full-time faculty member who went to Yunnan Normal University in
              China with scholarship as a part of an exchange program with
              Eastern University. She has completed a one-and-a-half-year
              diploma course on Chinese Language and Culture there and is now
              teaching the courses at EU. The launching of a certificate course
              on Japanese Language is in process. Few other professional courses
              on various modern languages will be launched soon.
            </p>
            <p className="allText">
              2. The Center for Research and Development (CRD) allocates
              research funds, procures and monitors research projects and
              consultancy projects, and organizes research seminars and training
              on research methodology. It publishes a bi-annual research
              journal, namely, The Eastern University Journal.
            </p>
            <p className="allText">
              3. The Center for Innovation and Incubation encourages students
              and faculty to come out with innovative ideas of products, designs
              or process, experiment and test them in the center and continue
              development of the potential ones to the final stage and
              commercialization, if feasible.
            </p>
            <p className="allText">
              4. The Center for Faculty and Staff Development offers courses,
              workshops, seminars, and conference for continuous skill
              development of faculty members in teaching-learning and of
              officers and executives in administrative tasks. The Center also
              conducts research for innovation and improvement in
              teaching-learning and administrative process.
            </p>
            <p className="allText">
              The GED cell aims at providing liberal arts education to all
              undergraduate students of the university. It offers, organizes and
              coordinates general education courses in the area of language,
              arts, humanities, science and social science. IQAC has been set up
              as per provision of article 36 of the Private University Act 2010.
              Since quality enhancement is a continuous process, the prime task
              of the IQAC is to develop a system for consistent improvement in
              the performance of the university towards academic excellence.
            </p>

            <p className="heading4 primaryHeading">
              Campus, Facilities and Services
            </p>

            <p className="heading5 primaryHeading">Campus</p>
            <p className="allText">
              Eastern University has already established its campus at Ashulia
              Model Town, Khagan, Akran, Ashulia, Dhaka. The campus is located
              in a calm and quiet place amidst the luscious greenery of
              countryside. The spacious classrooms, well equipped labs, a
              seminar hall and a conference room provide necessary support to
              the teaching-learning process of the students and teachers. The
              campus is built on 20 bighas of land approved by RAJUK on 13
              October 2015. The 9-storeyed state-of-the-art academic building
              has a total of 1, 48, 500 square feet space. The building consists
              of a library, seminar hall, class rooms, engineering labs and a
              conference room with all the modern amenities. Along with all
              these, there is a prayer hall, guest room, teachers’ lounge,
              cafeteria, indoor games space and a fascinating multi-purpose
              playground. The complete campus project includes spacious football
              and cricket field, a large auditorium, a gymnasium, a bigger
              library, residential facilities for the faculty members,
              officials, and separate dormitories for male and female students
              on a sprawling 20 bighas of land with natural sights and scenes.
            </p>
            <p className="heading5 primaryHeading">
              Accommodation for Students
            </p>
            <p className="allText">
              Accommodation facility for male and female students is an
              indispensable part of any university. The university has the
              arrangement of a female hostel and two male hostels located near
              the campus. Equipped with all the modern amenities, hostels are
              available for all the students who take admission in the
              university. The security of the students is our first priority.
              One security guard for each of the male and female hostels are on
              constant duty for 24 hours. The hostels are under CCTV
              observation. There are also fire-fighting equipment to prevent any
              fire hazard. 24-hour internet facility is provided on every floor.
              The students can avail their food according to their requirements.
            </p>
            <p className="heading5 primaryHeading">Library</p>
            <p className="allText">
              The digital library of the university has more than 15000 books,
              over 100 national and international research journals, 700 audio
              visual materials, 25000 online journals and many eBooks through
              41databases. The library services can be accessed any time of the
              day and night over internet from anywhere.
            </p>
            <p className="heading5 primaryHeading">
              <NavLink exact to="/admissionOffice">
                Admission Office
              </NavLink>
            </p>
            <p className="allText">
              The University is committed to preparing its students as
              professionals by exploring and promoting their creativity,
              infusing knowledge, developing their skills and forming proactive
              attitude. Admission office plays an important role in fulfilling
              this goal by recruiting and selecting the right kind of students.
              The trained counselors of the office provide guidance to the
              prospective students in selecting the right academic program,
              financial advice and accommodation and living tips. For the
              convenience of the prospective students, the Admission Office
              remains open on weekends and holidays.
            </p>

            <p className="heading5 primaryHeading">Clubs and Forums</p>
            <p className="allText">
              Eastern University (EU) nurtures 23 student clubs vital for 4IR
              skills. These platforms guide students through 4IR challenges,
              building essential capabilities, leadership, and loyalty.
              Engagement in clubs deepens education loyalty, extending beyond
              classrooms. Teamwork and hands-on experiences prepare students for
              modern challenges. The Office of Students Affairs (OSA) organizes
              co-curricular and extra-curricular activities of the students
              through clubs and forums. By participating in these clubs and
              forums, students can enhance their interpersonal, social and
              leadership skills. All EU students are encouraged to enroll in at
              least one club/ forum of her/his choice. ‘Seeds of Leadership’ , a
              ten-day intensive and exclusive workshop for undergraduate
              students of Eastern University, is designed to be a transformative
              personal experience to generate innovative thinking and develop
              leadership skills.
            </p>

            <p className="heading5 primaryHeading">
              Internship and Job placement
            </p>
            <p className="allText">
              Eastern University (EU) fosters robust partnerships between
              academia and industry, offering a wide array of internship
              opportunities to eligible students. These opportunities are taken
              care of by dedicated faculty members, with support from the Career
              Services and International Office (CSIO). It is worthy of mention
              that currently EU students have the chance to pursue internships
              in Japan as an integral component of their academic journey. CSIO
              plays a pivotal role in coordinating on-campus recruitment efforts
              and disseminating vacancy announcements via official notice boards
              and online platforms. Moreover, the office extends its assistance
              in internship and job preparedness, ensuring a seamless process
              for all involved. Through extensive counseling, the CSIO guides
              students that addresses students’ immediate career needs, draws
              the timeline to focus on their goal, helps them to design the
              career strategies, establishes the bridge among students, alumni,
              potential employers/ universities for jobs and higher education.
              It provides entry level career guidelines and aggressively
              facilitate their employment. Recently, in pursuance of better
              opportunity for the students, the university has signed a MoU with
              Othello Financial Service Co. Ltd. of Japan under which the EU
              students will be able to do paid internships in Japan. They are
              also offering free Japanese Language course for the students and
              staff of the university.
            </p>
            <p className="allText">
              The University has academic collaboration with over 20 renowned
              universities and institutions in USA, UK, Finland, Australia, New
              Zealand, China, Malaysia and Thailand for credit transfer of
              students with financial support, student exchange, faculty
              exchange, research collaboration and assistance in setting up new
              programs.The CSIO provides information and counseling for students
              who are interested in pursuing higher studies in these or other
              universities abroad, facilitate their placement in the
              universities, monitors their progress and problems and help them
              with suggestions. The Office organizes study tour of the EU and
              foreign students and facilitates visit of the EU and foreign
              officials and recruitment of visiting faculty.
            </p>

            <p className="heading5 primaryHeading">
              Medical Service and Psycho-social Counseling
            </p>
            <p className="allText">
              The Medical Service offers general and emergency medical care to
              students, faculty and staff free of cost. Under an agreement with
              Delta Medical College and Hospital, EU members get 30% discount on
              pathological tests and related services. The Chief Medical Officer
              serves as a consultant, monitors hygiene aspects in the campus,
              provides health education of the EU members and oversees
              maintenance of medical records.
            </p>
            <p className="allText">
              The Psycho-social Counselor offers counseling services to students
              to cope with stress and anxiety, right decision-making, managing
              relationships and social problems, developing assertiveness,
              coping with sexual harassment etc. She monitors mental health of
              students and staff and educates them for maintaining good mental
              health. The psycho-social counselor works closely with the
              Committee on Prevention of Sexual Harassment investigates
              thoroughly into the complaints on sexual harassments of any type
              and recommends punitive measures to be taken against the
              offender(s) to the Disciplinary Committee.
            </p>

            <p className="heading5 primaryHeading">
              Fees and Financial Assistance
            </p>
            <p className="allText">
              In pursuance of the commitment of the Founders of the University
              to provide “higher education at affordable cost,” and to open
              opportunities of education to meritorious students of limited
              income, the tuition and other fees have been set at a reasonable
              level. In addition, several types of financial assistance,
              scholarships and a Student Loan scheme have been provided for. In
              order to know the details of the fees and financial assistance
              visit our website. (www.easternuni.edu.bd)
            </p>
          </div>
        </div>
        <OtherpageSideBar />
      </div>
    </Wrapper>
  );
}

export default AboutEU;
