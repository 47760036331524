import React, { Component } from 'react';
import GovernanceContent from './content/GovernanceContent';

import Footer from '../../component/Footer';

class governance extends Component {
    render() {
        return (
            <div>
                <GovernanceContent/>
                <Footer/>
            </div>
        );
    }
}

export default governance;