import { Link } from "react-router-dom/cjs/react-router-dom.min";

import useJournal from "../../features/useJournal";
import Spinner from "../../UI/Spinner";

import moment from "moment";
import styled from "styled-components";
import JournalLayout from "./JournalLayout";

const Heading = styled.h3`
  font-size: 1.8rem;
  font-family: "Roboto";
  text-transform: capitalize;
  margin: 3rem 0 0.5rem 0;
`;

const Label = styled.p`
  text-decoration: none;
  font-size: 1.8rem;
  text-transform: capitalize;
`;

function Issues() {
  const { journals, isLoading } = useJournal();
  if (isLoading) return <Spinner />;
  return (
    <JournalLayout>
      <div className="journals">
        {journals.map((item) => (
          <IssuesList key={item.id} volume={item} />
        ))}
      </div>
    </JournalLayout>
  );
}

export default Issues;

function IssuesList({ volume }) {
  return (
    <div className="issues">
      <Heading>{volume.volume}</Heading>
      {volume.issues.map((item) => (
        <>
          <Link to={`/issue/${item.id}`}>
            <Label>{item.issue}</Label>
          </Link>
          <p className="alltext">
            Publication date:{" "}
            {moment(item.publication_date).format("MMM Do YYYY")}
          </p>
        </>
      ))}
    </div>
  );
}
