import React from "react";

import moment from "moment/moment";
import { NavLink } from "react-router-dom";

import LeftSideBar from "../component/NewsAndUpdatesSideBar";
import Footer from "../../component/Footer";
import useUpcomingEvents from "../../features/useUpcomingEvents";

import MessageBox from "../../component/MessageBox";
import Spinner from "../../UI/Spinner";

function UpcomingEvents() {
  const { events, isLoading, error } = useUpcomingEvents();
  if (isLoading) return <Spinner />;

  if (error) return <MessageBox>{error.message}</MessageBox>;
  const ErrorMessage = <p className="heading4">There is no Upcoming Events</p>;

  const EventsList = events.map((mylist) => {
    return (
      <div className="card-body" key={mylist.id}>
        <div className="events-date">
          <p className="date">{moment(mylist.ToDate).day()}</p>
          <p className="month">{moment(mylist.ToDate).format("MMM")}</p>
        </div>
        <div className="events-description text-left">
          <p className="card-title heading5">
            <NavLink exact to={"/upcomingEvents/" + mylist.id}>
              {mylist.Headline}
            </NavLink>
          </p>
          <p className="allText">
            {`${mylist.details.substring(0, 60)}... `}
            <NavLink exact to={"/upcomingEvents/" + mylist.id}>
              {" "}
              More Info
            </NavLink>{" "}
          </p>
        </div>
      </div>
    );
  });
  return (
    <>
      <div className="content">
        <div className="OtherPagecontent">
          <hr></hr>
          <p className="heading2 text-center primaryHeading">Upcoming Events</p>
          <hr></hr>
          <div className="Upcoming_Events">
            <div className="card">
              {EventsList.length <= 0 ? ErrorMessage : EventsList}
            </div>
          </div>
        </div>
        <LeftSideBar />
      </div>
      <Footer />
    </>
  );
}

export default UpcomingEvents;
