import React, { useState } from "react";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import ReactHtmlParser from "react-html-parser";

const ClubInfo = ({ name, details, objectives, activities }) => {
  const [showInfo, setShowInfo] = useState(false);
  return (
    <article className="clubInfo">
      <header>
        <p className="heading5">{name}</p>
        <button className="btn" onClick={() => setShowInfo(!showInfo)}>
          {showInfo ? (
            <AiOutlineMinus className="clubIcon" />
          ) : (
            <AiOutlinePlus className="clubIcon" />
          )}
        </button>
      </header>
      {showInfo && <p className="allText">{ReactHtmlParser(details)}</p>}
      {showInfo && <p className="heading6">Objectives:</p>}
      {showInfo && <p className="allText">{ReactHtmlParser(objectives)}</p>}
      {showInfo && <p className="heading6">Activities:</p>}
      {showInfo && <p className="allText">{ReactHtmlParser(activities)}</p>}
    </article>
  );
};

export default ClubInfo;
