import React from "react";
import { NavLink } from "react-router-dom";
import { useFetch } from "../../hooks/useFetch";
import LoadingBox from "../../component/LoadingBox";

function ContactSideBar() {
  const { data: officeList, loading } = useFetch("employee/office");

  if (loading) return <LoadingBox />;

  return (
    <div className="OtherpageSideBar">
      <ul className="list-group list-group-flush">
        {officeList
          .filter((items) => items.isactive)
          .map((office) => (
            <OfficeLink office={office} key={office.id} />
          ))}
      </ul>
    </div>
  );
}

export default ContactSideBar;

function OfficeLink({ office }) {
  return (
    <li className="nav-item list-group-item">
      <NavLink className="nav-link" exact to={"/office/" + office.id}>
        {office.name}
      </NavLink>
    </li>
  );
}
