import React, { useState } from 'react';
import LeftSideNavigationCSE from '../../component/leftSideNavigationCSE';
import FacultyName from '../../component/facultyName';
import Footer from '../../../../component/Footer';
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const LabFacilitiesCSE = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <div>
            <FacultyName name='Department of Computer Science and Engineering' />
            <div className='facultyContent' id='deanArts'>
                <LeftSideNavigationCSE />
                <div className='rightSideContent mt-4'>
                    <p className='heading3 text-center'>Lab Facilities</p>
                    <div class="">
                        <p className='heading5'>Importance of Labs in the Faculty of Engineering and Technology at 
                            Eastern University (EU)
                        </p>
                        <p className='allText'>
                            The Laboratories of the Faculty of Engineering and Technology at Eastern University 
                            (EU) are designed with modern equipment and instruments to foster distinction in 
                            teaching, learning and research. EU laboratories play a central role in supporting 
                            faculty members and students to develop practical experiences for higher education or 
                            for careers in the industry. The university is committed to developing and maintaining 
                            a state-of-the-art lab. EU has created one of the best lab facilities in the country 
                            for undergraduate students of Computer Science and Engineering (CSE) and 
                            Electrical & Electronic Engineering (EEE). All the laboratories of the EU are 
                            well spacious and comply with safety where good laboratory practice is strictly 
                            maintained. The laboratory information of the Faculty of Engineering and 
                            Technology shared by two departments-CSE and EEE, is described in the following section.
                        </p>
                        <p className='heading5'>
                            Software Engineering Lab
                        </p>
                        <p className='allText'>
                        The Software Engineering Lab is equipped with HP Brand desktop computers and a 
                        multimedia projector. Software Engineering Lab is too much rich laboratory in the 
                        faculty of E&T. All the lab computers are networked and use CISCO switches. The 
                        lab provides a range of software to support lab classes and research works in the 
                        broad field of Software Systems, including programming, database systems, 
                        information system design, software engineering, computer networking, and 
                        high-performance simulation and modeling.
                        </p>
                        <p className='heading5'>
                            Operating System Lab
                        </p>
                        <p className='allText'>
                            The Operating System Lab is equipped with HP and Dell desktop computers and a 
                            multimedia projector. All the computers of the lab are networked and use CISCO 
                            switches where Windows and Linux run together on all computers. 
                        </p>
                        <p className='heading5'>
                            Database Management System Lab
                        </p>
                        <p className='allText'>
                            The Database Management System Lab is equipped with Clone and HP Brand desktop 
                            computers and a multimedia projector. All the computers of the lab are networked 
                            and use CISCO switches. DBMS Labs use various software such as SQL, Oracle 11G, 
                            and MS Access. 
                        </p>
                        <p className='heading5'>
                            Computer Network Lab 
                        </p>
                        <p className='allText'>
                            The Computer Network Lab is equipped with Clone and HP Brand desktop computers 
                            and a multimedia projector. The Labs Objective is to familiarize students with 
                            many of the standard networking tools, devices, software used to build modern 
                            network infrastructures.  
                        </p>
                        <p className='heading5'>
                            Computer Practice Lab
                        </p>
                        <p className='allText'>
                            The Computer Practice Lab is equipped with Dell desktop computers. All the 
                            computers of the lab are networked and use CISCO switches. The students 
                            practice in this lab all time.
                        </p>
                    </div>
                    {/* Mobile Responsive Start*/}
                    <div
                        id="mobileMenuButton"
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                    >
                        <button>
                            <VscAdd className="moreMenu" />
                        </button>
                    </div>
                    {mobileMenuOpen && (
                        <div id="mobileMenuContent">
                            <button
                                id="closeButton"
                                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                            >
                                <VscChromeClose className="closeButtonIcon" />
                            </button>
                            <LeftSideNavigationCSE />
                        </div>
                    )}
                    {/* Mobile Responsive End*/}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default LabFacilitiesCSE;