import React from 'react';
import { NavLink } from 'react-bootstrap';
import CSIOSidebar from '../../../component/CSIOSidebar';

const InternationalOfficeContent = () => {
    return (
        <div className='content'>
            <div className='OtherPagecontent'>
                <hr />
                <p className='heading2 text-center'>International Office</p>
                <hr />
                <p className='heading4'>
                    International Relations, The top priorities of EU.
                </p>
                <p className='heading6'>
                    Welcome to the International Office of Eastern University, Bangladesh!
                </p>
                <p className='allText'>
                    The EU International Office is a faculty independent general office for students. Whether you are an international student interested in applying to EU, a current student searching for information on study abroad opportunities or an overseas institution looking to work with EU, we are here for you. Eastern University has developed academic collaboration with a number of renowned foreign universities and institutions for students and faculty exchange, research collaboration and assistance in setting up new programs.
                </p>
                <p className='heading6'>
                    International office of Eastern University provides the following support to the students:
                </p>
                <p className='allText'>
                    <ul>
                        <li className='allText'>
                            Advice and support EU students for higher education with the partner Universities
                        </li>
                        <li className='allText'>
                            Information on study- and research possibilities abroad
                        </li>
                        <li className='allText'>
                            Maintain Global Connections
                        </li>
                        <li className='allText'>
                            Visa assistance, finding accommodation and formalities for students
                        </li>
                        <li className='allText'>
                            Advice and information on aspects of internationalization
                        </li>
                        <li className='allText'>
                            Admission of incoming exchange students
                        </li>
                        <li className='allText'>
                            Information on partner universities and organizations abroad
                        </li>
                        <li className='allText'>Scholarship information</li>
                        <li className='allText'>
                            Organizing International Events and Fair
                        </li>
                    </ul>
                </p>
                <p className='heading6'>
                    Highlights of Partner Universities/Institutions:
                </p>
                <p className='allText'>
                    <ul>
                        <li className='allText'>
                            <p className='heading6'>
                                <a href='http://www.worcester.ac.uk/'>
                                    University of Worcester. UK
                                </a>
                            </p>
                            Eastern University has signed an agreement with University of Worcester UK under which EU students of BBA program with 60 credits or more with a minimum GPA of 3.0 can transfer credits and can earn a UK degree.
                        </li>
                        <li className='allText'>
                            <p className='heading6'>
                                <a href='http://english.ynnu.edu.cn/'>
                                    Yunnan Normal University, China (YNNU)
                                </a>
                            </p>
                            YNNU and EU has signed a collaboration agreements to establish a Chinese Language Center, Faculty exchange program and student scholarship program.
                        </li>
                        <li className='allText'>
                            <p className='heading6'>
                                <a href='http://en.ynufe.edu.cn/'>
                                    Yunnan University of Finance and Economics, China
                                </a>
                            </p>
                            Yunnan University of Finance and Economics China will provide a full scholarship to an Eastern University student each year.
                        </li>
                        <li className='allText'>
                            <p className='heading6'>
                                <a href='https://www.ait.ac.th/'>
                                    Asian Institute of Technology (AIT), Thailand
                                </a>
                            </p>
                            AIT and Eastern University are working on a collaboration agreement, under which EU students of Engineering program with 60 credits or more with a minimum GPA of 3.0 will be able to transfer credits and earn a degree from AIT. After completion of 90 credits with a minimum GPA 3.0 students will be able to go to AIT to study for 2 years and will get both Bachelor and Master degree from AIT.
                        </li>
                        <li className='allText'>
                            <p className='heading6'>
                                <a href='http://gjjl.ynvtc.cn/gjjlc_en/'>
                                    Yunnan Open University, China
                                </a>
                            </p>
                            Eastern University (EU) is collaborating with Yunnan Open University for setting up a Centre for China Studies at EU which will organize seminars, conferences and short courses on Chinese economy, business, culture and literature for interested persons in general and credit courses for degree program students. The MoU also has provision for student and faculty exchange, joint research and publications exchange.
                        </li>
                    </ul>
                </p>
                <p className='heading4'>
                    International Academic Collaboration:
                </p>
                <p className='allText'>
                    The International Office of Eastern University has collaborations with more than 20 internationally reputed institutions spanning around the globe including those in the US, UK, Europe, Southeast Asia, and Australasia. More than 24 international scholarships are available for students of Eastern University every year. The Yunnan Open University of China offers study tour and exchange program to the students of Eastern University. More than 50 students have visited and experienced the Chinese Language and Culture in the last three years.
                </p>
                <p className='allText'>
                    In addition to the above EU has academic exchanges and collaborations with the following institute:
                </p>
                <p className='allText'>
                    <ul>
                        <li className='allText'>
                            <a href='http://www.worcester.ac.uk/'> University of Worcester</a>
                        </li>
                        <li className='allText'>
                            <a href='http://www.londonpremiercollege.com/'> London Premier College (London University, UK)</a>
                        </li>
                        <li className='allText'>
                            <a href='http://www.salford.ac.uk/'>University of Salford, Manchester</a>
                        </li>
                        <li className='allText'>
                            <a href='http://www.franklin.edu/'>Franklin University, Ohio, USA</a>
                        </li>
                        <li className='allText'>
                            <a href='https://www.suny.edu/'> State University of New York (SUNY)</a>
                        </li>
                        <li className='allText'>
                            <a href='http://www.cambridgecollege.co.uk/'>Cambridge International College </a>
                        </li>
                        <li className='allText'>
                            <a href='http://www.ais.ac.nz/'> Auckland Institute of Studies (AIS), St Helens, Auckland, New Zealand </a>
                        </li>
                        <li className='allText'>
                            <a href='http://www.ait.ac.th/'>Asian Institute of Technology </a>
                        </li>
                        <li className='allText'>
                            <a href='http://www.tni.ac.th/web/tni2014-en/'>Thai-Nichi Institute of Technology </a>
                        </li>
                        <li className='allText'>
                            <a href='http://www.tamk.fi/en'>Tampere University of Applied Science, Finland</a>
                        </li>
                        <li className='allText'>
                            <a href='http://www.unimap.edu.my/'> Universiti Malaysia Perlis (UniMAP), Malaysia </a>
                        </li>
                        <li className='allText'>
                            <a href='http://www.msu.edu.my'>Management & Science University, Malaysia</a>
                        </li>
                        <li className='allText'>
                            <a href='http://www.uitm.edu.my/index.php/en/'>Universiti Teknologi MARA (UiTM)</a>
                        </li>
                        <li className='allText'>
                            <a href='http://www.uum.edu.my/index.php/en/'>University Utara Malaysia</a>
                        </li>
                        <li className='allText'>
                            <a href='http://putrabusinessschool.edu.my/'>Putra Business School</a>
                        </li>
                        <li className='allText'>
                            <a href='http://en.ynufe.edu.cn/'>Yunnan University of Finance and Economics, (YUFE)</a>
                        </li>
                        <li className='allText'>
                            <a href='http://school.cuecc.com/Yunnan/ynou/index.html'>Yunnan Open University (YNOU)</a>
                        </li>
                        <li className='allText'>
                            <a href='http://english.ynnu.edu.cn/'>Yunnan Normal University, China (YNNU)</a>
                        </li>
                        <li className='allText'>
                            Fen Liang (International) Art College in Jiang Xi province of China
                        </li>
                        <li className='allText'>
                            <a href='http://www.csc.edu.cn/studyinchina/indexen.aspx'>Hangzhou Normal University</a>
                        </li>
                    </ul>
                </p>
                <p className='allText'>
                    If you are interested in receiving information about admission requirements for Eastern University, please visit the <NavLink exact to='/admissionInformation'>Eastern University Admissions homepage</NavLink>.
                </p>
            </div>
            <CSIOSidebar />
        </div>
    );
};

export default InternationalOfficeContent;