import React from "react";

import Wrapper from "../../component/Wrapper";

import OtherpageSideBar from "../component/OtherpageSideBar";

import useFacultyMembers from "../../features/useFacultyMembers";
import Spinner from "../../UI/Spinner";
import { replace_string, server_django } from "../../util";

function Proctor() {
  const { facultyMembers: data, isLoading } = useFacultyMembers();

  if (isLoading) return <Spinner />;

  const proctor = data?.find((employee) =>
    employee.role.find((role) => role.role === "Proctor")
  );

  const AssistantProctors = data?.filter((employee) =>
    employee.role.find((role) => role.role === "Assistant Proctor")
  );

  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">Proctors</p>
          <hr />
          {proctor && (
            <div className="card text-center">
              <img
                className="mx-auto proctorImageSize"
                src={proctor.staff_picture?.replace(
                  replace_string,
                  server_django
                )}
                alt={`${proctor.staff_name} as proctor of Eastern University`}
              />
              <div className="card-body">
                <p className="heading6 primaryHeading">{proctor.staff_name}</p>
                <p className="allText text-center" style={{ marginBottom: 0 }}>
                  Proctor
                </p>
                <p className="allText text-center" style={{ marginBottom: 0 }}>
                  {proctor.official_email}
                </p>
              </div>
            </div>
          )}

          <div className="proctorContainer">
            {AssistantProctors.map((assProc) => (
              <div className="card text-center">
                <img
                  className="mx-auto imageSize"
                  src={assProc.staff_picture?.replace(
                    replace_string,
                    server_django
                  )}
                  alt={`${assProc.staff_name} as Assistant proctor of Eastern University`}
                />
                <div className="card-body">
                  <p className="heading6 primaryHeading">
                    {assProc.staff_name}
                  </p>
                  <p className="allText text-center">Assistant Proctor </p>
                  <p className="allText text-center">
                    Faculty of{" "}
                    {assProc.department.at(0).collegecode.collegename}
                  </p>
                  <p className="allText text-center">
                    {assProc.official_email}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <OtherpageSideBar />
      </div>
    </Wrapper>
  );
}

export default Proctor;
