import React, { Component } from 'react';
import TopNews from './topNews';
import SocialIcon from './SocialIcon';
import QuickLinks from './QuickLinks';



class TopHeader extends Component {
    render() {
        return (
            <div className='rowC'>
                <TopNews />
                <QuickLinks />
                <SocialIcon />

            </div>
        );
    }
}

export default TopHeader;