import React, { useState } from "react";
import LeftSideNavigationBusiness from "../component/leftSideNavigationBusiness";
import FacultyName from "../component/facultyName";

import { engFacultyTestImg, MBAIslamCourseDistridution } from "../../../static";
import Footer from "../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const ProgramMBAIslam = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <div>
      <FacultyName
        name="Faculty of Business Administration"
        facultyImg={engFacultyTestImg}
      />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationBusiness />
        <div className="rightSideContent m-4">
          <p className="heading3">
            MBA in Islamic Finance, Banking and Insurance (Master of Business
            Administration)
          </p>
          <p className="heading5">
            <a href={MBAIslamCourseDistridution}>Download program brocheure</a>
          </p>

          <p className="allText">
            Islamic finance, banking and Insurence is now a successful reality
            not only in Bangladesh in the global context as well. Shariah based
            finance, banking and insurance businesses are on the rapid increase
            in Bangladesh. To cater in the needs and rapid requirements of these
            business enterprises, the Faculty of Business Administration at
            Eastern University has come up with the MBA Program in Islamic
            Finance, Banking and Insurence . This is the first ever graduate
            program in Bangladesh, duly approved by the UGC, Bangladesh. It is a
            professional graduate program designed to impart skills and
            judgemental capacity in an individual for effective management of
            Islamic banking, insurance and financial enterprises. The emphasis
            will be on developing the student's ability to evaluate business and
            organizational situations to enable him/her to make informed and
            creative judgments about policy and operational issues keeping
            Shariya's considerations in view. Central to this approach will be
            the development of creativity and reasoning in students which are
            essential for every manager regardless of functions or levels within
            an organization. A unique educational approach will be taken to
            groom the graduates who will be entering the program from diverse
            backgrounds.
          </p>

          <p className="heading5">Vision</p>
          <p className="allText">
            To be one of the top graduate program in Bangladesh recognized
            regionally for excellence in teaching, research, and innovative
            contributions in business and management especially with the concept
            of Islamic principles heralded by dedicated, highly educated and
            field level experienced faculty members.
          </p>
          <p className="heading5">Mission</p>
          <p className="allText">
            To produce future corporate leaders; socially responsible business
            graduates imbued with general and Shariah knowledge, skill and hands
            on work experience; capable of facing national and global business
            challenges with special respect to Islamic concepts and Shariah
            framework ; abreast of advanced technology, possessing strong
            motivation and communication skills, and able to lead in a
            multi-cultural environment.
          </p>
        </div>
        {/* Mobile responsive start here */}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationBusiness />
          </div>
        )}
        {/* Mobile responsive end here */}
      </div>
      <Footer />
    </div>
  );
};

export default ProgramMBAIslam;
