import { useQuery } from "@tanstack/react-query";
import { getCurrentIssue } from "../services/apis";

function useCurrentIssue() {
  const {
    isLoading,
    data: currentIssue,
    error,
  } = useQuery({
    queryKey: ["current-issue"],
    queryFn: () => getCurrentIssue(),
  });

  return { isLoading, currentIssue, error };
}

export default useCurrentIssue;
