import React, { Component } from 'react';
import AccreditationContent from './content/AccreditationContent';
import Footer from '../../component/Footer';

class accreditation extends Component {
    render() {
        return (
            <div>
                <AccreditationContent/>
                <Footer/>
            </div>
        );
    }
}

export default accreditation;