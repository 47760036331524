import React, { useState } from "react";
import LeftSideNavigationBusiness from "../component/leftSideNavigationBusiness";
import FacultyName from "../component/facultyName";

import { engFacultyTestImg } from "../../../static";
import Footer from "../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import Message from "../../../component/Message";
import { departmentCode } from "../../../util";

const ChairpersonMessageBusiness = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const department = departmentCode.bba;

  return (
    <div>
      <FacultyName
        name="Faculty of Business Administration"
        facultyImg={engFacultyTestImg}
      />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationBusiness />
        <div className="rightSideContent mt-4">
          <Message messageFrom="Chairperson" department={department} />
          {/* Mobile responsive start here */}
          <div
            id="mobileMenuButton"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <button>
              <VscAdd className="moreMenu" />
            </button>
          </div>
          {mobileMenuOpen && (
            <div id="mobileMenuContent">
              <button
                id="closeButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <VscChromeClose className="closeButtonIcon" />
              </button>
              <LeftSideNavigationBusiness />
            </div>
          )}
          {/* Mobile responsive end here */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChairpersonMessageBusiness;
