import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class AdmissionSidebar extends Component {
  render() {
    return (
      <div className="OtherpageSideBar">
        <ul className="list-group list-group-flush">
          <li className="nav-item dropdown list-group-item">
            <a
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Admission Overview
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li className="list-group-item">
                <NavLink
                  className="dropdown-item"
                  exact
                  to="/admissionInformation"
                >
                  Admission Information
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink className="dropdown-item" exact to="/whyEasternUni">
                  Why Eastern University
                </NavLink>
              </li>
            </div>
          </li>
          <li className="nav-item dropdown list-group-item">
            <a
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Undergraduate Programs
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li className="list-group-item">
                <NavLink
                  className="dropdown-item"
                  exact
                  to="/programsBAEnglishDepartment"
                >
                  B.A.(Hons.) in English
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink
                  className="dropdown-item"
                  exact
                  to="/programsBBADepartment"
                >
                  BBA
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink className="dropdown-item" exact to="/bscinCivil">
                  BSc in Civil
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink
                  className="dropdown-item"
                  exact
                  to="/bscinCSEDepartmentProgram"
                >
                  BSc in CSE
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink
                  className="dropdown-item"
                  exact
                  to="/bscinEEEDepartmentProgram"
                >
                  BSc in EEE
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink
                  className="dropdown-item"
                  exact
                  to="/programsLLBLawDepartment"
                >
                  LL.B (Hons.)
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink
                  className="dropdown-item"
                  exact
                  to="/bachelorOfPharmacy"
                >
                  B. Pharm
                </NavLink>
              </li>
            </div>
          </li>
          <li className="nav-item dropdown list-group-item">
            <a
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Graduate Programs
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li className="list-group-item">
                <NavLink
                  className="dropdown-item"
                  exact
                  to="/programsRegularMBADepartment"
                >
                  MBA (Master of Business Administration){" "}
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink
                  className="dropdown-item"
                  exact
                  to="/programsExecutiveMBADepartment"
                >
                  EMBA (Evening Master of Business Administration)
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink
                  className="dropdown-item"
                  exact
                  to="/programsMAEnglishDepartment"
                >
                  M.A. ELL(English Language & Literature)
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink
                  className="dropdown-item"
                  exact
                  to="/programsLLMLawDepartment"
                >
                  LL.M
                </NavLink>
              </li>
            </div>
          </li>
          <li className="list-group-item">
            <NavLink className="nav-link" exact to="/tuitionFees">
              Tuition and Fees
            </NavLink>
          </li>
          <li className="list-group-item">
            <NavLink
              className="nav-link"
              exact
              to="/financialAidAndScholarship"
            >
              Scholarship And Waivers
            </NavLink>
          </li>
          <li className="nav-item dropdown list-group-item">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              International Students
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li className="list-group-item">
                <NavLink
                  className="dropdown-item"
                  exact
                  to="/internationalStudents"
                >
                  International Students
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink
                  className="dropdown-item"
                  exact
                  to="/feeStructureForForeignStudents"
                >
                  Fee Structure For Foreign Students
                </NavLink>
              </li>
            </div>
          </li>
          {/* <li className="nav-item dropdown list-group-item">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                            role="button" aria-haspopup="true"
                            aria-expanded="false">
                            Facilities
                                            </a>

                    </li> */}

          <li className="list-group-item">
            <NavLink className="nav-link" exact to="/howToApply">
              How to Apply
            </NavLink>
          </li>
          <li className="list-group-item">
            <NavLink className="nav-link" exact to="/OnlineAdmission">
              Apply Online
            </NavLink>
          </li>
          {/* <li className="nav-item dropdown list-group-item">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                            role="button" aria-haspopup="true"
                            aria-expanded="false">
                            Admission Test Result
                        </a>
                    </li> */}
          <li className="nav-item dropdown list-group-item">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Admission Procedures
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li className="list-group-item">
                <NavLink
                  className="dropdown-item"
                  exact
                  to="/admissionEligibility"
                >
                  Admission Eligibility
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink
                  className="dropdown-item"
                  exact
                  to="/admissionRequirements"
                >
                  Admission Requirements
                </NavLink>
              </li>
            </div>
          </li>

          <li className="nav-item dropdown list-group-item">
            <a
              className="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Payment System
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li className="list-group-item">
                <NavLink
                  className="dropdown-item"
                  exact
                  to="/paymentInformation"
                >
                  Payment Information
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink className="dropdown-item" exact to="/paymentProcedure">
                  Payment Procedure
                </NavLink>
              </li>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default AdmissionSidebar;
