import React from 'react';
import AcademicSidebar from '../../component/AcademicSideBar'

const TestsAndExamsContent = () => {
    return (
        <div className='content'>
            <div className='OtherPagecontent'>
                <hr></hr>
                <p className='heading2 primaryHeading text-center'>
                   Test and Exams</p>
                <hr></hr>

               <>
               <div className="container" id="text&exams">
                    <ul id="textexams" className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active" href="#QuizzesTestsclassNameAssignments" role="tab" data-toggle="tab">
                                <p className="allText">
                                        Quizzes /Tests /className Assignments
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#MidtermExam" role="tab" data-toggle="tab">
                                <p className="allText">
                                        Mid-term Exam
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#FinalExam" role="tab" data-toggle="tab">
                                <p className="allText">
                                        Final Exam
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#FairnessinTestsQuizzesExaminationsetc" role="tab" data-toggle="tab">
                                <p className="allText">
                                        Fairness in Tests, Quizzes, Examinations, etc.
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#MakeupQuizzesTestsandExams" role="tab" data-toggle="tab">
                                <p className="allText">
                                        Make up Quizzes, Tests and Exams
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#ComputationofGradePoints" role="tab" data-toggle="tab">
                                <p className="allText">
                                        Computation of GPA and CGPA
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#IncompleteGrade" role="tab" data-toggle="tab">
                                <p className="allText">
                                        Incomplete Grade
                                </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#CourseRetake" role="tab" data-toggle="tab">
                                <p className="allText">
                                        Course Retake
                                </p>
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="tab-content">
                    <br></br>
                    <div  role="tabpanel" className="tab-pane fade show active" id="QuizzesTestsclassNameAssignments">
                        <p className="allText">
                            <span className="heading6">1. Quizzes /Tests/ className Assignments</span> are 
                            scheduled in className hour or tutorial hour. The duration of a quiz may 
                            be between 5 to 15 minutes and that of a className test between 15 to 30 minutes.
                        </p>
                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="MidtermExam">
                        <p className="allText">
                            <span className="heading6">2. Mid-term Exam</span>  is held in the week announced 
                            in the Semester Calendar. The duration of the mid term exam is between one 
                            to two hours. 
                        </p>
                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="FinalExam">
                        <p className="allText">
                            <span className="heading6">3. Final Exam</span>  schedule is announced by the 
                            university. The duration of the final exam is between 2 to 3 hours. 
                        </p>
                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="FairnessinTestsQuizzesExaminationsetc">
                        <p className="allText">
                            <p className="heading6">Fairness in Tests, Quizzes, Examinations, etc.</p>
                            1. Students are strictly forbidden from adopting unfair means in assignments, 
                            tests, quizzes, examinations, project work, term papers, etc. 
                            The following acts or activities shall be considered unfair means during 
                            examinations and in other contexts: 
                            <br></br>
                            <div>
                                <ul className="allText">
                                    <li>
                                            communicating with fellow students for obtaining help; 
                                            (For example, talking , eye contact, signaling, body 
                                            language etc.).
                                    </li>
                                    <li>
                                            copying from another student's script or report or paper;
                                    </li>
                                    <li>
                                            copying from printed matter, hand-written script, writing on 
                                            desk or palm of hand, or from  other incriminating documents;
                                    </li>
                                    <li>
                                            possession of any incriminating document, whether used or not;
                                    </li>
                                    <li>
                                            any approach in direct or indirect form to influence a 
                                            teacher concerning grade and
                                    </li>
                                    <li>
                                            any other unruly behavior which is disruptive of the academic program.
                                    </li>
                                </ul>
                            </div>
                        </p>
                        <p className="allText">
                                2. If a student is detected by the course teacher while adopting or having 
                                adopted unfair means, he/she may be given a 'F' grade for the assignment, 
                                test, quiz, paper, report, examination or for that course at the discretion 
                                of the invigilator/the course teacher. Adoption of unfair means may result 
                                in the dismissal of the student from the program and expulsion of the 
                                student from the university.
                        </p>
                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="MakeupQuizzesTestsandExams">
                        <p className="allText">
                            <p className="heading6">Make up Quizzes, Tests and Exams</p> 
                            Students are required to attend all tests, quizzes, assignments and exams 
                            as per announced schedule. Make up tests, exams or quizzes may be organized 
                            for those students who could not take the tests/exam/quizzes for medical or 
                            other compelling/ unavoidable reasons acceptable to the course teacher and 
                            the Dean of Faculty. A student has to apply for make up tests, exams etc. 
                            showing reasons with evidence for his failure to take the scheduled tests. 
                            A student seeking leave or make up exam on medical grounds must contact the 
                            Medical Officer of the university as soon as possible and obtain verification 
                            of Medical Certificate from him/her. For make up of quizzes, className tests, 
                            assignments, participation etc, he/she should apply to the course teacher 
                            and for make up of mid-term and final exam, he/she should apply in prescribed 
                            form to the Chairperson/Dean of the concerned Faculty through the course 
                            teacher. A student   seeking make up exam must pay the make up exam fee at 
                            the prevailing rate. After completing these formalities, the student shall 
                            watch for the schedule of mid-term make up exam or final make up exams and 
                            sit for the exam. Mid term make up exam is held only once before final exam 
                            week and the make up final exam is held within 4 weeks from the date of 
                            commencement of the new semester. If a student misses the schedule, he/she 
                            will have to retake the course. 
                        </p>
                    </div>
                    <div role="tabpanel" className="tab-pane fade" id="ComputationofGradePoints">
                        <p className="allText">
                            <p className="heading6">Computation of Grade Points Average (GPA ) and 
                                Cumulative Grade Points Average (CGPA)</p> 

                            <p className="MsoNormal allText">
                                <span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>(GP in C1 x CH) + (GP in C1 x CH) + ………. +(GP in Cn x CH)
        
                            </p>
                            <p className="MsoNormal allText">
                                GPA =<span >&nbsp;&nbsp;&nbsp; </span>-------------------------------------------------------------------------------
        
                            </p>
                            <p className="MsoNormal allText">
                                <span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>CH of C1 + CH of C2 +………….+ CH of Cn
        
                            </p>
                            <p className="MsoNormal allText">
                        GP = Grade point earned
                            </p>
                            <p className="MsoNormal allText">
                        CH = Credit hours of the course attempted
                         
                            </p>
                            <p className="MsoNormal allText">
                        C1,2 …n= Courses
                         
                            </p>
                            <p className="MsoNormal allText">
                                <span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>(GPA of all courses completed) x (total credit hours attempted in all courses)
        
                            </p>
                            <p className="MsoNormal allText">
                2.<span >&nbsp; </span>CGPA =<span >&nbsp;&nbsp; </span>--------------------------------------------------------------------------------------------
        
                            </p>
                            <p className="MsoNormal allText">
                                <span >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>Total credit hours attempted in all semesters
        
                            </p>

                        </p >
                    </div >
                    <br></br>
                    <div role="tabpanel" className="tab-pane fade" id="IncompleteGrade">
                        <p className="allText">
                            <p className="heading6">
                Incomplete Grade (i)
                            </p>
                            If a student has completed all the requirements of a course except final 
                            exam or a project report/term paper, he/she may apply to the course teacher 
                            for an incomplete grade explaining the reason for missing the final exam. 
                            If the reason is acceptable, the course teacher may assign an incomplete 
                            grade, which is recorded as "I" on the final grade sheet. A student is 
                            permitted to complete an incomplete course within four weeks from the date 
                            of the commencement of new semester. If the incomplete grade is due to 
                            absence in final exam, he/she may sit for the make up final exam which is 
                            held within 4 weeks from the date of commencement of the new semester. 
                            If a student fails to sit for this exam, he/she will earn "F" grade in the 
                            course and he/she will have to retake the course. 
                        </p>
                    </div>
                    <br>
                    </br>
                    <div role="tabpanel" className="tab-pane fade" id="CourseRetake">
                        <p className="allText">
                            <p className="heading6">
                Course Retake
                            </p>
                            A student earning "F" grade in a course is required to earn at least the 
                            passing grade by retaking the course in the next semester on payment of 
                            requisite fees. A student may also choose to improve the grade by retaking 
                            the course (when he/she gets B+ or below in that particular course) on 
                            payment of 100% course fees. In case of a course retake, the transcript 
                            of a student shall show the grade which is higher. 
                        </p>
                    </div>
                </div >
               </>
            </div >
    <AcademicSidebar />
        </div >
    );
};

export default TestsAndExamsContent;