import React from "react";
import { NavLink } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";
import { departmentCode } from "../../../util";

const LeftSideNavigationPharmacy = () => {
  return (
    <div id="facultyLeftSideMenu" className="sidemenu">
      <Menu vertical className="sidemenuBar">
        <Dropdown text="Home" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <NavLink exact to="/departmentOfPharmacy">
              <Dropdown.Item>Pharmacy Home</Dropdown.Item>
            </NavLink>
            <NavLink exact to="/">
              <Dropdown.Item>EU Home</Dropdown.Item>
            </NavLink>

            <Dropdown text="About" className="link item sideMenuBarDropdown">
              <Dropdown.Menu>
                <NavLink exact to="/welcomeMessagePharmacy">
                  <Dropdown.Item>Welcome Message</Dropdown.Item>
                </NavLink>
                {/* <NavLink exact to="/exploringPharmacy">
                  <Dropdown.Item>Exploring Pharmacy</Dropdown.Item>
                </NavLink> */}
                <NavLink exact to="/clubInformationPharmacy">
                  <Dropdown.Item>Clubs Information</Dropdown.Item>
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="Academic" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            {/* Programs */}
            <Dropdown text="Programs" className="link item sideMenuBarDropdown">
              <Dropdown.Menu>
                <Dropdown text="Undergraduate" className="link item">
                  <Dropdown.Menu>
                    <NavLink exact to="/bachelorOfPharmacy">
                      <Dropdown.Item>Bachelor of Pharmacy</Dropdown.Item>
                    </NavLink>
                  </Dropdown.Menu>
                </Dropdown>
              </Dropdown.Menu>
            </Dropdown>

            {/* Admission */}
            <Dropdown
              text="Admission"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <NavLink exact to="/admissionPharmacyDepartment">
                  <Dropdown.Item>Undergraduate</Dropdown.Item>
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
            {/* Curriculum */}
            <Dropdown
              text="Curriculum"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <NavLink exact to="/underGraduateCurriculumPharma">
                  <Dropdown.Item>Undergraduate</Dropdown.Item>
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="People" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            {/* Faculty Members */}
            {/* <Dropdown
              text="Faculty Members"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <NavLink exact to="/atAGlanceFacultyMembersPharmacy">
                  <Dropdown.Item>At A Glance</Dropdown.Item>
                </NavLink>
                <NavLink exact to="/currentFacultyMembersPharmacy">
                  <Dropdown.Item>Current Faculty</Dropdown.Item>
                </NavLink>
                <NavLink exact to="/adjunctFacultyMembersPharmacy">
                  <Dropdown.Item>Adjunct Faculty</Dropdown.Item>
                </NavLink>
                <NavLink exact to="/onLeaveFacultyMembersPharmacy">
                  <Dropdown.Item>On Leave</Dropdown.Item>
                </NavLink>
              </Dropdown.Menu>
            </Dropdown> */}
            <Dropdown.Item>
              <NavLink exact to="/atAGlanceFacultyMembersPharmacy">
                Faculty Members
              </NavLink>
            </Dropdown.Item>
            {/* Officials */}
            <Dropdown.Item>
              <NavLink exact to="/officialsPharmacy">
                Officials
              </NavLink>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* Lab */}
        <Menu.Item className="sideBarSingleItem">
          <NavLink exact to="/labFacilitiesPharmacy">
            Lab Facilities
          </NavLink>
        </Menu.Item>

        {/* <Dropdown text="Research" className="link item sideMenuBarDropdown">
              <Dropdown.Menu>
                <Dropdown.Item>
                <NavLink exact to='/publicationsCSE'>Publications</NavLink> 
                  <a
                    href="http://dspace.easternuni.edu.bd:8080/xmlui/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Publications
                  </a>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/researchGroupCivil">
                    Research Groups
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}

        {/* Announcement */}
        <Dropdown text="Announcement" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <NavLink exact to="/newsPharmacy">
              <Dropdown.Item>News</Dropdown.Item>
            </NavLink>
            <NavLink exact to="/upcomingEventsPage">
              <Dropdown.Item>Upcoming Events</Dropdown.Item>
            </NavLink>
            <NavLink exact to="/noticePharmacy" tabindex="-1">
              <Dropdown.Item>Notice</Dropdown.Item>
            </NavLink>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item className="sideBarSingleItem">
          {/* <NavLink exact to='/alumniCSE'>Alumni</NavLink> */}
          <a
            href={`http://webportal.easternuni.edu.bd/Online_Alumni/_AlumniSearch.aspx?department=${departmentCode.pharmacy}`}
            target="_blank"
          >
            Alumni
          </a>
        </Menu.Item>
        {/* <Menu.Item className="sideBarSingleItemLast">
              <NavLink exact to="/contactUsCivil">
                Contact Us
              </NavLink>
            </Menu.Item> */}
      </Menu>
    </div>
  );
};

export default LeftSideNavigationPharmacy;
