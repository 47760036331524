import React from "react";

import { server_django, replace_string } from "../util";
import moment from "moment/moment";
import ReactHtmlParser from "react-html-parser";
import Footer from "../component/Footer";
import useUpcomingEvents from "../features/useUpcomingEvents";
import Spinner from "../UI/Spinner";
import MessageBox from "./MessageBox";

const SingleUpcomingEvents = (props) => {
  const id = window.location.pathname.split("/").pop();
  // const fetchData = async () => {
  //   await axios
  //     .get(server_django + "/events/" + id, { auth })
  //     .then((res) => {
  //       setSingleEvents(res.data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   fetchData();
  // }, [id]);
  const { events: singleEvents, isLoading, error } = useUpcomingEvents();

  if (isLoading) return <Spinner />;
  if (error) return <MessageBox>{error.message}</MessageBox>;

  const singleEvent = singleEvents.find((item) => item.id === +id);

  console.log(singleEvent);

  const events = singleEvent && (
    <div className="singlePost">
      <div className="card">
        <div className="card-body">
          <p className="heading3">{singleEvent.headline}</p>

          <p className="allText">
            Event Start on :{" "}
            {moment(singleEvent.startDate, "YYYY-MM-DD").format("LL")}
          </p>
          <p className="allText">Event Start at : {singleEvent.startTime}</p>
          {singleEvent.organizedBy.length > 0 && (
            <p className="allText">
              Organized by : {singleEvent.organizedBy.name}
            </p>
          )}
          {singleEvent.venue && (
            <p className="allText">Venue : {singleEvent.venue}</p>
          )}
          <p className="allText">{ReactHtmlParser(singleEvent.details)}</p>
          <p className="allText">{ReactHtmlParser(singleEvents.Remarks)}</p>
        </div>
        <img
          className="card-img-bottom img-fluid"
          src={singleEvent.pictureLocation.replace(
            replace_string,
            server_django
          )}
          alt={encodeURIComponent(singleEvent.pictureLocation)}
        ></img>
      </div>
    </div>
  );
  return (
    <div>
      {events}
      <Footer />
    </div>
  );
};

export default SingleUpcomingEvents;
