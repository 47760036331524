import React, { useState } from "react";
import LeftSideNavigationEEE from "../../component/leftSideNavigationEEE";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import CurrentFaculty from "../../component/FacultyMembersByDepartment";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import { departmentCode, employeeType } from "../../../../util";

const AdjunctCurrentFacultyMembersEEE = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const config = { department: departmentCode.eee, part: employeeType.part };

  return (
    <div>
      <FacultyName name="Department of Electrical and Electronic Engineering" />
      <div className="facultyContent">
        <LeftSideNavigationEEE />
        <div className="rightSideContent mt-4">
          <CurrentFaculty
            heading="Adjunct Faculty Members"
            config={config}
            statusID={config.part}
          />
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationEEE />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </div>
  );
};

export default AdjunctCurrentFacultyMembersEEE;
