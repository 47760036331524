import React, { Component } from 'react';
import AdmissionRequirementsContent from './Content/AdmissionRequirementsContent';

import Footer from '../../component/Footer';

class admissionRequirements extends Component {
    render() {
        return (
            <div>
                <AdmissionRequirementsContent/>
                <Footer/>
            </div>
        );
    }
}

export default admissionRequirements;