import React from 'react';
import AcademicSidebar from '../../component/AcademicSideBar'

const AcademicRulesAndProceduresContent = () => {
    return (
        <div className='content'>
            <div className='OtherPagecontent'>
                <hr></hr>
                <p className="heading2 primaryHeading text-center">
                    Academic Rules & Procedures
                </p>
                <hr></hr>
                <br></br>
                <p className="heading6 primaryHeading">
                    Non Degree Students
                    </p>
                <p className="allText">
                    A student of another university or equivalent educational institution may
                    register in courses as a non degree student on payment of requisite fees
                    for such students. The total number of courses he/she may register for,
                    shall not exceed 50 percent of the courses required for the degree.
                </p>
                <br></br>

                <p className="heading6 primaryHeading">
                    Provisional Admission
                    </p>

                <p className="allText">
                    Every student must submit the required certificates and other documents at
                    the time of admission. Applicants for admission who are not able to produce
                    one or more documents at the time of admission may be admitted provisionally.
                    All provisionally admitted students are required to submit appropriate
                    documents within a given deadline as a prerequisite for continued enrollment.
                </p>
                <br></br>

                <p className="heading6 primaryHeading">
                    Re-admission
                    </p>

                <p className="allText">
                    Students with study breaks or semester withdrawals are allowed re-admission
                    subject to payment of re-admission and other fees.<br></br><br></br>
                    Authorized Banks:<br></br>
                    i) Dhaka Bank, Road No. 7, Dhanmondi R/A or any other branch<br></br>
                    ii) IFIC Bank, Road No. 4 (Mirpur Road), Dhanmondi Branch
                </p>

                <p className="heading6 primaryHeading">
                    Student Identification Card
                    </p>
                <p className="allText">
                    A new student receives upon admission, an identification (ID) card with a
                    number. This card is used for entering and staying in the campus, attending
                    classNamees, using the library, internet and computer services, etc. The students
                    must take good care of the ID and must report of its loss, if any, to the
                    Registrar's Office immediately so that the card can be cancelled to prevent
                    its misuse. The Registrar's Office will issue a duplicate ID Card upon
                    application and payment of fees by the student. A student is liable to pay
                    for any loss incurred by the University for his losing the card.
                </p>
                <br></br>

                <p className="heading6 primaryHeading">
                    Transfer Students
                    </p>

                <p className="allText">
                    Transfer students are expected to have at least a 2.50 CGPA on a 4-point
                    scale at the university level programs to be considered for admission to
                    Eastern University. Catalogs and official transcripts from previously
                    attended universities must be furnished with the application for admission.
                    If an undergraduate student seeking transfer went though the Admission Test
                    in his/her previous university, he/she will be waived from admission test
                    requirement of the Eastern University. If needed, transcripts may have to
                    be sent directly to the EU Registrar's Office from each institution attended.
                    <br></br>
                    An undergraduate student may transfer (through grade transfer or exemption or
                    a combination of both) a maximum of 35 percent of the total credit hours
                    required for the degree in question at EU. In exceptional cased, the
                    Equivalence Committee may, on recommendation of concerned Dean, consider
                    extending the credits up to 40 percent.
                    <br></br>
                    For transfer of grade of a course, a student must have at least B grade in
                    the course. In some cases, courses with C+ grades as per Eastern University
                    standard may be considered for transfer or exemption. For the purpose of
                    transferring credits, the Equivalence Committee of the University determines
                    equivalence of courses and grades for each transfer student. The curriculum,
                    evaluation system and grading standard of the university from which the
                    student is transferring must be comparable to that Eastern University which
                    reserves the right to make final decision on transfer. Incase of any course
                    transfer against 2 or more courses, the average grade will be shown in the EU
                    transcript. GED/ Foundation courses will only be exempted instead of transfer.
                </p>
                <br></br>

                <p className="heading6 primaryHeading">
                    Course Waiver/ Exemption for Graduate Students
                    </p>

                <p className="allText">
                    A graduate student with undergraduate degree from Eastern University may get
                    waiver or exemption of courses to a maximum of 50% of the total credits
                    required for the degree. A graduate student with undergraduate degree from
                    other universities may get waiver or exemption of courses to a maximum of 35%
                    of the total credits required for the degree. In exceptional cases, the limit
                    may be raised to 40% by the appropriate authority. Transcripts and other
                    supporting documents must be submitted with the application for
                    exemption/waiver. A student may apply for exemption from courses of other
                    universities in which he/she has at least B-grades and the course contents
                    match at least up to 85% with the contents of the corresponding course at EU.
                </p>



            </div>
            <AcademicSidebar />
        </div>
    );
};

export default AcademicRulesAndProceduresContent;