import React, { useState } from 'react';
import LeftSideNavigationLaw from '../component/leftSideNavigationLaw';
import FacultyName from '../component/facultyName'

import { engFacultyTestImg } from '../../../static';
import { LLBCourseDistribution } from '../../../static';

import Footer from '../../../component/Footer';
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const ProgramLLB = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div>
            <FacultyName name='Faculty of Law' facultyImg={engFacultyTestImg} />
            <div className='facultyContent' id='deanArts'>
                <LeftSideNavigationLaw />
                <div className='rightSideContent m-4'>
                    <p className='heading3'>LL.B (Hons.)</p>

                    <p className='heading5'>
                        <a href={LLBCourseDistribution}>Download Course Distribution</a>
                    </p>
                    <p className='heading5'>Degree Requirement</p>
                    <p className='allText'>
                        To qualify for the LL.B. (Hons.), a student has to pass all required courses individually with a minimum Cumulative Grade Point Average (CGPA) of 2.50. Students are required to undertake 130 credit hours.
                    </p>
                </div>
                {/* Mobile responsive start here */}
                <div id='mobileMenuButton' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                    <button >
                        <VscAdd className='moreMenu' />
                    </button>
                </div>
                {
                    (mobileMenuOpen) && (
                        <div id='mobileMenuContent'>
                            <button id='closeButton' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                <VscChromeClose className='closeButtonIcon' />
                            </button>
                            <LeftSideNavigationLaw />
                        </div>
                    )
                }
                {/* Mobile responsive end here */}
            </div>
            <Footer />
        </div>
    );
};

export default ProgramLLB;