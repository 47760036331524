import React, { Component } from 'react';
import AdmissionSidebar from '../../component/AdmissionSidebar';
import { AdmissionForm } from '../../../static';

class EuAdmissionFormContent extends Component {
    render() {
        return (
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr />
                    <p className='heading2 primaryHeading text-center'>
                        Admission Form
                    </p>
                    <hr />
                    <p className='allText'>
                        Admission Form : <a href={AdmissionForm}>Click Here.</a>
                    </p>
                </div>
                <AdmissionSidebar />
            </div>
        );
    }
}

export default EuAdmissionFormContent;