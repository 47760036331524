import React from 'react';
import AcademicSidebar from '../../component/AcademicSideBar'

import { studentloginPageImg, StudentHomePage, OnlinePay, OnlinePay1, feePage, StudentPayNowPage_6, Medium, bkash, bkash8, Bkashpin, nagad_1, nagad_2, nagad_3, VisaCardPayment, RocketPayment, ledger } from '../../../static'


const OnlinePaymentProcedureContent = () => {
    return (
        <div className='content'>
            <div className='OtherPagecontent' id='onlinePaymentProcedure'>
                <hr></hr>
                <p className='heading2 primaryHeading text-center'>
                    Online Payment Procedure</p>
                <hr></hr>

                <>
                    <div className="row card-deck">
                        <div className="card col-md-4 text-center">
                            <div className="card-body">

                                <p className="allText">
                                    1. Go to Student login <br></br>
                                    from website and Enter <br></br>
                                    your ID and Password
                                </p>
                            </div>
                            <img className="mx-auto img-fluid" src={studentloginPageImg} alt="Card image cap"></img>

                        </div>
                        <div className="card col-md-4 text-center">
                            <div className="card-body">
                                <p className="allText">
                                    2. Select Online Payment<br></br>
                                    Option in Quick Links.
                                </p>
                            </div>
                            <img className="mx-auto img-fluid" src={StudentHomePage} alt="Card image cap"></img>

                        </div>
                        <div className="card col-md-4 text-center">
                            <div className="card-body">
                                <p className="allText">
                                    3. Check and update the<br></br>
                                    contact number and email<br></br>
                                    address then submit it.
                                </p>
                            </div>
                            <img className="mx-auto img-fluid" src={OnlinePay} alt="Card image cap"></img>

                        </div>
                    </div>
                    <br></br>
                    <div className="row card-deck">
                        <div className="card col-md-4 text-center">
                            <div className="card-body">

                                <p className="allText">
                                    4. Select Semester and<br></br>
                                    Year for your Payment <br></br>
                                    and Submit it
                                </p>
                            </div>
                            <img className="mx-auto img-fluid" src={OnlinePay1} alt="Card image cap"></img>

                        </div>
                        <div className="card col-md-4 text-center">
                            <div className="card-body">
                                <p className="allText">
                                    5.Select Particulars Heads,<br></br>
                                    Year, Semester, Amounts <br></br>
                                    and Click Submit Button
                                </p>

                            </div>
                            <img className="mx-auto img-fluid" src={feePage} alt="Card image cap"></img>

                        </div>
                        <div className="card col-md-4 text-center">
                            <div className="card-body">
                                <p className="allText">
                                    6.Click Nagad or SSL Icon for Payment
                                </p>
                                <br></br>

                            </div>
                            <img className="mx-auto img-fluid" src={StudentPayNowPage_6} alt="Card image cap"></img>

                        </div>
                    </div>
                    <br></br>
                    <div className="row">
                        <div className="card col-md-4 text-center">
                            <div className="card-body">

                                <p className="allText">
                                    7. select any payment <br></br>
                                    option and pay now<br></br>
                                </p>
                            </div>
                            <img className="mx-auto img-fluid" src={Medium} alt="Card image cap"></img>

                        </div>

                    </div>
                    <br></br>
                    <p className="allText"><b>For Bkash Payment</b></p>
                    <div className="row">
                        <div className="card col-md-4 text-center">
                            <div className="card-body">

                                <p className="allText">
                                    8.Give Your Personal <br></br>
                                    Bkash Account Number <br></br>
                                    and Click Confirm
                                </p>
                            </div>
                            <img className="mx-auto img-fluid" src={bkash} alt="Card image cap"></img>

                        </div>
                        <div className="card col-md-4 text-center">
                            <div className="card-body">

                                <p className="allText">
                                    9.Insert Bkash Verification <br></br>
                                    Code and Click Confirm
                                </p>
                                <br></br>
                            </div>
                            <img className="mx-auto img-fluid" src={bkash8} alt="Card image cap"></img>

                        </div>
                        <div className="card col-md-4 text-center">
                            <div className="card-body">

                                <p className="allText">
                                    10. Insert Your Bkash <br></br>
                                    pin number and Confirm it.
                                </p>
                                <br></br>
                            </div>
                            <img className="mx-auto img-fluid" src={Bkashpin} alt="Card image cap"></img>

                        </div>

                    </div>
                    <br></br>
                    <p className="allText"><b>or <br></br>For Nagad Payment</b></p>
                    <div className="row card-deck">

                        <div className="card col-md-4 text-center">
                            <p className="allText">
                                11. Enter Your Nagad Number, check on terms and condition button and proceed it.
                            </p>
                            <div className="card-body">

                            </div>
                            <img className="mx-auto img-fluid" src={nagad_1} alt="Card image cap"></img>
                        </div>
                        <div className="card col-md-4 text-center">
                            <p className="allText">
                                12. Enter Verification  Code and Click Proceed
                            </p>
                            <div className="card-body">

                            </div>
                            <img className="mx-auto img-fluid" src={nagad_2} alt="Card image cap"></img>

                        </div>
                        <div className="card col-md-4 text-center">
                            <p className="allText">
                                13. Enter your pin number and Click Proceed.
                            </p>
                            <div className="card-body">

                            </div>
                            <img className="mx-auto img-fluid" src={nagad_3} alt="Card image cap"></img>

                        </div>


                    </div>
                    <br></br>
                    <p className="allText"><b>or <br></br>For Visa or Mastercard Payment</b></p>
                    <div className="row">

                        <div className="card col-md-4 text-center">
                            <p className="allText">
                                14. Insert Card number, CVV2, Cardholders Name, Expiration Date and Click Confirm.
                            </p>
                            <div className="card-body">

                            </div>
                            <img className="mx-auto img-fluid" src={VisaCardPayment} alt="Card image cap"></img>
                            <div>
                                <p className="allText">
                                    **Online Purchase Permission should be activated by The Bank for Card Payment
                                </p>
                            </div>
                        </div>


                    </div>
                    <br></br>
                    <p className="allText"><b>or <br></br>For Rocket Payment</b></p>

                    <div className="row">

                        <div className="card col-md-4 text-center">
                            <p className="allText">
                                16.Insert Rocket Account Number, Pin Number and Click Confirm
                            </p>
                            <div className="card-body">

                            </div>
                            <img className="mx-auto img-fluid" src={RocketPayment} alt="Card image cap"></img>
                        </div>


                    </div>
                    <br></br>
                    <p className="allText"><b>**please login again and check your ledger and email, you will get a payment invoice.</b></p>
                    <div className="row">

                        <div className="card col-md-4 text-center">
                            <img className="mx-auto img-fluid" src={ledger} alt="Card image cap"></img>
                        </div>
                    </div>
                    <br></br>
                    <br></br>

                </>

            </div >
            <AcademicSidebar />
        </div >
    );
};

export default OnlinePaymentProcedureContent;