import React, { useState } from 'react';
import FacultyName from '../component/facultyName';
import Footer from '../../../component/Footer';
import LeftSideNavigationEngineering from '../component/leftSideNavigationEngneering';
import { engFacultyTestImg } from '../../../static';
import { EEECourseDistridution } from '../../../static';
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const BscinEEEProgram = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div>
            <FacultyName name='Faculty of Engineering and Technology' facultyImg={engFacultyTestImg} />
            <div className='facultyContent' id='deanArts'>
                <LeftSideNavigationEngineering />
                <div className='rightSideContent m-4'>
                    <p className='heading3'>B.Sc. in EEE (Electrical and Electronic Engineering)</p>
                    <p className='heading4'>
                        <a href={EEECourseDistridution}>Download Course Distribution</a>
                    </p>
                    <p className='allText'>
                        The Department of Electrical and Electronic Engineering offers program leading to the degree of B.Sc. in Electrical and Electronic Engineering (EEE). The credit hours required for completion of the B. Sc. (EEE) program is 149. The program includes courses from General areas namely, English, Physics, Chemistry, Economics, Accounting, Arts, and from Engineering disciplines namely, Electrical, Electronics, Telecommunication, Industrial Derives, Control System and Power Systems Engineering as major area, and also Computer and Mechanical Engineering as allied Engineering. The program requires some of the courses as core courses and it has left option for the students to choose a few courses as electives for specialization.
                    </p>
                    <p className='allText'>
                        The curriculum for the B.Sc. (EEE) has been designed to make the program goal-oriented to fulfill the needs of the age.
                    </p>
                    <p className='heading5'>
                        Entry Requirement
                    </p>
                    <p className='allText'>
                        Candidates with minimum GPA of 2.0 in both SSC/Dhakil and HSC/Alim/Diploma from Technical Education Board or minimum GPA of 2.00 in one of those exams with total GPA of 6.00 in both the exams are eligible for admission. The GCE students must have passed five subjects in O-Level and two subject in A-Level.
                    </p>

                    <p className='heading5'>Duration of the program</p>
                    <p className='allText'>
                        The duration for B. Sc. in Electrical and Electronic Engineering program is four years divided into 12 semesters.
                    </p>
                    <p className='heading5'>Graduation Requirement</p>
                    <p className='allText'>
                        To obtain BSEEE degree a student will have to complete 149.0 cr. hrs. with a minimum CGPA of 2.5.
                    </p>
                </div>
                {/* Mobile responsive start here */}
                <div id='mobileMenuButton' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                    <button >
                        <VscAdd className='moreMenu' />
                    </button>
                </div>
                {
                    (mobileMenuOpen) && (
                        <div id='mobileMenuContent'>
                            <button id='closeButton' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                <VscChromeClose className='closeButtonIcon' />
                            </button>
                            <LeftSideNavigationEngineering />
                        </div>
                    )
                }
                {/* Mobile responsive end here */}
            </div>
            <Footer />
        </div>
    );
};

export default BscinEEEProgram;