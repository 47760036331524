import React, { Component } from 'react';
import OtherPageSidebar from '../../component/OtherpageSideBar';
import { ExamControllerKhaledVai } from '../../../static';

class ExamControllerContent extends Component {
    render() {
        return (
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr />
                    <p className='heading2 primaryHeading text-center'>
                        Exam Controller
                    </p>
                    <hr />
                    <div className='history'>
                        <div className='card'>
                            <img className='mx-auto' src={ExamControllerKhaledVai} alt='Exam Controller Image Here' />
                        </div>
                        <p className='heading4'>

                            Work on Progress...
                    </p>
                        <p className='heading6'>Mr. Khaled Md. Ebadul Hoque<br />
                        Deputy Controller
                    </p>
                    </div>
                </div>
                <OtherPageSidebar />
            </div>
        );
    }
}

export default ExamControllerContent;