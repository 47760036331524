import React, { useState } from 'react';
import LeftSideNavigationCSE from '../../component/leftSideNavigationCSE';
import FacultyName from '../../component/facultyName';
import Footer from '../../../../component/Footer';
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const AlumniCSE = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <div>
            <FacultyName name='Department of Computer Science and Engineering' />
            <div className='facultyContent' id='deanArts'>
                <LeftSideNavigationCSE />
                <div className='rightSideContent mt-4'>
                    <p className='heading3 text-center'>Alumni</p>
                    <div class="card">
                        <p className='heading1'>Work in Progress</p>
                    </div>
                    {/* Mobile Responsive Start*/}
                    <div
                        id="mobileMenuButton"
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                    >
                        <button>
                            <VscAdd className="moreMenu" />
                        </button>
                    </div>
                    {mobileMenuOpen && (
                        <div id="mobileMenuContent">
                            <button
                                id="closeButton"
                                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                            >
                                <VscChromeClose className="closeButtonIcon" />
                            </button>
                            <LeftSideNavigationCSE />
                        </div>
                    )}
                    {/* Mobile Responsive End*/}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default AlumniCSE;