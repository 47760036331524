import React, { useState } from "react";
import LeftSideNavigationEnglish from "../../component/leftSideNavigationEnglish";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import { BAEnglishCourseDistribution } from "../../../../static";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const ProgramsBAEnglishDepartment = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName name="Department of English" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationEnglish />
        <div className="rightSideContent mt-4">
          <p className="heading4 text-center">B.A.(Hons.) in English</p>

          <p className="heading5">Admission Requirement</p>
          <p className="allText">
            To be eligible to apply for admission into B.A. (Hons.) in English a
            candidate must have a minimum GPA of 2.50 at the SSC/Dakhil and
            HSC/Alim/Diploma of Bangladesh Technical Education Board. GCE
            students must have passed five subjects at O-Level and two subjects
            at A-Level. The final selection will be made on the basis of
            Admission Test. Students with average GPA 4 or above in SSC (or
            equivalent) exam and HSC (or equivalent) exam do not need to sit for
            the admission test
          </p>

          <p className="heading5">Degree Requirements</p>
          <p className="allText">
            To qualify for the B.A. (Hons.) degree, a student has to pass all
            the required courses individually , earn a minimum Cumulative Grade
            Point Average (CGPA) of 2.50 on completion of 128 credit hours and
            maintain a record of good conduct.
          </p>
          <p className="heading5">
            <a href={BAEnglishCourseDistribution}>
              Download Course Distribution
            </a>
          </p>

          {/* Mobile Responsive Start*/}
          <div
            id="mobileMenuButton"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <button>
              <VscAdd className="moreMenu" />
            </button>
          </div>
          {mobileMenuOpen && (
            <div id="mobileMenuContent">
              <button
                id="closeButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <VscChromeClose className="closeButtonIcon" />
              </button>
              <LeftSideNavigationEnglish />
            </div>
          )}
          {/* Mobile Responsive End*/}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProgramsBAEnglishDepartment;
