import React from "react";
import IqacDocumentsContent from "./content/IqacDocumentsContent";
import Footer from "../../../component/Footer";

const iqacDocuments = () => {
  return (
    <div>
      <IqacDocumentsContent />
      <Footer />
    </div>
  );
};

export default iqacDocuments;
