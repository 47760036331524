import React from "react";
import { NavLink } from "react-router-dom";

const AcademicSideBar = () => {
  return (
    <div className="OtherpageSideBar">
      <ul className="list-group list-group-flush">
        <li className="nav-item dropdown list-group-item">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Faculties
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li className="list-group-item">
              <NavLink className="dropdown-item" exact to="/facultyOfArts">
                Faculty of Arts
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink
                className="dropdown-item"
                exact
                to="/facultyOfBusinessAdministration"
              >
                Faculty of Business Administration
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink
                className="dropdown-item"
                exact
                to="/facultyOfEngineering"
              >
                Faculty of Engineering & Technology
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink className="dropdown-item" exact to="/facultyOfLaw">
                Faculty of Law
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink
                className="dropdown-item"
                exact
                to="/facultyOfLifeScience"
              >
                Faculty of Life Science
              </NavLink>
            </li>
          </div>
        </li>
        <li className="nav-item dropdown list-group-item">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Departments
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li className="list-group-item">
              <NavLink
                className="dropdown-item"
                exact
                to="/departmentOfBusinessAdministration"
              >
                Department of Business Administration
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink className="dropdown-item" exact to="/departmentOfCivil">
                Department of Civil
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink className="dropdown-item" exact to="/departmentOfCSE">
                Department of CSE
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink className="dropdown-item" exact to="/departmentOfEEE">
                Department of EEE
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink
                className="dropdown-item"
                exact
                to="/departmentOfEnglish"
              >
                Department of English
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink className="dropdown-item" exact to="/departmentOfLaw">
                Department of Law
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink
                className="dropdown-item"
                exact
                to="/departmentOfPharmacy"
              >
                Department of Pharmacy
              </NavLink>
            </li>
          </div>
        </li>
        <li className="nav-item dropdown list-group-item">
          <NavLink className="nav-link" exact to="/registrationProcedure">
            Registration Procedures
          </NavLink>
        </li>
        <li className="nav-item dropdown list-group-item">
          <NavLink className="nav-link" exact to="/onlinePaymentProcedure">
            Online Payment Procedures
          </NavLink>
        </li>
        {/* <li className="nav-item dropdown list-group-item">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Registration Procedures
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li className="list-group-item">
              <NavLink
                className="dropdown-item"
                exact
                to="/onlinePaymentProcedure"
              >
                Online Payment Procedure
              </NavLink>
            </li>
          </div>
        </li> */}
        <li className="nav-item dropdown list-group-item">
          <a
            className="nav-link dropdown-toggle"
            href="#"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Academic Rules and Regulations
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li className="list-group-item">
              <NavLink
                className="dropdown-item"
                exact
                to="/academicRulesAndProcedures"
              >
                Academic Rules and Procedures
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink className="dropdown-item" exact to="/codeOfConduct">
                Code of Conduct
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink
                className="dropdown-item"
                exact
                to="/academicStandingOfAStudent"
              >
                Academic Standing of a Student
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink className="dropdown-item" exact to="/testsAndExams">
                Tests and Exams
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink
                className="dropdown-item"
                exact
                to="/rulesOfClassAttendance"
              >
                Rules of Class Attendance
              </NavLink>
            </li>
          </div>
        </li>
        <li className="nav-item dropdown list-group-item">
          <NavLink className="nav-link" exact to="academicCalender">
            Academic Calender
          </NavLink>
        </li>
        <li className="nav-item dropdown list-group-item">
          <NavLink className="nav-link" exact to="/iqac">
            IQAC
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default AcademicSideBar;
