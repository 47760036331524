import React from "react";
import Wrapper from "../../component/Wrapper";
import AdmissionSidebar from "../component/AdmissionSidebar";

const AdmissionProcessForInternational = () => {
  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">
            Admission Process for International Students
          </p>
          <hr />

          <div className="details">
            <p className="allText">
              <strong>Step-1 :</strong> Email the following documents to
              admission office :
              <a href="mailto:admission@easternuni.edu.bd">
                admission@easternuni.edu.bd
              </a>
              <ol className="list-group list-group-numbered">
                <li className="list-group-item">
                  Scanned attested copy of Secondary School Certificate/Higher
                  Secondary Certificate/ 12 years of schooling pre-university
                  education certificates
                </li>
                <li className="list-group-item">
                  Scanned copy of passport Information Page
                </li>
                <li className="list-group-item">
                  Passport size photograph 1 copy
                </li>
                <li className="list-group-item">
                  For Masters program: Scanned attested copy of Bachelor’s
                  Transcript & Bachelor’s Certificate
                </li>
              </ol>
            </p>
            <p className="allText">
              <strong>Step-2 :</strong>
              <ol className="list-group list-group-numbered">
                <li className="list-group-item">
                  Receive the offer letter via email issued by the Registrar
                  after it is approved by the respective department.
                </li>
                <li className="list-group-item">
                  Apply for a student visa to the nearest Bangladesh Embassy.
                  Eastern University, Admission office may assist the student in
                  applying for the student visa. However, the student will have
                  to manage the visa by himself/herself.
                </li>
              </ol>
              Send your flight ticket to the Admission Office
              (admission@easternuni.edu.bd) at least 2-days before your
              departure.
            </p>
            <p className="allText">
              <strong>Step-3 :</strong> Report to the Admission office at
              Eastern University Campus. The Admission Office will guide you for
              the enrollment.
            </p>
            <p className="allText">Or</p>
            <p className="allText">Apply Online</p>

            <br />
            <p className="heading6">International Student Admission Contact:</p>
            <p className="allText"> Nazla Fatmi</p>
            <p className="allText">Director</p>
            <p className="allText">
              Email: director.admission@easternuni.edu.bd
            </p>
            <p className="allText">Cell: +88 01746 943702 (WhatsApp)</p>
            <br />
            <p className="allText"> Forhad Hossain</p>
            <p className="allText">Assistant Director</p>
            <p className="allText">Email: csio@easternuni.edu.bd</p>
            <p className="allText">Cell: +880 960 2666651-2(Ext:255)</p>
          </div>
        </div>
        <AdmissionSidebar />
      </div>
    </Wrapper>
  );
};

export default AdmissionProcessForInternational;
