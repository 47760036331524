import React, { Component } from 'react';
import TransferStudentsContent from './Content/TransferStudentsContent';
import Footer from '../../component/Footer';

class transferStudents extends Component {
    render() {
        return (
            <div>
                <TransferStudentsContent/>
                <Footer/>
            </div>
        );
    }
}

export default transferStudents;
