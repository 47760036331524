import React from "react";

import Wrapper from "../../component/Wrapper";
import { NavLink } from "react-router-dom";
import AdmissionSidebar from "../component/AdmissionSidebar";

const graduateProgram = () => {
  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 text-center">Graduate Program</p>
          <hr />
          <p className="allText">Current academic programs:</p>

          <NavLink
            className="heading4"
            exact
            to="/facultyOfBusinessAdministration"
          >
            Faculty of Business Administration
          </NavLink>
          <ul>
            <li>
              <p className="allText">
                <NavLink exact to="/programsRegularMBADepartment">
                  MBA (Master of Business Administration)
                </NavLink>
              </p>
            </li>
            <li>
              <p className="allText">
                <NavLink exact to="/programsExecutiveMBADepartment">
                  EMBA (Evening Master of Business Administration)
                </NavLink>
              </p>
            </li>
            <li>
              <p className="allText">
                <NavLink exact to="/programsMBAonIslamicDepartment">
                  MBA in Islamic Finance, Banking and Insurance (Master of
                  Business Administration)
                </NavLink>
              </p>
            </li>
          </ul>

          <NavLink className="heading4" exact to="/facultyOfArts">
            Faculty of Arts
          </NavLink>
          <ul>
            <li>
              <p className="allText">
                <NavLink exact to="/programsMAEnglishDepartment">
                  M.A. ELL (English Language and Literature)
                </NavLink>
              </p>
            </li>
            <li>
              <p className="allText">
                M.A. ELT (English Language Teaching) - 1 year
              </p>
            </li>
          </ul>

          <NavLink className="heading4" exact to="/facultyOfLaw">
            Faculty of Law
          </NavLink>
          <ul>
            <li>
              <p className="allText">
                <NavLink exact to="/programsLLMLawDepartment">
                  LL.M
                </NavLink>
              </p>
            </li>
          </ul>
        </div>
        <AdmissionSidebar />
      </div>
    </Wrapper>
  );
};

export default graduateProgram;
