import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Wrapper from "../../component/Wrapper";
import JournalSideBar from "../component/JournalSideBar";
import useArticles from "../../features/useArticles";
import Spinner from "../../UI/Spinner";
import styled from "styled-components";
import { PiFilePdfDuotone } from "react-icons/pi";
import ReactHtmlParser from "react-html-parser";
import { replace_string, server_django } from "../../util";

const Title = styled.p`
  font-size: 2rem;
  font-weight: 600;
  text-transform: uppercase;
  color: #424242;
`;

const Link = styled.a`
  font-size: 3rem;
  display: flex;
  gap: 2rem;
  align-items: center;
`;

function Article() {
  const { id } = useParams();
  const { articles, isLoading } = useArticles();
  if (isLoading) return <Spinner />;
  const article = articles.find((item) => item.id === Number(id));

  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <Title>{article.title}</Title>
          <p className="allText">
            <strong>Author(s):</strong> {article.writer}
          </p>
          <p className="allText">
            <strong>Abstract:</strong>{" "}
            {ReactHtmlParser(article.abstract?.replace(/\r\n/g, "<br />"))}
          </p>

          {/* <PDFViewer document={LLBCourseDistribution} /> */}
          {/* <iframe title="pdf" width="100%" allow="autoplay" src={article.pdf} /> */}
          {/* <PDFViewer url={LLBCourseDistribution} /> */}
          <Link href={article.pdf.replace(replace_string, server_django)}>
            Download Pdf
            <PiFilePdfDuotone />
          </Link>
        </div>
        <JournalSideBar />
      </div>
    </Wrapper>
  );
}

export default Article;
