import React from 'react';
import InternationalOfficeContent from './content/InternationalOfficeContent';
import Footer from '../../../component/Footer';

const internationalOffice = () => {
    return (
        <div>
            <InternationalOfficeContent />
            <Footer />
        </div>
    );
};

export default internationalOffice;