import React from "react";
import { NavLink } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";
import { departmentCode } from "../../../util";

const leftSideNavigationEEE = () => {
  return (
    <div id="facultyLeftSideMenu" className="sidemenu">
      <Menu vertical className="sidemenuBar">
        <Dropdown text="Home" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <Dropdown.Item>
              <NavLink exact to="/departmentOfEEE">
                EEE Home
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/">
                EU Home
              </NavLink>
            </Dropdown.Item>

            <Dropdown text="About" className="link item sideMenuBarDropdown">
              <Dropdown.Menu>
                <Dropdown.Item>
                  <NavLink exact to="/welcomeMessageEEE">
                    Welcome Message
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/exploringEEE">
                    Exploring EEE EU
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/clubInformationEEE">
                    Clubs Information
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item className="sideBarSingleItem">
          <NavLink exact to="/missionVisionEEE">
            Mission and Vision
          </NavLink>
        </Menu.Item>
        <Dropdown text="Academic" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            {/* Programs */}
            <Dropdown text="Programs" className="link item sideMenuBarDropdown">
              <Dropdown.Menu>
                <Dropdown text="Undergraduate" className="link item">
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <NavLink exact to="/bscinEEEDepartmentProgram">
                        B.Sc in EEE
                      </NavLink>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Dropdown.Menu>
            </Dropdown>

            {/* Admission */}
            <Dropdown
              text="Admission"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <Dropdown.Item>
                  <NavLink exact to="/admissionEEEDepartment">
                    Undergraduate
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* Curriculum */}
            <Dropdown
              text="Curriculum"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <Dropdown.Item>
                  <NavLink exact to="/underGraduateCurriculumEEE">
                    Undergraduate
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="People" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            {/* Faculty Members */}
            {/* <Dropdown
              text="Faculty Members"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <NavLink exact to="/atAGlanceFacultyMembersEEE">
                  <Dropdown.Item>At A Glance</Dropdown.Item>
                </NavLink>
                <NavLink exact to="/currentFacultyMembersEEE">
                  <Dropdown.Item>Current Faculty</Dropdown.Item>
                </NavLink>
                <NavLink exact to="/adjunctCurrentFacultyMembersEEE">
                  <Dropdown.Item>Adjunct Faculty</Dropdown.Item>
                </NavLink>
                <NavLink exact to="/onLeaveFacultyMembersEEE">
                  <Dropdown.Item>On Leave</Dropdown.Item>
                </NavLink>
              </Dropdown.Menu>
            </Dropdown> */}
            <NavLink exact to="/atAGlanceFacultyMembersEEE">
              <Dropdown.Item>Faculty Members</Dropdown.Item>
            </NavLink>
            {/* Officials */}
            <NavLink exact to="/officialsEEE">
              <Dropdown.Item>Officials</Dropdown.Item>
            </NavLink>
          </Dropdown.Menu>
        </Dropdown>

        {/* Lab */}
        {/* <Menu.Item className='sideBarSingleItem'>
                    <NavLink exact to='/labFacilitiesEEE'>Lab Facilities</NavLink>
                </Menu.Item> */}

        <Dropdown text="Research" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <Dropdown.Item>
              <NavLink
                exact
                to={`/publicationsEEE?depid=${departmentCode.eee}`}
              >
                Publications
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/researchGroupEEE">
                Research Groups
              </NavLink>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* Announcement */}
        <Dropdown text="Announcement" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <NavLink exact to="/newsEEE">
              <Dropdown.Item>News</Dropdown.Item>
            </NavLink>
            <NavLink exact to="/upcomingEvents">
              <Dropdown.Item>Upcoming Events</Dropdown.Item>
            </NavLink>
            <NavLink exact to="/noticesEEE">
              <Dropdown.Item>Notice</Dropdown.Item>
            </NavLink>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item className="sideBarSingleItem">
          {/* <NavLink exact to='/alumniEEE'>Alumni</NavLink> */}
          <a
            href={`http://webportal.easternuni.edu.bd/Online_Alumni/_AlumniSearch.aspx?department=${departmentCode.eee}`}
            target="_blank"
          >
            Alumni
          </a>
        </Menu.Item>
        <Menu.Item className="sideBarSingleItemLast">
          <NavLink exact to="/contactUsEEE">
            Contact Us
          </NavLink>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default leftSideNavigationEEE;
