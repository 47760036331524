import { Link } from "react-router-dom/cjs/react-router-dom.min";

import useCurrentIssue from "../../features/useCurrentIssue";
import SpinnerMini from "../../UI/SpinnerMini";

function JournalSideBar() {
  const { currentIssue, isLoading } = useCurrentIssue();
  if (isLoading) return <SpinnerMini />;
  return (
    <div className="OtherpageSideBar">
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <Link to="/journal/about">About</Link>
        </li>
        <li className="list-group-item">
          <Link to="/journal/editor-message"> Message from the Editor</Link>
        </li>

        <li className="list-group-item">
          <Link to="/journal/guidelines"> Paper Submission Guidelines</Link>
        </li>
        <li className="list-group-item">
          <Link to="/journal/editorial-board">Editorial Board</Link>
        </li>
        <li className="list-group-item">
          <Link to="/journal/advisory-board">Board of Advisors</Link>
        </li>
        <li className="list-group-item">
          <Link to={`/issue/${currentIssue[0].id}`}>Current Issue</Link>
        </li>
        <li className="list-group-item">
          <Link to="/issues">Archive</Link>
        </li>
      </ul>
    </div>
  );
}

export default JournalSideBar;
