import React from 'react';
import { NavLink } from 'react-router-dom';


const CampusSidebar = () => {
    return (
        <div className="OtherpageSideBar">
            <ul className="list-group list-group-flush">
                <li className="nav-item dropdown list-group-item">
                    <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        Campus Activities
          </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li className="list-group-item">
                            <NavLink className="dropdown-item" exact to="/studentActivities">
                                Student Activities
              </NavLink>
                        </li>
                        <li className="list-group-item">
                            <NavLink className="dropdown-item" exact to="/facebook">
                                Facebook Link
              </NavLink>
                        </li>
                        <li className="list-group-item">
                            <NavLink className="dropdown-item" exact to="/youtube">
                                Youtube Link
              </NavLink>
                        </li>
                        <li className="list-group-item">
                            <NavLink className="dropdown-item" exact to="/careerServie">
                                CSIO
              </NavLink>
                        </li>
                    </div>
                </li>
                <li className="nav-item dropdown list-group-item">
                    <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        Facilities
          </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li className="list-group-item">
                            <NavLink
                                className="dropdown-item"
                                exact
                                to="/clubsAndExtraCurricular"
                            >
                                Clubs & Extra Curricular Activities
              </NavLink>
                        </li>
                        <li className="list-group-item">
                            <NavLink
                                className="dropdown-item"
                                exact
                                to="/hostelFacilities"
                            >
                                Hostel Facilities
              </NavLink>
                        </li>
                        <li className="list-group-item">
                            <NavLink className="dropdown-item" exact to="/transportFacilities">
                                Transport Facilities
              </NavLink>
                        </li>
                        <li className="list-group-item">
                            <NavLink className="dropdown-item" exact to="/psychoSocialCounselling">
                                Psycho-Social Counselling
              </NavLink>
                        </li>
                    </div>
                </li>
                <li className="nav-item dropdown list-group-item">
                    <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        Students
          </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                        <li className="list-group-item">
                            <NavLink
                                className="dropdown-item"
                                exact
                                to="/noticePage"
                            >
                                Notice Board
              </NavLink>
                        </li>
                        <li className="list-group-item">
                            <NavLink
                                className="dropdown-item"
                                exact
                                to="/studentPortal"
                            >
                                Student Portal
              </NavLink>
                        </li>
                        <li className="list-group-item">
                            <NavLink
                                className="dropdown-item"
                                exact
                                to="/library"
                            >
                                Library
              </NavLink>
                        </li>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default CampusSidebar;