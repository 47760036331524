import React from 'react';
import { NavLink } from 'react-router-dom';


const StudentActivitiesSideBar = () => {
    return (
        <div className='OtherpageSideBar'>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">
                    <NavLink className="nav-link" exact to="/aboutosa">
                        About OSA
                    </NavLink>
                </li>
                <li className="list-group-item">
                    <NavLink className="nav-link" exact to="/studentActivities">
                        Student Activities
                    </NavLink>
                </li>
                <li className="list-group-item">
                    <NavLink className="nav-link" exact to="/coreValues">
                        Core Values
                    </NavLink>
                </li>

                <li className="list-group-item">
                    <NavLink className="nav-link" exact to="/clubsAndExtraCurricular">
                        Clubs & Extra Curricular Activities
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};

export default StudentActivitiesSideBar;