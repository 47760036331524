import React from "react";

import Wrapper from "../../component/Wrapper";
import AdmissionSidebar from "../component/AdmissionSidebar";

import { useOffice } from "../../features/useOffice";
import LoadingBox from "../../component/LoadingBox";
import MessageBox from "../../component/MessageBox";

const AdmissionContact = () => {
  const { office, isLoading, error } = useOffice();

  if (isLoading) return <LoadingBox />;
  if (error) return <MessageBox>{error.message}</MessageBox>;

  const filtered = office.filter((e) => e.name === "Office of the Admission");

  const AdmissionOffice = filtered[0].employees?.map((mylist) => {
    return (
      <div className="col-md-6 col-sm-12" key={mylist.id}>
        <div className="admissionOfficeEmployee">
          <div className="details">
            <p className="allText">{mylist.staff_name}</p>
            <p className="allText">
              {mylist.role.length > 0
                ? mylist.role.map((item) => item.role)
                : mylist.designation.designation}
            </p>
            <p className="allText">{mylist.phone_number}</p>
            <p className="allText">{mylist.official_email}</p>
          </div>
          <div className="image">
            <img
              className="img-fluid"
              src={mylist.staff_picture}
              alt={mylist.staff_name}
            />
          </div>
        </div>
      </div>
    );
  });
  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">
            Admission Contact
          </p>
          <hr />
          <address className="AdmissionLocation">
            <p className="heading1 text-center">Eastern University</p>
            <p className="allText text-center">
              Ashulia Model Town, Ashulia, Dhaka
            </p>
            <p className="allText text-center">
              Contact: <strong>09602666651</strong> ,
              <strong>09602666652</strong>
            </p>
            <p className="allText text-center">
              Email:{" "}
              <strong>
                :
                <a href="mailto:admission@easternuni.edu.bd">
                  {" "}
                  admission@easternuni.edu.bd
                </a>
              </strong>{" "}
              ,
            </p>
            <p className="allText text-center">
              Location:{" "}
              <strong>
                :
                <a href="https://g.page/eastern-university-bangladesh?share">
                  {" "}
                  Click Here
                </a>
              </strong>{" "}
              ,
            </p>
          </address>

          <div className="row">{AdmissionOffice}</div>
        </div>
        <AdmissionSidebar />
      </div>
    </Wrapper>
  );
};

export default AdmissionContact;
