import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap";
import "../css/bootstrap.min.css";
import SubMenubar from "./SubMenubar";
import TopHeader from "../component/TopHeader";
import { img } from "../static";
import { FaBars } from "react-icons/fa";
import {
  aboutImg,
  academicImg,
  admissionImg,
  researchImg,
  newsAndUpdateImg,
} from "../static";
import NavLinkOptions from "../UI/NavLinkOptions";

class Navigation extends Component {
  constructor() {
    super();
    this.state = {
      menu: "",
      ElementStyle: {
        paddingBottom: "3rem",
        backgroundColor: "white",
        width: "45rem",
        marginLeft: "7rem",
      },
    };
  }

  brandImgResize() {
    this.setState({
      ElementStyle: {
        ...this.state.ElementStyle,
        width: "45rem",
        marginLeft: "7rem",
      },
    });
  }
  brandImgResizeHome() {
    this.setState({
      ElementStyle: {
        ...this.state.ElementStyle,
        width: "100vw",
        marginLeft: "0",
      },
    });
  }

  render() {
    return (
      <div id="navMenu">
        <TopHeader />
        <div className="brandImg" style={this.state.ElementStyle}>
          <NavLink
            to="/"
            style={{ backgroundColor: "white" }}
            onClick={this.brandImgResizeHome.bind(this)}
          >
            <img
              src={img}
              className="img-fluid EasternLOGO"
              alt="Eastern University of Bangladesh"
            />
          </NavLink>
          {/* <img src={mujibLogo} className='img-fluid mujibLOGO' alt='Sheikh Mujibur Rahman' /> */}
        </div>

        <Navbar
          collapseOnSelect={true}
          expand="lg"
          className="nav-bar sticky-nav text-left"
          bg="blue"
          variant="dark"
        >
          <Container>
            <Navbar.Brand href="OnlineAdmission" className="mobileViewNavLink">
              Online Admission
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav ml-auto">
              <FaBars color="white" size="2.5rem" />
            </Navbar.Toggle>
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="nav ml-4" activeKey="">
                <Nav.Link
                  exact
                  to="/"
                  onClick={this.brandImgResizeHome.bind(this)}
                  // className={`${isHover ? "active" : ""}`}
                  eventKey="1"
                  as={NavLink}
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  exact
                  to="/aboutEU"
                  // onMouseOver={this.subMenu.bind(this, 'About')}
                  eventKey="2"
                  as={NavLink}
                  onClick={this.brandImgResize.bind(this)}
                >
                  About
                  <div className="sub-menu-block">
                    <div className="leftSideSubMenu">
                      <div className="card">
                        <ul className="list-group list-group-flush">
                          <li className="nav-item dropdown list-group-item">
                            <NavLink className="nav-link" exact to="/aboutEu">
                              EU Profile
                            </NavLink>
                          </li>
                          <li className="nav-item dropdown list-group-item">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Trustees of EU
                            </a>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <li className="list-group-item">
                                <NavLink exact to="/messageFromChairman">
                                  Message from the Chairman
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink exact to="/boardOfTrustees">
                                  Board of Trustees
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink eaxct to="/formerChairmen">
                                  Former Chairmen of EU
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink exact to="/membersofEUTrust">
                                  Members of EU Foundation
                                </NavLink>
                              </li>
                            </div>
                          </li>

                          <li className="nav-item dropdown list-group-item">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Executive Leaders
                            </a>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <li className="list-group-item">
                                <NavLink exact to="/messageFromVC">
                                  Message from the Vice Chancellor
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink exact to="/treasurer">
                                  Treasurer
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink exact to="/deansChairperson">
                                  Deans & Chairpersons
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink exact to="/administration">
                                  Administration
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink exact to="/proctor">
                                  Proctors
                                </NavLink>
                              </li>
                            </div>
                          </li>

                          <li className="nav-item dropdown list-group-item">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Authorities
                            </a>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <li className="list-group-item">
                                <NavLink exact to="/governance">
                                  Governance
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink exact to="/syndicate">
                                  Syndicate
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink exact to="/academicCouncil">
                                  Academic Council
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink eaxct to="/otherCommittees">
                                  Other Committees
                                </NavLink>
                              </li>
                            </div>
                          </li>

                          <li className="nav-item dropdown list-group-item">
                            <NavLink
                              className="nav-link"
                              exact
                              to="/allOtherOffices"
                            >
                              Offices
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="centerSubMenu">
                      <div className="card">
                        <div className="card mt-3">
                          <p>
                            Eastern University is widely known for its quality
                            education, superior faculty composition, excellent
                            academic environment, sincere care for students,
                            extensive co and extra- curricular activities,
                            successful internship and job placement, modern
                            digital library, good governance and administration
                            and convenient location of the campus.
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="rightSideSubMenu">
                      <img
                        src={aboutImg}
                        alt="About Us"
                        className="img-fluid"
                      ></img>
                    </div>
                  </div>
                </Nav.Link>
                <Nav.Link
                  exact
                  to="/academicRulesAndProcedures"
                  // onMouseOver={this.subMenu.bind(this, 'Academic')}
                  eventKey="3"
                  as={NavLink}
                  onClick={this.brandImgResize.bind(this)}
                >
                  Academic
                  <div className="sub-menu-block">
                    <div className="leftSideSubMenu">
                      <div className="card">
                        <ul className="list-group list-group-flush">
                          <li className="nav-item dropdown list-group-item">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Faculties
                            </a>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <li className="list-group-item">
                                <NavLink exact to="/facultyOfArts">
                                  Faculty of Arts
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink
                                  exact
                                  to="/facultyOfBusinessAdministration"
                                >
                                  Faculty of Business Administration
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink exact to="/facultyOfEngineering">
                                  Faculty of Engineering and Technology
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink exact to="/facultyOfLaw">
                                  Faculty of Law
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink exact to="/facultyOfLifeScience">
                                  Faculty of Life Science
                                </NavLink>
                              </li>
                            </div>
                          </li>
                          <li className="nav-item dropdown list-group-item">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Departments
                            </a>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <li className="list-group-item">
                                <NavLink
                                  className="dropdown-item"
                                  exact
                                  to="/departmentOfBusinessAdministration"
                                >
                                  Department of Business Administration
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink
                                  className="dropdown-item"
                                  exact
                                  to="/departmentOfCivil"
                                >
                                  Department of Civil
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink
                                  className="dropdown-item"
                                  exact
                                  to="/departmentOfCSE"
                                >
                                  Department of CSE
                                </NavLink>
                              </li>

                              <li className="list-group-item">
                                <NavLink
                                  className="dropdown-item"
                                  exact
                                  to="/departmentOfEEE"
                                >
                                  Department of EEE
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink
                                  className="dropdown-item"
                                  exact
                                  to="/departmentOfEnglish"
                                >
                                  Department of English
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink
                                  className="dropdown-item"
                                  exact
                                  to="/departmentOfLaw"
                                >
                                  Department of Law
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink
                                  className="dropdown-item"
                                  exact
                                  to="/departmentOfPharmacy"
                                >
                                  Department of Pharmacy
                                </NavLink>
                              </li>
                            </div>
                          </li>

                          <li className="list-group-item nav-item dropdown">
                            <NavLink exact to="/registrationProcedure">
                              Registration Procedures
                            </NavLink>
                          </li>
                          <li className="list-group-item nav-item dropdown">
                            <NavLink exact to="/onlinePaymentProcedure">
                              Online Payment Procedures
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="centerSubMenu">
                      <div className="card">
                        <ul className="list-group list-group-flush">
                          <li className="nav-item dropdown list-group-item">
                            <NavLink exact to="academicCalender">
                              Academic Calender
                            </NavLink>
                          </li>
                          <li className="nav-item dropdown list-group-item">
                            <a
                              className="nav-link dropdown-toggle"
                              href="#"
                              id="navbarDropdown"
                              role="button"
                              data-toggle="dropdown"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Academic Rules and Regulations
                            </a>
                            <div
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <li className="list-group-item">
                                <NavLink
                                  exact
                                  to="/academicRulesAndProcedures"
                                  className="dropdown-item"
                                >
                                  Academic Rules and Procedures
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink
                                  exact
                                  to="/codeOfConduct"
                                  className="dropdown-item"
                                >
                                  Code of Conduct
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink
                                  exact
                                  to="/academicStandingOfAStudent"
                                  className="dropdown-item"
                                >
                                  Academic Standing of a student
                                </NavLink>
                              </li>

                              <li className="list-group-item">
                                <NavLink
                                  exact
                                  to="/testsAndExams"
                                  className="dropdown-item"
                                >
                                  Tests and Exams
                                </NavLink>
                              </li>
                              <li className="list-group-item">
                                <NavLink
                                  exact
                                  to="/rulesOfClassAttendance"
                                  className="dropdown-item"
                                >
                                  Rules of Class Attendance
                                </NavLink>
                              </li>
                            </div>
                          </li>
                          <li className="nav-item dropdown list-group-item">
                            <NavLink className="nav-link" exact to="/iqac">
                              IQAC
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="rightSideSubMenu">
                      <img
                        src={academicImg}
                        alt={academicImg}
                        className="img-fluid"
                      ></img>
                    </div>
                  </div>
                </Nav.Link>
                <Nav.Link
                  exact
                  to="/admissionInformation"
                  // onMouseOver={this.subMenu.bind(this, 'Admission')}
                  onClick={this.brandImgResize.bind(this)}
                  eventKey="4"
                  as={NavLink}
                >
                  Admission
                  {/* <div className="sub-menu-block">
                    <div className="leftSideSubMenu">
                      <div className="card">
                        <ul className="list-group list-group-flush">
                          <li className="nav-item list-group-item">
                            <NavLink exact to="/underGraduateProgram">
                              Undergraduate Program
                            </NavLink>
                          </li>
                          <li className="nav-item list-group-item">
                            <NavLink exact to="/graduateProgram">
                              Graduate Program
                            </NavLink>
                          </li>

                          <li className="nav-item list-group-item">
                            <NavLink exact to="/internationalStudents">
                              International Students
                            </NavLink>
                          </li>
                          <li className="nav-item list-group-item">
                            <NavLink exact to="/howToApply">
                              How to Apply
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="centerSubMenu">
                      <div className="card">
                        <ul className="list-group list-group-flush">
                          <li className="list-group-item">
                            <NavLink exact to="/OnlineAdmission">
                              Online Admission Form
                            </NavLink>
                          </li>

                          <li className="nav-item list-group-item">
                            <NavLink exact to="/tuitionFees">
                              Tuition and Fees
                            </NavLink>
                          </li>
                          <li className="nav-item list-group-item">
                            <NavLink exact to="/financialAidAndScholarship">
                              Financial Aid and Scholarship
                            </NavLink>
                          </li>
                          <li className="nav-item list-group-item">
                            <NavLink exact to="/admissionInformation">
                              Admission Information
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="rightSideSubMenu">
                      <img
                        src={admissionImg}
                        alt="About Us"
                        className="img-fluid"
                      ></img>
                    </div>
                  </div> */}
                  <div className="sub-menu-block">
                    <div className="leftSideSubMenu">
                      <div className="card">
                        <ul className="list-group list-group-flush">
                          <p className="campusHeader">Admission Information</p>

                          <li className="list-group-item">
                            <NavLink exact to="/AdmissionContact">
                              Admission Contact
                            </NavLink>
                          </li>

                          <li className="list-group-item">
                            <NavLink to="/admissionEligibility">
                              Admission Eligibility
                            </NavLink>
                          </li>
                          <li className="nav-item list-group-item">
                            <NavLink exact to="/underGraduateProgram">
                              Undergraduate Program
                            </NavLink>
                          </li>
                          <li className="nav-item list-group-item">
                            <NavLink exact to="/graduateProgram">
                              Graduate Program
                            </NavLink>
                          </li>
                          <li className="nav-item list-group-item">
                            <NavLink exact to="/whyEasternUni">
                              Why do you study in EU?
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="centerSubMenu">
                      <div className="card">
                        <ul className="list-group list-group-flush">
                          <p className="campusHeader"> Guideline </p>
                          <li className="list-group-item">
                            <NavLink
                              exact
                              to="/admissionProcessForNativeStudents"
                            >
                              Admission Process for Native Students
                            </NavLink>
                          </li>
                          <li className="list-group-item">
                            <NavLink
                              exact
                              to="/admissionProcessForInternationalStudents"
                            >
                              Admission Process for International Students
                            </NavLink>
                          </li>
                          <li className="list-group-item">
                            <NavLink exact to="/admissionRequiredDocuments">
                              Admission Required Documents
                            </NavLink>
                          </li>
                          <li className="list-group-item">
                            <NavLink exact to="/creditTransferFacilities">
                              Credit Transfer Facilities
                            </NavLink>
                          </li>
                          <li className="list-group-item">
                            <NavLink exact to="/paymentProcedure">
                              Admission Payment Guideline
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="rightSideSubMenu">
                      <div className="card">
                        <ul className="list-group list-group-flush">
                          <p className="campusHeader">Fees and Scholarship</p>
                          <li className="list-group-item">
                            <NavLink exact to="/OnlineAdmission">
                              Online Admission Form
                            </NavLink>
                          </li>
                          <li className="list-group-item">
                            <NavLink exact to="/tuitionFees">
                              Tuition Fees for Native Students
                            </NavLink>
                          </li>
                          <li className="list-group-item">
                            <NavLink exact to="/feeStructureForForeignStudents">
                              Tuition Fees for International Students
                            </NavLink>
                          </li>
                          <li className="list-group-item">
                            <NavLink exact to="/financialAidAndScholarship">
                              Scholarship for Native Students
                            </NavLink>
                          </li>
                          <li className="list-group-item">
                            <NavLink exact to="/financialAidAndScholarship">
                              Scholarship for Int. Students
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Nav.Link>
                <Nav.Link
                  exact
                  to="/CRD"
                  // onMouseOver={this.subMenu.bind(this, 'Research')}
                  onClick={this.brandImgResize.bind(this)}
                  eventKey="5"
                  as={NavLink}
                >
                  Research
                  <div className="sub-menu-block">
                    <div className="leftSideSubMenu">
                      <div className="card">
                        <ul className="list-group list-group-flush">
                          <li className="nav-item list-group-item">
                            <NavLink exact to="/CRD">
                              Centre for Research and Development
                            </NavLink>
                          </li>
                          {/* <li className="nav-item list-group-item">

                            <NavLink exact to='/researchGroupBusiness'>Department of Business Administration</NavLink>

                          </li> */}

                          <li className="nav-item list-group-item">
                            <NavLink exact to="/researchGroupCSE">
                              Department of Computer Science and Engineering
                            </NavLink>
                          </li>
                          {/* <li className="nav-item list-group-item">

                            <NavLink exact to='/researchGroupEEE'>Department of Electrical and Electronic Engineering</NavLink>
                          </li> */}
                          {/* <li className="nav-item list-group-item">

                            <NavLink exact to='/researchGroupEnglish'>Department of English</NavLink>

                          </li> */}

                          {/* <li className="nav-item list-group-item">
                            <NavLink exact to='/researchGroupLawDepartment'>Department of Law</NavLink>

                          </li> */}
                        </ul>
                      </div>
                    </div>
                    <div className="centerSubMenu">
                      <div className="card mt-3">
                        <ul className="list-group list-group-flush">
                          <li className="nav-item list-group-item">
                            <NavLink exact to="/journal/about">
                              Journal
                            </NavLink>
                          </li>
                        </ul>
                        <p>
                          No research is ever quite complete. It is the glory of
                          a good bit of work that it opens the way for something
                          still better, and this repeatedly leads to its own
                          eclipse.
                        </p>
                      </div>
                    </div>
                    <div className="rightSideSubMenu">
                      <img
                        src={researchImg}
                        alt={researchImg}
                        className="img-fluid"
                      ></img>
                    </div>
                  </div>
                </Nav.Link>
                <Nav.Link
                  exact
                  to="/studentactivities"
                  // onMouseOver={this.subMenu.bind(this, 'CommunityAndServices')}
                  eventKey="6"
                  as={NavLink}
                  onClick={this.brandImgResize.bind(this)}
                >
                  Campus
                  <div className="sub-menu-block">
                    <div className="leftSideSubMenu">
                      <div className="card">
                        <ul className="list-group list-group-flush">
                          <p className="campusHeader">Campus Activities</p>

                          <li className="list-group-item">
                            <NavLink exact to="/studentactivities">
                              Student Activities
                            </NavLink>
                          </li>

                          <li className="list-group-item">
                            <NavLink to="/facebook">Facebook Link</NavLink>
                          </li>
                          <li className="list-group-item">
                            <NavLink to="/youtube">Youtube Link</NavLink>
                          </li>
                          <li className="list-group-item">
                            <NavLink to="/careerServie">
                              Career Services and International Office
                              Activities
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="centerSubMenu">
                      <div className="card">
                        <ul className="list-group list-group-flush">
                          <p className="campusHeader"> Facilities </p>
                          <li className="list-group-item">
                            <NavLink exact to="/clubsAndExtraCurricular">
                              Clubs & Extra Curricular Activities
                            </NavLink>
                          </li>
                          <li className="list-group-item">
                            <NavLink exact to="/hostelFacilities">
                              Hostel Facilities
                            </NavLink>
                          </li>
                          <li className="list-group-item">
                            <NavLink exact to="/transportFacilities">
                              Transport Facilities
                            </NavLink>
                          </li>
                          <li className="list-group-item">
                            <NavLink exact to="/psychoSocialCounselling">
                              Psycho-Social Counselling
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="rightSideSubMenu">
                      <div className="card">
                        <ul className="list-group list-group-flush">
                          <p className="campusHeader">Students</p>
                          <li className="list-group-item">
                            <NavLink exact to="/noticePage">
                              Notice Board
                            </NavLink>
                          </li>
                          <li className="list-group-item">
                            <NavLink exact to="/studentPortal">
                              Student Portal
                            </NavLink>
                          </li>
                          <li className="list-group-item">
                            <NavLink exact to="/library">
                              Library
                            </NavLink>
                          </li>
                          <li className="list-group-item">
                            <NavLink exact to="/transportSchedule">
                              Transport Schedule
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </Nav.Link>
                <Nav.Link
                  exact
                  to="/newsPage"
                  // onMouseOver={this.subMenu.bind(this, 'NewsUpdates')}
                  eventKey="7"
                  as={NavLink}
                  onClick={this.brandImgResize.bind(this)}
                >
                  News & Updates
                  <div className="sub-menu-block">
                    <div className="leftSideSubMenu">
                      <div className="card">
                        <ul className="list-group list-group-flush">
                          <li className="nav-item list-group-item">
                            <NavLink className="nav-link" exact to="/newsPage">
                              News
                            </NavLink>
                          </li>
                          <li className="nav-item list-group-item">
                            <NavLink
                              className="nav-link"
                              exact
                              to="/upcomingEventsPage"
                            >
                              Upcoming Events
                            </NavLink>
                          </li>
                          <li className="nav-item list-group-item">
                            <NavLink
                              className="nav-link"
                              exact
                              to="/noticePage"
                            >
                              Notices
                            </NavLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="centerSubMenu">
                      <div className="card mt-3">
                        <p>
                          Eastern University is widely known for its quality
                          education, superior faculty composition, excellent
                          academic environment, sincere care for students,
                          extensive co and extra- curricular activities,
                          successful internship and job placement, modern
                          digital library, good governance and administration
                          and convenient location of the campus.
                        </p>
                      </div>
                    </div>
                    <div className="rightSideSubMenu">
                      <img
                        src={newsAndUpdateImg}
                        alt={newsAndUpdateImg}
                        className="img-fluid"
                      ></img>
                    </div>
                  </div>
                </Nav.Link>

                <Nav.Link
                  exact
                  to="/login"
                  className="mobileViewNavLink"
                  eventKey="8"
                  as={NavLink}
                >
                  Login
                </Nav.Link>
                <Nav.Link
                  exact
                  to="/library"
                  className="mobileViewNavLink"
                  eventKey="9"
                  as={NavLink}
                >
                  Library
                </Nav.Link>
                <Nav.Link
                  exact
                  to="/contactDirectories"
                  // onMouseOver={this.subMenu.bind(this, 'ContactDirectories')}
                  onClick={this.brandImgResize.bind(this)}
                  eventKey="10"
                  as={NavLink}
                >
                  Contacts
                  <div className="sub-menu-block">
                    <div className="leftSideSubMenu">
                      <div className="card">
                        <ul className="list-group list-group-flush">
                          {/* <li className="nav-item list-group-item">
                            <NavLink
                              className="nav-link"
                              exact
                              to="/chairmanOffice"
                              id="navbarDropdown"
                              role="button"
                              aria-haspopup="true"
                              aria-expanded="false"
                            >
                              Chairman Office
                            </NavLink>
                          </li> */}

                          <NavLinkOptions link="Office of the Vice Chancellor">
                            Vice Chancellor Office
                          </NavLinkOptions>
                          <NavLinkOptions link="Office of the Treasurer">
                            Treasurer Office
                          </NavLinkOptions>
                          <NavLinkOptions link="Office of the Registrar">
                            Registrar Office
                          </NavLinkOptions>
                          <NavLinkOptions link="Office of the Exam Controller">
                            Exam Controller Office
                          </NavLinkOptions>
                          <NavLinkOptions link="Office of the Library">
                            Library Office
                          </NavLinkOptions>
                          <NavLinkOptions link="Office of the Transport">
                            Transport Office
                          </NavLinkOptions>
                        </ul>
                      </div>
                    </div>
                    <div className="centerSubMenu">
                      <div className="card">
                        <ul className="list-group list-group-flush">
                          <NavLinkOptions link="Office of the Admission">
                            Admission Office
                          </NavLinkOptions>
                          <NavLinkOptions link="Office of the Public Relations">
                            Public Relations Office
                          </NavLinkOptions>
                          <NavLinkOptions link="Career Services and International Office">
                            Career Service and International Office
                          </NavLinkOptions>
                          <NavLinkOptions link="Office of the Finance and Accounts">
                            Account Office
                          </NavLinkOptions>
                          <NavLinkOptions link="Office of the IT">
                            IT Office
                          </NavLinkOptions>
                          <NavLinkOptions link="Office of the Engineering Department">
                            Engineering Department Office
                          </NavLinkOptions>
                        </ul>
                      </div>
                    </div>
                    <div className="rightSideSubMenu">
                      <div className="card">
                        <ul className="list-group list-group-flush">
                          <NavLinkOptions link="Office of the Medical Service">
                            Medical Service Office
                          </NavLinkOptions>

                          <NavLinkOptions link="Office of the Counsellor">
                            Counsellor Office
                          </NavLinkOptions>

                          <NavLinkOptions link="Office of Student Affairs">
                            Student Affairs Office
                          </NavLinkOptions>

                          <NavLinkOptions link="Office of the Internal Audit">
                            Internal Audit Office
                          </NavLinkOptions>
                          <NavLinkOptions link="Office of the Procurement and Logistics">
                            Logistics Office
                          </NavLinkOptions>
                          <NavLinkOptions link="Office of Store">
                            Store Office
                          </NavLinkOptions>
                        </ul>
                      </div>
                    </div>
                    <div className="campusAddress">
                      <h1>Eastern University</h1>
                      <p>
                        <i className="fas fa-map-marker-alt"></i>
                        Road 6, Block B, Ashulia Model Town Khagan, Birulia,
                        Savar, Dhaka, Bangladesh
                      </p>
                      <p>
                        <i className="fas fa-mobile-alt"></i> +8809602666651,
                        +8809602666652<br></br>
                        {/* <i className="fas fa-fax"></i>+8802967 5981<br></br> */}
                        <i className="far fa-envelope"></i>
                        info@easternuni.edu.bd,
                        <br></br>
                        registrar@easternuni.edu.bd
                        <br></br>
                        For Admission : +8801844169651, +8801844169659,
                        +8801844169660, +8801741300002
                        <br></br>
                        Email: admission@easternuni.edu.bd
                      </p>
                    </div>
                  </div>
                </Nav.Link>
                <Nav.Link
                  exact
                  to="/alumniDirectory"
                  className="mobileViewNavLink"
                  eventKey="14"
                  as={NavLink}
                >
                  Alumni Directory
                </Nav.Link>
                <Nav.Link
                  exact
                  to="/career"
                  className="mobileViewNavLink"
                  eventKey="11"
                  as={NavLink}
                >
                  Career
                </Nav.Link>
                <Nav.Link
                  exact
                  to="/convocation"
                  className="mobileViewNavLink"
                  eventKey="11"
                  as={NavLink}
                >
                  7th Convocation Registration
                </Nav.Link>
                <Nav.Link
                  exact
                  to="/webmail"
                  className="mobileViewNavLink"
                  eventKey="15"
                  as={NavLink}
                >
                  Webmail
                </Nav.Link>
                <Nav.Link
                  exact
                  to="/OnlineAdmission"
                  eventKey="13"
                  as={NavLink}
                >
                  <span className="blinking">Online Admission*</span>
                </Nav.Link>
                <Nav.Link exact to="/conference" eventKey="14" as={NavLink}>
                  <span>Conference</span>
                </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        {this.state.menu && <SubMenubar MENU={this.state.menu} />}
      </div>
    );
  }
}

export default Navigation;
