import React from "react";
import IqacEventScheduleContent from "./content/IqacEventScheduleContent";
import Footer from "../../../component/Footer";

const iqacEventSchedule = () => {
  return (
    <div>
      <IqacEventScheduleContent />
      <Footer />
    </div>
  );
};

export default iqacEventSchedule;
