import React, { useState } from 'react';
import LeftSideNavigationCSE from '../../component/leftSideNavigationCSE';
import FacultyName from '../../component/facultyName';
import Footer from '../../../../component/Footer';
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const ResearchGroupCSE = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <div>
            <FacultyName name='Department of Computer Science and Engineering' />
            <div className='facultyContent' id='deanArts'>
                <LeftSideNavigationCSE />
                <div className='rightSideContent mt-4'>
                    <p className='heading3'>Research Groups</p>
                    <div id='researchGroup'>
                    <div className="card">
                        <p className='researchName'>
                            1.Graphics, Vision and Digital Signal Processing Research Group
                            
                        </p>
                        <ul className='list-items'>
                            <li>Professor Dr. Md. Mahfuzur Rahman</li>
                            
                        </ul>
                    </div>
                    <div className="card">
                        <p className='researchName'>
                            2.Network, Cyber Security and Digital Forensics Research Group 
                            
                        </p>
                        <ul className='list-items'>
                        <li>Professor Dr. Md. Mahfuzur Rahman</li>
                            <li>Muhammad Mahfuz Hasan</li>
                            <li>Md. Mahir Ashhab</li>
                            <li>Nawshin Tabassum Tanny</li>
                        </ul>
                    </div>
                    <div className="card">
                        <p className='researchName'>
                            3.AI, ML and HCI Research Group 
                            
                        </p>
                        <ul className='list-items'>
                            <li>Mousumi Bala</li>
                            <li>Mohimenul Karim</li>
                            <li>Sefat-E-Rahman</li>
                            <li>Tabeen Tasneem</li>
                            <li>Tazeen Tasneem</li>
                        </ul>
                    </div>
                    <div className="card">
                        <p className='researchName'>
                            4.Information Systems, IoT and Data Analytics Research Group 
                            
                        </p>
                        <ul className='list-items'>
                            <li>Professor Dr. Md. Mahfuzur Rahman</li>
                            <li>Paramita Basak Upama</li>
                            <li>Md. Rashid Abid</li>
                            <li>Tasniah Mohiuddin</li>
                        </ul>
                    </div>
                    <div className="card">
                        <p className='researchName'>
                            5.Advanced Intelligent Multidisciplinary Research Group 
                            
                        </p>
                        <ul className='list-items'>
                            <li>Md. Towfiqur Rahman</li>
                            <li>Mohammad Rifat Arefin</li>
                            <li>Sabrina Haue</li>

                        </ul>
                    </div>
                    </div>
                    {/* Mobile Responsive Start*/}
                    <div
                        id="mobileMenuButton"
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                    >
                        <button>
                            <VscAdd className="moreMenu" />
                        </button>
                    </div>
                    {mobileMenuOpen && (
                        <div id="mobileMenuContent">
                            <button
                                id="closeButton"
                                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                            >
                                <VscChromeClose className="closeButtonIcon" />
                            </button>
                            <LeftSideNavigationCSE />
                        </div>
                    )}
                    {/* Mobile Responsive End*/}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ResearchGroupCSE;