import React, { useState } from 'react';
import LeftSideNavigationLawDepartment from '../../component/leftSideNavigationDepartmentofLaw';
import FacultyName from '../../component/facultyName';
import Footer from '../../../../component/Footer';
import { engFacultyTestImg, LLBCourseDistribution } from '../../../../static';
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const ProgramsLLBLawDepartment = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <div>
            <FacultyName name='Department of Law' facultyImg={engFacultyTestImg} />
            <div className='facultyContent' id='deanArts'>
                <LeftSideNavigationLawDepartment />
                <div className='rightSideContent m-4'>
                    <p className='heading3'>LL.B (Hons.)</p>


                    <p className='heading5'>Degree Requirement</p>
                    <p className='allText'>
                        To qualify for the LL.B. (Hons.), a student has to pass all required courses individually with a minimum Cumulative Grade Point Average (CGPA) of 2.50. Students are required to undertake 130 credit hours.
                    </p>
                    <p className='heading5'>
                        <a href={LLBCourseDistribution}>Download Course Distribution</a>
                    </p>
                </div>
                {/* Mobile Responsive Start*/}
                <div
                    id="mobileMenuButton"
                    onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                    <button>
                        <VscAdd className="moreMenu" />
                    </button>
                </div>
                {mobileMenuOpen && (
                    <div id="mobileMenuContent">
                        <button
                            id="closeButton"
                            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                        >
                            <VscChromeClose className="closeButtonIcon" />
                        </button>
                        <LeftSideNavigationLawDepartment />
                    </div>
                )}
                {/* Mobile Responsive End*/}
            </div>
            <Footer />
        </div>
    );
};

export default ProgramsLLBLawDepartment;