function ConferenceSideBar() {
  return (
    <div className="OtherpageSideBar">
      <ul className="list-group list-group-flush">
        <li className="list-group-item">
          <a className="nav-link" href="#about">
            About
          </a>
        </li>
        <li className="list-group-item">
          <a className="nav-link" href="#deadlines">
            Important Deadlines
          </a>
        </li>
        <li className="list-group-item">
          <a className="nav-link" href="#registration">
            Registration Information
          </a>
        </li>
        <li className="list-group-item">
          <a className="nav-link" href="#guideline">
            Paper submission guidelines
          </a>
        </li>
      </ul>
    </div>
  );
}

export default ConferenceSideBar;
