import OfficeEmployeeList from "./OfficeEmployeeList";

function Offices({ office }) {
  const data = office.map((item) => {
    if (!item.employees.length) return null;
    return (
      <div className="OtherPagecontent">
        <p className="heading2 primaryHeading text-center">{item.name}</p>
        <hr />
        <table className="table officeImg">
          <tbody>
            <OfficeEmployeeList employees={item.employees} />
          </tbody>
        </table>
      </div>
    );
  });

  return data;
}

export default Offices;
