import React from "react";

import Wrapper from "../../component/Wrapper";
import OtherpageSideBar from "../component/OtherpageSideBar";
import { useFetch } from "../../hooks/useFetch";
import Spinner from "../../UI/Spinner";
import MessageBox from "../../component/MessageBox";
import FormerCharimanContent from "./content/FormerCharimanContent";

const FormerChairman = () => {
  const { data, loading, error } = useFetch("api/formerChairman/");
  if (loading) return <Spinner />;
  if (error) return <MessageBox>{error.message}</MessageBox>;
  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">
            Former Chairmen of Trustee Board
          </p>
          <hr />
          <p className="heading4 primaryHeading">Our Glory - Our Heritage</p>
          <hr />
          <div className="formerChairmen">
            {data.map((formerchairman) => (
              <FormerCharimanContent chairman={formerchairman} />
            ))}
          </div>
        </div>
        <OtherpageSideBar />
      </div>
    </Wrapper>
  );
};

export default FormerChairman;
