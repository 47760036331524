import React from "react";

import { NavLink } from "react-router-dom";
import LeftSideBar from "../component/NewsAndUpdatesSideBar";
import useUpcomingEvents from "../../features/useUpcomingEvents";
import LoadingBox from "../../component/LoadingBox";
import MessageBox from "../../component/MessageBox";

function Events() {
  const { events, isLoading, error } = useUpcomingEvents();
  if (isLoading) return <LoadingBox />;

  if (error) return <MessageBox>{error.message}</MessageBox>;
  return (
    <div className="content">
      <div className="OtherPagecontent">
        <hr></hr>
        <p className="heading2 text-center primaryHeading">Events</p>
        <hr></hr>
        <div id="upcomingEventsAll">
          {events.map((event) => {
            return (
              <div className="card" key={event.id}>
                <img
                  className="img-fuild"
                  src={event.PictureLocation}
                  alt={event.Headline}
                ></img>
                <div className="card-body">
                  <p className="card-title heading5">
                    <NavLink exact to={"/upcomingEvents/" + event.id}>
                      {event.Headline}
                    </NavLink>
                  </p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <LeftSideBar />
    </div>
  );
}

export default Events;
