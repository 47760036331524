import React, { useState } from "react";

import { Link } from "react-router-dom";
import LeftSideBar from "../component/NewsAndUpdatesSideBar";
import Footer from "../../component/Footer";
import moment from "moment/moment";

import LoadingBox from "../../component/LoadingBox";
import MessageBox from "../../component/MessageBox";
import Pagination from "../../UI/Pagination";
import useNewsArchive from "../../features/useNewsArchive";

const News = () => {
  const [search, setSearch] = useState("");
  const { news, isLoading, error, count } = useNewsArchive();
  if (isLoading) return <LoadingBox />;
  if (error) return <MessageBox>{error.message}</MessageBox>;

  const filterNews = search
    ? news.filter((item) => item.headline.toLowerCase().indexOf(search) > -1)
    : news;

  function handleSubmit(e) {
    e.preventDefault();
    console.log(e.target);
  }

  const newsDiv =
    filterNews &&
    filterNews.map((newsList) => {
      return (
        <div className="card" key={newsList.id}>
          <img
            className="img-fuild"
            src={newsList.headerImage}
            alt={newsList.headerImage}
          ></img>
          <div className="card-body">
            <div className="newsFooter">
              <div className="newsHeadlines">
                <Link exact to={"/news/" + newsList.id}>
                  <p className="heading5">{newsList.headline}</p>
                </Link>
                <p className="publsihDate">
                  {moment(newsList.newsDate, "YYYY-MM-DD").format("LL")}
                </p>
              </div>
            </div>
          </div>
        </div>
      );
    });

  return (
    <>
      <div className="content">
        <div className="OtherPagecontent">
          <hr></hr>
          <p className="heading2 text-center primaryHeading">News</p>
          <hr></hr>
          {/* <form onSubmit={handleSubmit}>
            <input
              className="px-4 py-2"
              type="text"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="search"
              name="search"
            />
            <button type="submit" className="btn btn-primary px-4 py-2">
              Search
            </button>
          </form> */}

          <div id="newsAll">{newsDiv}</div>
          <Pagination count={count} />
        </div>
        <LeftSideBar />
      </div>
      <Footer />
    </>
  );
};

export default News;
