import React from "react";
import IqacGoalsContent from "./content/IqacGoalsContent";
import Footer from "../../../component/Footer";

const iqacGoals = () => {
  return (
    <div>
      <IqacGoalsContent />
      <Footer />
    </div>
  );
};

export default iqacGoals;
