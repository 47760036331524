import React, { useState } from "react";
import LeftSideNavigationEEE from "../../component/leftSideNavigationEEE";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const ExploringEEE = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName name="Department of Electrical and Electronic Engineering" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationEEE />
        <div className="rightSideContent mt-4">
          <p className="heading4 text-center">
            Exploring Eastern University Electrical & Electronics Engineering
          </p>
          <div className="deanMessage text-left">
            <p className="allText">
              The Department of Electrical and Electronic Engineering (EEE)
              offers a program leading to the degree of B. Sc. in Electrical and
              Electronic Engineering [B.Sc. (EEE)]. The total credit hours for
              completion of the B.Sc. (EEE) program is 149. The program includes
              courses from (1) general areas, namely, English, Physics,
              Chemistry, Mathematics, Arts, Social sciences, Accounting, and (2)
              Engineering disciplines, namely, Electrical and Electronic
              Engineering as major area consisting of (a) Power (b) Electronics
              (c) Communication and (d) Control and Automation. The program
              requires some of the courses as core courses and students have
              option to choose a few courses as elective for specialization. The
              curriculum for the B.Sc. (EEE) has been designed to make the
              program goal-oriented and to fulfil the present and future needs
              of the age.
            </p>
            <p className="heading5">Vision of Dept. of EEE</p>
            <p className="allText">
              To equip the graduates of Electrical and Electronic Engineering
              with high professional skills for the advancement of humanitarian
              technology by ensuring quality education and research excellence.
            </p>
            <p className="heading5">Mission of Dept. of EEE</p>
            <p className="allText">
              <strong>Statement-1:</strong> To provide quality education and
              on-hand practical training and to adapt with the modern
              technologies.{" "}
            </p>
            <p className="allText">
              <strong>Statement-2:</strong> To adapt with competitive world
              through academic research and lifelong learning maintaining
              professional ethics.
            </p>
            <p className="allText">
              <strong>Statement-3:</strong> To ensure collaboration with
              industrial environment for engaging students with real life
              situations.
            </p>
            <p className="heading5">
              The Program Educational Objectives (PEOs):
            </p>
            <p className="allText">
              The Program Educational Objectives (PEOs) are intended to prepare
              graduates professionally eligible after completion of their
              graduation. The purpose of PEOs is to prepare graduates to possess
              the ability to:
            </p>

            <p className="allText">
              <strong>PEO-1:</strong> : Gather knowledge to analyze critical
              engineering problems in order to meet the specific needs of
              humanitarian, safety, cultural and societal considerations.
            </p>
            <p className="allText">
              <strong>PEO-2:</strong> Utilize modern technology taking
              environmental factors into consideration along with human values
              and ethics to contribute to the welfare of people.
            </p>
            <p className="allText">
              <strong>PEO-3: </strong>Prepare the graduates to have effective
              communication skills and to able to work as an individual, team
              member or a team leader in diverse settings exhibiting superior
              managerial skills.
            </p>

            <p className="heading5"> Program Outcomes: </p>
            <p className="allText">
              Program outcomes are mainly focused on developing the students in
              terms of skills, knowledge and behavior during their graduation
              period. There are 12 POs for the engineering students which are
              listed below.
            </p>

            <table className="table table-striped ">
              <thead className="thead-light">
                <tr>
                  <th>POs</th>
                  <th>Knowledge</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th>PO-1</th>
                  <th>Engineering Knowledge</th>
                  <td>
                    Apply knowledge of mathematics, science, engineering
                    fundamentals and an engineering specialization to the
                    solution of complex engineering problems.
                  </td>
                </tr>
                <tr>
                  <th>PO-2</th>
                  <th>Problem Analysis</th>
                  <td>
                    Identify, formulate, research literature and analyze complex
                    engineering problems searching substantiated conclusions
                    using first principles of mathematics, natural sciences and
                    engineering sciences.
                  </td>
                </tr>
                <tr>
                  <th>PO-3</th>
                  <th>Design/ development of solutions</th>
                  <td>
                    Design solutions for complex engineering problems and design
                    systems, components and processes that meet specified needs
                    with appropriate consideration for public health and safety,
                    cultural, societal and environmental considerations.
                  </td>
                </tr>
                <tr>
                  <th>PO-4</th>
                  <th>Investigation</th>
                  <td>
                    Conduct investigations of complex problems using research
                    based knowledge and research methods including design of
                    experiments, analysis and interpretation of data and
                    synthesis of information to provide valid conclusions.
                  </td>
                </tr>
                <tr>
                  <th>PO-5</th>
                  <th>Modern Tool Usage</th>
                  <td>
                    Create, select and apply appropriate techniques, resources
                    and modern engineering and IT tools, including prediction
                    and modeling to complex engineering activities with an
                    understanding of the limitations.
                  </td>
                </tr>
                <tr>
                  <th>PO-6</th>
                  <th>The Engineer and Society</th>
                  <td>
                    Apply reasoning informed by contextual knowledge to assess
                    the societal, health, safety, legal and cultural issues and
                    the consequent responsibilities relevant to professional
                    engineering practice.
                  </td>
                </tr>
                <tr>
                  <th>PO-7</th>
                  <th>Environment and Sustainability</th>
                  <td>
                    Understand the impact of professional engineering solutions
                    in societal and environmental contexts and demonstrate
                    knowledge of and need for sustainable development.
                  </td>
                </tr>
                <tr>
                  <th>PO-8</th>
                  <th>Ethics</th>
                  <td>
                    Apply ethical principles and commit to professional ethics
                    and responsibilities and norms of engineering practice.
                  </td>
                </tr>
                <tr>
                  <th>PO-9</th>
                  <th>Individual and Teamwork</th>
                  <td>
                    Function effectively as an individual, and as a member or
                    leader in diverse teams and in multi-disciplinary settings.
                  </td>
                </tr>
                <tr>
                  <th>PO-10</th>
                  <th>Communication</th>
                  <td>
                    Communicate effectively on complex engineering activities
                    with the engineering community and with society at large,
                    such as being able to comprehend and write effective reports
                    and design documentation, make effective presentations and
                    give and receive clear instructions.
                  </td>
                </tr>
                <tr>
                  <th>PO-11</th>
                  <th>Project Management and Finance</th>
                  <td>
                    Demonstrate knowledge and understanding of engineering and
                    management principles and apply these to one’s own work, as
                    a member and leader in a team, to manage projects and
                    multidisciplinary environments.
                  </td>
                </tr>
                <tr>
                  <th>PO-12</th>
                  <th>Lifelong learning</th>
                  <td>
                    Understand the impact of professional engineering solutions
                    in societal and environmental contexts and demonstrate
                    knowledge of and need for sustainable development.
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* Mobile Responsive Start*/}
          <div
            id="mobileMenuButton"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <button>
              <VscAdd className="moreMenu" />
            </button>
          </div>
          {mobileMenuOpen && (
            <div id="mobileMenuContent">
              <button
                id="closeButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <VscChromeClose className="closeButtonIcon" />
              </button>
              <LeftSideNavigationEEE />
            </div>
          )}
          {/* Mobile Responsive End*/}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ExploringEEE;
