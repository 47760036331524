import moment from "moment";
import React from "react";
import { replace_string, server_django } from "../../../util";

function FormerCharimanContent({ chairman }) {
  return (
    <div className="item">
      <img
        className="img-fuild"
        src={chairman.bot.image.replace(replace_string, server_django)}
        alt={chairman.bot.name}
      ></img>
      <p className="allText text-left">{chairman.bot.name}</p>
      <p className="text-muted">{`${moment(
        chairman.activationDate,
        "YYYY-MM-DD"
      ).format("MMM YYYY")} to ${moment(
        chairman.expiredDate,
        "YYYY-MM-DD"
      ).format("MMM YYYY")} `}</p>
    </div>
  );
}

export default FormerCharimanContent;
