import { Link } from "react-router-dom";
import Wrapper from "../component/Wrapper";
import ConferenceSideBar from "./component/ConferenceSideBar";

function Conference() {
  return (
    <Wrapper>
      <div className="content conference">
        <div className="OtherPagecontent ">
          <Link to="/conferenceBBA" className="heading4">
            Eastern University National Conference -2024
          </Link>
          <p className="allText">
            Fourth Industrial Revolution: Impact on Business Management & Social
            Sciences
          </p>
          <p className="heading5">
            Date: 17 February, 2024 (Saturday: 9:00 a.m. to 4:00 p.m.)
          </p>
          <hr />
          <Link to="/conferenceLaw" className="heading4">
            South Asian Postgraduate Law Conference, 2023
          </Link>
          <p className="allText">
            the second South Asian Postgraduate Law Conference 2023
            (SAPLawC’23), which is to be held through Hybrid mode on 27th and
            28th November 2023 in Colombo Sri Lanka.
          </p>
          <p className="heading5">Date: 27th and 28th November 2023</p>
        </div>
        <div className="OtherpageSideBar">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <Link to="/conferenceBBA">
                Eastern University National Conference -2024
              </Link>
            </li>
            <li className="list-group-item">
              <Link to="/conferenceLaw">
                South Asian Postgraduate Law Conference, 2023
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
}

export default Conference;
