import React from "react";
import Wrapper from "../../component/Wrapper";
import { NavLink } from "react-router-dom";
import AdmissionSidebar from "../component/AdmissionSidebar";

const underGraduateProgram = () => {
  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 text-center">Undergraduate Programs</p>
          <hr />
          <p className="allText">Current academic programs:</p>
          <p className="allText">
            <NavLink className="heading4" exact to="/facultyOfArts">
              Faculty of Arts
            </NavLink>
            <ul>
              <li>
                <p className="allText">
                  <NavLink exact to="/programsBAEnglishDepartment">
                    B.A. (Hons.) in English
                  </NavLink>
                </p>
              </li>
            </ul>
          </p>
          <p className="allText">
            <NavLink
              className="heading4"
              exact
              to="/facultyOfBusinessAdministration"
            >
              Faculty of Business Administration
            </NavLink>
            <ul>
              <li>
                <p className="allText">
                  <NavLink exact to="/programsBBADepartment">
                    BBA (Bachelor of Business Administration)
                  </NavLink>
                </p>
              </li>
              <li>
                <p className="allText">
                  <NavLink exact to="/programsBBAonIslamicDepartment">
                    BBA in Islamic Finance,Banking and Insurance (Bachelor of
                    Business Administration)
                  </NavLink>
                </p>
              </li>
            </ul>
          </p>
          <p className="allText">
            <NavLink className="heading4" exact to="/facultyOfEngineering">
              Faculty of Engineering and Technology
            </NavLink>
            <ul>
              <li>
                <p className="allText">
                  <NavLink exact to="/bscinCivil">
                    B.Sc. in CE (Civil Engineering)
                  </NavLink>
                </p>
              </li>
              <li>
                <p className="allText">
                  <NavLink exact to="/bscinCSEDepartmentProgram">
                    B.Sc. in CSE (Computer Science and Engineering)
                  </NavLink>
                </p>
              </li>
              <li>
                <p className="allText">
                  <NavLink exact to="/bscinEEEDepartmentProgram">
                    B.Sc. in EEE (Electrical and Electronic Engineering)
                  </NavLink>
                </p>
              </li>
            </ul>
          </p>

          <p className="allText">
            <NavLink className="heading4" exact to="/facultyOfLaw">
              Faculty of Law
            </NavLink>
            <ul>
              <li>
                <p className="allText">
                  <NavLink exact to="/programsLLBLawDepartment">
                    LL.B (Hons.)
                  </NavLink>
                </p>
              </li>
            </ul>
          </p>
          <p className="allText">
            <NavLink className="heading4" exact to="/facultyOfLifeScience">
              Faculty of Life Science
            </NavLink>
            <ul>
              <li>
                <p className="allText">
                  <NavLink exact to="/bachelorOfPharmacy">
                    B. Pharm. (Bachelor of Pharmacy)
                  </NavLink>
                </p>
              </li>
            </ul>
          </p>
        </div>
        <AdmissionSidebar />
      </div>
    </Wrapper>
  );
};

export default underGraduateProgram;
