import React, { Component } from "react";
import OtherPageSidebar from "../../component/OtherpageSideBar";

// image
import {
  president,
  Chairman,
  VCSir,
  Treasurer,
  Registrar,
  ChairmanName,
} from "../../../static";

class GovernanceContent extends Component {
  render() {
    return (
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">Governance</p>
          <hr />
          <table className="table officeImg">
            <tbody>
              <tr className="contactInfo">
                <td className="info">
                  <p className="card-title heading4">
                    Mr. Mohammed Shahabuddin
                  </p>
                  <p className="card-text heading5">Hon'ble President</p>
                  <p className="card-text allText">
                    People's Republic of Bangladesh
                  </p>
                  <p className="card-text allText">
                    Chancellor, Eastern University
                  </p>
                </td>
                <td className="contactImg">
                  <img
                    className="img-fluid"
                    src={president}
                    alt={`President of Bangladash Mohammed Shahabuddin`}
                  ></img>{" "}
                </td>
              </tr>
              <tr className="contactInfo">
                <td className="info">
                  <p className="card-title heading4">{ChairmanName}</p>
                  <p className="card-text heading5">Chairman</p>
                  <p className="card-text allText">Board of Trustees</p>
                </td>
                <td className="contactImg">
                  <img
                    className="img-fluid"
                    src={Chairman}
                    alt="Chairman"
                  ></img>{" "}
                </td>
              </tr>
              <tr className="contactInfo">
                <td className="info">
                  <p className="card-title heading4">
                    Professor Dr. Shahid Akhtar Hossain
                  </p>
                  <p className="card-text heading5">Vice Chancellor</p>
                  <p className="card-text allText">
                    Ph.D. in Sustainable Agriculture, University of Queensland,
                    Australia
                  </p>
                  <p className="card-text allText">
                    M.Sc. in Soil Science, University of Dhaka
                  </p>
                  <p className="card-text allText">
                    B. Sc. in Soil Science, University of Dhaka
                  </p>
                </td>
                <td className="contactImg">
                  <img className="img-fluid" src={VCSir} alt="VC  "></img>{" "}
                </td>
              </tr>
              <tr className="contactInfo">
                <td className="info">
                  <p className="card-title heading4">
                    Professor Md. Shamsul Huda
                  </p>
                  <p className="card-title heading5">Treasurer</p>
                  <p className="card-text allText"></p>
                  <p className="card-text allText"></p>
                </td>
                <td className="contactImg">
                  <img
                    className="img-fluid"
                    src={Treasurer}
                    alt="Treasurer sir "
                  ></img>{" "}
                </td>
              </tr>
              <tr className="contactInfo">
                <td className="info">
                  <p className="card-title heading4">Dr. Abul Basher Khan</p>
                  <p className="card-title heading5">Registrar</p>
                  <p className="card-text allText">
                    B.A (Hons.), M.A (Eco.), M.A (Eng.), PhD Fellow (IIUM)
                  </p>
                  <p className="card-text allText"></p>
                </td>
                <td className="contactImg">
                  <img
                    className="img-fluid"
                    src={Registrar}
                    alt="Registrar sir  comes here!"
                  ></img>{" "}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <OtherPageSidebar />
      </div>
    );
  }
}

export default GovernanceContent;
