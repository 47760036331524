import React from 'react';
import AcademicSidebar from '../../component/AcademicSideBar'

const AcademicStandingOfAStudentContent = () => {
    return (
        <div className='content'>
            <div className='OtherPagecontent'>
                <hr></hr>
                <p className='heading2 primaryHeading text-center'>
                    Academic Standing of a Student</p>
                <hr></hr>
                <>
                    <p class="allText">
                        To remain in good standing, a student must maintain a minimum GPA of 2.50
                        in a semester.  The GPA is calculated on the basis of the number of courses
                        completed, including the course(s) in which the student receives "F" grade,
                        until he or she repeats the course and the "F" grade is substituted by a
                        passing grade.
                </p>
                    <br></br>

                    <p class="heading6 primaryHeading">
                        Academic Probation and Dismissals
                    </p>

                    <p class="allText">
                        A student with a GPA of less than 2.50 in a semester is placed on probation,
                        and is allowed a maximum of two semesters to raise his/her GPA. A student
                        who fails to achieve a GPA of 2.50 within two consecutive semesters may be
                        allowed additional chance depending on the improvement made, may be asked to
                        transfer to another degree program or may be dismissed from the university.
                </p>
                    <br></br>

                    <p class="heading6 primaryHeading">
                        Readmission after Academic Dismissal
                    </p>

                    <p class="allText">
                        Students who have been dismissed from the university for academic reasons
                        may apply for readmission.  Such applications would be treated on individual
                        basis. However, upon readmission students may seek credit transfer for courses
                        where they had earned a minimum of C+ grade before dismissal. Readmission is
                        granted only once.
                </p>
                    <br></br>

                    <p class="heading6 primaryHeading">
                        Withdrawal from the Program
                    </p>

                    <p class="allText">
                        1. If a student who has not registered in a semester but is willing to
                        withdraw from a semester or more must apply to the respective Dean/Chairperson.
                        A registered student who wishes to withdraw from a semester (dropping all
                        courses for the semester) must do so within the deadline by contacting the
                        Dean/Advisor of the respective Faculty. Withdrawals are not allowed after the
                        deadline except in the case of verifiable non-academic hardships if granted by
                        the appropriate authority of the University. A statement is entered on the
                        withdrawing student's academic record indicating the official withdrawal.
                        The grade "F" is recorded for students who abandon their courses without
                        officially withdrawing from a semester or from a course.
                    <br></br>
                    2.  A student who has not yet registered in a semester and wishes to
                    temporarily withdraw from the program for a semester or more , must apply
                    to the Dean of the concerned Faculty stating the reason for withdrawal.
                    The Dean may permit withdrawal if the reason is acceptable.
                    <br></br>
                    3. A student who wishes to permanently withdraw from the program may do so
                    by applying to the Dean of the concerned Faculty.
                    <br></br>
                    4. A student who did not formally withdraw from a program and later on wants
                    to register in courses will have to seek permission from the Dean and pay
                    50% of the admission fees.
                </p>
                    <br></br>

                    <p class="heading6 primaryHeading">
                        Re-entry
                    </p>

                    <p class="allText">
                        After the withdrawal period is over, a student may apply for re-entry into
                        the Program and in such case he/she must complete the requirements for the
                        degree at the time of re-entry.
                </p>
                    <br></br>

                    <p class="heading6 primaryHeading">
                        Change of Degree Program
                    </p>

                    <p class="allText">
                        Students seeking to change their Program must submit a written request to
                        the respective Dean/Advisor.  Upon recommendation from the Dean/Advisor of
                        the Faculty to which the student intends to transfer, the appropriate/relevant
                        office of the university shall make the necessary changes in the student's
                        record.
                </p>
                </>

            </div>
            <AcademicSidebar />
        </div>
    );
};

export default AcademicStandingOfAStudentContent;