import React from "react";
import Wrapper from "../../component/Wrapper";
import AdmissionSidebar from "../component/AdmissionSidebar";

const AdmissionRequiredDocuments = () => {
  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">
            Admission Required Documents
          </p>
          <hr />

          <div className="details">
            <p className="heading primaryHeading text-center">
              Necessary Documents during Admission (For the Native Students)
            </p>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">Program</th>
                  <th scope="col">Necessary Documents</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>For Bachelor Program Students</td>
                  <td>
                    <ol style={{ marginLeft: "1rem" }}>
                      <li>
                        Applicants have to bring All SSC and HSC Photocopies of
                        Certificate/ Testimonial and Mark sheet/ Transcript
                        during admission.
                      </li>
                      <li>
                        Three (03) copies of passport size recent formal
                        photographs.
                      </li>
                      <li>
                        Birth Certificate/NID/Passport photocopy. (parents and
                        students).
                      </li>
                      <li>
                        Applicants in the Freedom Fighter category are requested
                        to submit relevant documents.
                      </li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>For Bachelor Program (Diploma Holder) Students</td>
                  <td>
                    <ol style={{ marginLeft: "1rem" }}>
                      <li>
                        Applicants have to bring All SSC and Diploma Photocopies
                        of Certificate/ Testimonial and Mark sheet/ Transcript
                        (From 1st to 8th Semester) during admission.
                      </li>
                      <li>
                        Three (03) copies of passport size recent formal
                        photographs.
                      </li>
                      <li>
                        Birth Certificate/NID/Passport photocopy. (parents and
                        students).
                      </li>
                      <li>
                        Applicants in the Freedom Fighter category are requested
                        to submit relevant documents.
                      </li>
                    </ol>
                  </td>
                </tr>
                <tr>
                  <td>For Master Program</td>
                  <td>
                    <ol style={{ marginLeft: "1rem" }}>
                      <li>
                        Applicants have to bring All (SSC, HSC and Graduation)
                        Photocopies of Certificate/ Testimonial and Mark sheet/
                        Transcript during admission.
                      </li>
                      <li>
                        Three (03) copies of passport size recent formal
                        photographs.
                      </li>
                      <li>
                        Birth Certificate/NID/Passport photocopy. (parents and
                        students).
                      </li>
                      <li>
                        Applicants in the Freedom Fighter category are requested
                        to submit relevant documents.
                      </li>
                      <li>
                        Job Experience Certificate for Executive MBA Program.
                      </li>
                    </ol>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <AdmissionSidebar />
      </div>
    </Wrapper>
  );
};

export default AdmissionRequiredDocuments;
