import React from "react";

import ContactSideBar from "../component/ContactSideBar";
import Footer from "../../component/Footer";

const contactDirectories = () => {
  return (
    <div>
      <div className="content">
        <div id="allOtherOffices">
          <div className="OtherPagecontent">
            <p className="heading2">Admission Office</p>
            <p className="allText">
              Phone: 09602666651 Cell: 01844169651, 01844169659 ,01844169660,
              01741300002
            </p>
            <p className="allText">E-mail: admission@easternuni.edu.bd</p>
            <p className="heading2">Registrar Office</p>
            <p className="allText">Phone: +8809602666651, +8809602666652</p>
            <p className="allText">E-mail: ro@easternuni.edu.bd</p>
          </div>
          <div className="locationContainer">
            <div className="locationText">
              <p className="heading2">Location</p>
              <p style={{ color: "White", fontSize: "2rem" }}>
                Eastern University
              </p>
              <p style={{ color: "White" }}>
                Road 6, Block B, Ashulia Model Town, Akran, Savar Dhaka.
              </p>
            </div>
            <div className="iframeContainer">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5159.97551218465!2d90.31163161172745!3d23.86642867946219!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c1f7ed5f4193%3A0x8159af0d71ff7374!2sEastern%20University!5e0!3m2!1sen!2sbd!4v1649136394775!5m2!1sen!2sbd"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
        <ContactSideBar />
      </div>
      <Footer />
    </div>
  );
};

export default contactDirectories;
