import React from "react";
import { NavLink } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";

const leftSideNavigationDepartmentofLaw = () => {
  return (
    <div id="facultyLeftSideMenu" className="sidemenu">
      <Menu vertical className="sidemenuBar">
        <Dropdown text="Home" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <Dropdown.Item>
              <NavLink exact to="/departmentOfLaw">
                Law Home
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/">
                EU Home
              </NavLink>
            </Dropdown.Item>

            <Dropdown text="About" className="link item sideMenuBarDropdown">
              <Dropdown.Menu>
                <Dropdown.Item>
                  <NavLink exact to="/welcomeMessageLawDepartment">
                    Welcome Message
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/exploringLawDepartment">
                    Exploring Law EU
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/clubInformationLawDepartment">
                    Clubs Information
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="Academic" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            {/* Programs */}
            <Dropdown text="Programs" className="link item sideMenuBarDropdown">
              <Dropdown.Menu>
                <Dropdown text="Undergraduate" className="link item">
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <NavLink exact to="/programsLLBLawDepartment">
                        LL.B (Hons.)
                      </NavLink>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown text="Graduate" className="link item">
                  <Dropdown.Menu>
                    <Dropdown.Item>
                      <NavLink exact to="/programsLLMLawDepartment">
                        LLM
                      </NavLink>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Dropdown.Menu>
            </Dropdown>

            {/* Admission */}
            <Dropdown
              text="Admission"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <Dropdown.Item>
                  <NavLink exact to="/underGraduateAdmissionLawDepartment">
                    Undergraduate
                  </NavLink>
                </Dropdown.Item>

                <Dropdown.Item>
                  <NavLink exact to="/graduateAdmissionLawDepartment">
                    Graduate
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* Curriculum */}
            <Dropdown
              text="Curriculum"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <Dropdown.Item>
                  <NavLink exact to="/underGraduateCurriculumLawDepartment">
                    Undergraduate
                  </NavLink>
                </Dropdown.Item>

                <Dropdown.Item>
                  <NavLink exact to="/graduateCurriculumLawDepartment">
                    Graduate
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="People" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            {/* Faculty Members */}
            {/* <Dropdown text='Faculty Members' className='link item sideMenuBarDropdown'>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <NavLink exact to='/atAGlanceFacultyMembersLawDepartment'>At A Glance</NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink exact to='/currentFacultyMembersLawDepartment'>Current Faculty </NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink exact to='/currentAdjunctFacultyMembersLawDepartment'>Adjunct Faculty</NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink exact to='/onLeaveFacultyMembersLawDepartment'>On Leave</NavLink>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown> */}
            <Dropdown.Item>
              <NavLink exact to="/atAGlanceFacultyMembersLawDepartment">
                Faculty Members
              </NavLink>
            </Dropdown.Item>
            {/* Officials */}
            <Dropdown.Item>
              <NavLink exact to="/officialsLawDepartment">
                Officials
              </NavLink>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* Lab */}
        {/* <Menu.Item className='sideBarSingleItem'>
                    <NavLink exact to='/labFacilitiesLawDepartment'>Lab Facilities</NavLink>
                </Menu.Item> */}

        {/* 
                <Dropdown text='Research' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            
                            <a href='http://dspace.easternuni.edu.bd:8080/xmlui/' target='_blank'>Publications</a>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/researchGroupLawDepartment'>Research Groups</NavLink>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown> */}

        {/* Announcement */}
        <Dropdown text="Announcement" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <Dropdown.Item>
              <NavLink exact to="/newsLawDepartment">
                News
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/upcomingEventsLawDepartment">
                Upcoming Events
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/noticeLawDepartment">
                Notice
              </NavLink>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item className="sideBarSingleItem">
          {/* <NavLink exact to='/alumniLawDepartment'>Alumni</NavLink> */}
          <a
            href="http://webportal.easternuni.edu.bd/Online_Alumni/_AlumniSearch.aspx"
            target="_blank"
          >
            Alumni
          </a>
        </Menu.Item>
        <Menu.Item className="sideBarSingleItemLast">
          <NavLink exact to="/contuctUsLawDepartment">
            Contact Us
          </NavLink>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default leftSideNavigationDepartmentofLaw;
