import React, { useState, useEffect } from "react";
import CampusSideBar from "../../component/CampusSidebar";
import Footer from "../../../component/Footer";
import Clubs from "./Clubs";
import Wrapper from "../../../component/Wrapper";

const AllClubsList = () => {
  return (
    <Wrapper>
      <div className="content">
        <Clubs />
        <CampusSideBar />
      </div>
    </Wrapper>
  );
};

export default AllClubsList;
