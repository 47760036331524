import React from 'react';
import Footer from '../../component/Footer'
import AcademicStandingOfAStudentContent from './content/AcademicStandingOfAStudentContent'

const academicStandingOfAStudent = () => {
    return (
        <div>
            <AcademicStandingOfAStudentContent />
            <Footer />
        </div>
    );
};

export default academicStandingOfAStudent;