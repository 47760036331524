import { useQuery } from "@tanstack/react-query";
import { getArticles } from "../services/apis";

function useArticles() {
  const {
    isLoading,
    data: articles,
    error,
  } = useQuery({
    queryKey: ["articles"],
    queryFn: () => getArticles(),
  });

  return { isLoading, articles, error };
}

export default useArticles;
