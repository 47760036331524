import React from 'react';
import Footer from '../../component/Footer';
import CodeOfConduct from './content/CodeOfConductContent'


const codeOfConduct = () => {
    return (
        <div>
            <CodeOfConduct />
            <Footer />
        </div>
    );
};

export default codeOfConduct;