import React, { useState } from 'react';
import LeftSideNavigationLaw from '../component/leftSideNavigationLaw';
import FacultyName from '../component/facultyName'

import { engFacultyTestImg } from '../../../static';

import Footer from '../../../component/Footer';
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import TutionFee from '../../Admission/Content/TutionFeesContent'


const TutionFeesAndFinalcialAidsLaw = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div>
            <FacultyName name='Faculty of Law' facultyImg={engFacultyTestImg} />
            <div className='facultyContent' id='deanArts'>
                <LeftSideNavigationLaw />
                <div className='rightSideContent'>
                    <TutionFee />
                    {/* Mobile responsive start here */}
                    <div id='mobileMenuButton' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                        <button >
                            <VscAdd className='moreMenu' />
                        </button>
                    </div>
                    {
                        (mobileMenuOpen) && (
                            <div id='mobileMenuContent'>
                                <button id='closeButton' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                    <VscChromeClose className='closeButtonIcon' />
                                </button>
                                <LeftSideNavigationLaw />
                            </div>
                        )
                    }
                    {/* Mobile responsive end here */}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default TutionFeesAndFinalcialAidsLaw;