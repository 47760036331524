import React from "react";
import { NavLink } from "react-router-dom";

const QuickLinks = () => {
  return (
    <div className="quickLinks">
      <ul>
        <li id="departMentList">
          Faculties and Departments
          <div className="sub-menu-block">
            <div id="allDepartmentName">
              <div className="faculties">
                <NavLink
                  exact
                  to="/facultyOfArts"
                  className="heading5 primaryHeading"
                >
                  Faculty of Arts
                </NavLink>
                <div className="list-group">
                  <NavLink
                    exact
                    to="/departmentOfEnglish"
                    className="list-group-item list-group-item-action"
                  >
                    Department of English
                  </NavLink>
                </div>
              </div>
              <div className="faculties">
                <NavLink
                  exact
                  to="/facultyOfBusinessAdministration"
                  className="heading5 primaryHeading"
                >
                  Faculty of Business Administration
                </NavLink>
                <div className="list-group">
                  <NavLink
                    exact
                    to="/departmentOfBusinessAdministration"
                    className="list-group-item list-group-item-action"
                  >
                    Department of Business Administration
                  </NavLink>
                </div>
              </div>
              <div className="faculties">
                <NavLink
                  exact
                  to="/facultyOfEngineering"
                  className="heading5 primaryHeading"
                >
                  Faculty of Engineering and Technology
                </NavLink>
                <div className="list-group">
                  <NavLink
                    exact
                    to="/departmentOfCivil"
                    className="list-group-item list-group-item-action"
                  >
                    Department of Civil Engineering
                  </NavLink>
                  <NavLink
                    exact
                    to="/departmentOfCSE"
                    className="list-group-item list-group-item-action"
                  >
                    Department of Computer Science and Engineering
                  </NavLink>

                  <NavLink
                    exact
                    to="/departmentOfEEE"
                    className="list-group-item list-group-item-action"
                  >
                    Department of Electrical and Electronics Engineering
                  </NavLink>
                </div>
              </div>

              <div className="faculties">
                <NavLink
                  exact
                  to="/facultyOfLaw"
                  className="heading5 primaryHeading"
                >
                  Faculty of Law
                </NavLink>
                <div className="list-group">
                  <NavLink
                    exact
                    to="/departmentOfLaw"
                    className="list-group-item list-group-item-action"
                  >
                    Department of Law
                  </NavLink>
                </div>
              </div>
              <div className="faculties">
                <NavLink
                  exact
                  to="/facultyOfLifeScience"
                  className="heading5 primaryHeading"
                >
                  Faculty of Life Science
                </NavLink>
                <div className="list-group">
                  <NavLink
                    exact
                    to="/departmentOfPharmacy"
                    className="list-group-item list-group-item-action"
                  >
                    Department of Pharmacy
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </li>
        <span className="topnewsSaparator">
          <strong>|</strong>
        </span>
        <li id="alumni">
          Alumni
          <div className="alumniMenu">
            <ul>
              <li className="list-group-item">
                <a
                  target="_blank"
                  href="http://webportal.easternuni.edu.bd/Online_Alumni/_login.aspx"
                >
                  Registration Form
                </a>
              </li>
              <li className="list-group-item">
                <a
                  target="_blank"
                  href="http://webportal.easternuni.edu.bd/Online_Alumni/_AlumniSearch.aspx"
                >
                  Graduates
                </a>
              </li>
            </ul>
          </div>
        </li>
        <span className="topnewsSaparator">
          <strong>|</strong>
        </span>
        <li>
          <NavLink to="/career">Career</NavLink>
        </li>
        <span className="topnewsSaparator">
          <strong>|</strong>
        </span>
        <li>
          <a
            target="_blank"
            href="https://www.google.com/a/easternuni.edu.bd/ServiceLogin?service=mail&passive=true&rm=false&continue=http://mail.google.com/a/easternuni.edu.bd/&ltmpl=default&ltmplcache=2"
          >
            Webmail
          </a>
        </li>
        <span className="topnewsSaparator">
          <strong>|</strong>
        </span>
        <li id="login">
          Login
          <div className="loginMenu">
            <ul>
              <li className="list-group-item">
                <a
                  target="_blank"
                  href="http://webportal.easternuni.edu.bd/employee/_login.aspx"
                >
                  Employee
                </a>
              </li>
              <li className="list-group-item">
                <a
                  target="_blank"
                  href="http://webportal.easternuni.edu.bd/student/_login.aspx"
                >
                  Student
                </a>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default QuickLinks;
