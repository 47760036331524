import Wrapper from "../component/Wrapper";
import { conferenceBBA } from "../static";
import ConferenceSideBar from "./component/ConferenceSideBar";

function ConferenceBBA() {
  return (
    <Wrapper>
      <div className="content conference">
        <div className="OtherPagecontent ">
          <p className="heading1">
            Eastern University National Conference -2024
          </p>
          <p className="heading3">
            Fourth Industrial Revolution: Impact on Business Management & Social
            Sciences
          </p>
          <p className="heading5">
            Date: 17 February, 2024 (Saturday: 9:00 a.m. to 4:00 p.m.)
          </p>
          <p className="heading6">
            Venue: Eastern University. Road 6, Block B, Ashulia Model Town,
            Savar, Dhaka 1345, Bangladesh
          </p>
          <img
            src={conferenceBBA}
            alt="Business Conference 2024"
            className="img-fluid"
          />
          <section id="about">
            <p className="heading4 text-left">About the theme</p>
            <p className="allText">
              The first industrial revolution began in the late 18th century,
              with the advent of mechanization and steam power. The second
              industrial revolution occurred in the late 19th and early 20th
              centuries, with the development of electricity, mass production,
              and the assembly line. The third industrial revolution took place
              in the latter half of the 20th century, with the rise of
              computerization, automation, and the internet. The Fourth
              Industrial Revolution builds on the advancements of the third, but
              is characterized by the convergence of technologies, such as AI,
              robotics, the Internet of Things, and advanced analytics. This
              convergence is blurring the lines between the physical, digital,
              and biological spheres, and creating new possibilities for
              innovation and growth. The Fourth Industrial Revolution also poses
              challenges, such as the displacement of workers due to automation,
              cyber security risks, and the ethical considerations of AI and
              robotics.
            </p>
            <p className="allText">
              Academic research on this area will help to push the boundaries of
              what is currently possible in terms of technology and innovation.
              By conducting research, academics can discover new techniques,
              methods, and technologies that can drive progress in the Fourth
              Industrial Revolution. The Revolution requires a highly skilled
              workforce that can adapt to new technologies and innovate.
              Academic research can help to inform the development of
              educational programs that can prepare workers for the jobs of the
              future.
            </p>
          </section>
          <section id="deadlines">
            <p className="heading4 text-left">Important Deadlines:</p>
            <ul className="px-4">
              <li className="allText">Abstract Submission: 20 December 2023</li>
              <li className="allText">
                Acceptance Notification: 30 December, 2023
              </li>
              <li className="allText">
                Full paper submission: 20 January, 2024
              </li>
              <li className="allText">
                Registration deadline: 10 February, 2024
              </li>
              <li className="allText">Conference event: 17 February, 2024</li>
            </ul>
            <p className="heading4 text-left">
              Paper (abstract/full paper) submission:
            </p>
            <p className="allText">
              <a href="mailto:nationalconference2024@easternuni.edu.bd">
                nationalconference2024@easternuni.edu.bd
              </a>
            </p>
          </section>
          <section id="registration">
            <p className="heading4 text-left">For registration: </p>
            <p className="heading5 text-left">Conference fees:</p>
            <ul className="text-left px-4">
              <li className="allText">General members: TK. 2,500</li>
              <li className="allText">
                Early bird (paid before 5 February, 2024): TK. 2,000
              </li>
              <li className="allText">
                For students: TK. 1500 (ID card needs to be shown)
              </li>
              <li className="allText">
                For internal faculty members: TK. 1500
              </li>
            </ul>
            <p className="heading4 text-left">Notes:</p>
            <ul className="text-left px-4">
              <li className="allText">
                Conference fees include all-day seminar package, meals,
                certificate and bus tour to Eastern University Campus, Ashulia,
                Savar.
              </li>
              <li className="allText">
                Accepted abstracts will be published in conference proceedings.
              </li>
              <li className="allText">
                Papers will be published in blind reviewed special edition of
                Eastern University Journal (selected papers only with
                publication fee)
              </li>
            </ul>
            <p className="heading4 text-left">Areas of paper submission:</p>
            <ul className="text-left px-4">
              <li className="allText">Accounting, Finance & Investment</li>
              <li className="allText">Business & E-commerce</li>
              <li className="allText">Management & Marketing</li>
              <li className="allText">
                Sociology, Social welfare, Economics & Education
              </li>
            </ul>
          </section>
          <section id="guideline">
            <p className="heading5 text-left">Further Information:</p>
            <p className="allText">
              You can also contact the Organizing Committee via
              <a href="mailto:afrojaakter@easternuni.edu.bd" className="px-4">
                afrojaakter@easternuni.edu.bd
              </a>
            </p>

            <p className="heading3">Paper submission guidelines</p>
            <p className="allText">
              The author must follow the following instructions to prepare
              conference paper.
            </p>
            <ol className="px-4">
              <li className="allText">
                <p className="font-weight-bold">Language & number of authors</p>
                <ol type="a" className="px-4">
                  <li>The paper must be in English </li>
                  <li>Numbers of authors would be three</li>
                </ol>
              </li>
              <li className="allText">
                <p className="font-weight-bold">Page layout</p>
                <ol type="a" className="px-4">
                  <li>
                    Paper must be a page size corresponding to Letter Size which
                    is (8.5") wide and (1”) long
                  </li>
                  <li>
                    The margins must be set as follows:Top: 1", Bottom = 1.5",
                    Left &. Right = 1"
                  </li>
                </ol>
              </li>
              <li className="allText">
                <p className="font-weight-bold">Page style</p>
                <ol type="a" className="px-4">
                  <li>All paragraphs must be indented.</li>
                  <li>
                    All paragraphs must be justified (both left0handed and
                    right-handed)
                  </li>
                  <li>Line spacing: Multiple (1.3)</li>
                </ol>
              </li>
              <li className="allText">
                <p className="font-weight-bold">Text font & entire document</p>
                <ol type="a" className="px-4">
                  <li>Times New Roman</li>
                  <li>Title must be in 18pt regular bold font.</li>
                  <li>
                    Heading must be in 12pt (small caps and number should be in
                    numeric) and inner font size is 11pt regular.{" "}
                  </li>
                  <li>
                    Author name must be in 12pt regular bold font. Author
                    affiliation must be in 11pt. email address must be in 10pt
                    courier regular font.
                  </li>
                  <li>
                    Cover page will include the institutional address, phone
                    number & email address of each author.
                  </li>
                </ol>
              </li>
              <li className="allText">
                <p className="font-weight-bold">Figures & tables</p>
                <ol type="a" className="px-4">
                  <li>Figures & tables must be centered in the page. </li>
                </ol>
              </li>
              <li className="allText">
                <p className="font-weight-bold">Figure captions</p>
                <ol type="a" className="px-4">
                  <li>
                    Figures must be numbered using Arabic numerals. Captions
                    must be in 8pt regular font to the below the figure.
                  </li>
                  <li>
                    Every word in a figure caption must be capitalized except
                    for short minor words.
                  </li>
                </ol>
              </li>

              <li className="allText">
                <p className="font-weight-bold">Table captions</p>
                <ol type="a" className="px-4">
                  <li>
                    Tables must be numbered using Arabic numerals. Captions must
                    be in 8pt regular font on the upper side of the table.
                  </li>
                  <li>
                    Every word in a table caption must be capitalized except for
                    short minor words
                  </li>
                </ol>
              </li>
              <li className="allText">
                <p className="font-weight-bold">Page numbers</p>
                <ol type="a" className="px-4">
                  <li>Bottom center, numeric regular number</li>
                </ol>
              </li>
              <li className="allText">
                <p className="font-weight-bold">Reference</p>
                <ol type="a" className="px-4">
                  <li>APA style</li>
                </ol>
              </li>
            </ol>

            <p className="allText" style={{ color: "tomato" }}>
              **Note: paper must be submitted in MS-word version.
            </p>
          </section>
        </div>
        <ConferenceSideBar />
      </div>
    </Wrapper>
  );
}

export default ConferenceBBA;
