import React, { useState } from "react";
import FacultyName from "../component/facultyName";
import LeftSideNavigationEngineering from "../component/leftSideNavigationEngneering";
import { engFacultyTestImg } from "../../../static";
import Footer from "../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import Message from "../../../component/Message";
import { departmentCode } from "../../../util";

const MessageFromCSEChairperson = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const department = departmentCode.cse;

  return (
    <div>
      <div>
        <FacultyName
          name="Faculty of Engineering and Technology"
          facultyImg={engFacultyTestImg}
        />
        <div className="facultyContent" id="deanArts">
          <LeftSideNavigationEngineering />
          <div className="rightSideContent mt-4">
            <Message department={department} messageFrom="Chairperson" />
            {/* Mobile responsive start here */}
            <div
              id="mobileMenuButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <button>
                <VscAdd className="moreMenu" />
              </button>
            </div>
            {mobileMenuOpen && (
              <div id="mobileMenuContent">
                <button
                  id="closeButton"
                  onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                  <VscChromeClose className="closeButtonIcon" />
                </button>
                <LeftSideNavigationEngineering />
              </div>
            )}
            {/* Mobile responsive end here */}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default MessageFromCSEChairperson;
