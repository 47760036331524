import axios from "axios";
import { auth, replace_string, server_django } from "../util";

export async function getSlider() {
  let errors;
  const { data } = await axios
    .get(`${server_django}/slider`, { auth })
    .catch((error) => {
      errors = error.message;
    });

  if (errors) throw new Error("Something went wrong!🔥");

  const newData = data.map((item) => ({
    ...item,
    image: item.image.replace(replace_string, server_django),
  }));

  return newData;
}

export async function getNotices(
  home = false,
  department = false,
  faculty = false
) {
  let errors;

  if (home) {
    const { data } = await axios
      .get(`${server_django}/allNotice/?home='all'`, {
        auth,
      })
      .catch((error) => {
        errors = error.message;
      });

    if (errors) throw new Error("Something went wrong!🔥");

    const newData = data.map((item) => ({
      ...item,
      pictureLocation: item.pictureLocation.replace(
        replace_string,
        server_django
      ),
    }));

    return newData;
  }
  if (department) {
    const { data } = await axios
      .get(`${server_django}/allNotice/?department=${department}`, {
        auth,
      })
      .catch((error) => {
        errors = error.message;
      });

    if (errors) throw new Error("Something went wrong!🔥");

    const newData = data.map((item) => ({
      ...item,
      pictureLocation: item.pictureLocation.replace(
        replace_string,
        server_django
      ),
    }));

    return newData;
  }
  if (faculty) {
    const { data } = await axios
      .get(`${server_django}/allNotice/?faculty=${faculty}`, {
        auth,
      })
      .catch((error) => {
        errors = error.message;
      });

    if (errors) throw new Error("Something went wrong!🔥");

    const newData = data.map((item) => ({
      ...item,
      pictureLocation: item.pictureLocation.replace(
        replace_string,
        server_django
      ),
    }));

    return newData;
  }
}

export async function getCources({ department, isGraduate = "0" }) {
  let errors;

  const { data } = await axios
    .get(
      `${server_django}/course/courses?department=${department}&graduate=${isGraduate}`,
      { auth }
    )
    .catch((error) => {
      errors = error.message;
    });

  if (errors) throw new Error("Something went wrong!🔥");

  return data;
}

export async function getACourse(id) {
  let errors;

  const { data } = await axios
    .get(`${server_django}/course/courses/${id}`, { auth })
    .catch((error) => {
      errors = error.message;
    });

  if (errors) throw new Error("Something went wrong!🔥");

  return data;
}

export async function getNews({ department, faculty }) {
  let errors;

  if (department) {
    const { data } = await axios
      .get(`${server_django}/allNews/?department=${department}`, {
        auth,
      })
      .catch((error) => {
        errors = error.message;
      });

    if (errors) throw new Error("Something went wrong!🔥");

    const newData = data.map((item) => ({
      ...item,
      headerImage: item.headerImage.replace(replace_string, server_django),
      pictureLocation: item.pictureLocation.replace(
        replace_string,
        server_django
      ),
    }));

    return newData;
  }
  if (faculty) {
    const { data } = await axios
      .get(`${server_django}/allNews/?faculty=${faculty}`, {
        auth,
      })
      .catch((error) => {
        errors = error.message;
      });

    if (errors) throw new Error("Something went wrong!🔥");

    const newData = data.map((item) => ({
      ...item,
      headerImage: item.headerImage.replace(replace_string, server_django),
      pictureLocation: item.pictureLocation.replace(
        replace_string,
        server_django
      ),
    }));

    return newData;
  }

  if (!department) {
    const { data } = await axios
      .get(`${server_django}/api/newsHome`, { auth })
      .catch((error) => {
        errors = error.message;
      });

    if (errors) throw new Error(errors);

    const newData = data.results?.map((item) => ({
      ...item,
      headerImage: item.headerImage.replace(replace_string, server_django),
      pictureLocation: item.pictureLocation.replace(
        replace_string,
        server_django
      ),
    }));
    return newData;
  }
}

export async function getNewsArchive({ page }) {
  let errors;
  const { data } = await axios
    .get(`${server_django}/news/?p=${page}`, { auth })
    .catch((error) => {
      errors = error.message;
    });

  if (errors) throw new Error("Something went wrong!🔥");

  const newData = data.results.map((item) => ({
    ...item,
    headerImage: item.headerImage?.replace(replace_string, server_django),
    pictureLocation: item.pictureLocation?.replace(
      replace_string,
      server_django
    ),
  }));

  return { data: newData, count: data.count };
}

export async function getNoticeArchive({ page }) {
  let errors;
  const { data } = await axios
    .get(`${server_django}/notice/?p=${page}`, { auth })
    .catch((error) => {
      errors = error.message;
    });

  if (errors) throw new Error("Something went wrong!🔥");

  const newData = data.results?.map((item) => ({
    ...item,
    pictureLocation: item.pictureLocation.replace(
      replace_string,
      server_django
    ),
  }));

  return { data: newData, count: data.count };
}

export async function getOffices() {
  let errors;
  const { data } = await axios
    .get(`${server_django}/employee/office`, { auth })
    .catch((error) => {
      errors = error.message;
    });

  if (errors) throw new Error("Something went wrong!🔥");

  const newData = data.map((item) => ({
    ...item,
    employees: item.employees.map((employee) => ({
      ...employee,
      staff_picture: employee.staff_picture.replace(
        replace_string,
        server_django
      ),
    })),
  }));

  return newData;
}

export async function getUpcomingEvents() {
  let errors;
  const { data } = await axios
    .get(`${server_django}/events`, { auth })
    .catch((error) => {
      errors = error.message;
    });

  if (errors) throw new Error("Something went wrong!🔥");

  const newData = data.results?.map((item) => ({
    ...item,
    pictureLocation: item.pictureLocation.replace(
      replace_string,
      server_django
    ),
  }));

  return newData;
}

export async function getJournals() {
  let errors;
  const { data } = await axios
    .get(`${server_django}/journal/journal`, { auth })
    .catch((error) => {
      errors = error.message;
    });

  if (errors) throw new Error("Something went wrong!🔥");

  return data;
}

export async function getIssues() {
  let errors;
  const { data } = await axios
    .get(`${server_django}/journal/issues`, { auth })
    .catch((error) => {
      errors = error.message;
    });

  if (errors) throw new Error("Something went wrong!🔥");

  return data;
}

export async function getArticles() {
  let errors;
  const { data } = await axios
    .get(`${server_django}/journal/articles`, { auth })
    .catch((error) => {
      errors = error.message;
    });

  if (errors) throw new Error("Something went wrong!🔥");

  return data;
}

export async function getCurrentIssue() {
  let errors;
  const { data } = await axios
    .get(`${server_django}/journal/current-issue`, { auth })
    .catch((error) => {
      errors = error.message;
    });

  if (errors) throw new Error("Something went wrong!🔥");

  return data;
}

export async function getFacultyMembers() {
  let errors;
  const { data } = await axios
    .get(`${server_django}/employee/facultyMembers`, { auth })
    .catch((error) => {
      errors = error.message;
    });

  if (errors) throw new Error("Something went wrong!🔥");

  return data;
}

export async function getAlbumImages(id) {
  let errors;
  const { data } = await axios
    .get(`${server_django}/albumimage/${id}`, { auth })
    .catch((error) => {
      errors = error.message;
    });

  if (errors) throw new Error("Something went wrong!🔥");

  const newData = data.map((item) => {
    return {
      ...item,
      picture_location: `${server_django}${item.picture_location}`,
    };
  });

  return newData;
}

export async function getEuFoundationMembers() {
  let errors;
  const { data } = await axios
    .get(`${server_django}/api/eu-foundation`, { auth })
    .catch((error) => {
      errors = error.message;
    });

  if (errors) throw new Error("Something went wrong!🔥");

  return data;
}

export async function getDepartmentPublications(depid) {
  let errors;
  const { data } = await axios
    .get(`${server_django}/employee/departmentPublication/?depid=${depid}`, {
      auth,
    })
    .catch((error) => {
      errors = error.message;
    });

  if (errors) throw new Error("Something went wrong!🔥");

  return data;
}
