import React, { Component } from 'react';
import ApplyOnlineContent from './Content/ApplyOnlineContent';

import Footer from '../../component/Footer';

class applyOnline extends Component {
    render() {
        return (
            <div>
                <ApplyOnlineContent/>
                <Footer/>
            </div>
        );
    }
}

export default applyOnline;