import React, { useState } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';
import ReactHtmlParser from 'react-html-parser';

const PublicationList = ({ Name, Publications }) => {
    const [showInfo, setShowInfo] = useState(false);
    return (
        <div className='publications'>
            <header>
                <h4>{Name}</h4>
                <button className='btn' onClick={() => setShowInfo(!showInfo)}>
                    {showInfo ? <AiOutlineMinus /> : <AiOutlinePlus />}
                </button>
            </header>
            {showInfo && <p className='allText'>{ReactHtmlParser(Publications)}</p>}
        </div>
    );
};

export default PublicationList;