import { useQuery } from "@tanstack/react-query";

import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { getDepartmentPublications } from "../services/apis";

function useDepartmentPublications() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const param = searchParams.get("depid");

  //   useEffect(() => {
  //     // issue side-effect
  //   }, [param]);
  const {
    isLoading,
    data: publications,
    error,
  } = useQuery({
    queryKey: ["department-publications", param],
    queryFn: () => getDepartmentPublications(param),
  });

  return { isLoading, publications, error };
}

export default useDepartmentPublications;
