import React, { useState } from "react";
import LeftSideNavigation from "../component/leftSideNavigationArts";
import { facultyImgArts } from "../../../static";
import FacultyName from "../component/facultyName";
import Footer from "../../../component/Footer";
import { VscChromeClose } from "react-icons/vsc";
import { VscAdd } from "react-icons/vsc";
import { departmentCode } from "../../../util";
import Message from "../../../component/Message";

const ChairpersonMessageArts = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const config = {
    department: departmentCode.english,
  };
  return (
    <div>
      <FacultyName name="Faculty of Arts" facultyImg={facultyImgArts} />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigation />
        <div className="rightSideContent mt-4">
          <div className="deanMessage text-left">
            <Message department={config.department} messageFrom="Chairperson" />
          </div>
          {/* Mobile responsive start here */}
          <div
            id="mobileMenuButton"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <button>
              <VscAdd className="moreMenu" />
            </button>
          </div>
          {mobileMenuOpen && (
            <div id="mobileMenuContent">
              <button
                id="closeButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <VscChromeClose className="closeButtonIcon" />
              </button>
              <LeftSideNavigation />
            </div>
          )}
          {/* Mobile responsive end here */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ChairpersonMessageArts;
