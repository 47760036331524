import React, { useState } from 'react';
import FacultyName from '../component/facultyName';
import Footer from '../../../component/Footer';
import LeftSideNavigationEngineering from '../component/leftSideNavigationEngneering';
import { engFacultyTestImg } from '../../../static';
import { CSECourseDistridution } from '../../../static';
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const BscinCSE = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div>
            <FacultyName name='Faculty of Engineering and Technology' facultyImg={engFacultyTestImg} />
            <div className='facultyContent' id='deanArts'>
                <LeftSideNavigationEngineering />
                <div className='rightSideContent m-4'>
                    <p className='heading3'>B.Sc. in CSE (Computer Science and Engineering)</p>
                    <p className='heading4'>
                        <a href={CSECourseDistridution}>Download Course Distribution</a>
                    </p>
                    <p className='allText'>
                        The department of CSE was eastablished in 2003. The department is housed in campus II. Currently the Department offers Bachelor of Science in Computer Science and Engineering Degree and graduates of this program have already secured excellent and prestigious jobs in the ICT industry of the countrty. The credit required for completion of the B.Sc. (CSE) program is 150.
                    </p>
                    <p className='allText'>
                        The department, science inception, has gradually advanced in terms of logistics and a good number of quality faculty members. At present the department has 13 full time and 8 part-time faculty members, who are highly qualifed and have completed their graduation from Dhaka University and BUET. There are well equipped Software Labs, Networking Lab, Microprocessor Lab, DLD Lab and Electrical and Electronic Engineering Lab.
                    </p>
                    <p className='allText'>
                        Apart from academic activities, the students of the Department regularly take part in different co-curricular and extra-curricular activities such as programming conttest, debates, sprots competitions, games contests, welfare programs, etc.
                    </p>
                    <p className='heading5'>
                        Entry Requirement
                    </p>
                    <p className='allText'>
                        Candidates with minimum GPA of 2.50 in both SSC/ Dhakil and HSC/ Alim/ Diploma from Technical Education Board or minimum GPA of 2.00 in one of those exams with total GPA of 6.00 in both the exams are eligible for admission. The GCE students must have passed 5 (five) subjects in O-Level with a minimum GPA of 2.50 and 2 (Two) subjects in A-Level a minimum GPA of 2.00. One "E" grade willbe accepted in "O" level or "A" level .
                    </p>
                    <p className='allText'>
                        In addition to the above requirements, for admissionin CSE Department, the candidate must have science background in both SSC and HSC (or in equivalent level) as well as the candidate must have mathematics at HSC Level (or in equivalent level).
                    </p>

                    <p className='heading5'>Duration of the program</p>
                    <p className='allText'>
                        The duration for B. Sc. in Computer Science and Engineering program is four years divided into 12 semesters
                    </p>
                    <p className='heading5'>Graduation Requirement</p>
                    <p className='allText'>
                        To obtain BSCSE degree a student will have to complete 150.0 cr. hrs. with a minimum CGPA of 2.5.
                    </p>
                </div>
                {/* Mobile responsive start here */}
                <div id='mobileMenuButton' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                    <button >
                        <VscAdd className='moreMenu' />
                    </button>
                </div>
                {
                    (mobileMenuOpen) && (
                        <div id='mobileMenuContent'>
                            <button id='closeButton' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                <VscChromeClose className='closeButtonIcon' />
                            </button>
                            <LeftSideNavigationEngineering />
                        </div>
                    )
                }
                {/* Mobile responsive end here */}
            </div>
            <Footer />
        </div>
    );
};

export default BscinCSE;