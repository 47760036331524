import React, { Component } from 'react';
import Admissionsidebar from '../../component/AdmissionSidebar';

class AdmissionRequirementsContent extends Component {
    render() {
        return (
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr/>
                    <p className='heading2 primaryHeading text-center'>
                        Admission Requirements
                    </p>
                    <hr/>
                    <p className='heading4 primaryHeading'>
                        General Requirements
                    </p>
                    <p className='heading5 primaryHeading'>
                        Application forms	
                    </p>
                    <p className='allText'>
                        Application forms are available at: Road 6, Block B, Ashulia Model Town, Savar, Dhaka-1345. You can also download the form by clicking on the “How to Apply” link on the left.
                    </p>
                    <p className='heading5 primaryHeading'>
                        Papers need to be submitted (Undergraduate):
                    </p>
                    <p className='allText'>
                        <ol className='allText'  style={{marginLeft:"5rem"}}>
                            <li>	Three copies of recent colored passport sized photographs.</li>
                            <li>	Photocopies ofAll Academic Mark-sheets and Certificates.</li>
                            <li>	Photocopy of Students and Parents NID Card</li>
                            <li>	Applicants in Freedom Fighter category are requested to submit relevant documents.</li>
                        </ol>
                    </p>
                    <p className='heading5 primaryHeading'>
                        Note: Admission test is waived for candidates with combined GPA 7.00. However, they may have to face an interview.
                    </p>
                    <p className='allText'>
                        Transfer of credits from comparable educational institutions may be considered before admission. Rules on credit transfer are available from the Admissions Office.
                    </p>
                    <p className='heading5 primaryHeading'>
                        Papers need to be submitted (Graduate):
                    </p>
                    <p className='allText'>
                        <ol className='allText'  style={{marginLeft:"5rem"}}>
                            <li>Three copies of recent colored passport sized photographs.</li>
                            <li>Photocopies of All Academic Mark-sheets and Certificates.</li>
                            <li>Photocopy of Students and Parents NID Card</li>
                            <li>Applicants in Freedom Fighter category are requested to submit relevant documents.</li>
                            <li>Job Experience Certificate for <b>Executive MBA Program</b></li>
                        </ol>
                    </p>
                    <p className='allText'>
                        Transfer of credits from comparable educational institutions may be considered before admission. Rules on credit transfer are available from the Admissions Office.
                    </p>
                    <p className='allText'>
                        Incomplete applications will not be accepted. Any applicant who gives false information during the application process will be immediately disqualified from the application process or summarily dismissed from the university.
                    </p>
                    <p className='heading5 primaryHeading'>
                        Payable during Admission
                    </p>
                    <p className='allText'>
                        <ul className='allText'  style={{marginLeft:"5rem"}}>
                            <li>Admission Form= Tk. 500</li>
                            <li>Admission Fee Tk. 10,000 + Registration Fee Tk. 7,000 + Security Deposit Tk. 2,000 = Total Tk. 19,000</li>
                        </ul>
                    </p>
                    <p className='heading5 primaryHeading'>
                        Total Payable during Admission = Tk. 19,500
                    </p>
                    <p className='heading5 primaryHeading'>
                        Admission Test
                    </p>
                    <p className='allText'>
                        EU will call applicants who meet the minimum eligibility requirements for an Admission Test, which will comprise of a written test and, in some cases, a departmental interview. 
                    </p>
                </div>
                <Admissionsidebar/>
            </div>
        );
    }
}

export default AdmissionRequirementsContent;