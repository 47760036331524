import React from "react";
import IqacMembersContent from "./content/IqacMembersContent";
import Footer from "../../../component/Footer";

const iqacMembers = () => {
  return (
    <div>
      <IqacMembersContent />
      <Footer />
    </div>
  );
};

export default iqacMembers;
