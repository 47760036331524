import React, { useState } from "react";
import LeftSideNavigationCSE from "../../component/leftSideNavigationCSE";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import { CSECourseDistridution } from "../../../../static";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const BscinCSEDepartmentProgram = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName name="Department of Computer Science and Engineering" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationCSE />
        <div className="rightSideContent mt-4">
          <p className="heading3">
            B.Sc. in CSE (Computer Science and Engineering)
          </p>
          <p className="heading5">Objectives of the Program Offering Entity</p>
          <p className="allText">
            To produce skilled graduates who can pursue their career and make a
            contribution to the society by their professional skill.
          </p>
          <p className="heading5">Name of the Degree</p>
          <p className="allText">
            B. Sc. in Computer Science and Engineering (B. Sc. in CSE)
          </p>
          <p className="heading5">Description of the Program</p>
          <p className="allText">
            The Department of Computer Science and Engineering offers program
            leading to the degree of B.Sc. in Computer Science and Engineering
            (CSE). According to the UGC guideline the curricula has been
            designed and prepared to make the program more goal-oriented to
            fulfill the needs of the age. This review has been performed mainly
            for converting the syllabus from trimester to semester system.
            Furthermore some new important and updated courses have been added
            to make the foundation of the participants stronger with diversified
            knowledge.
          </p>

          <p className="heading5">Admission Requirements</p>
          <ul className="px-2">
            <li>
              <p className="allText">
                Minimum GPA 2.50 each at SSC and HSC /Diploma (If GPA is below
                2.50 then minimum GPA 2.00 and total GPA 6.00 is required
              </p>
            </li>
            <li>
              <p className="allText">
                ‘O’-level in 5(five) subjects and ‘A’ level in 2 (two) major
                subjects with minimum ‘B’ grade or GPA 4.00 in four subjects and
                ‘C’ grade or GPA 3.50 in three subjects.
              </p>
            </li>
            <li>
              <p className="allText">
                The applicants must have science background in both SSC and HSC
                (or in equivalent level)
              </p>
            </li>
            <li>
              <p className="allText">
                The applicants must have Mathematics in HSC (or in equivalent
                level).
              </p>
            </li>
          </ul>

          <p className="heading5">Duration of the program</p>
          <p className="allText">
            The duration for B. Sc. in Computer Science and Engineering program
            is four years divided into 8 semesters
          </p>

          <p className="heading5">
            Total minimum credit requirement to complete the program : 155
          </p>
          <p className="heading5">Total class weeks in a semester : 14</p>

          <p className="heading5">
            <a href={CSECourseDistridution}>
              Download Semester Wise Course Distribution
            </a>
          </p>
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationCSE />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </div>
  );
};

export default BscinCSEDepartmentProgram;
