import React, { useState } from "react";
import LeftSideNavigationBusinessDepartment from "../../component/leftSideNavigationDepartmentofBusiness";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const MissionandVissionBusiness = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName name="Department of Business Administration" />
      <div className="facultyContent">
        <LeftSideNavigationBusinessDepartment />
        <div className="rightSideContent mt-4">
          <p className="heading3 text-center">
            Departmental Vision and Mission
          </p>
          <div>
            <p className="heading5">Vision of Dept. of Business</p>
            <p className="allText">
              The vision of the school of business is transforming individuals
              for the global business need through quality education.
            </p>
            <p className="heading5">Mission of Dept. of Business</p>
            <p className="allText">
              <strong>Mission-1:</strong> Bringing positive changes with real
              life knowledge and example sharing.
            </p>
            <p className="allText">
              <strong>Mission-2:</strong> Sharpening the skill levels of
              students by the most effective teaching methods.
            </p>
            <p className="allText">
              <strong>Mission-3:</strong> Creating corporate liaison
              continuously for students to be absorbed.
            </p>
            <p className="allText">
              <strong>Mission-4:</strong> Organizing exclusive programs for
              students regularly to enhance multidisciplinary knowledge.
            </p>
          </div>
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationBusinessDepartment />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </div>
  );
};

export default MissionandVissionBusiness;
