import { useQuery } from "@tanstack/react-query";
import { getNotices } from "../services/apis";
export function useNotices(noticeConfig = {}) {
  const { home, faculty, department } = noticeConfig;
  const {
    data: notices,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["notices", home, department, faculty],
    queryFn: () => getNotices(home, department, faculty),
    retry: 3,
  });
  return { notices, isLoading, error };
}
