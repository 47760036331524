import React, { Component } from 'react';
import AdmissionSidebar from '../../component/AdmissionSidebar';

class WhyEasternUniContent extends Component {
    render() {
        return (
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr />
                    <p className='heading2 primaryHeading text-center'>
                        Why Eastern University
                    </p>
                    <hr />
                    <p className='heading6 primaryHeading'>
                        Government & UGC Approved and Recognized Leader in Higher Education:
                    </p>
                    <p className='allText'>

                        Eastern University and its campus, the education programs and curricula,
                        and the governing policies—all are approved by the University Grants
                        Commission. The high quality of education, democratic governance of the
                        University, its strict adherence to laws, policies and rules, impartial
                        selection of students, faculty and staff and non interference in its
                        academic and administrative affairs from any one or from any quarter—all
                        of these have, over the years, established Eastern University as a
                        proven leader and role-model in the area of higher education.
                        The University now enjoys the reputation of being one of the best and
                        youngest universities in Bangladesh.
                    </p>
                    <p className='heading6 primaryHeading'>
                        Student Growth:
                    </p>
                    <p className='allText'>

                        The number of students enrolled at Eastern has steadily grown, as
                        evidenced from the graph below, in the past few years and now it enjoys
                        a healthy number of students where the University can provide the
                        finest education irrespective of where the student comes from or which
                        department they are enrolled in.
                    </p>
                    <p className='heading6 primaryHeading'>
                        Faculty Strength:
                    </p>
                    <p className='allText'>
                        Eastern University hires top-of-the class, talented graduates as junior
                        faculty, experienced teachers and researchers as senior faculty and
                        professionally accomplished experts as adjunct faculty. Eastern also
                        promotes professional development of all faculty through a series of
                        programs and workshops with the goal to establish the best faculty at any
                        university.
                    </p>
                    <p className='heading6 primaryHeading'>
                        High Quality Education:
                    </p>
                    <p className='allText'>
                        Eastern University is seriously committed to, and aggressively pursues, the
                        goal of providing the highest quality education. With innovative and
                        student-centered, discussion, and case-study based instruction method,
                        forward looking and practical curricula, carefully selected students,
                        highly qualified faculty ensure this high quality education.
                    </p>
                    <p className='heading6 primaryHeading'>
                        Superior Internship Experience at Recognized Industries:
                    </p>
                    <p className='allText'>
                        The BBA and MBA Program curricula requires its students to undergo a
                        one semester, full time internship at a well-recognized business
                        organization. This allows our students to gain valuable job experience.
                        Engineering & Technology students are required to work on projects
                        which also gives them valuable experiential learning. The moot-court
                        practice helps Law students gain practical experience of a law court.
                        The Arts students go through thesis preparation that help them gain
                        mastery over the subject as well as exposure to real life exercise.
                    </p>
                    <p className='heading6 primaryHeading'>
                        Job Opportunity:
                    </p>
                    <p className='allText'>
                        Graduates from the Eastern are often employed at the best and recognized
                        organizations in the country, as well as in reputed multinational and
                        national companies like Standard Chartered Bank, HSBC Bank, General
                        Electric BEXIMCO, SQUARE, PRAN, Partex, etc. Many students are hired
                        even before their graduation by the organizations where they do
                        internships. Others get jobs soon after graduation, either through
                        attending Job-Fairs organized by Eastern, or through aggressive
                        individual effort or joint effort with the Career Service Office at
                        Eastern.
                    </p>
                    <p className='heading6 primaryHeading'>
                        Affordable Fees:
                    </p>
                    <p className='allText'>
                        The Founders have set up Eastern University with a spirit of social
                        service – not for profit. Compared to the high quality education
                        provided by Eastern, the tuition and other fees are considered very
                        reasonable compared to other, similar universities.
                    </p>
                    {/* <p className='heading6 primaryHeading'>
                        Convenient Location:
                    </p>
                    <p className='allText'>
                        The campus is conveniently located at the center of Dhanmandi residential
                        area and can be accessed from any part of Dhaka by public transportation
                        as well as taxi cab and rickshaw.
                    </p> */}
                </div>
                <AdmissionSidebar />
            </div>
        );
    }
}

export default WhyEasternUniContent;