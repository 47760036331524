import React, { useState } from "react";
import LeftSideNavigationBusinessDepartment from "../../component/leftSideNavigationDepartmentofBusiness";
import FacultyName from "../../component/facultyName";
import { engFacultyTestImg } from "../../../../static";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const ExploringBusinessDepartment = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const welcomeMessage1 =
    "The Faculty of Business Administration at Eastern University is one of the leading institutions among the private universities renowned for business education of the highest quality to the students and practitioners. Here, students find a great blend of theoretical knowledge with practical knowledge and become equipped with the knowledge, tools and techniques ready to contribute to the success of their employers right from the beginning. It is not surprising that you will find Eastern University business graduates are found in leading and senior positions of major business organizations in the country. ";
  const welcomeMessagePart2 =
    "In 2015, in order to prepare our expanding enrolment for BBA, MBA and Executive MBA students, we have taken steps to raise our faculty strength in number and also to include faculty members with doctorate degrees from the developed countries, particularly, USA, Canada, UK, Western Europe and Australia. Students become proficient in using advanced technology and many of the classes utilize case studies extensively. Students appreciate that learning is not just about theory, it needs to be applied in the real world and they do get applied in the real world. ";
  const welcomeMessagePart3 =
    "The Faculty of Business Administration serves approximately 2,000 students now with 39 full faculty members led by the Dean. The Faculty of Business Administration of Eastern University certainly has the highest qualification among all the business faculties of the private universities in Bangladesh. There are nine full time faculty members with doctorate degrees from different universities of the world. There are eight full time professors, three associate professors, 15 assistant professors, and the remaining are senior lecturers and lecturers, who had been outstanding graduates of Dhaka University or hold Masters’ degrees from reputed foreign universities. ";
  const welcomeMessagePart4 =
    "To facilitate the teaching and learning of the students, the faculty has air conditioned class rooms with multimedia projectors and audio visual facilities. To address the needs of the students, the faculty provides fully equipped computer lab with internet facility, a modern digital library with good number of books, journals and digital resources, photocopy services, modern cafeteria, common room and prayer room for the female students, auditorium, a fulltime doctor with adequate medical facilities, and sufficient premises for academic and co-curricular activities of the students. Corporate leaders and renowned personalities often visit the campus to share their experience and wisdom with the students.";
  const welcomeMessagePart5 =
    "The campus is vibrant with activities. There are many opportunities for getting involved in extra-curricular activities at Eastern University. The instructors may be demanding, yet are very friendly and helpful and so are all the staff and support people. When you need help, there will be help for you.";
  return (
    <div>
      <FacultyName
        name="Department of Business Administration"
        facultyImg={engFacultyTestImg}
      />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationBusinessDepartment />
        <div className="rightSideContent mt-4">
          <p className="heading4 text-left">
            Exploring Eastern University Department of Business Administration
          </p>
          <div className="deanMessage text-left">
            <p className="allText">{welcomeMessage1}</p>
            <p className="allText">{welcomeMessagePart2}</p>
            <p className="allText">{welcomeMessagePart3}</p>
            <p className="allText">{welcomeMessagePart4}</p>
            <p className="allText">{welcomeMessagePart5}</p>
          </div>
          {/* Mobile Responsive Start*/}
          <div
            id="mobileMenuButton"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <button>
              <VscAdd className="moreMenu" />
            </button>
          </div>
          {mobileMenuOpen && (
            <div id="mobileMenuContent">
              <button
                id="closeButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <VscChromeClose className="closeButtonIcon" />
              </button>
              <LeftSideNavigationBusinessDepartment />
            </div>
          )}
          {/* Mobile Responsive End*/}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ExploringBusinessDepartment;
