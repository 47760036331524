import React, { useState } from "react";
import LeftSideNavigationEEE from "../../component/leftSideNavigationEEE";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const LabFacilitiesEEE = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName name="Department of Electrical and Electronic Engineering" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationEEE />
        <div className="rightSideContent mt-4">
          <p className="heading3 text-center">Lab Facilities</p>
          <div class="card">
            <p className="heading5">
              Importance of Labs in the Faculty of Engineering and Technology at
              Eastern University (EU)
            </p>
            <p className="allText">
              The Laboratories of the Faculty of Engineering and Technology at
              Eastern University (EU) are designed with modern equipment and
              instruments to foster distinction in teaching, learning and
              research. EU laboratories play a central role in supporting
              faculty members and students to develop practical experiences for
              higher education or for careers in the industry. The university is
              committed to developing and maintaining a state-of-the-art lab. EU
              has created one of the best lab facilities in the country for
              undergraduate students of Computer Science and Engineering (CSE)
              and Electrical & Electronic Engineering (EEE). All the
              laboratories of the EU are well spacious and comply with safety
              where good laboratory practice is strictly maintained. The
              laboratory information of the Faculty of Engineering and
              Technology shared by two departments-CSE and EEE, is described in
              the following section.
            </p>
            <p className="heading5">Electrical Circuit & Measurements Lab</p>
            <p className="allText">
              This lab is used to conduct experiments in Electrical Circuits Lab
              and Electrical Measurements Lab.
            </p>
            <p className="heading5">Electrical Circuits Lab</p>
            <p className="allText">
              The significance of the Electrical Circuits Lab is renowned in the
              various fields of engineering applications. For an Electrical
              Engineer, it is obligatory to have practical ideas about
              Electrical Circuits and simulations. In the Electrical Circuit,
              Lab students can create their own electrical circuits and do
              measurements on them. In the circuits, the students can use
              resistors, light bulbs, switches. Here can be measured three-phase
              active and reactive powers. There is an AC/DC ammeter, voltmeter,
              wattmeter and an ohmmeter. This laboratory also has inductor,
              capacitor, and resistor banks, single-phase variable AC power
              sources, DC power supplies, oscilloscopes, signal generators, Lab
              Modules.
            </p>
            <p className="heading5">Electrical Measurements Lab</p>
            <p className="allText">
              An electrical measurement laboratory is set up to supplement the
              theory course on electrical measurements and instrumentation. This
              Lab deals with different types of electrical bridges, calibration
              of single-phase energy meters, etc. Through hands-on experiments
              with real measuring equipment, students gain practical experience
              of various types of measuring devices, current transformers and
              potential transformers. The lab is equipped with a single-phase
              energy Meter, Current Transformer etc.
            </p>
            <p className="heading5">Electronics Lab</p>
            <p className="allText">
              This lab is used to conduct experiments in Electronics Lab and
              Power Electronics Lab.
            </p>
            <p className="allText">
              Work-related to electronics and power electronics courses is
              performed in this lab. It is equipped with modern digital storage
              oscilloscopes, analog and digital trainer boards, signal
              generators, DC power supply and all types of IC chips, BJTs and
              other semiconductor devices.
            </p>
            <p className="allText">
              The lab has equipment's like Diode, SCR, MOSFET & IGBT, Single
              Phase AC Voltage Controller, Single Phase series& parallel
              inverter, Single Phase Bridge inverter, DC Jones chopper, Single
              Phase dual converter, Three-phase fully controlled converter.
            </p>
            <p className="allText">
              This lab provides the students to a better understanding of the
              concepts and working of advanced power semiconductor devices and
              power electronics circuits. This lab also provides the platform
              for the UG students to perform and check their minor project,
              multi-disciplinary design and major project.
            </p>
            <p className="heading5">
              Digital Logic Design, Microprocessor and Interfacing Lab
            </p>
            <p className="=allText">
              This lab is used to conduct experiments in Digital Logic Design,
              Microprocessors and Interfacing Lab.
            </p>
            <p className="heading5">Digital Logic Design Lab</p>
            <p className="allText">
              To provide practical experience for the Digital Logic Design
              course where students learn how to build and implementation of
              digital logic circuits, digital logic circuit design and analysis
              of digital systems. Digital logic is the basis of electronic
              systems. The digital logic is based on binary code, a series of
              zeros and ones. This system includes logic gates and facilitates
              the design of electronic circuits that transmit information in the
              form of digital bits, the functions of digital logic gates include
              and, or, not, Ex-or. The lab equipment's like module KL- 31001, IC
              tester.
            </p>
            <p className="heading5">Microprocessor Lab</p>
            <p className="allText">
              In this lab, students learn about the hardware and software of the
              Intel 8085/8086/8088 processor. Experiments involve programming of
              the microprocessor and interfacing with input-output (I/O)
              devices. Major Equipment • Logic control & traffic light• Display
              & Keyboard• PCs running required simulation software and
              cross-assembler.
            </p>
            <p className="heading5">Chemistry Lab</p>
            <p className="allText">
              The EU chemistry lab is enriched with a lot of necessary
              glassware, analytical instruments and chemicals/reagents. It
              covers inorganic, organic, analytical chemistry experiments that
              have a direct interest to engineering undergraduates. This lab has
              excellent research facilities for the faculty members as well as
              for the students. To maintain state-of-art laboratory facilities,
              we update equipment and chemicals continuously. EU chemistry lab
              featured some fascinating research, such as monitoring the air
              quality of Dhaka using IoT, textile dyeing techniques, and
              preparation of disinfectants during COVID-19. Advanced
              experimentation in the chemistry lab helps students think
              critically and improve problem-solving skills that prepare
              undergraduates for higher education or careers in the industry.
            </p>
            <p className="heading5">Physics Lab</p>
            <p className="allText">
              EU features an experimental physics laboratory where students can
              perform experiments and measure various physical phenomena. The
              lab is equipped with Vernier instrumentation supporting the
              motion, force, energy, momentum, waves, optics, and electricity
              experiments. EU Physics Laboratory has all the equipment in
              sufficient quantity so that every student can perform his
              practicals individually. In this lab, students can practice modern
              physics techniques, analyze data, answer questions, and think
              innovatively, allowing faculty members and students to carry out
              state-of-the-art research.
            </p>
            <p className="heading5">Communication Lab</p>
            <p className="allText">
              The purpose of the Communication Lab is primarily to offer
              hands-on experience to students for training and research by
              providing them scope for practical demonstrations and enabling
              exercises for courses in communications. The lab also contains
              analog communication trainers, digital communication trainers and
              optical fiber communication trainers.
            </p>
            <p className="heading5">Electrical Machines & Power Systems Lab.</p>
            <p className="allText">
              This lab is used to conduct experiments in Electrical Machine – I
              Lab, Electrical Machine – II Lab, Power Systems Lab.
            </p>
            <p className="heading5">Electrical Machine I & II Lab</p>
            <p className="allText">
              This lab concerns AC & DC machine's power, efficiency,
              characteristics of electrical machines as a motor, generator,
              determining the parameters and performance characteristics of the
              transformer, methods of control of the speed of the motor, control
              of the generator voltage etc. Through hands-on experiments with
              real machines, students gain practical experience on single-phase
              and three-phase transformers and machine drives and also, a wide
              variety of practical experiments are performed here with the
              combination of different rotating machines. The laboratory is also
              used for research activities in machines and to carry out project
              works on energy conversion. The lab is equipped with a
              single-phase transformer, three-phase Induction motor, three-phase
              alternator, three phases synchronous motor, single-phase induction
              motor, DC shunt generator, DC series generator, DC compound
              generator DC shunt motor and DC series motor etc.
            </p>
            <p className="heading5">Power Systems Lab</p>
            <p className=" allText">
              Power system Laboratory comprises of protection, high voltage and
              machine-related experiments. Facilities are available for
              overcurrent, directional, differential relays, including different
              numerical relays. Power systems lab main aim is to perform testing
              of CT, PT's and Transformer to perform fault analysis on
              Transmission line models. Understand Different protection methods.
              The major equipment's in the lab includes, Over Current Relay,
              Differential Relay and CT, MCB and transmission line model.
            </p>
            <p className="heading5">Engineering Workshop Lab</p>
            <p className=" allText">
              This lab is used to conduct experiments in Electrical and
              Electronic Shop and Electronic Project Design
            </p>
            <p className="heading5">Electrical and Electronic Shop</p>
            <p className=" allText">
              The electrical workshop lab main aim is to enhance practical
              knowledge related to different subjects such as to develop
              hardware skills like soldering, winding and testing of circuits
              etc. The Electrical Workshop is sufficiently outfitted with all
              the equipment and accessories for conducting practical classes in
              wiring practices. Models of modern wiring accessories are deployed
              for the benefit of the students. The students are exposed to the
              current practices in electrical wiring and industrial
              installation.
            </p>
            <p className="heading5">Electronic Project Design</p>
            <p className="allText">
              The purpose of the electronic project design lab is to work on
              different hardware projects.
            </p>
          </div>
          {/* Mobile Responsive Start*/}
          <div
            id="mobileMenuButton"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <button>
              <VscAdd className="moreMenu" />
            </button>
          </div>
          {mobileMenuOpen && (
            <div id="mobileMenuContent">
              <button
                id="closeButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <VscChromeClose className="closeButtonIcon" />
              </button>
              <LeftSideNavigationEEE />
            </div>
          )}
          {/* Mobile Responsive End*/}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LabFacilitiesEEE;
