import React from "react";

import { server_django, replace_string } from "../util";
import Gallery from "react-grid-gallery";
import { useLocation, useParams } from "react-router-dom";

import useAlbumImages from "../features/useAlbumImages";
import Spinner from "../UI/Spinner";

function SinglePhotoAlbum() {
  const { id } = useParams();

  const locationData = useLocation();
  const name = locationData.state;

  const { images, isLoading } = useAlbumImages(id);

  if (isLoading) return <Spinner />;
  const IMAGES =
    images.length &&
    images.map((mylist) => {
      return {
        src: mylist.picture_location,
        thumbnail: mylist.picture_location,
        thumbnailWidth: 320,
        thumbnailHeight: 174,
        isSelected: false,
        alt: mylist.picture_location,
      };
    });

  return (
    <div id="galary">
      <p className="heading4">{name}</p>
      <hr />
      <Gallery
        images={IMAGES}
        enableLightbox={true}
        enableImageSelection={true}
      />
    </div>
  );
}

export default SinglePhotoAlbum;
