import React from 'react';
import Footer from '../component/Footer'

const login = () => {
    return (
        <section id='loginMobile'>
            <hr />
            <p className='heading2'>Please Login</p>
            <hr />
            <div className='loginForAll'>
                <a href='http://webportal.easternuni.edu.bd/employee/_login.aspx' target='_blank'>Employee</a>
            </div>
            <div className='loginForAll'>
                <a href='http://webportal.easternuni.edu.bd/student/_login.aspx' target='_blank'>Student</a>

            </div>
            <div className='loginForAll mb-4'>
                <a href='http://webportal.easternuni.edu.bd/Online_Alumni/_login.aspx' target='_blank'>Alumni</a>

            </div>
            <Footer />
        </section>
    );
};

export default login;