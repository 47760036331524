import React, { Component } from 'react';
import {tutionFeeImage,admissionRelatedInformation} from '../../../static';
class TutionFeesContent extends Component {
    render() {
        return (

            <div className='OtherPagecontent'>
                <hr /><p className="heading2 primaryHeading text-center">Tuition and Fees</p><hr />

               <img className='img-fluid' src={tutionFeeImage} alt='Tuition fee Loading...'/>
               {/* <img className='img-fluid' src={admissionRelatedInformation} alt='Tution fee Loading...'/> */}


            </div>


        );
    }
}

export default TutionFeesContent;