import React from "react";
import { NavLink } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";

const leftSideNavigationEnglish = () => {
  return (
    <div id="facultyLeftSideMenu" className="sidemenu">
      <Menu vertical className="sidemenuBar">
        <Dropdown text="Home" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <NavLink exact to="/departmentOfEnglish">
              <Dropdown.Item>English Home</Dropdown.Item>
            </NavLink>
            <NavLink exact to="/">
              <Dropdown.Item>EU Home</Dropdown.Item>
            </NavLink>

            <Dropdown text="About" className="link item sideMenuBarDropdown">
              <Dropdown.Menu>
                <NavLink exact to="/welcomeMessageEnglish">
                  <Dropdown.Item>Welcome Message</Dropdown.Item>
                </NavLink>
                <NavLink exact to="/exploringEnglish">
                  <Dropdown.Item>Exploring English EU</Dropdown.Item>
                </NavLink>
                <NavLink exact to="/clubInformationEnglish">
                  <Dropdown.Item>Clubs Information</Dropdown.Item>
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="Academic" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            {/* Programs */}
            <Dropdown text="Programs" className="link item sideMenuBarDropdown">
              <Dropdown.Menu>
                <Dropdown text="Undergraduate" className="link item">
                  <Dropdown.Menu>
                    <NavLink exact to="/programsBAEnglishDepartment">
                      <Dropdown.Item>B.A.(Hons.) in English</Dropdown.Item>
                    </NavLink>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown text="Graduate" className="link item">
                  <Dropdown.Menu>
                    <NavLink exact to="/programsMAEnglishDepartment">
                      <Dropdown.Item>
                        M.A. EL (English Literature)
                      </Dropdown.Item>
                    </NavLink>
                  </Dropdown.Menu>
                </Dropdown>
              </Dropdown.Menu>
            </Dropdown>

            {/* Admission */}
            <Dropdown
              text="Admission"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <NavLink exact to="/underGraduateAdmissionEnglish">
                  <Dropdown.Item>Undergraduate</Dropdown.Item>
                </NavLink>

                <NavLink exact to="/graduateAdmissionEnglish">
                  <Dropdown.Item>Graduate</Dropdown.Item>
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
            {/* Curriculum */}
            <Dropdown
              text="Curriculum"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <NavLink exact to="/underGraduateCurriculumEnglish">
                  <Dropdown.Item>Undergraduate</Dropdown.Item>
                </NavLink>

                <NavLink exact to="/graduateCurriculumEnglish">
                  <Dropdown.Item>Graduate</Dropdown.Item>
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="People" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            {/* Faculty Members */}
            {/* <Dropdown
              text="Faculty Members"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <NavLink exact to="/atAGlanceFacultyMembersEnglish">
                  <Dropdown.Item>At A Glance</Dropdown.Item>
                </NavLink>
                <NavLink exact to="/currentFacultyMembersEnglish">
                  <Dropdown.Item>Current Faculty </Dropdown.Item>
                </NavLink>
                <NavLink exact to="/currentAdjunctFacultyMembersEnglish">
                  <Dropdown.Item>Adjunct Faculty</Dropdown.Item>
                </NavLink>
                <NavLink exact to="/onLeaveFacultyMembersEnglish">
                  <Dropdown.Item>On Leave</Dropdown.Item>
                </NavLink>
              </Dropdown.Menu>
            </Dropdown> */}
            <NavLink exact to="/atAGlanceFacultyMembersEnglish">
              <Dropdown.Item>Faculty Members</Dropdown.Item>
            </NavLink>

            {/* Officials */}
            <NavLink exact to="/officialsEnglish">
              <Dropdown.Item>Officials</Dropdown.Item>
            </NavLink>
          </Dropdown.Menu>
        </Dropdown>

        {/* Lab */}
        {/* <Menu.Item className='sideBarSingleItem'>
                    <NavLink exact to='/labFacilitiesEnglish'>Lab Facilities</NavLink>
                </Menu.Item> */}

        {/* <Dropdown text='Research' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <NavLink exact to='/publicationsEnglish'>Publications</NavLink>
                            <a href='http://dspace.easternuni.edu.bd:8080/xmlui/' target='_blank'>Publications</a>

                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/researchGroupEnglish'>Research Groups</NavLink>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown> */}

        {/* Announcement */}
        <Dropdown text="Announcement" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <NavLink exact to="/newsEnglish">
              <Dropdown.Item>News</Dropdown.Item>
            </NavLink>
            <NavLink exact to="/upcomingEventsEnglish">
              <Dropdown.Item>Upcoming Events</Dropdown.Item>
            </NavLink>
            <NavLink exact to="/noticeEnglish">
              <Dropdown.Item>Notice</Dropdown.Item>
            </NavLink>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item className="sideBarSingleItem">
          {/* <NavLink exact to='/alumniEnglish'>Alumni</NavLink> */}
          <a
            href="http://webportal.easternuni.edu.bd/Online_Alumni/_AlumniSearch.aspx"
            target="_blank"
          >
            Alumni
          </a>
        </Menu.Item>
        <Menu.Item className="sideBarSingleItemLast">
          <NavLink exact to="/contuctUsEnglish">
            Contact Us
          </NavLink>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default leftSideNavigationEnglish;
