import React from "react";
import Wrapper from "../../component/Wrapper";
import OtherpageSidebar from "../component/OtherpageSideBar";

function academicCouncil() {
  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">
            Academic Council
          </p>
          <p className="heading6 text-center">
            (Effective period : November 2023 – October 2025)
          </p>
          <hr />
          <div id="academicCouncil">
            <div className="comitteeMemberList d-flex justify-content-center">
              <table className="table-sm table-bordered table-hover allText">
                <caption>
                  ** Duration : 2 years from the date of nomination**
                </caption>
                <tbody className="thead-dark">
                  <tr>
                    <td>#</td>
                    <td>Position as per Private University ACT 2010</td>
                    <td>Name of the Members</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>
                      <p className="allText">Chairman</p>
                      <p className="allText">(By designation)</p>
                    </td>
                    <td>
                      <p className="allText">
                        Professor Dr. Shahid Akhtar Hossain
                      </p>
                      <p className="allText">Vice Chancellor </p>
                    </td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>
                      <p className="allText">Member</p>
                      <p className="allText">(By designation)</p>
                    </td>
                    <td>
                      <p className="allText">_ _ _ _ _ _ _ _ _ _ _ _ _</p>
                      <p className="allText">Pro-Vice Chancellor</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">3</p>
                      <p className="allText">4</p>
                      <p className="allText">5</p>
                    </td>
                    <td>
                      <p className="allText">Member</p>
                      <p className="allText">(Three members of the BOT)</p>
                    </td>
                    <td>
                      <p className="allText">Prof. Dr. A.K.M. Saiful Majid</p>
                      <p className="allText">Mr. Abul Khair Chowdhury</p>
                      <p className="allText">Engr. Kh. Mesbah Uddin Ahmed</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">6</p>
                      <hr />
                      <p className="allText">7</p>
                      <hr />
                      <p className="allText">8</p>
                      <hr />
                      <p className="allText">9</p>
                    </td>
                    <td>
                      <p className="allText">Member</p>
                      <p className="allText">(All Deans by designation)</p>
                    </td>
                    <td>
                      <p className="allText">
                        Professor Dr. Md. Abbas Ali khan
                      </p>
                      <p className="allText">
                        Dean, Faculty of Business Administration
                      </p>
                      <hr />
                      <p className="allText">Prof. Dr. Md. Mahfuzur Rahman</p>
                      <p className="allText">Dean, Faculty of E&T</p>
                      <hr />
                      <p className="allText">Mohd. Moniruzzaman Akhand</p>
                      <p className="allText">Dean, Faculty of Arts</p>
                      <hr />
                      <p className="allText">Mr. A.B.M.Imdadul Haque Khan</p>
                      <p className="allText">Dean, Faculty of Law</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">10</p>
                      <hr />
                      <p className="allText">11</p>
                      <hr />
                      <p className="allText">12</p>
                      <hr />
                      <p className="allText">13</p>
                      <hr />
                      <p className="allText">14</p>
                      <hr />
                      <p className="allText">15</p>
                      <hr />
                      <p className="allText">16</p>
                    </td>
                    <td>
                      <p className="allText">Member</p>
                      <p className="allText">
                        (All Chairpersons by designation)
                      </p>
                    </td>
                    <td>
                      <p className="allText">Prof. Dr. Mirza Golam Rabbani</p>
                      <p className="allText">Chairperson, Dept. of EEE</p>
                      <hr />
                      <p className="allText">
                        Prof. Dr. Sharif Moniruzzaman Shirazi, CEng
                      </p>
                      <p className="allText">
                        Chairperson, Department of Civil Engineering
                      </p>
                      <hr />
                      <p className="allText">Mr. Syed Habib Anwar Pasha</p>
                      <p className="allText">
                        Chairperson, Dept.of Business Administration
                      </p>
                      <hr />
                      <p className="allText">Mr. Muhammad Mahfuz Hasan</p>
                      <p className="allText">Chairperson, Department of CSE</p>
                      <hr />
                      <p className="allText">Dr. Abu Bin Ihsan</p>
                      <p className="allText">
                        Chairperson, Department of Pharmacy
                      </p>
                      <hr />
                      <p className="allText">Ms. Rowshon Ara</p>
                      <p className="allText">
                        Chairperson, Department of English
                      </p>
                      <hr />
                      <p className="allText">Mr. Mohammad Mamunur Rashid</p>
                      <p className="allText">Chairperson, Department of Law</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">17</p>
                      <hr />
                      <p className="allText">18</p>
                      <hr />
                      <p className="allText">19</p>
                    </td>
                    <td>
                      <p className="allText">Member</p>
                      <p className="allText">
                        (One Senior Professor from each department, by
                        designation)
                      </p>
                    </td>
                    <td>
                      <p className="allText">Dr. Zakia Begum</p>
                      <p className="allText">Professor, Department of CSE</p>
                      <hr />
                      <p className="allText">Dr. Ashraf Hossain</p>
                      <p className="allText">
                        Professor, Dept. of Business Administration
                      </p>
                      <hr />
                      <p className="allText">Dr. Md. Abdullah Al Humayun</p>
                      <p className="allText">Professor, Dept. of EEE</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">20</p>
                      <hr />
                      <p className="allText">21</p>
                    </td>
                    <td>
                      <p className="allText">Member</p>
                      <p className="allText">
                        (Two specialists nominated by the Syndicate)
                      </p>
                    </td>
                    <td>
                      <p className="allText">
                        Prof. Shibli Rubayat Ul Islam (On Deputation-Chairman,
                        BSEC)
                      </p>
                      <p className="allText">
                        Former Professor, Faculty of Business, DU
                      </p>
                      <hr />
                      <p className="allText">Dr. ABM Shahidul Islam </p>
                      <p className="allText">
                        Chairman, Dept. of Marketing, DU
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">22</p>
                    </td>
                    <td>
                      <p className="allText">Member Secretary</p>
                      <p className="allText">(By designation)</p>
                    </td>
                    <td>
                      <p className="allText">Dr. Abul Basher Khan</p>
                      <p className="allText">Registrar</p>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <p className="allText">23</p>
                    </td>
                    <td>
                      <p className="allText">On Special Invitation</p>
                    </td>
                    <td>
                      <p className="allText">Mr. Md. Azizul Islam</p>
                      <p className="allText">Member, BOT</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <OtherpageSidebar />
      </div>
    </Wrapper>
  );
}

export default academicCouncil;
