import React from 'react';
import OtherpageSideBar from '../../component/OtherpageSideBar'

import { TA, HZNU_logo, YNU, ynvtc, YUNNAN, putra, UUM, UITM, TNI_logo, msu, AIT, AIS, cambridgeInternationalCollege, State_University_of_New_York_seal, UniMAP, Frank, Salford, LPC, worcester } from '../../../static'




const InternationalAcademicCollaborationContent = () => {
    return (
        <div className='content'>
            <div className='OtherPagecontent'>
                <hr></hr>
                <p class='heading2 primaryHeading text-center'>Internaltional Academic Collaboration</p>
                <hr></hr>
                <p class="allText">
                    The International Office of Eastern University has collaborations with more
                    than 20 internationally reputed institutions spanning the globe including
                    those in the US, UK, Europe, Southeast Asia, and Australasia. More than 24
                    international scholarships are available for students of Eastern University
                    every year. The Yunnan Open University of China offers study tour and exchange
                    program to the students of Eastern University. More than 50 students have
                    visited and experienced the Chinese Language and Culture in the last three
                    years. Following is a list of such universities and institutions:
                </p>
                <div class="card">
                    <div class="listOfIntnationIns">

                        <div class="card">
                            <a href="http://www.worcester.ac.uk/">
                                <img src={worcester}
                                    class="card-img-top rounded" alt="University of Worcester. UK" /></a>
                            <div class="card-body text-center">
                                <a href="http://www.worcester.ac.uk/">
                                    <p class='heading6'>University of Worcester. UK</p>
                                </a>
                            </div>
                        </div>
                        <div class="card">
                            <a href="http://www.londonpremiercollege.com/">
                                <img src={LPC}
                                    class="card-img-top rounded" alt="London Premier College (London University, UK)" /></a>
                            <div class="card-body text-center">
                                <a href="http://www.londonpremiercollege.com/">
                                    <p class='heading6'>London Premier College (London
                                        University, UK)</p>
                                </a>
                            </div>
                        </div>
                        <div class="card">
                            <a href="http://www.salford.ac.uk/">
                                <img src={Salford}
                                    class="card-img-top rounded" alt="University of Salford, Manchester" /></a>
                            <div class="card-body text-center">
                                <a href="http://www.salford.ac.uk/">
                                    <p class='heading6'>University of Salford, Manchester</p>
                                </a>
                            </div>
                        </div>
                        <div class="card">
                            <a href="http://www.franklin.edu/">
                                <img src={Frank}
                                    class="card-img-top rounded" alt="Franklin University, Ohio, USA" /></a>
                            <div class="card-body text-center">
                                <a href="http://www.franklin.edu/">
                                    <p class='heading6'>Franklin University, Ohio, USA</p>
                                </a>
                            </div>
                        </div>
                        <div class="card">
                            <a href="http://www.unimap.edu.my/">
                                <img src={UniMAP}
                                    class="card-img-top rounded" alt="University Malaysis Peris (UniMAP), Malaysia" /></a>
                            <div class="card-body text-center">
                                <a href="http://www.unimap.edu.my/">
                                    <p class='heading6'>University Malaysis Peris (UniMAP), Malaysia</p>
                                </a>
                            </div>
                        </div>
                        <div class="card">
                            <a href="https://www.suny.edu/">
                                <img src={State_University_of_New_York_seal}
                                    class="card-img-top rounded" alt="State University of New York (SUNY)" /></a>
                            <div class="card-body text-center">
                                <a href="https://www.suny.edu/">
                                    <p class='heading6'>State University of New York (SUNY)</p>
                                </a>
                            </div>
                        </div>
                        <div class="card">
                            <a href="http://www.cambridgecollege.co.uk/">
                                <img src={cambridgeInternationalCollege}
                                    class="card-img-top rounded" alt="Cambridge International College" /></a>
                            <div class="card-body text-center">
                                <a href="http://www.cambridgecollege.co.uk/">
                                    <p class='heading6'>Cambridge International College</p>
                                </a>
                            </div>
                        </div>
                        <div class="card">
                            <a href="http://www.ais.ac.nz/">
                                <img src={AIS}
                                    class="card-img-top rounded" alt="Auckland Institute of Studies (AIS), St Helens, Auckland, New Zealand" /></a>
                            <div class="card-body text-center">
                                <a href="http://www.ais.ac.nz/">
                                    <p class='heading6'>Auckland Institute of Studies (AIS), St Helens, Auckland, New Zealand</p>
                                </a>
                            </div>
                        </div>
                        <div class="card">
                            <a href="http://www.ait.ac.th/">
                                <img src={AIT}
                                    class="card-img-top rounded" alt="Asian
                                    Institute of Technology" /></a>
                            <div class="card-body text-center">
                                <a href="http://www.ait.ac.th/">
                                    <p class='heading6'>Asian
                                        Institute of Technology</p>
                                </a>
                            </div>
                        </div>
                        <div class="card">
                            <a href="http://www.msu.edu.my">
                                <img src={msu}
                                    class="card-img-top rounded" alt="Management
                                    &amp; Science University, Malaysia" /></a>
                            <div class="card-body text-center">
                                <a href="http://www.msu.edu.my">
                                    <p class='heading6'>Management
                                        &amp; Science University, Malaysia</p>
                                </a>
                            </div>
                        </div>

                        <div class="card">
                            <a href="http://www.tni.ac.th/web/tni2014-en/">
                                <img src={TNI_logo}
                                    class="card-img-top rounded" alt="Thai-Nichi Institute of Technology" /></a>
                            <div class="card-body text-center">
                                <a href="http://www.tni.ac.th/web/tni2014-en/">
                                    <p class='heading6'>Thai-Nichi Institute of Technology</p>
                                </a>
                            </div>
                        </div>

                        <div class="card">
                            <a href="http://www.uitm.edu.my/index.php/en/">
                                <img src={UITM}
                                    class="card-img-top rounded" alt="Universiti Teknologi MARA (UiTM)" /></a>
                            <div class="card-body text-center">
                                <a href="http://www.uitm.edu.my/index.php/en/">
                                    <p class='heading6'>Universiti Teknologi MARA (UiTM)</p>
                                </a>
                            </div>
                        </div>

                        <div class="card">
                            <a href="http://www.uum.edu.my/index.php/en/">
                                <img src={UUM}
                                    class="card-img-top rounded" alt="University Utara Malaysia" /></a>
                            <div class="card-body text-center">
                                <a href="http://www.uum.edu.my/index.php/en/">
                                    <p class='heading6'>University Utara Malaysia</p>
                                </a>
                            </div>
                        </div>

                        <div class="card">
                            <a href="http://putrabusinessschool.edu.my/">
                                <img src={putra}
                                    class="card-img-top rounded" alt="Putra Business School" /></a>
                            <div class="card-body text-center">
                                <a href="http://putrabusinessschool.edu.my/">
                                    <p class='heading6'>Putra Business School</p>
                                </a>
                            </div>
                        </div>

                        <div class="card">
                            <a href="http://en.ynufe.edu.cn/">
                                <img src={YUNNAN}
                                    class="card-img-top rounded" alt="Yunnan University of Finance and Economics, (YUFE)" /></a>
                            <div class="card-body text-center">
                                <a href="http://en.ynufe.edu.cn/">
                                    <p class='heading6'>Yunnan University of Finance and Economics, (YUFE)</p>
                                </a>
                            </div>
                        </div>

                        <div class="card">
                            <a href="http://school.cuecc.com/Yunnan/ynou/index.html">
                                <img src={ynvtc}
                                    class="card-img-top rounded" alt="Yunnan Open University (YNOU)" /></a>
                            <div class="card-body text-center">
                                <a href="http://school.cuecc.com/Yunnan/ynou/index.html">
                                    <p class='heading6'>Yunnan Open University (YNOU)</p>
                                </a>
                            </div>
                        </div>

                        <div class="card">
                            <a href="http://english.ynnu.edu.cn/">
                                <img src={YNU}
                                    class="card-img-top rounded" alt="Yunnan Normal University, China (YNNU)" /></a>
                            <div class="card-body text-center">
                                <a href="http://english.ynnu.edu.cn/">
                                    <p class='heading6'>Yunnan Normal University, China (YNNU)</p>
                                </a>
                            </div>
                        </div>

                        <div class="card">
                            <a href="http://www.csc.edu.cn/studyinchina/indexen.aspx">
                                <img src={HZNU_logo}
                                    class="card-img-top rounded" alt="Hangzhou Normal
                                    University" /></a>
                            <div class="card-body text-center">
                                <a href="http://www.csc.edu.cn/studyinchina/indexen.aspx">
                                    <p class='heading6'>Hangzhou Normal
                                        University</p>
                                </a>
                            </div>
                        </div>

                        <div class="card">
                            <a href="http://www.tamk.fi/en">
                                <img src={TA}
                                    class="card-img-top rounded" alt="Tampere University of Applied Science, Finland" /></a>
                            <div class="card-body text-center">
                                <a href="http://www.tamk.fi/en">
                                    <p class='heading6'>Tampere University of Applied Science, Finland</p>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <OtherpageSideBar />
        </div>
    );
};

export default InternationalAcademicCollaborationContent;