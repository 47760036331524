import React, { Component } from 'react';
import AdmissionSidebar from '../../component/AdmissionSidebar';

class TransferStudentsContent extends Component {
    render() {
        return (
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr />
                    <p className='heading2 primaryHeading text-center'>
                        Transfer Student
                    </p>
                    <hr />
                    <p className='allText'>
                        Transfer students are expected to have at least a 2.50 CGPA on a 4-point scale at the University level programs to be considered for admission to Eastern University. They have to sit for and pass the Admission Test unless waived by EU Authority.
                    </p>
                    <p className='allText'>
                        Catalogs and official transcripts from previously attended Universities must be furnished with the application for admission. If needed, transcripts may have to be sent directly to the EU Registrar's Office from each institution attended. An undergraduate student may transfer (through grade transfer or course exemption or a combination of both) a maximum of 35 percent of the total credit hours required for the degree in question at EU. In exceptional cases, the Equivalence Committee may, on recommendation of concerned Dean, consider extending the credits up to 40 percent. Courses with C+ grades as per Eastern University standard may be considered for transfer or exemption. For the purpose of transferring credits, the Equivalence Committee of the University determines equivalence of courses and grades for each transfer student. The curriculum, evaluation system and grading standard of the university from which the student is transferring must be comparable to those of Eastern University which reserves the right to make final decision on transfer.
                    </p>
                </div>
                <AdmissionSidebar />
            </div>
        );
    }
}

export default TransferStudentsContent;