import React, { useState } from "react";
import LeftSideNavigationCSE from "../../component/leftSideNavigationCSE";

import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import Spinner from "../../../../UI/Spinner";
import useDepartmentPublications from "../../../../features/useDepartmentPublications";
import ResearchDataset from "../../component/ResearchDataset";

const PublicationsCSE = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { publications, isLoading } = useDepartmentPublications();
  if (isLoading) return <Spinner />;

  return (
    <div>
      <FacultyName name="Department of Computer Science and Engineering" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationCSE />
        {/* <Sidebar /> */}
        <div className="rightSideContent mt-4">
          <p className="heading3 text-center">Publications</p>
          {publications.map((item) => (
            <ResearchDataset research={item} key={item.year} />
          ))}

          {/* Mobile Responsive Start*/}
          <div
            id="mobileMenuButton"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <button>
              <VscAdd className="moreMenu" />
            </button>
          </div>
          {mobileMenuOpen && (
            <div id="mobileMenuContent">
              <button
                id="closeButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <VscChromeClose className="closeButtonIcon" />
              </button>
              {/* <LeftSideNavigationCSE /> */}
            </div>
          )}
          {/* Mobile Responsive End*/}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PublicationsCSE;
