import React from "react";

import Footer from "../../component/Footer";
import Wrapper from "../../component/Wrapper";
import AdmissionSidebar from "../component/AdmissionSidebar";

const financialAidAndScholarship = () => {
  const cellStyle = {
    verticalAlign: "middle", // Aligns the content vertically in the middle
    textAlign: "center", // Aligns the content horizontally in the center
  };
  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">
            Scholarship And Waivers
          </p>
          <hr />
          <br />
          <div className="container" id="financialaid&Scholarship">
            <p className="heading5">Result Based Waiver:</p>
            <p className="allText">
              Eastern University offers a waiver on tuition fees based on SSC
              and HSC results. Result based waivers are:
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th rowSpan={3} style={cellStyle}>
                    For Undergraduate Programs
                  </th>
                  <td>
                    Total GPA 10 <br /> (Without 4th subject)
                  </td>

                  <td>Total GPA 10.00</td>

                  <td>
                    <p className="alltext">
                      Total GPA <br /> 9.50 {"<"} 10.00
                    </p>
                  </td>

                  <td>
                    Total GPA <br /> 9.00 {"<"} 9.49
                  </td>

                  <td>
                    Total GPA <br /> 8.00 {"<"} 8.99
                  </td>

                  <td>
                    Total GPA <br /> 6.00 {"<"} 7.99
                  </td>
                </tr>
                <tr>
                  <td>
                    100% <br />{" "}
                    <span style={{ color: "red" }}> Required SGPA 3.50</span>
                  </td>
                  <td>40%</td>
                  <td>35%</td>
                  <td>30%</td>
                  <td>25%</td>
                  <td>20%</td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
                <tr>
                  <th rowSpan={2}>For Graduate Programs</th>
                  <td></td>
                  <td>
                    Total GPA <br /> 12.00
                  </td>
                  <td colSpan={2}>
                    Total GPA <br /> 11.00 {"<"} 11.99
                  </td>
                  <td colSpan={2}>
                    Total GPA <br /> 10.00 {"<"} 10.99
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>40%</td>
                  <td>30%</td>
                  <td></td>
                  <td>20%</td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td colSpan={4}>
                    <span style={{ color: "red" }}>
                      {" "}
                      *** No SGPA required for the above mentioned waiver except
                      100% waiver achiever.
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="heading5">Waiver on Semester result:</p>
            <p className="allText">
              Every semester students get an extra waiver based on their
              semester result.
            </p>
            <table className="table">
              <thead>
                <tr>
                  <th rowSpan={2} style={cellStyle}>
                    Tuition fee waiver based on SGPA (For Undergraduate)
                  </th>
                  <td>SGPA 3.85{"<"}4.00</td>
                  <td>SGPA 3.70{"<"}3.85</td>
                  <td>SGPA 3.50{"<"}3.70</td>
                </tr>
                <tr>
                  <td>30%</td>
                  <td>20%</td>
                  <td>10%</td>
                </tr>
              </thead>
            </table>
            <p className="heading5">Children of Freedom Fighter:</p>
            <p className="allText">
              Children of Freedom Fighter get 100% waiver on tuition fees. Out
              of total admission 03% students get Freedom Fighter children
              waiver.
            </p>

            <p className="heading5">Waiver for Player:</p>
            <p className="allText">
              National Team players get 75% waiver and Divisional level get 50%
              waiver on tuition fees (Certificate regarding Divisional and
              National level is required to avail this waiver).
            </p>

            <p className="heading5">Waiver for Artist:</p>
            <p className="allText">
              National prize winning artists (singer, dancer, actor/actress) get
              75% waiver and divisional prize winning artists get 50% waiver.
            </p>
            <p className="heading5">Female:</p>
            <p className="allText" style={{ color: "red" }}>
              {" "}
              Female students get an extra 10% waiver on tuition fees.
            </p>

            <p className="heading5">Tribal:</p>
            <p className="allText">
              {" "}
              Tribal students get an extra 10% waiver on tuition fees.
              (Certificate as Tribal is required to avail this waiver).
            </p>

            <p className="heading5">Siblings/Spouse:</p>
            <p className="allText">
              {" "}
              If siblings or spouses get admission together any one of them gets
              an extra 10% waiver on tuition fees. This offer will be invalid If
              any one of them cancels admission or semester registration.
            </p>

            <p className="heading5">Physically Disabled:</p>
            <p className="allText">
              {" "}
              Physically disabled students get an extra 10% waiver on tuition
              fees.
            </p>

            <p className="heading5">Acid Victim:</p>
            <p className="allText">
              {" "}
              Acid victim students get an extra 10% waiver on tuition fees.
            </p>

            <p className="heading5">EU Alumni:</p>
            <p className="allText">
              EU alumni for graduate programs get an extra 20% waiver on tuition
              fees.
            </p>

            <p className="heading5">
              EU Alumni and Employee Siblings/Spouse/Children:
            </p>
            <p className="allText">
              {" "}
              We are also offering an extra 10% waiver for EU Alumni and
              Employee Siblings/Spouse/Children.
            </p>

            <p className="heading5">Bhuiyan Academy/LCLS:</p>
            <p className="allText">
              {" "}
              Students from Bhuiyan Academy/LCLS get an extra 10% waiver on
              tuition fees.
            </p>

            <p className="heading5">Financial Aid:</p>
            <p className="allText">
              Eastern University provides 100% waiver on tuition fees to the
              poor and meritorious students of rural areas. Students obtaining
              minimum CGPA 3.00 and above are eligible to apply for this waiver.
            </p>

            <p className="allText" style={{ color: "red" }}>
              {" "}
              <span style={{ fontSize: "2rem", fontWeight: "bolder" }}>
                Note:{" "}
              </span>
              Cumulative waiver for Undergraduate programs will not be more than
              50% and for Graduate programs 40%.
            </p>

            <p className="heading5">Other funded scholarships:</p>
            <ul className="allText">
              <li>
                <p className="allText">
                  Abul Quasem Haider Education Development Scholarship
                </p>
              </li>
              <li>
                <p className="allText">AKM Saiful Majid Scholarship</p>
              </li>
              <li>
                <p className="allText">ABM Shahidul Islam Scholarship</p>
              </li>
              <li>
                <p className="allText">
                  Mohammad Enayet Ullah Foundation Scholarship
                </p>
              </li>
              <li>
                <p className="allText">Dr Samsuddoha Khandaker Scholarship</p>
              </li>
              <li>
                <p className="allText">Saleh Ahmed Chowdhury Scholarship</p>
              </li>
              <li>
                <p className="allText">Anwara Begum Scholarship</p>
              </li>
              <li>
                <p className="allText">Alkad Banu Alam Shikkha Britti</p>
              </li>
              <li>
                <p className="allText">Zinnater Noor Scholarship</p>
              </li>
              <li>
                <p className="allText">Farida Akhter Scholarship</p>
              </li>
              <li>
                <p className="allText">Late Aminul Islam Scholarship</p>
              </li>
              <li>
                <p className="allText">Khursdida Begum Scholarship</p>
              </li>
              <li>
                <p className="allText">Khalil Anwara Scholarship</p>
              </li>
              <li>
                <p className="allText">Rezia -Maksud Foundation Scholarship</p>
              </li>
              <li>
                <p className="allText">Jahima Khatun Scholarship</p>
              </li>
              <li>
                <p className="allText">Alif Group Scholarship</p>
              </li>
              <li>
                <p className="allText">
                  Late Zamila Khatun Memorial Trust Scholarship
                </p>
              </li>
              <li>
                <p className="allText">Abdul Matlub Ahmad Scholarship</p>
              </li>
              <li>
                <p className="allText">Prof. Dr. Abdur Rab Scholarship</p>
              </li>
            </ul>
          </div>
        </div>

        <AdmissionSidebar />
      </div>
      <Footer />
    </Wrapper>
  );
};

export default financialAidAndScholarship;
