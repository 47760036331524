import { NavLink } from "react-router-dom";

import MessageBox from "../component/MessageBox";
import { useOffice } from "../features/useOffice";
import SpinnerMini from "./SpinnerMini";

function NavLinkOptions({ link, children }) {
  const { office, isLoading, error } = useOffice();
  if (isLoading) return <SpinnerMini />;
  if (error) return <MessageBox>{error.message}</MessageBox>;

  const filtered = office.filter((e) => e.name === link);

  return (
    <li className="nav-item list-group-item">
      <NavLink
        className="nav-link"
        exact
        to={`/office/${filtered[0].id}`}
        id="navbarDropdown"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {children}
      </NavLink>
    </li>
  );
}

export default NavLinkOptions;
