import React, { Component } from 'react';
import AdmissionSidebar from '../../component/AdmissionSidebar';
import { AdmissionForm } from '../../../static';
import { CreditTransferFrom } from '../../../static';
import { Link } from 'react-router-dom';

class HowToApplyContent extends Component {
    render() {
        return (
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr />
                    <p className='heading2 primaryHeading text-center'>
                        How to Apply
                    </p>
                    <hr />
                    <p className='allText'>
                        Welcome to the Admissions Office at EU. EU offers a diverse set of programs at the undergraduate and graduate level.
                    </p>
                    <p className='allText'>
                        Students seeking admission can collect Admission Form from admission office paying Tk.500 or apply online or download it from the link given bellow and can submit the same either online or directly to admission office.
                    </p>
                    <p className='heading4 primaryHeading'>
                        Visit and talk to us
                    </p>
                    <p className='allText'>
                        We have several ways for you to apply. The best one is to come in and talk with our Admissions Counselors. Our address is:
                    </p>
                    <p className='allText'>
                        Road 6, Block B,<br />
                        Ashulia Model Town, Savar, Dhaka-1345.<br />
                        Phone: 09602666651 <br />
                        Cell: +8801844169651, +8801844169659, <br />
                        +8801844169660, +8801741300002<br />
                        Email: admission@easternuni.edu.bd
                    </p>
                    <p className='heading4 primaryHeading'>
                        Apply Online
                    </p>
                    <p className='allText'>
                        Please click here to access <a className='primaryHeading' href="https://webportal.easternuni.edu.bd/onlineadmission/Admission.aspx">Apply Online</a>.
                    </p>
                    <p className='heading4 primaryHeading'>
                        Download Forms
                    </p>
                    <p className='allText'>
                        Download Form for Fresh Student :
                           <a href={AdmissionForm}>Click Here.</a>

                    </p>
                    <p className='allText'>
                        Download Form for Credit Transfer Student :
                        <a className='primaryHeading' href={CreditTransferFrom}>Click Here</a>

                    </p>

                    <p className='heading6'>
                        <Link exact to='/transferStudents'>Click here for information about Transfer Student</Link>

                    </p>
                </div>
                <AdmissionSidebar />
            </div>
        );
    }
}

export default HowToApplyContent;