import React from 'react';
import StudentActivitiesSideBar from '../../component/StudentActivitiesSideBar';
import Footer from '../../../component/Footer';

const coreValues = () => {
    return (
        <>
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr />
                    <p className='heading2 text-center'>
                        Core Values
                    </p>
                    <hr />
                    <p className='heading6'>
                        OSA of EU values the following :
                    </p>
                    <div id='OsaCoreValues' className='card-deck'>
                        <div className='card'>
                            <p className='heading6'>Academic Success</p>
                            <div className="card-body">
                                <ul>
                                    <li>
                                        <p className='allText'>
                                            Nurturing talents
                                    </p>
                                    </li>
                                    <li>
                                        <p className='allText'>
                                            Career development
                                    </p>
                                    </li>
                                    <li>
                                        <p className='allText'>
                                            Self actualization
                                    </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='card'>
                            <p className='heading6'>Leadership</p>
                            <div className="card-body">
                                <ul>
                                    <li>
                                        <p className='allText'>
                                            Accountability
                                    </p>
                                    </li>
                                    <li>
                                        <p className='allText'>
                                            Opportunity
                                    </p>
                                    </li>
                                    <li>
                                        <p className='allText'>
                                            Sustainability
                                    </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='card'>
                            <p className='heading6'>Community</p>
                            <div className="card-body">
                                <ul>
                                    <li>
                                        <p className='allText'>
                                            Civility
                                    </p>
                                    </li>
                                    <li>
                                        <p className='allText'>
                                            Health & safety
                                    </p>
                                    </li>
                                    <li>
                                        <p className='allText'>
                                            Respect
                                    </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='card'>
                            <p className='heading6'>Excellence</p>
                            <div className="card-body">
                                <ul>
                                    <li>
                                        <p className='allText'>
                                            Diversity
                                    </p>
                                    </li>
                                    <li>
                                        <p className='allText'>
                                            Equity
                                    </p>
                                    </li>
                                    <li>
                                        <p className='allText'>
                                            Integrity
                                    </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <p className='allText'>
                        OSA values respecting individual dignity, reaching pick point of their career development and the growth of self-actualization. It is not just about teaching students to be good for their <p className='heading6'>academic success</p> rather nurturing them to be their best.
                    </p>
                    <p className='allText'>
                        OSA prepares EU students for the <p className='heading6'>leadership</p> role which is accomplished through effectiveness of OSA’s programs and services and using reliable information to support responsible decision-making. OSA provides opportunities to develop the leadership competencies that will allow students to create meaningful and sustainable change in their respective societies.
                    </p>
                    <p className='allText'>
                        OSA’s strong <p className='heading6'>community</p> partnerships and deep involvements actively engage students in the activity and promote social justice and civic engagement. OSA offers a safe, open and welcoming community that nurtures healthy personal development and a sense of belonging to everyone in the community through respect.
                    </p>
                    <p className='allText'>
                        OSA values charting the way towards <p className='heading6'>excellence</p> for all through pursuing diversity in culture, equity in education and integration of being respectful, honest and ethical.
                    </p>
                </div>
                <StudentActivitiesSideBar />
            </div>
            <Footer />
        </>
    );
};

export default coreValues;