import React, { useState } from "react";
import LeftSideNavigationEEE from "../../component/leftSideNavigationEEE";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const ResearchGroupEEE = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName name="Department of Electrical and Electronic Engineering" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationEEE />
        <div className="rightSideContent mt-4 research">
          <p className="heading3 text-center">Research Group</p>
          <div id="researchGroup">
            <p className="heading5">
              1.Electrical Energy and Power Systems (EEPS)
            </p>
            <p className="allText">
              Within the department of Electrical and Electronic Engineering,
              the field of Electrical Energy and Power Systems addresses vital
              issues related to the production, distribution, and use of energy.
              It is a fundamental component of the infrastructure of
              contemporary society. Large-scale, nonlinear power systems are
              controlled in real time by researchers in the field of electrical
              energy and power systems using complex modeling.
            </p>
            <p className="allText">
              Utilizing electric cars, renewable energy sources, and
              cutting-edge battery technology, power generation and
              transportation are being revolutionized, lowering carbon emissions
              and advancing sustainability
            </p>
            <p className="heading6">
              List of Faculty Members with Primary Affiliation
            </p>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Research Interest</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="allText">Dr. Mirza Golam Rabbani</p>
                    <p className="allText">Professor and Chairperson, EEE</p>
                  </td>
                  <td>
                    <p className="allText">
                      Power System control and Stability, Fuzzy and Neural
                      Network controller, Renewable energy, Energy storage, SMES
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">Md. Maidul islam</p>
                    <p className="allText">Associate Professor, EEE</p>
                  </td>
                  <td>
                    <p className="allText">
                      Power System Stability, Renewable energy Integration,
                      Syncronphasor technology, PMU Electrical Vehicles, DC-DC
                      Convertor
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">Sujon Mia</p>
                    <p className="allText">Lecturer, EEE</p>
                  </td>
                  <td>
                    <p className="allText">Hybrid Renewable Energy Systems</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">Naimul Hasan</p>
                    <p className="allText">Lecturer, EEE</p>
                  </td>
                  <td>
                    <p className="allText">Power system optimization</p>
                  </td>
                </tr>
              </tbody>
            </table>

            <p className="heading5">2. Electronics and Photonics</p>
            <p className="allText">
              Within the department of Electrical and Electronic Engineering,
              the dynamic and revolutionary field of Electronics and Photonics
              is at the forefront of technological progress. By delving into the
              complexities of material properties at the nanoscale, researchers
              in electronics and photonics are able to uncover the possibility
              for previously unheard-of device functionality.
            </p>
            <p className="heading6">
              List of Faculty Members with Primary Affiliation
            </p>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Research Interest</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="allText">Dr. Md. Abdullah Al Humayun</p>
                    <p className="allText">Professor, EEE</p>
                  </td>
                  <td>
                    <p className="allText">
                      Optoelectronic Devices, Solid State Device
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">Md. Mehedy Hasan Sumon</p>
                    <p className="allText">Assistant Professor, EEE</p>
                  </td>
                  <td>
                    <p className="allText">VLSI Testing</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">Md. Rajaur Rahman</p>
                    <p className="allText">Lecturer, EEE</p>
                  </td>
                  <td>
                    <p className="allText">Optoelectronic</p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">Asad-Uz-Zaman</p>
                    <p className="allText">Lecturer, EEE</p>
                  </td>
                  <td>
                    <p className="allText">
                      Semiconductor materials, Photonics, VLSI, Optoelectronic
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="heading5">3. Communication and Signal Processing</p>
            <p className="allText">
              Within the department of Electrical and Electronic Engineering,
              the discipline of Communication and Signal Processing is a vital
              and vibrant field of study that is deeply interwoven with the
              fourth industrial revolution. In this sense, it is essential in
              contemporary technologically advanced societies where the
              convergence of artificial intelligence, digital technologies, and
              the Internet of Things (IoT) has become the standard. In addition
              to designing, analyzing, and optimizing communication systems,
              communication and signal processing researchers also actively
              participate in the smooth integration of AI and IoT.
            </p>
            <p className="heading6">
              List of Faculty Members with Primary Affiliation
            </p>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Research Interest</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p className="allText">Kazi Rizwanul Huq</p>
                    <p className="allText">Assistant Professor, EEE</p>
                  </td>
                  <td>
                    <p className="allText">
                      Wireless Communication, mm Wave, 5G mmWave Wireless
                      Communication, Reconfigurable intelligent surface
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">Sohana Tanzeem</p>
                    <p className="allText">Assistant Professor, EEE</p>
                  </td>
                  <td>
                    <p className="allText">
                      Image analysis, Signal processing, Mathematical Analysis
                      of signal
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">Md. Mehedy Hasan Sumon</p>
                    <p className="allText">Assistant Professor, EEE</p>
                  </td>
                  <td>
                    <p className="allText">
                      Optical Communication, memristor technology.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">S M Shakil Hassan</p>
                    <p className="allText">Assistant Professor, EEE</p>
                  </td>
                  <td>
                    <p className="allText">
                      Microstrip filters and antenna design for microwave
                      communication, Machine Learning and artificial
                      engineering, Biomedical Engineering.
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">Auditi Das</p>
                    <p className="allText">Lecturer, EEE</p>
                  </td>
                  <td>
                    <p className="allText">
                      Biomedical Signal Processing, Speech Signal Processing,
                      Data Science
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="allText">Md. Sumsur Rahman Shanto</p>
                    <p className="allText">Lecturer, EEE</p>
                  </td>
                  <td>
                    <p className="allText">
                      Radar Communication, Image processing
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* Mobile Responsive Start*/}
          <div
            id="mobileMenuButton"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <button>
              <VscAdd className="moreMenu" />
            </button>
          </div>
          {mobileMenuOpen && (
            <div id="mobileMenuContent">
              <button
                id="closeButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <VscChromeClose className="closeButtonIcon" />
              </button>
              <LeftSideNavigationEEE />
            </div>
          )}
          {/* Mobile Responsive End*/}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResearchGroupEEE;
