import React, { useState } from 'react';
import LeftSideNavigation from '../component/leftSideNavigationArts'
import FacultyName from '../component/facultyName'
import { facultyImgArts } from '../../../static'
import Footer from '../../../component/Footer';
import { VscChromeClose } from "react-icons/vsc";
import { VscAdd } from "react-icons/vsc";

const ProgramsBAEnglish = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div>
            <FacultyName name='Faculty of Arts' facultyImg={facultyImgArts} />
            <div className='facultyContent' id='deanArts'>
                <LeftSideNavigation />
                <div className='rightSideContent m-4'>
                    <p className='heading3'>B.A.(Hons.) in English</p>
                    <p className='heading5'>Admission Requirement</p>
                    <p className='allText'>To be eligible to apply for admission into B.A. (Hons.) in English a candidate must have a minimum GPA of 2.50 at the SSC/Dakhil and HSC/Alim/Diploma of Bangladesh Technical Education Board. GCE students must have passed five subjects at O-Level and two subjects at A-Level. The final selection will be made on the basis of Admission Test. Students with average GPA 4 or above in SSC (or equivalent) exam and HSC (or equivalent) exam do not need to sit for the admission test</p>

                    <p className='heading5'>Degree Requirements</p>
                    <p className='allText'>To qualify for the B.A. (Hons.) degree, a student has to pass all the required courses individually , earn a minimum Cumulative Grade Point Average (CGPA) of 2.50 on completion of 121 credit hours and maintain a record of good conduct.</p>
                </div>
                {/* Mobile responsive start here */}
                <div id='mobileMenuButton' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                    <button >
                        <VscAdd className='moreMenu' />
                    </button>
                </div>
                {
                    (mobileMenuOpen) && (
                        <div id='mobileMenuContent'>
                            <button id='closeButton' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                <VscChromeClose className='closeButtonIcon' />
                            </button>
                            <LeftSideNavigation />
                        </div>
                    )
                }
                {/* Mobile responsive end here */}
            </div>
            <Footer />
        </div>
    );
};

export default ProgramsBAEnglish;