import React, { useState } from "react";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import { LeftSideNavigationCivil } from "../../component/leftSideNavigationCivil";
import { cgpaCalculation, civilCouseDistridution } from "../../../../static";

const BscInCivil = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <FacultyName name="Department of Civil Engineering" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationCivil />
        <div className="rightSideContent mt-4">
          <p className="heading3">Bachelor of Science in Civil Engineering</p>
          <p className="allText">
            The Department of Civil Engineering offers a 4 years undergraduate
            Program of Bachelor of Science in Civil Engineering. To become a
            graduate in this field one has to complete 160 credit hours. It is
            utmost necessity to enhance the quality of higher education in
            Bangladesh for national and global context. Eastern University (EU)
            is one of the private university trying to maintain quality
            education for creating the leader of the leaders since 2003.
            Department of Civil Engineering has projected itself as one of the
            vital departments of the science and engineering faculty. Almost all
            the physical infrastructure development of the country are the
            products of Civil Engineering. Construction, operation and
            maintenance of buildings, bridges, roads, railways, airports,
            embankment, river training, irrigation, canals, sea and river ports,
            power houses, transmission towers, dams and barrages etc. require
            the knowledge and technology of Civil Engineering. Experienced and
            learned teachers teach the courses based on Outcome Based Education
            (OBE) system. Eastern University is well equipped with necessary lab
            facilities for test, and research works. The academic programs of
            this University are continually being updated and improved to
            reflect the latest development in Engineering education. The EU
            firmly believes that education is not only the backbone of the
            nation but the quality education is the last resort of a nation to
            attain the quickest emancipation. Knowledge is not only the power,
            but also the super power in the changed context. The mission of
            Civil Engineering department is to become a department of quality
            and distinction enabling students to flourish academically,
            personally, and socially through obtaining soft skill by OBE system.
            The reason for offering Bachelor of Science in Civil Engineering is
            to prepare students for a wide range of exciting industrial
            opportunities including construction, transportation, mining, roads
            and highway, aerospace, chemical, biomedical, nuclear power,
            robotics, and textiles. Civil Engineers can find employment in the
            field of construction and management, surveying, structural,
            geotechnical, water and transportation.
          </p>
          <p className="allText">
            The program is designed to satisfy the growing demand of civil
            professionals throughout the country. It gives students the
            opportunity to obtain broad-base knowledge of Civil Engineering
            suitable for taking the challenge of building infrastructure that
            can minimize the natural and environmental hazards. It also gives
            freedom to tailor the program according to student’s individual
            needs by choosing the optional subjects available in higher
            semesters in the field of structural, geotechnical, environmental
            and transportation engineering. Moreover, there are sufficient
            numbers of Mathematics, Electronics, Electrical Engineering, Project
            Management and other basic science, commerce, Arts and Humanities
            courses.
          </p>
          <p className="allText">
            Apart from academic activities, the students of the Department
            regularly take part in different co-curricular and extra-curricular
            activities such as programming conttest, debates, sprots
            competitions, games contests, welfare programs, etc.
          </p>
          <p className="heading5">Assessment Strategy</p>
          <p className="allText">
            A student with 12 years formal pre-University education (i.e. S.S.C.
            + H.S.C. or its equivalent) must complete sufficient courses for
            graduation that is he/she must earn at total of 160 credits that
            includes General Education (suggested by UGC), Basic Science and
            Mathematics, Core Engineering and Other Engineering courses. The
            following uniform grading system approved by the UGC for all
            Universities is applied in evaluating the examinations of students.
            The minimum passing grade for graduation is CGPA 2.50. The overall
            performance of a student will be expressed in terms of Cumulative
            Grade Point Average (CGPA). A student can sit for any retake /
            improvement exam on payment of prescribed fees. Student’s
            performance evaluation in a course is shown in Figure. Students are
            continuously assessed through class attendance, participation, group
            discussion and Class tests. To maintain the quality education
            question papers should be prepared based on Bloom’s Taxonomy.
            Rubrics should be set out for every course so that every student
            ensure what criteria is maintained in the evaluation process of
            examination script.
          </p>
          <p className="heading5">Grading System</p>
          <p className="allText">
            The following uniform grading system approved by the UGC for all
            universities is applied in the examinations:
          </p>
          <p className="allText">Numerical Marks Letter Grade Grade Point </p>
          <p className="allText">80% and above A+ (A Plus) 4.00 </p>
          <p className="allText">75% to less than 80% A (A regular) 3.75 </p>
          <p className="allText">70% to less than 75% A- (A minus) 3.50 </p>
          <p className="allText">65% to less than 70% B+ (B Plus) 3.25 </p>
          <p className="allText">60% to less than 65% B (B regular) 3.00 </p>
          <p className="allText">55% to less than 60% B- (B minus) 2.75</p>
          <p className="allText">50% to less than 55% C+ (C Plus) 2.50 </p>
          <p className="allText">45% to less than 50% C (C regular) 2.25 </p>
          <p className="allText">40% to less than 45% D 2.00</p>
          <p className="allText"> less than 40% F 0.00 ,</p>
          <p className="allText"> I - Incomplete </p>
          <p className="allText"> W Withdrawn </p>
          <p className="allText">X Continuation </p>

          <p className="heading5">Course Withdrawal</p>
          <p className="allText">
            Students are not allowed to withdraw their courses once they
            register.
          </p>
          <p className="heading5">Incomplete (I) course</p>
          <p className="allText">
            An incomplete course indicates that the student started/register the
            courses in a semester but has not appeared in the final examination.
            If the total mark of the student excluding the final exam is
            ‘satisfactory’, the student will get a chance to sit for retake
            examination. The ‘satisfactory’ mark will depend on the circumstance
            and will be decided by the authority.
          </p>

          <p className="heading5">Retake</p>
          <p className="allText">
            Retake Examination will be applicable for the students who have got
            F/I/WH (Withheld) grade in the course. Retake exam means the student
            will be allowed to sit for the final examination once again after
            proper formalities. Normally such exams are taken within 2 weeks of
            next semester commencement or within the prescribed schedule
            declared by the authority.
          </p>
          <p className="heading5">Grade Improvement</p>
          <p className="allText">
            There is a provision for the students to improve their grades. If
            any grade of the student is below or equal to B+ (Plus), then the
            student may be permitted to sit for the grade improvement exam
            before the final exam of the next semester or within the prescribed
            schedule declared by the authority. Moreover, the student has the
            scope to improve overall CGPA by improving grades in the courses
            with below or equal to B+ (Plus) grade. However, this improvement
            exam must be attempted before starting the thesis/internship and the
            number of such grade Improvement exams will not be more than 10% of
            total courses of the program.
          </p>
          <p className="heading5">Dropout</p>
          <p className="allText">
            By dropout, we mean a student who did not start/register the courses
            for specific semester/s. In case of dropout from a semester, the
            student will be required to apply to the registrar for continuing
            study and attempt the courses or semester/s again after proper
            formalities if he/she returns.
          </p>
          <p className="heading5">
            Grade Point Average (GPA) and Cumulative Grade Point Average (CGPA)
          </p>
          <p className="allText">
            The process of Grade Point Average (GPA) and Cumulative Grade Point
            Average (CGPA) is almost same. GPA is used to denote students’
            performance of a specific semester whereas CGPA is used to denote
            performance of all the semesters as a whole. The process of
            calculation of GPA and CGPA is as follows.
          </p>
          <img className="img-fluid" src={cgpaCalculation} alt="CGPA" />
          <p className="heading5">Graduation Requirement</p>
          <p className="allText">
            To obtain B.Sc in Civil degree a student will have to complete 160.0
            cr. hrs. with a minimum CGPA of 2.5.
          </p>
          <p className="heading5">
            <a href={civilCouseDistridution}>
              Download Semester Wise Course Distribution
            </a>
          </p>
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationCivil />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </>
  );
};

export default BscInCivil;
