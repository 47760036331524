import JournalLayout from "./JournalLayout";
import Spinner from "../../UI/Spinner";

import MessageBox from "../../component/MessageBox";
import { useFetch } from "../../hooks/useFetch";
import styled from "styled-components";

const Name = styled.p`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.3;
  padding: 0;
  margin-bottom: 0.2rem;
`;
const Info = styled.p`
  font-size: 1.6rem;
  margin: 0;
`;
function JournalEditorialBoard() {
  const { data, loading, error } = useFetch("journal/editorial-board");
  if (loading) return <Spinner />;
  if (error) return <MessageBox>{error}</MessageBox>;
  const editor = data.find((editor) => editor.post === "Editor");
  const members = data.filter((editor) => editor.post !== "Editor");
  return (
    <JournalLayout>
      <h4 className="heading4">Editorial Board</h4>
      <h6 className="heading5 mb-2">Editor</h6>
      {editor && (
        <div className="item_list mb-4">
          <Name>{editor.name}</Name>
          <Info>{editor.description}</Info>
          <Info>{editor.institute}</Info>
        </div>
      )}
      <p className="heading5 mb-2">Members:</p>
      {members.length &&
        members.map((member) => (
          <div className="item_list mb-4">
            <Name>{member.name}</Name>
            <Info>{member.description}</Info>
            <Info>{member.institute}</Info>
          </div>
        ))}
    </JournalLayout>
  );
}

export default JournalEditorialBoard;
