import React, { Component } from 'react';
import UndergraduateAdmissionEligibilityContent from './Content/UndergraduateAdmissionEligibilityContent';
import Footer from '../../component/Footer';

class undergraduateAdmissionEligibility extends Component {
    render() {
        return (
            <div>
                <UndergraduateAdmissionEligibilityContent/>
                <Footer/>
            </div>
        );
    }
}

export default undergraduateAdmissionEligibility;