import React, { Component } from 'react';
import WhyeasternuniContent from './Content/WhyEasternUniContent';
import Footer from '../../component/Footer';

class whyEasternUni extends Component {
    render() {
        return (
            <div>
                <WhyeasternuniContent/>
                <Footer/>
            </div>
        );
    }
}

export default whyEasternUni;