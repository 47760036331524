import React, { Component } from 'react';
import OtherpageSidebar from '../../component/OtherpageSideBar';

class AccreditationContent extends Component {
    render() {
        return (
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr />
                    <p className='heading2 primaryHeading text-center'>
                        Accreditation
                    </p>
                    <hr />
                    <p className='allText text-center'>
                        Work On Progress..
                    </p>
                </div>
                <OtherpageSidebar />
            </div>
        );
    }
}

export default AccreditationContent;