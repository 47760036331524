import React,{useState} from 'react';
import LeftSideNavigation from '../component/leftSideNavigationArts';
import FacultyName from '../component/facultyName';
import { facultyImgArts } from '../../../static';
import Footer from '../../../component/Footer';
import { VscChromeClose } from "react-icons/vsc";
import { VscAdd } from "react-icons/vsc";

const ResearchInEnglish = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div>
            <FacultyName name='Faculty of Arts' facultyImg={facultyImgArts} />
            <div className='facultyContent' id='deanArts'>
                <LeftSideNavigation />
                <div className='rightSideContent m-4'>
                    <p className='heading3'>Research in English</p>
                    < p className='allText'>
                        Work in progress....
                    </p>
                </div>
                {/* Mobile responsive start here */}
                <div id='mobileMenuButton' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                        <button >
                            <VscAdd className='moreMenu' />
                        </button>
                    </div>
                    {
                        (mobileMenuOpen) && (
                            <div id='mobileMenuContent'>
                                <button id='closeButton' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                    <VscChromeClose className='closeButtonIcon' />
                                </button>
                                <LeftSideNavigation />
                            </div>
                        )
                    }
                    {/* Mobile responsive end here */}
            </div>
            <Footer />
        </div>
    );
};

export default ResearchInEnglish;