import React, { useState } from "react";
import LeftSideNavigationEEE from "../../component/leftSideNavigationEEE";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const FormerFacultyMembersEEE = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName name="Department of Electrical and Electronic Engineering" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationEEE />
        <div className="rightSideContent mt-4">
          <p className="heading3 text-center">Former Faculty Members</p>
          <div class="card">
            <table class="table table-responsive">
              <tbody>
                <tr>
                  <td>
                    <p class="allText">Database based</p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* Mobile Responsive Start*/}
          <div
            id="mobileMenuButton"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <button>
              <VscAdd className="moreMenu" />
            </button>
          </div>
          {mobileMenuOpen && (
            <div id="mobileMenuContent">
              <button
                id="closeButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <VscChromeClose className="closeButtonIcon" />
              </button>
              <LeftSideNavigationEEE />
            </div>
          )}
          {/* Mobile Responsive End*/}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FormerFacultyMembersEEE;
