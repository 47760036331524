import React from 'react';
import RegistrationProcedureContent from './content/RegistrationProcedureContent';
import Footer from '../../component/Footer';

const registrationProcedure = () => {
    return (
        <div>
            <RegistrationProcedureContent />
            <Footer />
        </div>
    );
};

export default registrationProcedure;