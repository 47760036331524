import React from "react";
import IqacSideBar from "../../../component/IqacSideBar";

const IqacGoalsContent = () => {
  return (
    <div className="content">
      <div className="OtherPagecontent">
        <hr />
        <p className="heading2 primaryHeading text-center">Goals</p>
        <hr />
        <p className="allText">
          Here at IQAC under the umbrella of Higher Education Quality
          Enhancement Project (HEQEP) of University Grant Commission (UGU), we
          thrive to create a quality environment in every aspect of the
          University. As quality has no boundaries, it’s an ongoing process and
          when we will achieve a satisfactory level of outcome; this will be our
          prime objective to embrace that position. We are trying to push the
          envelope to bring our University in International arena of University
          ranking. Under the guideline of HEQEP we are now performing
          Self-assessment in program level.
        </p>
        <p className="allText">
          HEQEP under its AIF sub project is funding under the following area:
        </p>
        <p className="heading6">Teaching-Learning:</p>
        <p className="allText">
          <ul>
            <li>
              Provisional/contractual recruitment of qualified and reputed
              teachers.
            </li>
            <li>
              Professional development of academic staff and non-academic
              personnel in support of teaching activities.
            </li>
            <li>
              Modernization of academic programs in under graduate and masters
              level that would enhance innovation in teaching-learning and
              skills of the graduates in latest scientific knowledge and
              technologies, meet the demands of labor market and increase the
              employability of graduates.
            </li>
            <li>
              Formulation of student-centered curricula designs with emphasis on
              learning outcomes, competencies, interdisciplinary and
              multi-disciplinary courses including results based programs.
            </li>
            <li>
              Invitations to post-doctorate fellows, eminent academics and
              scholars to offer courses and participate in research programs
            </li>
            <li>Improvement of library services.</li>
            <li>
              Designing and implementing self-assessment exercise at academic
              program or department level.
            </li>
          </ul>
        </p>
        <p className="heading6">Research/Post-graduate Programs</p>
        <p className="allText">
          <ul>
            <li>
              Modernization and/or improvement of existing M.Phil, Ph.D and
              Post-Doctoral programs that will consider the development of
              research capacities of universities.
            </li>
            <li>
              Initiate joint academic and research programs with national and
              foreign universities and research organizations.
            </li>
            <li>
              Formulation of innovative research designs oriented towards
              increasing productivity in the country's industries and market
              demands
            </li>
            <li>
              Development and/or improvement of the laboratories and workshops
              with state-of-the-art scientific instruments and equipment with
              the aim to conduct innovative researches and experiments.
            </li>
            <li>
              Collaborative initiatives between the academics, researchers and
              industries for the application of results of innovative
              researches.
            </li>
            <li>
              Professional development of academic and support staff for
              research activities.
            </li>
          </ul>
        </p>
        <p className="heading6">University-wide Innovation</p>
        <p className="allText">
          <ul>
            <li>
              Design and implementation of campus access networks (WANs and
              LANs).
            </li>
            <li>
              Improvement of library services through automation, refurbishing
              of seminar halls and performing arts theater with modern acoustics
              and lighting system, refurbishing of lecture theater with
              audio-visual devices, preservation of rare and ancient manuscripts
              by slate-of-the-art techniques and their reproduction for
              researchers' use; collection of books, journals and special
              publications etc.
            </li>
            <li>
              Equipments for central scientific instrumentation laboratory/
              machine workshops and centers.
            </li>
            <li>
              Joint activities/programs with national and foreign universities
              and research organizations.
            </li>
          </ul>
        </p>
      </div>
      <IqacSideBar />
    </div>
  );
};

export default IqacGoalsContent;
