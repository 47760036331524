import React from "react";
import { NavLink } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";

const leftSideNavigation = () => {
  return (
    <div id="facultyLeftSideMenu" className="sidemenu">
      <Menu vertical className="sidemenuBar mt-4">
        <Dropdown text="Home" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <NavLink exact to="/facultyOfEngineering">
              <Dropdown.Item>About</Dropdown.Item>
            </NavLink>
            <NavLink exact to="/deanMessageEandT">
              <Dropdown.Item>Dean's Message</Dropdown.Item>
            </NavLink>
            <NavLink exact to="/welcomeMessageCivil">
              <Dropdown.Item>Civil Chairperson's Message</Dropdown.Item>
            </NavLink>
            <NavLink exact to="/messageFromCSEChairperson">
              <Dropdown.Item>CSE Chairperson's Message</Dropdown.Item>
            </NavLink>
            <NavLink exact to="/messageFromEEEChairperson">
              <Dropdown.Item>EEE Chairperson's Message</Dropdown.Item>
            </NavLink>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="Departments" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <NavLink exact to="/departmentOfCSE">
              <Dropdown.Item>Department of CSE</Dropdown.Item>
            </NavLink>
            <NavLink exact to="/departmentOfCivil">
              <Dropdown.Item>Department of Civil</Dropdown.Item>
            </NavLink>
            <NavLink exact to="/departmentOfEEE">
              <Dropdown.Item>Department of EEE</Dropdown.Item>
            </NavLink>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="Programs" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <Dropdown text="Undergraduate" className="link item">
              <Dropdown.Menu>
                <NavLink tabindex="-1" exact to="/bscinCivil">
                  <Dropdown.Item>B.Sc in Civil</Dropdown.Item>
                </NavLink>
                <NavLink tabindex="-1" exact to="/bscinCSEProgram">
                  <Dropdown.Item>B.Sc in CSE</Dropdown.Item>
                </NavLink>

                <NavLink tabindex="-1" exact to="/bscinEEEProgram">
                  <Dropdown.Item>B.Sc in EEE</Dropdown.Item>
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="Admission" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <Dropdown
              text="Admission Information"
              pointing="bottom"
              className="link item"
            >
              <Dropdown.Menu>
                <Dropdown.Item>
                  <NavLink exact to="/admissionCSE">
                    Admission in CSE
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/admissionEEE">
                    Admission in EEE
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown.Item>
              <NavLink exact to="/tutionFeesAndFinalcialAidsEngineering">
                Tution Fees and Finalcial Aids
              </NavLink>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* <Dropdown text='Research' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <NavLink exact to='/researchHighlightsEngineering'>Research Highlights</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/researchEventsEngineering'>Events</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/researchInCSE'>Research in CSE</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/researchInEEE'>Research in EEE</NavLink>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
        <Dropdown
          text="News and Events"
          className="link item sideMenuBarDropdown"
        >
          <Dropdown.Menu>
            <Dropdown.Item>
              <NavLink exact to="/newsEngineering">
                News
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/researchEventsEngineering">
                Events
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/noticeEngineering">
                Notice
              </NavLink>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Menu.Item className="sideBarSingleItemLast">
          <NavLink exact to="contactUsEngineering">
            Contact Us
          </NavLink>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default leftSideNavigation;
