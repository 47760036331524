// export const server_django = "http://192.168.10.185:8000";
export const server_django = "https://webapi.easternuni.edu.bd";
// export const server_django = "http://localhost:8000";
// export const server_django = "http://192.168.0.9:8081";

// export const server = "http://localhost:8000";
// export const server = 'http://163.47.38.218:8000'
export const replace_string = "http://192.168.0.6:8001";

export const GoogleTrackingID = "UA-190484854-1";

export const auth = {
  username: "webview",
  password: "*webview@eu#",
  // username: "ragib",
  // password: "dinerweb",
};

export const departmentCode = {
  facultyOfArts: "03",
  facultyOfBusiness: "02",
  facultyOfEngineering: "01",
  facultyOfLaw: "04",
  facultyOfLifeScince: "05",
  bba: 1,
  cse: 2,
  english: 3,
  eee: 4,
  law: 5,
  pharmacy: 6,
  civil: 7,
};

export const employeeType = {
  full: 1,
  part: 2,
  leave: 3,
  acting: 4,
  contractual: 5,
};

export const PAGE_SIZE = 30;

export function getNumberWithOrdinal(n) {
  var s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
}
