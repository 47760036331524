import React, { Component } from 'react';
import TreasurerContent from './content/TreasurerContent';

import Footer from '../../component/Footer';

class treasurer extends Component {
    render() {
        return (
            <div>
                <TreasurerContent/>
                <Footer/>
            </div>
        );
    }
}

export default treasurer;