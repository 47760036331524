import React from "react";
import IqacResourcesContent from "./content/IqacResourcesContent";
import Footer from "../../../component/Footer";

const iqacResources = () => {
  return (
    <div>
      <IqacResourcesContent />
      <Footer />
    </div>
  );
};

export default iqacResources;
