import React, { Component } from 'react';
import OtherpageSidebar from '../../component/OtherpageSideBar';
import { Registrar } from '../../../static'

class RegistrarContent extends Component {
    render() {
        return (
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr />
                    <p className='heading2 primaryHeading text-center'>
                        Message From Registrar
                    </p>
                    <hr />
                    <div className='history'>
                        <div className='item'>
                            <div className='card text-center'>
                                <img className='mx-auto' src={Registrar} alt='Registrar sir image comes here' />
                            </div>
                        </div>
                        <p className='allText text-center'>
                            Work On Progress..
                        </p>
                        <p className='heading6'>
                            Dr. Abul Basher Khan
                            <br />
                            Registrar
                        </p>
                    </div>
                </div>
                <OtherpageSidebar />
            </div>
        );
    }
}

export default RegistrarContent;