import React, { Component } from "react";
import Wrapper from "../../component/Wrapper";
import AdmissionSidebar from "../component/AdmissionSidebar";

class admissionEligibility extends Component {
  render() {
    return (
      <Wrapper>
        <div className="content">
          <div className="OtherPagecontent">
            <hr />
            <p className="heading2 primaryHeading text-center">
              Admission Eligibility
            </p>
            <hr />
            <p className="heading4 primaryHeading">
              For Undergraduate Admission Requirements:
            </p>
            <div className="container">
              <p className="allText">
                To be eligible for admission as a regular student the
                application must have:
              </p>
              <ol start="1" className="allText text-left">
                <li>
                  Completed higher secondary education (12 years of schooling
                  pre-university education) under a system different from SSC/
                  O-Levels/ Equivalent or HSC/ A-Levels/ 12 class passes will be
                  considered equivalent and may apply for admission.
                </li>
                <li>
                  A minimum GPA of 2.50 each in SSC/ Equivalent and HSC/
                  equivalent examination. Candidates with GPA 2.00 in any
                  examination must have a minimum GPA 6.00 combined.
                </li>
                <li>
                  <strong>For the English Medium Students:</strong> Students
                  must have a minimum 05 (five) subjects in ‘0’ Level and 02
                  (two) subjects in ‘A’ Level. Out of the 7 subjects, s/he must
                  obtain minimum ‘B’ Grade or GPA 4.00 in four subjects and ‘C’
                  Grade or GPA 3.50 in three subjects.
                </li>
                <li>
                  <strong>For B.Sc. in EEE/CSE/Civil programs,</strong> students
                  must have Physics and Mathematics in HSC/A-Levels or
                  equivalent level with fulfilling the above criteria.
                </li>
                <li>
                  <strong> For Diploma Holder:</strong> Students must have a
                  minimum GPA of 2.50 each in SSC/ Equivalent and CGPA 2.50 in
                  Diploma (04 years) from relevant subjects.{" "}
                </li>
                <li>
                  <strong>For Bachelor of Pharmacy programs,</strong>
                  <ol type="i" className="ml-4">
                    <li>
                      Students must have an aggregate GPA of 8.00 (on a scale of
                      10) in SSC/ equivalent examinations in science department.
                      However, the GPA in any examination shall not be less than
                      3.50 (on a scale of 5)
                    </li>
                    <li>
                      Students who fail in Mathematics/ without Mathematics in
                      HSC/ A level or equivalent examination can get admission.
                      However, they are required to complete an additional
                      mathematics course for 3 credits consistent with the B
                      pharm curriculum.
                    </li>
                    <li>
                      Students must have GPA 4.00 (on a scale of 5) in Physics,
                      Chemistry and Biology separately and minimum GPA of 3.50
                      (on a scale of 5) in Mathematics in HSC/ Equivalent
                      (except A Level) examination. For A level, minimum B grade
                      in Physics, Chemistry and Biology and C grade in
                      Mathematics.
                    </li>
                  </ol>
                </li>
                <li>
                  In the case of the ward of Freedom Fighter, at least GPA 5.00
                  combined.
                </li>
                <li>Acceptable score in EU Admission Test.</li>
              </ol>
            </div>
            <p className="heading4 primaryHeading">
              Graduate Admission Requirements:
            </p>
            <div className="container">
              <ol start="1" className="allText">
                <li>
                  The candidates applying for Master programs must have minimum
                  GPA 2.00 or equivalent results at the Bachelor level and must
                  fulfill the admission requirement for Bachelor program as per
                  UGC’s set criteria.
                </li>
                <li>
                  A relevant undergraduate degree from a recognized university
                  with a GPA of 2.00 (in a scale of 4.0) or 40% marks in
                  graduation level out of 100 marks.
                </li>
                <li>
                  At least 2 years of job experience with Bachelor degree or
                  4-year Bachelor (Honors) degree required for EMBA.
                </li>
              </ol>
            </div>
          </div>
          <AdmissionSidebar />
        </div>
      </Wrapper>
    );
  }
}

export default admissionEligibility;
