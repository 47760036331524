import { useQuery } from "@tanstack/react-query";
import { getIssues } from "../services/apis";

function useIssues() {
  const {
    isLoading,
    data: issues,
    error,
  } = useQuery({
    queryKey: ["issues"],
    queryFn: () => getIssues(),
  });

  return { isLoading, issues, error };
}

export default useIssues;
