import React, { Component } from 'react';
import HowToApplyContent from './Content/HowToApplyContent';
import Footer from '../../component/Footer';

class howToApply extends Component {
    render() {
        return (
            <div>
                <HowToApplyContent/>
                <Footer/>
            </div>
        );
    }
}

export default howToApply;