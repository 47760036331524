import React, { useState } from "react";
import LeftSideNavigationBusiness from "../component/leftSideNavigationBusiness";
import FacultyName from "../component/facultyName";

import { engFacultyTestImg } from "../../../static";
import Footer from "../../../component/Footer";
import NoticeByDepartment from "../component/noticeByDepartment";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import { departmentCode } from "../../../util";

const NoticeBusinessFaculty = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const config = { faculty: departmentCode.facultyOfBusiness };

  return (
    <div>
      <FacultyName
        name="Faculty of Business Administration"
        facultyImg={engFacultyTestImg}
      />
      <div className="facultyContent">
        <LeftSideNavigationBusiness />
        <div className="rightSideContent m-4">
          <p className="heading3">Notice</p>

          <NoticeByDepartment config={config} />
        </div>
        {/* Mobile responsive start here */}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationBusiness />
          </div>
        )}
        {/* Mobile responsive end here */}
      </div>
      <Footer />
    </div>
  );
};

export default NoticeBusinessFaculty;
