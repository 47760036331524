import React from "react";

import useEuFoundation from "../../../features/useEuFoundation";
import Spinner from "../../../UI/Spinner";
import { replace_string, server_django } from "../../../util";

const MessageFromChairmanContent = () => {
  const { euFoundationMembers, isLoading } = useEuFoundation();
  if (isLoading) return <Spinner />;

  const chairman = euFoundationMembers.find(
    (member) => member.designation.role === "Chairman"
  );

  return (
    <div className="content">
      <div className="OtherPagecontent">
        <p className="heading2 text-center primaryHeading">
          Message from the Chairman
        </p>
        <hr></hr>
        <div className="history">
          <div className="messageContent text-center">
            <img
              className="img-fluid messagePic "
              src={chairman.image?.replace(replace_string, server_django)}
              alt={chairman?.name}
            ></img>
            <div className="placeHolder"></div>
          </div>
          <p className="allText">
            Eastern University has made praiseworthy success over the years and
            is growing rapidly in terms of academic progress by providing
            quality education. With our vision to become a leading university in
            South Asia in its chosen fields of education, our mission is to be a
            “Centre of Excellence” by setting new standards of quality education
            in Bangladesh. With cooperation from all concerned, I am sure, we
            will achieve our mission and realize our vision through quality
            education, training, research, entrepreneurship and community
            services.{" "}
          </p>
          <p className="allText">
            Our integrated educational curriculum is comprised of four major
            components, namely Sciences & Technology, Business, Arts and Law
            that aims at providing our graduates with the knowledge, skills and
            attitude required to contribute towards a knowledge-based economy.
            Our faculty members are experienced, professional and academically
            bright. In addition, the University provides strong administrative
            supports, financial assistance, ample opportunities to be involved
            in various co-curricular activities, etc. to its students.{" "}
          </p>
          <p className="allText">
            Please explore our website to keep updated with the current events
            and activities that are planned at the university and to find out
            what makes Eastern University such an excellent institution. We
            appreciate your feedback on any areas that we can improve.
          </p>

          <p className="heading6 mt-4 pt-4">{chairman?.name}</p>
        </div>
      </div>
    </div>
  );
};

export default MessageFromChairmanContent;
