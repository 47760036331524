import React, { Component } from 'react';
import PaymentProcedureContent from './Content/paymentProcedureContent';
import Footer from '../../component/Footer';


class paymentProcedure extends Component {
    render() {
        return (
            <div>
                <PaymentProcedureContent/>
                <Footer/>
            </div>
        );
    }
}

export default paymentProcedure;