import React from "react";
import { NavLink } from "react-router-dom";
import IqacSideBar from "../../../component/IqacSideBar";
import {
  IQACPresentaionQualityAssuaranceSystem,
  IQACPresentaionSelfAssessmentWorkshopSAC2015October292,
  IQACPresentaionSelfAssessmentProgramLavel1,
  IQACPresentaionAssuranceofLearningAUMCollegeofBusiness,
  IQACPresentationProfessionalDevelopmentProgramMahboobAliAIT,
  IQACPresentationWorkshoponSACResponsibilitiesSAC2015October29,
  IQACPresentationSelfAssessmentatProgramLevel2,
  IQACPresentationQualityAssuranceSystemPPbyDrMesbahuddin,
  IQACPresentationQualityCurriculumDevelopmentProcess,
  IQACPresentationASSESSMENTFORIMPROVINGSTUDENTLEARNING,
  IQACPresentationOrientationworkshopatUGCbyAdhikary,
  IQACPresentationIQACProposalwritingRound2,
  IQACPresentationPreparationofBudget,
  IQACPresentationWorkshoponSACProcess,
  IQACPresentationWorkshoponSACResponsibilitiesSAC2015,
  IQACPresentationConductingSurvey,
  IQACPresentationIQACSA,
  IQACPresentationPresentation1SAC2015October292,
  IQACPresentationSurveyPresentation,
} from "../../../../static";

const IqacPresentationsContent = () => {
  return (
    <div className="content">
      <div className="OtherPagecontent">
        <hr />
        <p className="heading2 primaryHeading text-center">Presentations</p>
        <hr />
        <p className="allText">
          <ul>
            <li>
              <a href={IQACPresentaionQualityAssuaranceSystem}>
                Quality Assurance Systems
              </a>
            </li>
            <li>
              <a href={IQACPresentaionSelfAssessmentWorkshopSAC2015October292}>
                Self-Assessment Workshop- SAC 2015-October 29-2
              </a>
            </li>
            <li>
              <a href={IQACPresentaionSelfAssessmentProgramLavel1}>
                Self-Assessment at Program Level-1
              </a>
            </li>
            <li>
              <a href={IQACPresentaionAssuranceofLearningAUMCollegeofBusiness}>
                Assurance of Learning AUM College of Business
              </a>
            </li>
            <li>
              <a
                href={
                  IQACPresentationProfessionalDevelopmentProgramMahboobAliAIT
                }
              >
                Professional Development Program Mahboob Ali -AIT
              </a>
            </li>
            <li>
              <a
                href={
                  IQACPresentationWorkshoponSACResponsibilitiesSAC2015October29
                }
              >
                Workshop on SAC Responsibilities- SAC 2015-October 29
              </a>
            </li>
            <li>
              <a href={IQACPresentationSelfAssessmentatProgramLevel2}>
                Self-Assessment at Program Level-2
              </a>
            </li>
            <li>
              <a href={IQACPresentationQualityAssuranceSystemPPbyDrMesbahuddin}>
                Quality Assurance System PP-by Dr. Mesbah uddin
              </a>
            </li>
            <li>
              <a href={IQACPresentationQualityCurriculumDevelopmentProcess}>
                Quality Curriculum Development Process
              </a>
            </li>
            <li>
              <a href={IQACPresentationASSESSMENTFORIMPROVINGSTUDENTLEARNING}>
                Assessment for Improving Student Learning
              </a>
            </li>
            <li>
              <a href={IQACPresentationOrientationworkshopatUGCbyAdhikary}>
                Orientation workshop at UGC- by Adhikary
              </a>
            </li>
            <li>
              <a href={IQACPresentationIQACProposalwritingRound2}>
                IQAC Proposal writing (Round 2)
              </a>
            </li>
            <li>
              <a href={IQACPresentationPreparationofBudget}>
                Preparation of Budget
              </a>
            </li>
            <li>
              <a href={IQACPresentationWorkshoponSACProcess}>
                Workshop on SAC Process
              </a>
            </li>
            <li>
              <a href={IQACPresentationWorkshoponSACResponsibilitiesSAC2015}>
                Workshop on SAC Responsibilities- SAC 2015
              </a>
            </li>
            <li>
              <a href={IQACPresentationConductingSurvey}>Conducting Survey</a>
            </li>
            <li>
              <a href={IQACPresentationIQACSA}>IQAC SA</a>
            </li>
            <li>
              <a href={IQACPresentationPresentation1SAC2015October292}>
                Presentation1- SAC 2015-October 29-2
              </a>
            </li>
            <li>
              <a href={IQACPresentationSurveyPresentation}>
                Survey Presentation
              </a>
            </li>
          </ul>
        </p>
      </div>
      <IqacSideBar />
    </div>
  );
};

export default IqacPresentationsContent;
