import React from "react";

import Footer from "../../component/Footer";
import AdmissionSidebar from "../component/AdmissionSidebar";
import Wrapper from "../../component/Wrapper";
import { NavLink } from "react-router-dom";

const paymentInformation = () => {
  const hrStyle = {
    height: "4px",
    backgroundColor: "black",
    border: "none",
    margin: "10px 0",
  };
  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">
            Payment Information
          </p>
          <hr />
          <p className="heading5">
            Payable during Admission for undergraduate programs:
          </p>
          <table>
            <tr>
              <td>Application Form Fee{"  "} </td>
              <td> = Tk. 500</td>
            </tr>
            <tr>
              <td>Admission Fee</td>
              <td> = Tk. 12,000</td>
            </tr>
            <tr>
              <td>Registration Fee</td>
              <td> = Tk. 16,000</td>
            </tr>
            <tr>
              <td>Security Deposit</td>
              <td> = Tk. 2000*</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <hr style={hrStyle} />
              </td>
            </tr>

            <tr>
              <td>Total</td>
              <td>= Tk. 30,500</td>
            </tr>
          </table>

          <p className="heading5">
            Payable during Admission for graduate programs:
          </p>
          <table>
            <tr>
              <td>Application Form Fee{"  "} </td>
              <td> = Tk. 500</td>
            </tr>
            <tr>
              <td>Admission Fee</td>
              <td> = Tk. 12,000</td>
            </tr>
            <tr>
              <td>Registration Fee</td>
              <td> = Tk. 11,000</td>
            </tr>
            <tr>
              <td>Security Deposit</td>
              <td> = Tk. 2000*</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <hr style={hrStyle} />
              </td>
            </tr>

            <tr>
              <td>Total</td>
              <td>= Tk. 25,500</td>
            </tr>
          </table>
          <p className="allText">
            * Security Deposit Tk. 2000 is refundable after completion of the
            degree
          </p>

          <p className="heading5">Online Application Fee Payment</p>
          <ul>
            <li>
              <p className="allText">
                Go to <a href="www.easternuni.edu.bd"> EU Website</a>
              </p>
            </li>
            <li>
              <p className="allText">
                Click{" "}
                <a href="https://webportal.easternuni.edu.bd/onlineadmission/Admission.aspx">
                  {" "}
                  Apply Online*
                </a>
              </p>
            </li>
            <li>
              <p className="allText">Fill-up the Online Application Form</p>
            </li>
            <li>
              <p className="allText">
                Make Payment Tk. 500 to bKash no. 01795711361 (Dial *247# or Use
                Bkash App)
              </p>
            </li>
            <li>
              <p className="allText">
                Input your contact number as a reference
              </p>
            </li>
            <li>
              <p className="allText">Input your Transaction ID</p>
            </li>
            <li>
              <p className="allText">Submit your form</p>
            </li>
          </ul>

          <p className="allText">
            {" "}
            <NavLink to="paymentProcedure" exact>
              {" "}
              please click here for Admission form payment instruction
            </NavLink>
          </p>
        </div>
        <AdmissionSidebar />
      </div>
      <Footer />
    </Wrapper>
  );
};

export default paymentInformation;
