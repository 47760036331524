import React, { useState } from 'react';
import LeftSideNavigation from '../component/leftSideNavigationArts'
import FacultyName from '../component/facultyName'
import { facultyImgArts } from '../../../static'
import Footer from '../../../component/Footer';
import { VscChromeClose } from "react-icons/vsc";
import { VscAdd } from "react-icons/vsc";

const ProgramsMAEnglish = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div>
            <FacultyName name='Faculty of Arts' facultyImg={facultyImgArts} />
            <div className='facultyContent' id='deanArts'>
                <LeftSideNavigation />
                <div className='rightSideContent m-4'>
                    <p className='heading3'>M.A. ELL(English Language & Literature)</p>
                    <p className='allText'>
                        The M.A. ELL program consists of 3 Semesters and 41 credit hours spread over 13 courses each having 3-credit hour weight. There will be a viva-voce exam of 2-credit hour weight to be held finally after the completion of all the written courses including Research Methodology and Practice course. Students have to do 4 courses a Semester, but there will be option to do 2/3 courses a Semester if a student so desires.</p>
                    <p className='heading5'>Admission Requirement</p>
                    <p className='allText'>For admission into M.A. ELL (English Language and Literature) a candidate must have a 4-year B.A.(Hons.) degree in English or must have passed M.A. (Preliminary) in English.</p>

                    <p className='heading5'>Degree Requirements</p>
                    <p className='allText'>To qualify for the M.A. ELL degree, a student has to pass all the required courses individually , earn a minimum Cumulative Grade Point Average (CGPA) of 2.50 on completion of 41 credit hours and maintain a record of good conduct.</p>
                </div>
                {/* Mobile responsive start here */}
                <div id='mobileMenuButton' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                    <button >
                        <VscAdd className='moreMenu' />
                    </button>
                </div>
                {
                    (mobileMenuOpen) && (
                        <div id='mobileMenuContent'>
                            <button id='closeButton' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                <VscChromeClose className='closeButtonIcon' />
                            </button>
                            <LeftSideNavigation />
                        </div>
                    )
                }
                {/* Mobile responsive end here */}
            </div>
            <Footer />
        </div>
    );
};

export default ProgramsMAEnglish;