import React from "react";

import { Link } from "react-router-dom";
import { useNews } from "../../../features/useNews";
import LoadingBox from "../../../component/LoadingBox";
import MessageBox from "../../../component/MessageBox";
import moment from "moment";

const NewsByDepartment = ({ config }) => {
  const { news, isLoading, error } = useNews(config);
  if (isLoading) return <LoadingBox />;
  if (error) return <MessageBox>{error.message}</MessageBox>;
  const NewsList = news.map((mylist) => {
    return (
      <div className="card" key={mylist.id}>
        <img
          className="img-fuild"
          src={mylist.headerImage}
          alt={mylist.headline}
        ></img>
        <div className="card-body">
          <div className="newsFooter">
            <div className="newsHeadlines">
              <Link exact to={"/news/" + mylist.id}>
                <p className="heading5">{mylist.headline}</p>
              </Link>
              <p className="publsihDate">
                {moment(mylist.newsDate, "YYYY-MM-DD").format("LL")}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  });
  return <div className="newsForDepartment">{NewsList}</div>;
};

export default NewsByDepartment;
