import { useQuery } from "@tanstack/react-query";
import { getJournals } from "../services/apis";

function useJournal() {
  const {
    isLoading,
    data: journals,
    error,
  } = useQuery({
    queryKey: ["journals"],
    queryFn: getJournals,
  });

  return { isLoading, journals, error };
}

export default useJournal;
