import React from "react";
import OtherpageSideBar from "../component/OtherpageSideBar";
import Wrapper from "../../component/Wrapper";

import LoadingBox from "../../component/LoadingBox";
import Offices from "./Offices";
import { useOffice } from "../../features/useOffice";

const AllOtherOffices = () => {
  // const { data, loading, error } = useFetch("employee/office");
  const { office, isLoading, error } = useOffice();

  if (isLoading) return <LoadingBox />;

  return (
    <Wrapper>
      <div className="content">
        <div id="allOtherOffices">
          {office.length && (
            <Offices office={office} loading={isLoading} error={error} />
          )}
        </div>
        <OtherpageSideBar />
      </div>
    </Wrapper>
  );
};

export default AllOtherOffices;
