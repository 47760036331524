import React, { useState } from "react";
import LeftSideNavigationBusinessDepartment from "../../component/leftSideNavigationDepartmentofBusiness";
import FacultyName from "../../component/facultyName";
import { engFacultyTestImg, BBACourseDistridution } from "../../../../static";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const ProgramsBBADepartment = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName
        name="Department of Business Administration"
        facultyImg={engFacultyTestImg}
      />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationBusinessDepartment />
        <div className="rightSideContent m-4">
          <p className="heading3">BBA (Bachelor of Business Administration)</p>

          <p className="allText">
            The BBA Program of Eastern University prepares students for entry
            level managerial positions along with knowledge required also for
            their advancement into executive and senior management positions in
            any type of organization. The objective of the BBA Curriculum is to
            impart a well-rounded general and business education so that the
            students can develop their business acumen, social and networking
            skill and can assume leadership role in the competitive business
            world. The up-to-date, forward looking curriculum will familiarize
            the students with general and business environment, equip them with
            tools and techniques of business analysis, build their comprehension
            and assimilation capacity and help them acquire entrepreneurial and
            managerial skill.
          </p>
          <p className="allText">
            The BBA is a 132 credit hours program consisting of 45 courses. The
            program includes general education, foundation, business core,
            business functions, integration and concentration courses and a full
            time internship or a project work for a semester. The program can be
            completed in twelve semesters (four years) if a student takes 12
            credit hours every semester. It can be completed in shorter time if
            a student takes more than 12 credit hours per semester.
          </p>
          <p className="heading5">Admission Requirement</p>
          <p className="allText">
            Candidates with a minimum GPA of 2.50 in both SSC/Dakhil and
            HSC/Alim/Diploma from Technical Education Board or Minimum GPA of
            2.00 in one of those exam with total GPA of 6.00 in both the exams
            are eligible for admission .The GCE Students must have passed five
            subjects in O-level and two subjects in A-level.
          </p>
          <p className="heading5">Degree Requirement</p>
          <p className="allText">
            To qualify for the BBA degree, a student has to pass all the
            required courses individually, pass the internship or the project
            with a minimum “C” grade, earn a minimum Cumulative Grade Point
            Average (CGPA) of 2.50 and maintain a record of good conduct
          </p>
          <p className='heading5'>
            <a href={BBACourseDistridution}>Download Course Distribution</a>
          </p>
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationBusinessDepartment />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </div>
  );
};

export default ProgramsBBADepartment;
