import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { SampleCV1, SampleCV2 } from '../../static';


const CSIOSidebar = () => {
    return (
        <div className="OtherpageSideBar">
            <ul className="list-group list-group-flush">
                <li className="nav-item dropdown list-group-item">
                    <NavLink className="nav-link" exact to="/careerServie">
                        Career Services
                    </NavLink>
                </li>
                <li className="nav-item dropdown list-group-item">
                    <NavLink className="nav-link" exact to="/internationalOffice">
                        International Office
                    </NavLink>
                </li>
                <li className="nav-item dropdown list-group-item">
                    <NavLink className="nav-link" exact to="/clubsAndExtraCurricular">
                        Clubs & Extra Curricular Activities
                    </NavLink>
                </li>
            </ul>
            <br />
            <hr />
            <table className='table table-responsive'>
                <tr>
                    <td className='allText'>
                        <p className='heading6'>CSIO Contact:</p>
                    </td>
                </tr>
                <tr>
                    <td className='allText'>
                        <p className='allText'>Road 6, Block B,</p>
                        <p className='allText'>Ashulia Model Town, Savar,</p>
                        <p className='allText'>Dhaka-1345</p>
                        <p className='allText'>Phone: 09602666651,09602666652</p>
                        <p className='allText'>E-mail: csio@easternuni.edu.bd</p>
                    </td>
                </tr>
            </table>
            <hr />
            <hr />
            <table className='table table-responsive'>
                <tr>
                    <td className='heading6'>Download CV </td>
                </tr>
                <tr>
                    <td className='allText'>
                        <p className='allText'>
                            <a href={SampleCV1}>Sample CV- 1</a>
                        </p>
                        <p className='allText'>
                            <a href={SampleCV2}>Sample CV- 2</a>
                        </p>
                    </td>
                </tr>
            </table>
        </div>
    );
};

export default CSIOSidebar;