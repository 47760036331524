import React from "react";

import Wrapper from "../../component/Wrapper";
import OtherpageSideBar from "../component/OtherpageSideBar";
import { useFetch } from "../../hooks/useFetch";
import Spinner from "../../UI/Spinner";
import MessageBox from "../../component/MessageBox";

function BoardOfTrustees() {
  const { data, loading, error } = useFetch("api/boardoftrustees");

  if (loading) return <Spinner />;

  if (error) return <MessageBox>{error.message}</MessageBox>;

  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr></hr>
          <p className="heading2 primaryHeading text-center">
            Board of Trustees
          </p>
          <hr></hr>

          {/* Database based list */}
          <table className="table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Name</th>
                <th scope="col">Designation</th>
              </tr>
            </thead>
            <tbody>
              {data.map((member, index) => (
                <tr key={member.id}>
                  <th scope="row">{index + 1}</th>
                  <td>{member.bot.name}</td>
                  <td>{member.designation.role}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <OtherpageSideBar />
      </div>
    </Wrapper>
  );
}

export default BoardOfTrustees;
