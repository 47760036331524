import React from "react";

import ClubInfo from "./ClubInfo";
import { useFetch } from "../../../hooks/useFetch";
import LoadingBox from "../../../component/LoadingBox";
import MessageBox from "../../../component/MessageBox";

const Clubs = () => {
  const { data: clubList, loading, error } = useFetch("clubs");

  if (loading) return <LoadingBox />;
  if (error) return <MessageBox>{error}</MessageBox>;
  console.log(clubList);

  const Club = clubList.map((mylist) => {
    return <ClubInfo key={mylist.ClubsID} {...mylist}></ClubInfo>;
  });
  return (
    <main className="OtherPagecontent">
      <div className="clubsMain">
        <hr></hr>
        <p className="heading2">Club Information</p>
        <hr></hr>
        <section className="Clubsinfo">{Club}</section>
      </div>
    </main>
  );
};

export default Clubs;
