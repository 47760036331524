import React, { useState } from "react";
import LeftSideNavigationBusinessDepartment from "../../component/leftSideNavigationDepartmentofBusiness";
import FacultyName from "../../component/facultyName";
import { engFacultyTestImg } from "../../../../static";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import { LabBBA } from '../../../../static'

const LabFacilitiesBusiness = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName
        name="Department of Business Administration"
        facultyImg={engFacultyTestImg}
      />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationBusinessDepartment />
        <div className="rightSideContent mt-4">
          <p className="heading3 text-center">Lab Facilities</p>

          {/* Mobile Responsive Start*/}
          <div
            id="mobileMenuButton"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <button>
              <VscAdd className="moreMenu" />
            </button>
          </div>
          {mobileMenuOpen && (
            <div id="mobileMenuContent">
              <button
                id="closeButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <VscChromeClose className="closeButtonIcon" />
              </button>
              <LeftSideNavigationBusinessDepartment />
            </div>
          )}
          {/* Mobile Responsive End*/}



          <p className='allText'>Faculty of Business Administration of Eastern University currently has two Internet enabled computer labs with over 65 computers to meet the students’ Information Technology needs. Trained and experienced teachers provide both theoretical and practical lessons for students to help them work through a rapidly changing technology driven world.</p>


          <p className='allText'>Computer Labs enable students to get training in computer applications and other software, for example: Microsoft Word, Excel, Power point, Access, SPSS, Tally, Troyee and Accounting & Data Analysis software. Therefore, once students become graduates, they can use the skills they learned for the betterment of their career. Additionally, our computer labs allow equity to the students. Every individual can get access to his computer. Students can work on machines without sharing PCs. Computer Lab is used to provide students with access to many services. It gives our students the access to software library and scope to acquire practical experiences.</p>

          <p className='allText'>There is always help available from lab in-charge & staff to solve any queries of students so that they get uninterrupted service to develop themselves.</p>
          <p className='heading6 text-center'>Computer Lab-1-Class Lab</p>
          <table className="table p-1 table-bordered table-hover">
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name of the equipment</th>
                <th>Number</th>
                <th>Configuration</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>PC</td>
                <td>30</td>
                <td>HP/Clone/Core-i3 Ram-2/4GB HDD-500GB</td>
              </tr>
              <tr>
                <td>2</td>
                <td>Projector</td>
                <td>01</td>
                <td>Hitachi</td>
              </tr>
              <tr>
                <td>3</td>
                <td>UPS</td>
                <td>30</td>
                <td>Apollo/Prolink 650VA</td>
              </tr>
            </tbody>
          </table>

          <p className='heading6 text-center'>Computer Lab-2-Practice Lab</p>
          <table className="table p-1 table-bordered table-hover">
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name of the equipment</th>
                <th>Number</th>
                <th>Configuration</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>PC</td>
                <td>35</td>
                <td>HP/Clone/core-i3 Ram-4/2GB HDD-500GB</td>
              </tr>
              <tr>
                <td>2</td>
                <td>UPS</td>
                <td>35</td>
                <td>Apollo/Prolink 650VA</td>
              </tr>
            </tbody>
          </table>

          <img className="img-fluid" src={LabBBA} alt="lab BBA Image" />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LabFacilitiesBusiness;
