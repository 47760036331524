import React from 'react';
import AcademicSidebar from '../../component/AcademicSideBar'

const codeOfConductContent = () => {
    return (
        <div className='content'>
            <div className='OtherPagecontent'>
                <hr></hr>
                <p className='heading2 primaryHeading text-center'>
                    Code of Conduct</p>
                <hr></hr>

                <>
                <div className="container" id="codeOfConduct">
                    <ul id="CodeConduct" className="nav nav-tabs" role="tablist" runat="server">
                        <li className="nav-item">
                            <a className="nav-link active" href="#Preamble" role="tab" data-toggle="tab"><p className="allText">Preamble</p></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#Article1" role="tab" data-toggle="tab"><p className="allText">Article 1</p></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#Article2" role="tab" data-toggle="tab"><p className="allText">Article 2</p></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#Article3" role="tab" data-toggle="tab"><p className="allText">Article 3</p></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#Article4" role="tab" data-toggle="tab"><p className="allText">Article 4</p></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#Article5" role="tab" data-toggle="tab"><p className="allText">Article 5</p></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#Article6" role="tab" data-toggle="tab"><p className="allText">Article 6</p></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#Article7" role="tab" data-toggle="tab"><p className="allText">Article 7</p></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#Article8" role="tab" data-toggle="tab"><p className="allText">Article 8</p></a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#Article9" role="tab" data-toggle="tab"><p className="allText">Article 9</p></a>
                        </li>
            
                    </ul>
            
                    {/* <!-- Tab panes --> */}
                    <div className="tab-content">
                        {/* <!-- Preamble start here --> */}
                        <div role="tabpanel" className="tab-pane fade show active" id="Preamble">
            
            
                            <p className="text-justify allText">Eastern University has constituted a Proctorial Body taking into account that discipline is the most essential and important constituent for the development of the institution. The constituted Proctorial Body shall assist the Vice Chancellor in exercising the power related to disciplinary action as provided in this document.</p>
            
                        </div>
                        {/* <!-- Preamble end here --> */}
            
                        {/* <!-- Article 1 start here --> */}
                        <div role="tabpanel" className="tab-pane fade" id="Article1">
            
                            <p className="allText text-justify">
                                <ol type="a" className="allText">
                                    <li>
                                        <p className="heading5 allText">The Proctorial Committee shall consist of: </p>
                                        <ul>
                                            <li>The Vice Chancellor.</li>
                                            <li>Pro-Vice Chancellor.</li>
                                            <li>Proctor.</li>
                                            <li>Assistant Proctors.</li>
                                        </ul>
                                    </li>
                                    <li>There shall be one Proctor and as many Assistant Proctors as deemed necessary by the University authority.</li>
                                    <li>The appointment of the Proctor and Assistant Proctors shall be made from the pool of Faculty Members of the University by the Vice Chancellor with the consultation of the respective Deans/Advisors of the Faculties.</li>
                                    <li>One Assistant Proctor and a proctor (female affairs) may be appointed from each of the Faculties and departments of the university after consultation with the respective Deans/ Advisors.</li>
                                    <li>The Proctor and the Assistant Proctors shall be directly responsible to the Vice Chancellor and for the functional purpose they shall report to the Pro Vice Chancellor.</li>
                                </ol>
                            </p>
                        </div>
                        {/* <!-- Article 1 end here -->
            
                        <!--Article 2 start here --> */}
                        <div role="tabpanel" className="tab-pane fade" id="Article2">
                            <p className="heading5 allText">The responsibility of the Proctorial body may include:</p>
                            <p className="allText">
                                <ol type="a" className="allText">
                                    <li>Maintain a peaceful environment inside and the adjacent area of the university premise; to oversee that the Code of Conduct of the Eastern University students, is maintained within the university campus and take such steps as appropriate to do that.</li>
                                    <li>Take cognizance of any breach of student’s Code of Conduct and suggest immediate disciplinary action in such cases;</li>
                                    <li>Decide the quantum of punishment to be imposed on the erring students.</li>
                                    <li>Monitor discipline among the students’ community in classNameroom, exam halls, library, canteen, common rooms, study rooms and inside and outside the campus.</li>
                                    <li>Conduct enquiries of the defaulter students against individual/collective indiscipline.</li>
                                    <li>Supervise the investigation of any alleged breach of the student’s Code of Conduct. That may involve interviewing the erring student/s and such other students as necessary, conveying the information to the reporting authority, the Chairperson of the respective department, guardians of the involved students, the law enforcement agency (if required). Written record of the whole proceeding shall be kept in the Proctorial office. </li>
                                </ol>
            
            
            
            
            
            
                            </p>
            
                        </div>
                        {/* <!--Article 2 end here -->
            
                        <!-- Article 3 start here --> */}
                        <div role="tabpanel" className="tab-pane fade" id="Article3">
                            <p className="heading5 allText">Scopes and Jurisdictions of the office of a Proctor and Assistant Proctors</p>
                            <p className="allText">
                                <ol type="a" className="allText">
                                    <li>The jurisdiction of the Proctor and the Assistant Proctors shall extend to the whole of the university;</li>
                                    <li>Ordinarily, during the university hours the Proctor or One Assistant Proctor shall be available to maintain and oversee the order and discipline in the students.
                                    </li>
                                    <li>During the University hours the Assistant Proctors from the various faculties and departments shall be entrusted with the duty of ensuring the discipline in their respective faculties and departments.</li>
                                    <li>The Proctor and the Assistant Proctors shall investigate, and recommend punishment in cases of disciplinary incidents. Those recommendations will be referred to the Vice Chancellor and/or the Pro Vice Chancellor or the Disciplinary Committee for decision and necessary action</li>
                                    <li>The Proctorial Body shall maintain a liaison with all the other committees related to disciplinary matters of the university.</li>
                                    <li>The proceedings of the Proctorial body shall protect individual confidentiality at all stages.</li>
                                    <li>Cases shall only be referred to the Law enforcement agencies upon taking approval from the Vice Chancellor when it constitutes a criminal offence and in such and other fitting cases the University will not prevent from disclosing confidential information where necessary for discharge of duties or as required by law. </li>
                                </ol>
                            </p>
                        </div>
                        {/* <!--Article 3 end here -->
            
                        <!-- Article 4 start here --> */}
                        <div role="tabpanel" className="tab-pane fade" id="Article4">
                            <p className="heading5 allText">Communication between the Office of the Vice Chancellor or the Pro Vice Chancellor and the Proctorial body</p>
                            <p className="allText text-justify">
                                <ol type="a" className="allText">
                                    <li>To ensure utmost efficiency and effectiveness of the Proctorial Body shall always have an open line of communication with the Office of the Vice chancellor/ Pro Vice Chancellor.</li>
                                    <li>The Proctorial Body shall be notified well before any decision which can potentially cause or attract dissent, protestation, opposition or any other harmful reaction from the students, is taken by the Administration. <i><u>Without such notice, taking any action in ensuing situations would become a matter of discretion for the Proctor and the Assistant Proctors.</u></i>
                                    </li>
                                    <li>Organization of any university, departmental or club activity that involves student participation is subject to notify of the Proctor and all such events would proceed with his notification on the matter of mitigating conflict factors and disciplinary incidents. </li>
                                    <li>Information about any disciplinary incident shall be communicated to the Proctor, if already had not been by a student, from the office and Proctor would notify and direct the respective Assistant Proctor or other Assistant proctor whom he deems fit or the Proctor himself may address the matter personally.  </li>
                                </ol>
                            </p>
                        </div>
                        {/* <!--Article 4 end here -->
            
                        <!-- Article 5 start here --> */}
                        <div role="tabpanel" className="tab-pane fade" id="Article5">
                            <p className="heading5 allText">Code of Conduct and penalties for violating those</p>
                            <p className="allText">There shall be a code of conduct for the students of the Eastern University consisting of offences. It includes:</p>
                            <div className="table-responsive-sm">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col"><p className="allText">Disciplinary Incident</p></th>
                                            <th scope="col"><p className="allText">Punishment</p></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <ol type="1" className="allText">
                                                    <li>Entering the University premise without Identity Cards.</li>
                                                    <li>Smoking inside the University premise.</li>
                                                    <li>Playing card.</li>
                                                    <li>Writing, drawing or painting on any university property.</li>
                                                    <li>Conduct and attire that is lewd, indecent, or obscene</li>
                                                </ol>
                                            </td>
                                            <td>
                                                <p className="allText">Seizing the ID card</p>
                                                <p className="allText">Fine tk 500- 1000</p>
            
                                            </td>
            
                                        </tr>
                                        <tr>
                                            <td>
                                                <ol start="6" type="1" className="allText">
                                                    <li>Cheating in the Exam</li>
                                                    <li>Disorderly conduct, including obstructive and disruptive behavior that interferes with teaching, research, administration, or other university or university-authorized activity.</li>
                                                    <li>Failure to comply with the directions of authorized university officials in the performance of their duties, including failure to identify oneself when requested to do so; failure to comply with the terms of a disciplinary sanction; or refusal to vacate a university facility when directed to do so.</li>
                                                    <li>Unauthorized entry, use, or occupancy of university facilities.</li>
                                                    <li>Verbal abuse of another person, including the following: An express or implied threat to:
            
                                                        <ol type="i" className="allText">
                                                            <li>Interfere with an individual’s personal safety, academic efforts, employment, or participation in university-sponsored activities and that under the circumstances causes the person to have a reasonable apprehension that such harm is about to occur; or </li>
                                                            <li>Injure that person, or damage his or her property; or </li>
                                                            <li>“Fighting words” that are spoken face-to-face as a personal insult to the listener or listeners in personally abusive language inherently likely to provoke a violent reaction by the listener or listeners to the speaker.</li>
                                                        </ol>
                                                    </li>
                                                    <li>Intentionally obstructing or blocking access to university facilities, property, or programs.</li>
                                                    <li>Engagement, solicitation, initiation, encouragement, abetment, organization, facilitation, provocation of any sort of political activity inside and in the adjacent are of the university premise.</li>
                                                    <li>Dishonest conduct including, but not limited to, false accusation of misconduct, forgery, alteration, or misuse of any university document, record, or identification; and giving to a university official information known to be false.</li>
                                                    <li>Assuming another person’s identity or role through deception or without proper authorization. Communicating or acting under the guise, name, identification, e-mail address, signature, or other indications of another person or group without proper authorization or authority.</li>
                                                    <li>Knowingly initiating, transmitting, filing, or circulating a false report or warning concerning an impending bombing, fire, or other emergency or catastrophe; or transmitting such a report to an official or an official agency.</li>
                                                    <li>Unauthorized release or use of any university access codes for computer systems, duplicating systems, and other university equipment.</li>
                                                    <li>Actions that endanger one’s self, others in the university community, or the academic process.</li>
                                                    <li>Unauthorized taking, possession, or use of university property or services or the property or services of others.</li>
                                                    <li>Damage to or destruction of university property or the property belonging to others.</li>
                                                    <li>Unauthorized setting of fires on university property; unauthorized use of or interference with fire equipment and emergency personnel.</li>
                                                    <li>Unauthorized possession, use, manufacture, distribution, or sale of illegal fireworks, incendiary devices, weapon or other dangerous explosives, drugs.</li>
                                                    <li>Acting with violence.</li>
                                                    <li>Aiding, encouraging, or participating in a riot.</li>
                                                    <li>Harassment</li>
                                                    <li>Stalking or hazing of any kind whether the behavior is carried out verbally, physically, electronically, or in written form. 
                                                        <ol type="i" className="allText">
                                                            <li>Stalking is defined as repeated, unwanted contact in the forms of, including but not limited to, phone calls, e-mail, physical presence, and regular mail. </li>
                                                            <li>Hazing is defined as any conducts that subjects another person, whether physically, mentally, emotionally, or psychologically, to anything that may endanger, abuse, degrade, or intimidate the person as a condition of association with a group or organization, regardless of the person’s consent or lack of consent. </li>
                                                        </ol>
                                                    </li>
            
                                                    <li>Physical abuse of any person, including the following: 
                                                        <ol type="i" className="allText">
                                                            <li>The use of physical force or violence to restrict the freedom of action or movement of another person or to endanger the health or safety of another person; </li>
                                                            <li>Physical behavior that involves an express or implied threat to interfere with an individual’s personal safety, academic efforts, employment, or participation in university-sponsored extracurricular activities or causes the person to have a reasonable apprehension that such harm is about to occur; or </li>
                                                            <li>Physical behavior that has the purpose or reasonably foreseeable effect of interfering with an individual’s personal safety, academic efforts, employment, or participation in university-sponsored extracurricular activities or causes the person to have a reasonable apprehension that such harm is about to occur; </li>
                                                            <li>Sexual assault, including while any party involved is in an impaired state; </li>
                                                            <li>Sexual contact with another person without consent, including while any party involved is in an impaired state. </li>
                                                        </ol>
                                                    </li>
                                                    <li>Gambling or any other game or any other activity with the element of betting.</li>
                                                    <li>Violation of other disseminated university regulations, policies, or rules.</li>
                                                    <li>A violation of any criminal law.</li>
                                                    <li>Engaging in or encouraging any behavior or activity that threatens or intimidates any potential participant in a judicial process.</li>
                                                </ol>
                                            </td>
            
                                            <td>
                                                <p className="allText text-justify">Seizing the ID Card</p>
                                                <p className="allText text-justify">Written Complaint</p>
                                                <p className="allText text-justify">Investigation</p>
                                                <p className="allText text-justify">Disciplinary Action</p>
            
            
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br></br>
                        </div>
                        {/* <!--Article 5 end here -->
            
                        <!-- Article 6 start here --> */}
                        <div role="tabpanel" className="tab-pane fade" id="Article6">
                            <p className="heading5 allText">Proctors’ Disciplinary Hearing </p>
                            <p className="allText">
                                <ol type="a" className="allText">
                                    <li>Unless an alleged breach of regulations involves harassment, serious injury to a person, serious damage to property, or a significant element of dishonesty, the Proctors can offer the student concerned the option to have the matter dealt with by the Proctors themselves.</li>
                                    <li>In that case, Proctors may preside so as to ensure that individuals hearing the case would be different from those responsible for investigating and prosecuting it. If the student exercises this option, he or she will be formally notified what regulations he or she is thought to have breached, and will be sent a notice to attend a Proctors’ Disciplinary Hearing (at which he or she may be accompanied or represented by a member of Congregation).</li>
                                    <li>At this hearing, the evidence will be presented and the student has the right to make a defence against the allegations or else to admit the breach(es) of regulations and to present evidence to explain his or her behavior. Witnesses may be called to attend, either by the Proctors or the student.</li>
                                    <li>The Proctor and the Assistant Proctors shall investigate, and recommend punishment in cases of disciplinary incidents. Those recommendations will be referred to the Vice Chancellor and/or the Pro Vice Chancellor or the Disciplinary Committee for decision and necessary action</li>
                                    <li>If the student admits the alleged breach of regulations, or is found guilty, the only penalties which the Proctors may impose are a fine (or fine plus compensation) of up to Tk. 1000 or a written warning about future conduct. If a fine or compensation order (including a Spot Fine for misconduct after University Examinations) is not paid, the amount will automatically be increased until continued non-payment results in the case being referred to the Student Disciplinary Panel.</li>
                                </ol>
                            </p>
                            <br></br>
                        </div>
                        {/* <!--Article 6 end here -->
            
                        <!-- Article 7 start here --> */}
                        <div role="tabpanel" className="tab-pane fade" id="Article7">
                            <p className="heading5 allText">Investigation Procedure</p>
                            <p className="allText">
                                <ol type="a" className="allText">
                                    <li>When, in the opinion of the proctor/assistant proctor, misconduct has occurred and action against a student is justified, the proctor shall oversee and investigate the matter. During the investigation, the proctor and the assistant proctor will usually convene a series of meetings to formally discuss the allegations with the subject of the complaint, the person making the complaint and any other person involved. In cases where the alleged offence/s involve/s more than one student, all or any of the cases may be dealt with at the same time.</li>
                                    <li>The alleged Students shall be required to attend any meeting conducted by the Proctorial body if deemed necessary by the same.</li>
                                    <li>The Proctor shall set out the allegation/s of misconduct.</li>
                                    <li>The student shall respond to the allegation/s and be present with their representatives/guardians where required, whenever oral evidence is being heard by the committee.</li>
                                    <li>The students shall be entitled to submit witness or other evidence to substantiate their claim and such witness or evidence may not be accepted without cross examination.</li>
                                    <li>The student in question shall be formally notified in writing the outcome, of the Proctorial body’s decision and advised of the procedure for submitting an appeal.</li>
                                    <li>Written reports of all decided matters shall be communicated to the Vice Chancellor, Pro Vice chancellor, Registrar, Respective Dean/Advisor/Chairperson, Faculty Members and the student’s guardians.</li>
                                </ol>
                            </p>
                            <br></br>
                        </div>
                        {/* <!--Article 7 end here -->
            
                        <!-- Article 8 start here --> */}
                        <div role="tabpanel" className="tab-pane fade" id="Article8">
                            <p className="heading5 allText">Appeal.</p>
                            <p className="allText">
                                A committee shall be constituted by the Vice Chancellor to which the student may prefer appeal after a disciplinary matter has been decided either by the Proctor, assistant Proctor or the higher authority. Such Appeal must be filed through a written application within 7 days of the decision of the Proctorial body. 
                            </p>
                        </div>
                        {/* <!--Article 8 end here -->
            
                        <!-- Article 9 start here --> */}
                        <div role="tabpanel" className="tab-pane fade" id="Article9">
                            <p className="heading5 allText">Amendment.</p>
                            <p className="allText">
                                The document can be amended by the Proctorial body with consensus of all the concerned. 
                            </p>
                        </div>
                        {/* <!--Article 9 end here --> */}
                    </div>
                </div>
                </>

            </div>
            <AcademicSidebar />
        </div>
    );
};

export default codeOfConductContent;