import React, { useState } from "react";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import OnLeaveFaculty from "../../component/FacultyMembersByDepartment";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import LeftSideNavigationPharmacy from "../../component/leftSideNavigationPharmacy";
import { departmentCode, employeeType } from "../../../../util";

const OnLeaveFacultyMembersPharmacy = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const config = {
    department: departmentCode.pharmacy,
    leave: employeeType.leave,
  };

  return (
    <div>
      <FacultyName name="Department ofPharmacy" />
      <div className="facultyContent">
        <LeftSideNavigationPharmacy />
        <div className="rightSideContent mt-4">
          <OnLeaveFaculty
            heading="On Leave Faculty Members"
            config={config}
            statusID={config.leave}
          />
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationPharmacy />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </div>
  );
};

export default OnLeaveFacultyMembersPharmacy;
