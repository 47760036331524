import React, { useState } from "react";

import LeftSideNavigationBusinessDepartment from "../../component/leftSideNavigationDepartmentofBusiness";
import FacultyName from "../../component/facultyName";
import { engFacultyTestImg } from "../../../../static";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import PublicationList from "../../component/PublicationList";

const PublicationsBusiness = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [publicationList, setPublicationList] = useState([]);

  const Publications = publicationList.map((mylist) => {
    return <PublicationList key={mylist.ClubsID} {...mylist}></PublicationList>;
  });
  return (
    <div>
      <FacultyName
        name="Department of Business Administration"
        facultyImg={engFacultyTestImg}
      />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationBusinessDepartment />
        <div className="rightSideContent mt-4">
          <p className="heading3 text-center">Publications</p>
          <div className="card">
            <div className="publicationMain">
              <section className="publicationinfo">{Publications}</section>
            </div>
          </div>
          {/* Mobile Responsive Start*/}
          <div
            id="mobileMenuButton"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <button>
              <VscAdd className="moreMenu" />
            </button>
          </div>
          {mobileMenuOpen && (
            <div id="mobileMenuContent">
              <button
                id="closeButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <VscChromeClose className="closeButtonIcon" />
              </button>
              <LeftSideNavigationBusinessDepartment />
            </div>
          )}
          {/* Mobile Responsive End*/}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PublicationsBusiness;
