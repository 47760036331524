import styled from "styled-components";

import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import { PAGE_SIZE } from "../util";

const StyledPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const P = styled.p`
  font-size: 1.4rem;
  margin-left: 0.8rem;

  & span {
    font-weight: 600;
  }
`;

const Buttons = styled.div`
  display: flex;
  gap: 0.6rem;
`;

const PaginationButton = styled.button`
  background-color: ${(props) =>
    props.active ? " var(--secondColor)" : "var(--primaryColor)"};
  color: ${(props) => (props.active ? " var(--secondColor)" : "inherit")};
  border: none;
  border-radius: 5px;
  padding: 8px 16px;
  font-weight: 500;
  font-size: 1.4rem;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  padding: 0.6rem 1.2rem;
  transition: all 0.3s;

  &:has(span:last-child) {
    padding-left: 0.4rem;
  }

  &:has(span:first-child) {
    padding-right: 0.4rem;
  }

  & svg {
    height: 1.8rem;
    width: 1.8rem;
  }

  &:hover:not(:disabled) {
    background-color: var(--secondColor);
    color: var(--primaryColor);
  }
`;

function Pagination({ count }) {
  const { search } = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(search);
  const param = searchParams.get("page");

  useEffect(() => {
    // issue side-effect
    window.scrollTo(0, 0);
  }, [param]);

  const currentPage = Number(searchParams.get("page")) || 1;

  const pageCount = Math.ceil(count / PAGE_SIZE);

  function handlePrev() {
    const prev = currentPage === 1 ? currentPage : currentPage - 1;

    history.push({
      search: `?page=${prev}`,
    });
  }
  function handleNext() {
    const next = currentPage === pageCount ? currentPage : currentPage + 1;

    history.push({
      search: `?page=${next}`,
    });
  }

  if (pageCount <= 1) return null;
  return (
    <StyledPagination>
      <P>
        Showing <span>{(currentPage - 1) * PAGE_SIZE + 1}</span> to{" "}
        <span>
          {currentPage === pageCount ? count : currentPage * PAGE_SIZE}
        </span>{" "}
        of <span>{count}</span> results
      </P>
      <Buttons>
        <PaginationButton onClick={handlePrev} disabled={currentPage === 1}>
          <span>Prev</span>
        </PaginationButton>
        <PaginationButton
          onClick={handleNext}
          disabled={currentPage === pageCount}
        >
          <span>Next</span>
        </PaginationButton>
      </Buttons>
    </StyledPagination>
  );
}

export default Pagination;
