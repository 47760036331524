import React from "react";

import { NavLink } from "react-router-dom";

import SpinnerMini from "../../../UI/SpinnerMini";
import { replace_string, server_django } from "../../../util";
import useFacultyMembers from "../../../features/useFacultyMembers";

function FacultyMembersByDepartment({
  config,
  statusID,
  heading,
  contractual,
  adjunct,
}) {
  const { facultyMembers: data, isLoading } = useFacultyMembers();

  if (isLoading) return <SpinnerMini />;

  const facultymembers = data?.filter(
    (item) =>
      item.department.some(
        (item) => item.depid === Number(config?.department)
      ) &&
      (item.job_type_id.id === Number(statusID) ||
        item.job_type_id.id === Number(contractual) ||
        (adjunct && item.job_type_id.id === Number(adjunct)))
  );

  if (!facultymembers?.length)
    return <p className="heading4">There is no {heading} </p>;

  return (
    <>
      <p className="heading4">{heading}</p>
      <div className="FacultyMembersByDepartment">
        {facultymembers?.length > 0 &&
          facultymembers?.map((facultymembersList) => {
            return (
              <div className="card" key={facultymembersList.id}>
                <div className="facultyImageWrapper">
                  <img
                    className="img-fuild"
                    src={facultymembersList.staff_picture.replace(
                      replace_string,
                      server_django
                    )}
                    alt={facultymembersList.staff_name}
                  ></img>
                </div>

                <NavLink
                  exact
                  to={"/facultyMemberDetails/" + facultymembersList.id}
                >
                  <div className="card-body">
                    <p className="heading6">
                      {`${facultymembersList.staff_name} ${
                        facultymembersList.job_type_id.id === Number(adjunct)
                          ? "(Adjunct)"
                          : ""
                      }`}{" "}
                    </p>
                    <p className="allText text-left">
                      {facultymembersList.role?.length > 0 &&
                        facultymembersList.role.map((item) => (
                          <span>{`${item.role}, `}</span>
                        ))}
                      {facultymembersList.designation.designation}
                    </p>
                  </div>
                </NavLink>
              </div>
            );
          })}
      </div>
    </>
  );
}

export default FacultyMembersByDepartment;
