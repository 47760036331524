import { useParams } from "react-router-dom";

import LoadingBox from "../../component/LoadingBox";
import { replace_string, server_django } from "../../util";

function TestOffice({ officeList, loading }) {
  const { officeId } = useParams();

  const office = officeList?.find((office) => office.id === Number(officeId));

  if (loading) return <LoadingBox />;

  return office ? (
    <div className="OtherPagecontent">
      <hr />
      <p className="heading2 primaryHeading text-center">{office.name}</p>
      <hr />
      <table className="table officeImg">
        <tbody>
          {office &&
            office.employees?.map((employeeList) => (
              <Employee employees={employeeList} />
            ))}
        </tbody>
      </table>
    </div>
  ) : null;
}

export default TestOffice;

function Employee({ employees }) {
  const actingRole = employees.role;

  return (
    <tr key={employees.id} className="contactInfo">
      <td className="info">
        <p className="heading5">{employees.staff_name}</p>
        <p className="allText">
          {actingRole.length
            ? actingRole.map((item) => <span>{`${item.role}, `}</span>)
            : ""}
          <span>{employees.designation.designation}</span>
          <span>{employees.section && ` (${employees.section.name})`}</span>
        </p>
        <p className="allText">{`${employees.phone_number} Ext:${employees.extension}`}</p>
        <p className="allText">{employees.official_email}</p>
      </td>
      <td className="contactImg">
        <img
          className="img-fluid"
          src={employees.staff_picture.replace(replace_string, server_django)}
          alt={employees.staff_name}
        ></img>{" "}
      </td>
    </tr>
  );
}
