import React from "react";
import Wrapper from "../../component/Wrapper";
import AdmissionSidebar from "../component/AdmissionSidebar";

const CreditTransferFacilities = () => {
  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">
            Credit Transfer Students
          </p>
          <hr />
          <p className="allText">
            Transfer students are expected to have at least a 2.50 CGPA on a
            4-point scale at the university level programs to be considered for
            admission to Eastern University. Catalogs and official transcripts
            from previously attended universities must be furnished with the
            application for admission. If an undergraduate student seeking
            transfer went through the Admission Test in his/her previous
            university, he/she will be waived from admission test requirement of
            the Eastern University. If needed, transcripts may have to be sent
            directly to the EU Registrar's Office from each institution
            attended.
          </p>
          <p className="allText">
            An undergraduate student may transfer (through grade transfer or
            exemption or a combination of both) a maximum of 35 percent of the
            total credit hours required for the degree in question at EU. In
            exceptional cases, the Equivalence Committee may, on recommendation
            of concerned Dean, consider extending the credits up to 40 percent.
          </p>
          <p className="allText">
            For transfer of grade of a course, a student must have at least B
            grade in the course. In some cases, courses with C+ grades as per
            Eastern University standard may be considered for transfer or
            exemption. For the purpose of transferring credits, the Equivalence
            Committee of the University determines equivalence of courses and
            grades for each transfer student. The curriculum, evaluation system
            and grading standard of the university from which the student is
            transferring must be comparable to that Eastern University which
            reserves the right to make final decisions on transfer. Incase of
            any course transfer against 2 or more courses, the average grade
            will be shown in the EU transcript. GED/ Foundation courses will
            only be exempted instead of transfer.
          </p>
        </div>
        <AdmissionSidebar />
      </div>
    </Wrapper>
  );
};

export default CreditTransferFacilities;
