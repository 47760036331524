import React, { useState } from 'react';
import LeftSideNavigationLaw from '../component/leftSideNavigationLaw';
import FacultyName from '../component/facultyName'

import { engFacultyTestImg } from '../../../static';
import { LLMCourseDistribution } from '../../../static';

import Footer from '../../../component/Footer';
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const ProgramLLM = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

    return (
        <div>
            <FacultyName name='Faculty of Law' facultyImg={engFacultyTestImg} />
            <div className='facultyContent' id='deanArts'>
                <LeftSideNavigationLaw />
                <div className='rightSideContent m-4'>
                    <p className='heading3'>LL.M </p>

                    <p className='heading5'>
                        <a href={LLMCourseDistribution}>Download Course Distribution</a>
                    </p>
                    <p className='heading5'>Duration of the Programs</p>
                    <p className='allText'>
                        LL.M.(1year) – LL.M. one year program consists of three semesters and each semester lasts for four months.
                    </p>
                    <p className='heading5'>Admission Requirements</p>
                    <p className='allText'>
                        For LL.M. (1 Year) : Any one who has obtained LL.B. (Hons.) degree from this University or from any other recognized University in Bangladesh, or obtained an equivalent degree from any other foreign University is eligible for admission.
                    </p>
                </div>
                {/* Mobile responsive start here */}
                <div id='mobileMenuButton' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                    <button >
                        <VscAdd className='moreMenu' />
                    </button>
                </div>
                {
                    (mobileMenuOpen) && (
                        <div id='mobileMenuContent'>
                            <button id='closeButton' onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                                <VscChromeClose className='closeButtonIcon' />
                            </button>
                            <LeftSideNavigationLaw />
                        </div>
                    )
                }
                {/* Mobile responsive end here */}
            </div>
            <Footer />
        </div>
    );
};

export default ProgramLLM;