import JournalLayout from "./JournalLayout";

function JournalAbout() {
  return (
    <JournalLayout>
      <p className="heading4">About</p>
      <p className="allText">
        The Eastern University Journal is a refereed journal published twice a
        year- in January and in July. The Journal is meant to promote research
        and scholarly pursuits among the faculty members of Eastern University
        and those of other universities of the country. Articles in the fields
        of humanities, business administration, social sciences, law,
        engineering, information technology and pharmacy are preferred. The
        articles must be in (British) English and should not normally exceed
        10,000 words (Excluding Reference). Papers are accepted (all) round the
        year, although periodic announcements are also made.
      </p>
      <p className="allText">
        A paper should be research based and demonstrate the author's own
        analysis and judgment. It must be an original work and not sent for
        publication elsewhere.Every article should begin with an abstract of
        about 250 words.
      </p>
      <p className="allText">
        Book reviews are also considered for publication and they should be
        within 1500 words (Excluding Reference).
      </p>
    </JournalLayout>
  );
}

export default JournalAbout;
