import { useQuery } from "@tanstack/react-query";
import { getUpcomingEvents } from "../services/apis";

function useUpcomingEvents() {
  const {
    data: events,
    isLoading,

    error,
  } = useQuery({
    queryKey: ["events"],
    queryFn: () => getUpcomingEvents(),
  });

  return { events, isLoading, error };
}

export default useUpcomingEvents;
