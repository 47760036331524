import { useQuery } from "@tanstack/react-query";
import { getCources } from "../services/apis";
export function useCourses(config = {}) {
  const { department, isGraduate } = config;
  const {
    data: courses,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["courses", department, isGraduate],
    queryFn: () => getCources({ department, isGraduate }),
  });
  return { courses, isLoading, error };
}
