import React, { Component } from 'react';
import InternationalStudentsContent from './Content/InternationalStudentsContent';
import Footer from '../../component/Footer';

class internationalStudents extends Component {
    render() {
        return (
            <div>
                <InternationalStudentsContent/>
                <Footer/>
            </div>
        );
    }
}

export default internationalStudents;