import React, { useState } from "react";
import LeftSideNavigationCSE from "../../component/leftSideNavigationCSE";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";

import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import { departmentCode } from "../../../../util";
import UpcomingEventsAll from "../../../../component/UpcomingEventsAll";

const UpcomingEventsCSE = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const config = { department: departmentCode.cse };

  return (
    <div>
      <FacultyName name="Department of Computer Science and Engineering" />
      <div className="facultyContent">
        <LeftSideNavigationCSE />
        <div className="rightSideContent mt-4">
          <p className="heading3">Upcoming Events</p>
          <UpcomingEventsAll config={config} />
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationCSE />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </div>
  );
};

export default UpcomingEventsCSE;
