import React, { Component } from 'react';
import GraduateAdmissionEligibilityContent from './Content/GraduateAdmissionEligibilityContent';
import Footer from '../../component/Footer';

class graduateAdmissionEligibility extends Component {
    render() {
        return (
            <div>
                <GraduateAdmissionEligibilityContent/>
                <Footer/>
            </div>
        );
    }
}

export default graduateAdmissionEligibility;