import React from 'react';
import DegreeRequirmentsContent from './content/DegreeRequirmentsContent';
import Footer from '../../component/Footer';

const degreeRequirments = () => {
    return (
        <div>
            <DegreeRequirmentsContent />
            <Footer />
        </div>
    );
};

export default degreeRequirments;