import React from "react";
import { NavLink } from "react-router-dom";

const IqacSideBar = () => {
  return (
    <div className="OtherpageSideBar">
      <ul className="list-group list-group-flush">
        <li className="nav-item dropdown list-group-item">
          <NavLink className="nav-link" exact to="/iqac">
            IQAC Home
          </NavLink>
        </li>
        <li className="nav-item dropdown list-group-item">
          <NavLink className="nav-link" exact to="/iqacMembers">
            Members
          </NavLink>
        </li>
        <li className="nav-item dropdown list-group-item">
          <NavLink className="nav-link" exact to="/iqacGoals">
            Goals
          </NavLink>
        </li>
        <li className="nav-item dropdown list-group-item">
          <NavLink className="nav-link" exact to="/iqacResources">
            Resources
          </NavLink>
        </li>
        <li className="nav-item dropdown list-group-item">
          <NavLink className="nav-link" exact to="/iqacEventSchedule">
            Event Schedule
          </NavLink>
        </li>

        <li className="nav-item dropdown list-group-item">
          <a
            className="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Downloads
          </a>
          <div className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li className="list-group-item">
              <NavLink eaxct to="/iqacPresentations" className="dropdown-item">
                Presentations
              </NavLink>
            </li>
            <li className="list-group-item">
              <NavLink eaxct to="/iqacDocuments" className="dropdown-item">
                Documents
              </NavLink>
            </li>
          </div>
        </li>
        <li className="nav-item dropdown list-group-item">
          <NavLink className="nav-link" exact to="/iqacPictureGallery">
            Picture Gallery
          </NavLink>
        </li>
        <li className="nav-item dropdown list-group-item">
          <NavLink className="nav-link" exact to="/iqacOffice">
            Office
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default IqacSideBar;
