import React, { Component } from 'react';
import AdmissionSidebar from '../../component/AdmissionSidebar';
import { CreditTransferFrom } from '../../../static';

class CreditTransferFormContent extends Component {
    render() {
        return (
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr />
                    <p className='heading2 primaryHeading text-center'>
                        Credit Transfer From
                    </p>
                    <hr />
                    <p className='allText'>
                        Credit Transfer Form : <a className='primaryHeading' href={CreditTransferFrom}>Click Here</a>
                    </p>
                </div>
                <AdmissionSidebar />
            </div>
        );
    }
}

export default CreditTransferFormContent;