import React from 'react';
import Footer from '../../component/Footer';
import RulesOfClassAttendanceContent from './content/RulesOfClassAttendanceContent'

const rulesOfClassAttendance = () => {
    return (
        <div>
            <RulesOfClassAttendanceContent />
            <Footer />
        </div>
    );
};

export default rulesOfClassAttendance;