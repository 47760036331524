import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import CSIOSidebar from '../../../component/CSIOSidebar';

const CareerServieContent = () => {
    return (
        <div className='content'>
            <div className='OtherPagecontent'>
                <hr />
                <p className='heading2 text-center'>
                    Career Service and International Office (CSIO)
                </p>
                <hr />
                <p className='heading4 text-center'>Change, discover the likelihood!</p>
                <p className='heading5'>
                    Welcome to the Career Services and International Office (CSIO) of Eastern University, Bangladesh.
                </p>
                <p className='allText'>
                    The Career Services and International Office (CSIO) at Eastern University (EU) offer resources and programs which not only focus on one time Internship/job links but also drive a student to a well structured career management system. CSIO guide students through their career path; they address students immediate needs, draw the timeline to focus on their goal, help them to design the career strategies, establish the bridge among students, alumni, potential employers/Universities for higher education.
                </p>
                <p className='allText'>
                    The staff members of CSIO offer students a one-stop-solution for now and equip them along the way with professional development skills that will last all through their life. Furthermore, CSIO promote superior industry resources along with EU faculty members in the Career Success Program (CSP). CSIO encourage every student to take advantage of the services right away; do not wait till you finalize your degree, contact career services from the beginning of your student life with EU.
                </p>
                <p className='heading5'>
                    Services offered by CSIO include:
                </p>
                <div className='CSIO'>
                    <div className='services'>
                        <p className='heading6'>
                            Career Services :
                        </p>
                        <ul>
                            <li> <p className='allText'>Career Success Program (CSP)</p> </li>
                            <li>
                                <p className='allText'>
                                    Career Counseling</p>
                                <ol type='a' className='allText ml-4 pl-4'>
                                    <li>Resume and Cover letter writing</li>
                                    <li>Interview technique / Mock Interview</li>
                                    <li>Job Search Method</li>
                                </ol>

                            </li>
                            <li className='allText'>Internship Recruitment</li>
                            <li><p className='allText'>
                                Job Listing and Resume Book
                                    <ol type='a' className='allText ml-4 pl-4'>
                                    <li>Job Posting</li>
                                    <li>Maintain Resume Database</li>
                                    <li>Career Newsletters</li>
                                </ol>
                            </p>
                            </li>
                            <li className='allText'>Networking</li>
                            <li className='allText'>Seminars and Workshops</li>
                            <li className='allText'>Guidance for Higher Education</li>
                            <li className='allText'>Career Fairs</li>
                            <li className='allText'>Experience Sharing / Mentoring</li>
                        </ul>

                    </div>
                    <div className='UsefulLinks'>
                        <div className='table-responsive'>
                            <table className='table table-responsive'>
                                <tr>
                                    <td>
                                        <p className='heading6'>Useful Links</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <ul>
                                            <li className='allText'>
                                                <NavLink exact to='/'> EU Alumni Chapters </NavLink>
                                            </li>
                                            <li className='allText'>
                                                <NavLink exact to='/'>EU Student Affairs</NavLink>
                                            </li>
                                            <li className='allText'>
                                                <NavLink exact to='/'>EU Newsletters</NavLink>
                                            </li>
                                            <li className='allText'>
                                                <NavLink exact to='/'>EU Career Profiling</NavLink>
                                            </li>
                                            <li className='allText'>
                                                <NavLink exact to='/CVCoverLetter'>CV/Cover Letter Writing</NavLink>
                                            </li>
                                            <li className='allText'>
                                                <NavLink exact to='/jobinterviewquestions'> Interview FAQs</NavLink>
                                            </li>
                                            <li className='allText'>
                                                <NavLink exact to='/'>Writing Centre </NavLink>
                                            </li>
                                            <li className='allText'>
                                                <NavLink exact to='/bdjobs'>BD Jobs</NavLink>
                                            </li>
                                            <li className='allText'>
                                                <NavLink exact to='/'>Independent jobs</NavLink>
                                            </li>
                                            <li className='allText'>
                                                <NavLink exact to='/loosemonkies'> Loose monkies</NavLink>
                                            </li>
                                            <li className='allText'>
                                                <NavLink exact to='/myperfectresume'>The Riley Guide</NavLink>
                                            </li>
                                            <li className='allText'>
                                                <NavLink exact to='/careeronestop'>Career onestop</NavLink>
                                            </li>
                                            <li className='allText'>
                                                <NavLink exact to='/mynextmove'>My Next Move</NavLink>
                                            </li>
                                            <li className='allText'>
                                                <NavLink exact to='/businessballs'>Businessballs</NavLink>
                                            </li>
                                            <li className='allText'>
                                                <NavLink exact to='/careeradvice'>Career Advice</NavLink>
                                            </li>
                                        </ul>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <p className='heading4'>
                    EU Alumni Affairs
                </p>
                <p className='allText'>
                    CSIO is to service all of its Alumni. The alumni network is well thought-out, diversified and unparalleled for its level of engagement and increasing global reach. The office interact with the graduates at a range of events including Alumni Chapter meetings, the Alumni Association gatherings, the Alumnus Dinner and all sort of social events organized by the Alumni Association, and via email, written and telephone communication. CSIO welcome you to become an Easterner, join the family which will guide you through the life.
                </p>
                <p className='heading5'>
                    Career Services Office facilitates the following services for EU Alumni Affairs:
                </p>
                <p className='allText'>
                    <ul>
                        <li className='allText'>
                            Offers Module 2 of CSP – Career Advancement Strategies
                        </li>
                        <li className='allText'>
                            Maintain EU Alumni Communication
                        </li>
                        <li className='allText'>
                            Arrange Homecomings
                        </li>
                        <li className='allText'>
                            Arrange meetings, forming committees
                        </li>
                        <li className='allText'>
                            Bridge the relationship among its members and EU authority
                        </li>
                        <li className='allText'>
                            Retain up-to-date database
                        </li>
                        <li className='allText'>
                            Publish newsletters
                        </li>
                        <li className='allText'>
                            Make graduate student aware
                        </li>
                        <li className='allText'>
                            Develop Career Development Strategies
                        </li>
                    </ul>
                </p>
                <p className='heading5'>
                    Information for Employers/Recruiters
                </p>
                <p className='allText'>
                    The Office of Career Services welcomes employers and/or Recruiters who would like to offer EU students and alumni for internships, fulltime positions, fellowships or other opportunities. There is no cost to an employer to circulate a position. Through the Career Services Office, there are many options available for organizations looking to recruit EU students, including on-campus recruitment, participation in career fairs, in-house Interviews and Career Services Office also can arrange video conference sessions on request.
                </p>
                <p className='allText'>
                    Employers are requested to contact EU Career Services Office directly for more information on options available to assist Employer in recruiting EU students and alumni.
                </p>
                <p className='heading5'>
                    International Office
                </p>
                <p className='allText'>
                    The EU International Office is a faculty independent general office for students. Whether you are an international student interested in applying to EU, a current student searching for information on study abroad opportunities or an overseas institution looking to work with EU, we are here for you. <NavLink exact to='/'>Find out more about the International Office.</NavLink>
                </p>
                <p className='allText'>
                    No matter what your affiliation is with EU, we look forward to see you!
                </p>
            </div>
            <CSIOSidebar />
        </div>
    );
};

export default CareerServieContent;