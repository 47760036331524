function ResearchDataset({ research }) {
  const { year, publications } = research;
  return (
    <div>
      <p className="heading4">Year : {year}</p>
      {publications.map((item, index) => (
        <div className="px-2 py-4">
          <p className="allText">
            <strong>
              {index + 1}.{" "}
              <em>
                {item.publicationLink === "" ? (
                  item.name
                ) : (
                  <a href={item.publicationLink}>{item.name}</a>
                )}
              </em>
            </strong>{" "}
            published at <em> {item.journal}</em> on{" "}
            <strong>{item.publicationYear}</strong> Based on {item.topices}
          </p>
          <p className="text-muted">Author: {item.facultyMember.staff_name}</p>
        </div>
      ))}
    </div>
  );
}

export default ResearchDataset;
