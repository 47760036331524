import React from "react";

import Wrapper from "../../component/Wrapper";
import OtherpageSideBar from "../component/OtherpageSideBar";
import useEuFoundation from "../../features/useEuFoundation";
import Spinner from "../../UI/Spinner";
import styled from "styled-components";

const Name = styled.p`
  font-size: 1.6rem;
  font-weight: 600;
`;
const Repesent = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
`;

function MembersofEUTrust() {
  const { euFoundationMembers, isLoading } = useEuFoundation();
  if (isLoading) return <Spinner />;
  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">
            Members of EU Foundation
          </p>
          <hr />
          <div className="memberOfEU">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>#</th>
                    <th className="primaryHeading">Name</th>
                    <th className="primaryHeading">Designation</th>
                  </tr>
                </thead>
                <tbody>
                  {euFoundationMembers.map((member, index) => {
                    return (
                      <tr>
                        <th>{index + 1}</th>
                        <td>
                          <Name>
                            {member.representative
                              ? member.representative
                              : member.name}
                          </Name>
                          {member.representative && (
                            <Repesent>Representative of {member.name}</Repesent>
                          )}
                        </td>

                        <td>{member.designation.role}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <OtherpageSideBar />
      </div>
    </Wrapper>
  );
}

export default MembersofEUTrust;
