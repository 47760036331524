import React from 'react'
import { NavLink } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react'

const leftSideNavigation = () => {
    return (
        <div id='facultyLeftSideMenu' className='sidemenu'>
            <Menu vertical className='sidemenuBar mt-4'>
                <Dropdown text='Home' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown.Item><NavLink exact to='/facultyOfBusinessAdministration'>About</NavLink></Dropdown.Item>
                        <Dropdown.Item><NavLink exact to='/deanMessageBusiness'>Dean's Message</NavLink></Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/chairpersonMessageBusiness'>Chairperson's Message</NavLink>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown text='Departments' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <NavLink exact to='/departmentOfBusinessAdministration'>Department of Business Administration</NavLink>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown text='Programs' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown text='Undergraduate' className='link item'>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <NavLink exact to='/programBBA'>BBA</NavLink>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown text='Graduate' className='link item'>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <NavLink exact to='/programMBARegular'>MBA - Regular </NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink exact to='/programMBAExecutive'>MBA - Executive</NavLink>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown text='Admission' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown text='Admission Information' pointing='bottom' className='link item'>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <NavLink exact to='/admissionBBA'>Admission in BBA</NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item>
                                    <NavLink exact to='/admissionMBA'>Admission in MBA</NavLink>
                                </Dropdown.Item>
                            </Dropdown.Menu>

                        </Dropdown>

                        <Dropdown.Item>
                            <NavLink exact to='/tutionFeesAndFinalcialAidsBusiness'>Tution Fees and Finalcial Aids</NavLink>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
                {/* <Dropdown text='Research' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <NavLink exact to='/researchHighlightsBusiness'>Research Highlights</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/researchEventsBusiness'>Events</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/researchInBusiness'>Research in Business</NavLink>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
                <Dropdown text='News and Events' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <NavLink exact to='/newsBusinessFaculty'>News</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/researchEventsBusiness'>Events</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/noticeBusinessFaculty'>Notice</NavLink>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Menu.Item className='sideBarSingleItemLast'>
                    <NavLink exact to='/contactUsBusinessFaculty'>Contact Us</NavLink>
                </Menu.Item>
            </Menu >
        </div>
    );
};

export default leftSideNavigation;