import React from 'react';
import IqacHome from './content/iqacHomeContent'
import Footer from '../../../component/Footer';

const home = () => {
    return (
        <div>
            <IqacHome />
            <Footer />
        </div>
    );
};

export default home;