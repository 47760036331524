import Wrapper from "../../component/Wrapper";
import JournalSideBar from "../component/JournalSideBar";

function JournalLayout({ children }) {
  return (
    <Wrapper>
      <div className="content " id="journal">
        <div className="OtherPagecontent">
          <h1 className="heading3 text-center ">Eastern University Journal</h1>
          {children}
        </div>
        <JournalSideBar />
      </div>
    </Wrapper>
  );
}

export default JournalLayout;
