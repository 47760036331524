import React, { Component } from 'react';
import AdmissionSidebar from '../../component/AdmissionSidebar';

class InternationalStudentsContent extends Component {
    render() {
        return (
            <div className='content'>
                <div className='OtherPagecontent'>
                <hr/>
                <p class="heading2 primaryHeading">International Students</p><hr/>
                <p class="allText">
                    Individuals who are not citizens or permanent residents of Bangladesh are 
                    defined as international applicants. International applicants seeking 
                    admission into EU must meet the same academic standards as those required 
                    for Bangladeshi students. There are wide variations, however, among 
                    educational systems throughout the world that make an exact comparison of 
                    educational standards difficult. International applicants who cannot sit 
                    for admission test are selected on the basis of their prior academic results, 
                    English proficiency, probability of success in the chosen curriculum as 
                    evidenced by prior work in the academic area involved, and certification of 
                    adequate financial resources.
                </p>
                </div>
                <AdmissionSidebar/>
            </div>
        );
    }
}

export default InternationalStudentsContent;