import React from "react";

import { NavLink } from "react-router-dom";
import moment from "moment/moment";
import useUpcomingEvents from "../../../features/useUpcomingEvents";
import LoadingBox from "../../../component/LoadingBox";
import MessageBox from "../../../component/MessageBox";
const UpcomingEventsByDepartment = ({ config }) => {
  const { events, isLoading, error } = useUpcomingEvents();

  if (isLoading) return <LoadingBox />;
  if (error) return <MessageBox>{error.message}</MessageBox>;

  let filterData = [];
  if (config.home) {
    filterData = events.filter((item) => item.is_all === true);
  } else if (config.faculty) {
    filterData = events.filter((item) =>
      item.faculty.some((i) => i.collegecode === config.faculty)
    );
  } else if (config.department) {
    filterData = events.filter((item) =>
      item.department.some((i) => i.depid === config.department)
    );
  }

  const ErrorMessage = <p className="heading4">There is no Upcoming Events</p>;

  const EventList = filterData.map((mylist) => {
    return (
      <div className="card">
        <div className="card-body" key={mylist.id}>
          <div className="events-date">
            <p className="date">{moment(mylist.ToDate).day()}</p>
            <p className="month">{moment(mylist.ToDate).format("MMM")}</p>
          </div>
          <div className="events-description text-left">
            <p className="card-title heading5">
              <NavLink exact to={"/upcomingEvents/" + mylist.EventsID}>
                {mylist.Headline}
              </NavLink>
            </p>
            <p className="allText">
              {mylist.Details.substring(0, 60)}
              <NavLink exact to={"/upcomingEvents/" + mylist.EventsID}>
                {" "}
                More Info ...
              </NavLink>{" "}
            </p>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div id="upcomingEventsByDepartment">
      {EventList.length <= 0 ? ErrorMessage : EventList}
    </div>
  );
};

export default UpcomingEventsByDepartment;
