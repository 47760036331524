import React from 'react';

const facultyName = (props) => {
    return (
        <div className='imageWithName'>
            {/* <img src={props.facultyImg}></img> */}
            <div className="centered">{props.name}</div>
        </div>
    );
};

export default facultyName;