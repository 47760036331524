import React, { useState } from 'react';
import LeftSideNavigationEnglish from '../../component/leftSideNavigationEnglish';
import FacultyName from '../../component/facultyName';
import Footer from '../../../../component/Footer';
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import Clubs from '../../../campus/Clubs/Clubs'


const ClubInformationEnglish = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <div>
            <FacultyName name='Department of English' />
            <div className='facultyContent' id='deanArts'>
                <LeftSideNavigationEnglish />
                <div className='rightSideContent'>
                    <div className='departmentClubs'>
                        <Clubs />
                    </div>
                    {/* Mobile Responsive Start*/}
                    <div
                        id="mobileMenuButton"
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                    >
                        <button>
                            <VscAdd className="moreMenu" />
                        </button>
                    </div>
                    {mobileMenuOpen && (
                        <div id="mobileMenuContent">
                            <button
                                id="closeButton"
                                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                            >
                                <VscChromeClose className="closeButtonIcon" />
                            </button>
                            <LeftSideNavigationEnglish />
                        </div>
                    )}
                    {/* Mobile Responsive End*/}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ClubInformationEnglish;