import React from "react";

import Wrapper from "../../component/Wrapper";
import OtherpageSideBar from "../component/OtherpageSideBar";
import Spinner from "../../UI/Spinner";
import { useFetch } from "../../hooks/useFetch";
import { replace_string, server_django } from "../../util";

const Administration = () => {
  const { data, loading } = useFetch("employee/sectionHeads/");

  if (loading) return <Spinner />;

  if (!data?.length) return null;

  const newData = data.map((item) => ({
    ...item,
    staff_picture: item.staff_picture.replace(replace_string, server_django),
  }));
  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">Administration</p>
          <hr />
          <div className="deansAndChairperson">
            <table className="table officeImg">
              <tbody>
                {newData?.length > 0 &&
                  newData.map((item) => {
                    return (
                      <tr>
                        <th scope="row"></th>
                        <td>
                          <p className="heading5">{item.staff_name}</p>
                          <p className="allText">
                            {item.designation.designation}
                          </p>
                          <p className="allText">
                            {item.office.map((item) => item.name)}
                          </p>
                          <p className="allText">{item.phone_number}</p>
                          <p className="allText">{item.official_email}</p>
                        </td>
                        <td>
                          <img
                            className="img-fluid"
                            src={item.staff_picture}
                            alt={item.staff_name}
                          />
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <OtherpageSideBar />
      </div>
    </Wrapper>
  );
};

export default Administration;
