import React from 'react';
import OtherpageSideBar from '../../component/OtherpageSideBar'


const HistoryContent = () => {
    return (
        <div className='content'>
            <div className='OtherPagecontent'>
                <hr></hr>
                <p className='heading2 primaryHeading text-center'>
                    EU Profile
                </p>
                <hr></hr>
                <div className='history'>
                    <p className='heading4 primaryHeading'>History, Vision and Mission</p>

                    <p className='allText'>
                        Committed to an ideal of “quality higher education at an affordable cost”,
                        a group of educational philanthropists comprising academics, industrialists,
                        engineers and retired civil servants established Eastern University in 2003.
                        The vision of Eastern University is to be a globally recognized university in its chosen fields
                        of
                        higher education and research in terms of quality.
                        The mission of the University is to equip the students with knowledge and skills essential
                        for leadership in private and public sector in the increasingly competitive and globalized
                        environment.
                        Its goal is to be one of the top five private universities by 2020
                    </p>
                    <p className='allText'>
                        Eastern University is widely known for its quality education, superior faculty composition,
                        excellent academic environment,
                        sincere care for students, extensive co and extra- curricular activities,
                        successful internship and job placement, modern digital library,
                        good governance and administration and convenient location of the campus.
                    </p>
                    <p className='allText'>
                        More than 9,140 students have graduated from the university by 2018.
                    </p>


                    <p className='heading4 primaryHeading'>Governance and Administration</p>

                    <p className='allText'>His Excellency the President of Bangladesh is the Chancellor of Eastern
                    University as per the Private University Act of 2010. The governance of the University is vested
                    with a 21 member Board of Trustees (BOT)comprising 20 members drawn from the Eastern University
                    Foundation and the Vice Chancellor as an ex-officio member. The Board is chaired by the Chairman of
                    the Board elected by the Foundation. The board is assisted by the Syndicate, Academic Council,
                    Finance Committee, Faculty Selection Committee, Staff Selection Committee and Institutional Quality
                    Assurance Committee. The administration consists of the Vice Chancellor, Pro-Vice Chancellor,
                    Treasurer, Registrar and Controller of Examinations.
</p>

                    <p className='heading4 primaryHeading'>Curricula, Students and Faculty</p>

                    <p className='allText'>The meticulously designed curricula of the academic programs combine and ye
                    tbalance the broad- based and the in-depth learning, and emphasize “knowing, doing and being” of the
                    students. The Curricula are of international standard and are upgraded and updated regularly to make
                    it relevant to the advancement of the discipline and the future job and professional requirements of
                    the students. The teaching-learning methods used are participative, interactive, experiential,
                    learner-centered and hands-on practice depending upon the nature of the course being taught.
</p>
                    <p className='allText'>
                        The students are carefully selected for admission on the basis of their excellent academic record,
                        high aptitude and strong motivation to learn. They are transformed into knowledgeable, skilled and
                        globally oriented human resources by the caring and committed faculty members of the University.
                        They are groomed to be leaders, value-driven and socially responsive citizens through a variety of
                        co-curricular and extra- curricular programs.The university has at present international students
                        from SAARC countries and Africa. The university has plans to attract students from other countries
                        also.
</p>
                    <p className='allText'>
                        Eastern University hires top-of-the className, talented graduates of highly reputed universities from
                        home and abroad as junior faculty, experienced teachers and researchers as senior faculty andwell
                        known academics aspart time faculty. In addition, the University appoints accomplished professionals
                        and practitioners as adjunct faculty and invites university teachers from abroad as Visiting
                        Faculty. The faculty members are chosen on the basis of their scholarly achievement, knack for
                        research and commitment in teaching. The Faculty Development Program of the University trains junior
                        faculty in pedagogy, research methods and in specific disciplines they teach.
</p>
                    <p className='heading4 primaryHeading'>Faculties and Programs</p>
                    <p className='allText'>
                        The University has at present four faculties: Faculty of Arts, Faculty of Business Administration,
                        Faculty of Engineering and Technology and Faculty of Law. Faculty of Science and Faculty of Social
                        Science will be added soon.
</p>

                    <p className='heading5 primaryHeading'>Faculty of Arts</p>

                    <p className='allText'>The Faculty of Arts offers a four year B.A. (Hons.) in English and a one-year
                    M.A. in ELL (English Language and Literature). The Programs follow a needs-based multi syllabus
                    approach combining well-balanced language and literature courses in order to cater to the job needs
                    of its graduates. A well- earned degree in English opens innumerable job opportunities at home and
                    abroad.
</p>

                    <p className='heading5 primaryHeading'>Faculty of Business Administration</p>

                    <p className='allText'>The Faculty of Business Administration (FBA) delivers innovative and world className
                    leadership education in business. Its highly qualified faculty members with extensive research
                    experience and international exposure provide tailor-made support to enable learning and development
                    of the student groups to become professional managers as well as good human beings.
</p>
                    <p className='allText'>
                        FBA offers a four-years Bachelor of Business Administration (BBA), a two-years Master of Business
                        Administration (MBA), and a one year Executive Master of Business Administration (EMBA). Graduates
                        of FBA are readily employed and are occupying leading positions in major domestic and international
                        companies providing excellent leadership as well as value addition to the society.
</p>

                    <p className='heading5 primaryHeading'>Faculty of Engineering & Technology</p>

                    <p className='allText'>
                        The Faculty of Engineering & Technology (E&T) offers four years B.Sc. Programs in Electrical &
                        Electronic Engineering (EEE) and Computer Science & Engineering (CSE). It also offers a one year
                        Master of Computer Science program.
</p>
                    <p className='allText'>
                        The Faculty attracts talented and outstanding faculty members with foreign degrees to nurture young
                        students in various fields of engineering and technology. Its Laboratories,furnished with
                        state-of-the-art equipment and software programs, provide an excellent learning environment The
                        Faculty is committed to ensuring quality education with a team of talented, dedicated and cordial
                        faculty members as mentors of the students.
</p>

                    <p className='heading5 primaryHeading'>Faculty of Law</p>
                    <p className='allText'>
                        The Faculty of Law of Eastern University is one of the premier law schools of the country. It has
                        always attracted the best legal minds academically and ideologically, thereby creating an
                        inspirational centre of exchanging ideas. It is a public-spirited faculty that makes distinctive
                        contributions to society in learning, teaching and engagement. EU’s law curricula reflect the
                        changing requirement of the legal profession. Students are given extensive training in various forms
                        of Legal Drafting and Trial Advocacy in the Moot Court Room. The Faculty offers a four-years LL.B
                        (Honours) and a one year LL.M Program.
</p>

                    <p className='heading5 primaryHeading'>International Collaboration</p>
                    <p className='allText'>
                        Eastern University has academic partnership with over 20 universities in UK, USA, Australia, New
                        Zealand, Finland, Malaysia and few other countries. Eastern University students can pursue higher
                        studies by credit transfer to these and other universities abroad. The Faculty of Business
                        Administration (FBA) has credit transfer arrangements for undergraduate program with several
                        universities in UK, USA and Malaysia for 2 years study at EU and 2 years study in the selected
                        foreign university earning degree of the foreign university and even double degree. Credit transfer
                        facility also exists for Masters program.
</p>

                    <p className='heading5 primaryHeading'>Opportunity for Study Abroad</p>
                    <p className='allText'>
                        Because of international standard curricula, the courses, credits and degree of Eastern University
                        are readily accepted by universities in USA, Europe, Australia and other countries and many EU
                        students are studying abroad in Bachelors, Masters and Ph.D Programs with credit transfer and degree
                        acceptance.
</p>
                    <p className='allText'>
                        Under a collaboration agreement, two students of the FBA are pursuing study in Master in Finance
                        with scholarship in Yunan University of Finance and Economics in Kunming, China. From now on, two
                        students will attend the program every year. Similar opportunities are being explored for students
                        of other faculties also.
</p>
                    <p className='allText'>
                        A faculty member of EU is attending a one year Chinese language course and another faculty member
                        will attend a Master in International Trade course on scholarship in Yunan Normal University in
                        Kunming. Under student exchange program, a group of 10 EU students recently visited Kunming on study
                        tour on invitation from Yunan Open University. A group of students for study tour from Kunming are
                        expected to visit EU soon. Similar study tours will take place on a regular basis. Under a
                        collaboration agreement, 20 undergraduate students of Eastern University will complete their final
                        two years of study with scholarships and earn the degree from Hangzhou Normal University of China.
</p>

                    <p className='heading5 primaryHeading'>Centres and Cells</p>
                    <p className='allText'>
                        The University has four specialized centres and two cells: Centre for Modern Languages, Centre for
                        Research and Development, Centre for Innovation and Incubation, Centre for Faculty and Staff
                        Development, General Education Cell and Institutional Quality Assurance Cell (IQAC).
</p>
                    <p className='allText'>
                        The Centre for Modern Languages at present offers certificate and short courses on Chinese. A
                        Chinese Language teacher on deputation under a collaboration agreement with Yunnan Normal University
                        in Kunming, China, is teaching the courses at EU. The launching of a Certificate course on Japanese
    is in process. Few other language courses will be added soon.</p>
                    <p className='allText'>
                        The Centre for Research and Development (CRD) allocates research funds, procuresand monitors
                        research projects and consultancy projects, and organizes research seminars and training on research
                        methodology. It publishes a bi-annual research journal, namely, The Eastern University Journal.
</p>
                    <p className='allText'>
                        The Centre for Innovation and Incubation encourages students and faculty to come out with innovative
                        ideas of products, designs or process, experiment and test them in the centre and continue
                        development of the potential ones to the final stage and commercialization, if feasible.
</p>
                    <p className='allText'>
                        The Centre for Faculty and Staff Development offers courses, workshops, seminars and conference for
                        continuous skill development of faculty members in teaching-learning and of officers and executives
                        in administrative tasks. The Centre also conducts research for innovation and improvement in
                        teaching-learning and administrative process.
</p>
                    <p className='allText'>
                        The General Education Cell aims at providing liberal arts education to all undergraduate students of
                        the university. It offers, organizes and coordinates general education courses in the area of
                        language, arts, humanities, science and social science.
</p>
                    <p className='allText'>
                        The Institutional Quality Assurance Cell (IQAC) sets quality standards, monitors and controls the
                        quality of teaching–learning and all aspects of academic matters and support services. The Cell is
                        under the direct supervision of the Vice Chancellor and reports to the Academic Council. The IQAC is
                        managed by its Director with assistance of the Associate Director and administrative and IT
                        professionals. Recently, the Cell has received a grant of Tk. 75.50 lakh from World Bank supported
                        Higher Education Quality Enhancement Project (HEQEP) of Bangladesh University Grants Commission.
</p>
                    <p className='heading4 primaryHeading'>Campus, Facilities and Services</p>

                    <p className='heading5 primaryHeading'>Campus</p>
                    <p className='allText'>
                        The present campus consisting of four large buildings is located in Dhanmondi which is easily
                        accessible from all parts and outskirts of Dhaka city. Just at a distance of three minutes walk from
                        the campus is the scenic lake and refreshing park of Dhanmondi where students can stroll and relax.
                        The spacious className rooms, well equipped labs and several seminar and conference rooms provide
                        necessary support to the leaching-learning of the students. A huge campus on sprawling lush green is
                        under construction in the University Zone in Ashuila – just 5minutes drive from Tongi Bridge. It has
                        been lavishly designed to provide enough facilities for academic programs, library, administration,
                        auditorium, common services, students and faculty residence, indoor and outdoor games and sports
    etc.</p>
                    <p className='heading5 primaryHeading'>Hostel for Students</p>
                    <p className='allText'>
                        The University has arrangement of a female hostel located nearby the campus. Similar hostel
                        accommodation is available for male students also. Full fledged and comfortable hostel facilities
    will be available for all students in the University campus under construction in Ashlia.</p>
                    <p className='heading5 primaryHeading'>Library</p>
                    <p className='allText'>
                        The digital library of the University has more than 15000 books, over 100 national and international
                        research journals, 700 audio visual materials, 25000 online journals and a large number of eBooks
                        through 41databases.The library services can be accessed any time of the day and night over internet
                        from anywhere.
</p>
                    <p className='heading5 primaryHeading'>Admission Office</p>
                    <p className='allText'>
                        The University is committed to preparing its students as professionals by exploring and promoting
                        their creativity, infusing knowledge, developing their skills and forming proactive attitude.
                        Admission office plays an important role in fulfilling this goal by recruiting and selecting the
                        right kind of students. The trained counselors of the office provide guidance to the prospective
                        students in selecting the right academic program, financial advice and accommodation and living
                        tips. For the convenience of the prospective students, the Admission Office remains open on weekends
                        and holidays.
</p>

                    <p className='heading5 primaryHeading'>Clubs and Forums</p>
                    <p className='allText'>
                        The University has 16 different clubs and forums for students to pursue their academic, social,
                        cultural, athletic & other interests.The Office of Students Affairs (OSA) organizes Co-curricular &
                        Extra-curricular activities of the students through clubs and forums. By participating in these
                        clubs and forums, students can enhance their interpersonal, social and leadership skills. All EU
                        students are encouraged to enroll in at least one club/ forum of her/his choice.‘Seeds of
                        Leadership’ , a ten-day intensive and exclusive workshop for undergraduate students of Eastern
                        University, is designed to be a transformative personal experience to generate innovative thinking
                        and develop leadership skills. Recently, a Cultural Troupe of EU visited and performed in India and
    won laurels.</p>

                    <p className='heading5 primaryHeading'>Internship and Job placement</p>
                    <p className='allText'>
                        Eastern University emphasizes experiential learning and therefore encourages students to do
                        internship, industry attachment or Under-study/probationer and the Career Service and International
                        Office (CSIO) of the University arranges these for the students. Through extensive counseling, the
                        Office guides students that addresses students’ immediate career needs, draws the timeline to focus
                        on their goal, helps them to design the career strategies, establishes the bridge among students,
                        alumni, potential employers/ universities for jobs and higher education. It providesentry level
                        career guidelines and aggressively facilitate their employment.There is hardly any unemployed EU
                        graduates. Module 1 of itsCareer Success Program(CSP) isa short course designed to provide
                        individuals with valuable tips on career awareness, self -assessment, career decision making skills,
                        CV writing, interview facing, office manners and etiquettes etc. The Office offers Module 2 of the
                        CSP for the graduates and alumni for professional development skills and career advancement
                        strategies that will last all through their life.
</p>
                    <p className='allText'>
                        The University has academic collaboration with over 20 renowned universities and institutions in
                        USA, UK, Finland, Australia, New Zealand, China, Malaysia and Thailand for credit transfer of
                        students with financial support, student exchange, faculty exchange, research collaboration and
                        assistance in setting up new programs.The CSIO provides information and counseling for students who
                        are interested in pursuing higher studies in these or other universities abroad, facilitate their
                        placement in the universities, monitors their progress and problems and help them with suggestions.
                        The Office organizes study tour of the EU and foreign students and facilitates visit of the EU and
    foreign officials and recruitment of visiting faculty.</p>

                    <p className='heading5 primaryHeading'>Medical Service and Psycho-social Counseling</p>
                    <p className='allText'>
                        The Medical Service offers general and emergency medical care to students, faculty and staff free of
                        cost. Under an agreement with Delta Medical College and Hospital, EU members get 30% discount on
                        pathological tests and related services. The Chief Medical Officer serves as a consultant, monitors
                        hygiene aspects in the campus, provides health education of the EU members and oversees maintenance
    of medical records.</p>
                    <p className='allText'>
                        The Psycho-social Counselor offers counseling services to students to cope with stress and anxiety,
                        right decision-making, managing relationships and social problems, developing assertiveness, coping
                        with sexual harassment etc. She monitors mental health of students and staff and educates them for
                        maintaining good mental health. The Committee on Prevention of sexual harassment investigates
                        thoroughly into the complaintson sexual harassments of any type and recommends punitive measures to
    be taken against the offender(s) to the Disciplinary Committee.</p>

                    <p className='heading5 primaryHeading'>Fees and Financial Assistance</p>
                    <p className='allText'>
                        In pursuance of the commitment of the Founders of the University to provide “higher education at
                        affordable cost,” and to open opportunities of education to meritorious students of limited income,
                        the tuition and other fees have been set at a reasonable level. In addition, several types of
                        financial assistance, scholarships and a Student Loan scheme have been provided for. Details of the
                        fees and financial assistance are available on the website of the university (www.easternuni.edu.bd)
</p>

                </div>



            </div>
            <OtherpageSideBar />
        </div>
    );
};

export default HistoryContent;