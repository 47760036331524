import React from 'react'
import { NavLink } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react'

const leftSideNavigation = () => {
    return (
        <div id='facultyLeftSideMenu' className='sidemenu'>
            <Menu vertical className='sidemenuBar mt-4'>
                <Dropdown text='Home' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown.Item><NavLink exact to='/facultyOfLaw'>About</NavLink></Dropdown.Item>
                        <Dropdown.Item><NavLink exact to='/deanMessageLaw'>Dean's Message</NavLink></Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/chairpersonMessageLaw'>Chairperson's Message</NavLink>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown text='Departments' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <NavLink exact to='/departmentOfLaw'>Department of Law</NavLink>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown text='Programs' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown text='Undergraduate' className='link item'>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <NavLink exact to='/programLLB'>LL.B (Hons.)</NavLink>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown text='Graduate' className='link item'>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <NavLink exact to='/programLLM'>LL.M </NavLink>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown text='Admission' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown text='Admission Information' pointing='bottom' className='link item'>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <NavLink exact to='/admissionLawFaculty'>Admission in Law</NavLink>
                                </Dropdown.Item>
                            </Dropdown.Menu>

                        </Dropdown>

                        <Dropdown.Item>
                            <NavLink exact to='/tutionFeesAndFinancialAidsLaw'>Tution Fees and Finalcial Aids</NavLink>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown>
                {/* <Dropdown text='Research' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <NavLink exact to='/researchHighlightsLaw'>Research Highlights</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/researchEventsLaw'>Events</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/researchInLaw'>Research in Law</NavLink>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
                <Dropdown text='News and Events' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <NavLink exact to='/newsLaw' >News</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/researchEventsLaw'>Events</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/noticeLaw'>Notice</NavLink>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>

                <Menu.Item className='sideBarSingleItemLast'>
                    <NavLink exact to='contactUsLaw'>Contact Us</NavLink>
                </Menu.Item>
            </Menu >
        </div>
    );
};

export default leftSideNavigation;