import { useQuery } from "@tanstack/react-query";
import { getFacultyMembers } from "../services/apis";

function useFacultyMembers() {
  const {
    isLoading,
    data: facultyMembers,
    error,
  } = useQuery({
    queryKey: ["facultyMembers"],
    queryFn: () => getFacultyMembers(),
  });

  return { isLoading, facultyMembers, error };
}

export default useFacultyMembers;
