import React from "react";

import Wrapper from "../../component/Wrapper";
import OtherpageSideBar from "../component/OtherpageSideBar";
import useFacultyMembers from "../../features/useFacultyMembers";
import Spinner from "../../UI/Spinner";
import { replace_string, server_django } from "../../util";

function DeansChairperson() {
  const { facultyMembers, isLoading } = useFacultyMembers();
  if (isLoading) return <Spinner />;

  const deansAndChairs = facultyMembers.filter((facultymember) =>
    facultymember.role?.some(
      (item) => item.role.includes("Dean") || item.role.includes("Chairperson")
    )
  );

  const sortedData = deansAndChairs.sort(
    (a, b) => a.role[0].priority - b.role[0].priority
  );

  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">
            Deans & Chairpersons
          </p>
          <hr />
          <div className="deansAndChairperson">
            {sortedData.map((item) => (
              <div className="card ">
                <img
                  src={item.staff_picture.replace(
                    replace_string,
                    server_django
                  )}
                  className="img-fluid"
                  alt={item.staff_name}
                />
                <div className="card-body">
                  <p className="heading6 primaryHeading">{item.staff_name}</p>
                  <p className="allText text-left">
                    {item.role.map((rl) => rl.role)},{" "}
                    {item.role.map((rl) => {
                      if (rl.role.includes("Dean")) {
                        return `Faculty of ${item.department[0].collegecode.collegename}`;
                      } else {
                        return item.department[0].depname;
                      }
                    })}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <OtherpageSideBar />
      </div>
    </Wrapper>
  );
}

export default DeansChairperson;
