import React from "react";
import ContactSideBar from "../component/ContactSideBar";

import Footer from "../../component/Footer";
import TestOffice from "./TestOffice";
import { useFetch } from "../../hooks/useFetch";
import LoadingBox from "../../component/LoadingBox";

const ContactOffice = () => {
  const { data: officeList, loading } = useFetch("employee/office");
  if (loading) return <LoadingBox />;

  return (
    officeList.length && (
      <div>
        <div className="content">
          <TestOffice officeList={officeList} loading={loading} />
          <ContactSideBar />
        </div>
        <Footer />
      </div>
    )
  );
};

export default ContactOffice;
