import React, { useState } from "react";
import LeftSideNavigationEEE from "../../component/leftSideNavigationEEE";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const MissionandVission = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName name="Department of Electrical and Electronic Engineering" />
      <div className="facultyContent">
        <LeftSideNavigationEEE />
        <div className="rightSideContent mt-4">
          <p className="heading3 text-center">
            Departmental Vision and Mission
          </p>
          <div>
            <p className="heading5">Vision of Dept. of EEE</p>
            <p className="allText">
              To be one of the leading departments in the field of Electrical
              and Electronic Engineering by providing quality education and
              advanced research that enable innovation and excellence for
              addressing national and global challenges.
            </p>
            <p className="heading5">Mission of Dept. of EEE</p>
            <p className="allText">
              <strong>Statement-1:</strong> Provide high-quality education and
              on-hand practical training to the graduates in a well-equipped
              environment that ensures the development of ultimate
              professionalism with the norm of engineering practice and
              professional ethics.{" "}
            </p>
            <p className="allText">
              <strong>Statement-2:</strong> Enhance the ability of working with
              complex engineering problems and sustainable solutions by
              providing special and sophisticate knowledge through research,
              innovation and use of modern tools that will help graduates to
              engage independently in broader context of technological change.
            </p>
            <p className="allText">
              <strong>Statement-3:</strong> Build the skills of effective
              communication, leadership and managerial capability by exercising
              team work on different level of engineering education,
              co-curricular, extra-curricular engineering activities within the
              department and by ensuring collaboration with industrial
              environment.
            </p>
          </div>
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationEEE />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </div>
  );
};

export default MissionandVission;
