import React, { Component } from "react";
import OtherpageSidebar from "../../component/OtherpageSideBar";
import { Treasurer } from "../../../static";

class TreasurerContent extends Component {
  render() {
    return (
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">Treasurer</p>
          <hr />
          <div className="history">
            <div className="item">
              <div className="card text-center">
                <img
                  className="mx-auto img-fluid"
                  src={Treasurer}
                  alt="Treasurer sir  comes here"
                />
              </div>
            </div>
            <p class="heading6 text-center">
              Professor Md. Shamsul Huda
              <br />
              Treasurer
            </p>
          </div>
        </div>
        <OtherpageSidebar />
      </div>
    );
  }
}

export default TreasurerContent;
