import React, { useState } from "react";
import LeftSideNavigationBusinessDepartment from "../../component/leftSideNavigationDepartmentofBusiness";
import FacultyName from "../../component/facultyName";
import { engFacultyTestImg, MBACourseDistridution } from "../../../../static";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const ProgramsExecutiveMBADepartment = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName
        name="Department of Business Administration"
        facultyImg={engFacultyTestImg}
      />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationBusinessDepartment />
        <div className="rightSideContent m-4">
          <p className="heading3">
            MBA (Masters of Business Administration) - Executive
          </p>


          <p className="allText">
            Recognizing the need to prepare highly skilled and qualified
            managers and professionals for private, public and not-for-profit
            organizations, Eastern University has designed its Executive MBA
            Program which offers a unique opportunity for managers, executives
            and professionals to upgrade their professional expertise and to
            improve their problem-solving skills. This is a multidisciplinary
            professional graduate program, which is designed for managers of all
            levels from both local, national and multinational organizations.
          </p>
          <p className="heading5">Objectives</p>
          <p className="allText">
            <ul>
              <li>
                To enhance real life exposure and practical orientation of the
                students especially in the areas of their present job.
              </li>
              <li>
                To promote the intellectual growth of the students admitted to
                the program in order that they become better executives/managers
                in their present workplaces.
              </li>
              <li>
                To develop competence necessary for the executives in an
                increasingly complex business world.
              </li>
              <li>
                To enhance the ability of the students to identify business
                opportunities and resolve managerial problems.
              </li>
              <li>
                To develop managers’ understanding of the functional areas of
                business and develop a general perspective of their
                interrelationships.
              </li>
              <li>
                To enhance critical thinking and develop communication,
                interpersonal and leadership skills of managers to work
                effectively with others.
              </li>
            </ul>
          </p>
          <p className="heading5">Features of the Executive MBA Program</p>
          <p className="allText">
            The Executive MBA is a four-semester program for full time students.
            A part time student will however take a longer time depending upon
            the number of courses he enrolls in every semester. In total, an
            Executive MBA student is required to complete 14 courses, each
            accounting for 3 credit hours, that is, a total of 42 credit hours.
            A student should take 2 to 4 courses per semester. Classes are held
            in the afternoon in the week ends and in the evening on selected
            days to suit the convenience of the students. A student with a
            4-year BBA or M.Com degree may be allowed waiver of a maximum of 15
            credit hours.
          </p>
          <p className="allText">
            Assignments, project works and case studies serve the purpose of
            cross functional integration of professional knowledge and skills in
            the context of real life business situations. Project works and
            assignments are designed to relate the learning in an individual
            course to the real life practices. Students are particularly
            encouraged to do project works in their respective organizations
            focusing on any strategic area.
          </p>
          <p className="heading5">Admission Requirement</p>
          <p className="allText">
            Candidates with a minimum GPA of 2.50 in both SSC/Dakhil and
            HSC/Alim/Diploma from Technical Education Board or Minimum GPA of
            2.00 in one of those exam with total GPA of 6.00 in both the exams
            are eligible for admission .The GCE Students must have passed five
            subjects in O-level and two subjects in A-level. In addition, the
            candidates must have at least 2 years of job experience.
          </p>
          <p className="heading5">Degree Requirement</p>
          <p className="allText">
            A student must complete the program within a maximum period of 4
            (four) years from the date of registration. To qualify for the EMBA
            degree, a student has to pass all the required courses individually,
            pass the internship or the project with a minimum “C” grade, earn a
            minimum Cumulative Grade Point Average (CGPA) of 2.50 and maintain a
            record of good conduct.
          </p>
          <p className='heading5'>
            <a href={MBACourseDistridution}>Download Course Distribution</a>
          </p>
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationBusinessDepartment />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </div>
  );
};

export default ProgramsExecutiveMBADepartment;
