import React, { useState } from "react";
import LeftSideNavigationBusinessDepartment from "../../component/leftSideNavigationDepartmentofBusiness";
import FacultyName from "../../component/facultyName";
import {
  engFacultyTestImg,
  BBAIslamCourseDistridution,
} from "../../../../static";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const ProgramBBAIslamicFinance = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName
        name="Department of Business Administration"
        facultyImg={engFacultyTestImg}
      />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationBusinessDepartment />
        <div className="rightSideContent m-4">
          <p className="heading3">
            BBA in Islamic Finance,Banking and Insurance (Bachelor of Business
            Administration)
          </p>

          <p className="allText">
            Islamic finance, banking and Insurence is now a successful reality
            not only in Bangladesh in the global context as well. Shariah based
            finance, banking and insurance businesses are on the rapid increase
            in Bangladesh. To cater in the needs and rapid requirements of these
            business enterprises, the Faculty of Business Administration at
            Eastern University has come up with the BBA Program in Islamic
            Finance, Banking and Insurence . This is the first ever
            under-graduate program in Bangladesh, duly approved by the UGC,
            Bangladesh. This four-year professional business program will blend
            academic theory and business practices while preparing students to
            excel in challenging work environments or in advanced academic
            studies. This state-of-the-art program will be of paramount
            importance in achieving the vision of preparing leaders who will be
            able to transform Shariah-based businesses through their rigorous
            analysis and innovative insights.
          </p>

          <p className="heading5">Vision</p>
          <p className="allText">
            To be one of the top graduate program in Bangladesh recognized
            regionally for excellence in teaching, research, and innovative
            contributions in business and management especially with the concept
            of Islamic principles heralded by dedicated, highly educated and
            field level experienced faculty members.
          </p>
          <p className="heading5">Mission</p>
          <p className="allText">
            To produce future corporate leaders; socially responsible business
            graduates imbued with general and Shariah knowledge, skill and hands
            on work experience; capable of facing national and global business 2
            challenges with special respect to Islamic concepts and Shariah
            framework ; abreast of advanced technology, possessing strong
            motivation and communication skills, and able to lead in a
            multi-cultural environment.
          </p>
          <p className="heading5">
            <a href={BBAIslamCourseDistridution}>Download program brocheure</a>
          </p>
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationBusinessDepartment />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </div>
  );
};

export default ProgramBBAIslamicFinance;
