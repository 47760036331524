import React from "react";

import moment from "moment/moment";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { useNotices } from "../../../features/useNotices";
import LoadingBox from "../../../component/LoadingBox";
import MessageBox from "../../../component/MessageBox";

const NoticeByDepartment = ({ config }) => {
  const { notices: notice, isLoading, error } = useNotices(config);
  if (isLoading) return <LoadingBox />;
  if (error) return <MessageBox>{error}</MessageBox>;
  const NoticeList = notice.map((mylist) => {
    return (
      <li class="list-group-item" key={mylist.id}>
        <p className="card-title heading6 text-left">
          <Link exact to={"/notice/" + mylist.id}>
            {ReactHtmlParser(mylist.headline)}
          </Link>
        </p>
        <h6 className="card-subtitle mb-2 text-muted text-left">
          Publish Date: {moment(mylist.noticeDate, "YYYY/MM/DD").format("LL")}
        </h6>
      </li>
    );
  });
  return <div className="noticeByDepartment pb-4">{NoticeList}</div>;
};

export default NoticeByDepartment;
