import Footer from "../../../../component/Footer";
import FacultyName from "../../component/facultyName";
import Curriculumn from "../../component/Curriculumn";
import { departmentCode } from "../../../../util";
import { useState } from "react";
import { VscAdd, VscChromeClose } from "react-icons/vsc";
import LeftSideNavigationPharmacy from "../../component/leftSideNavigationPharmacy";

function UnderGraduateCurriculumPharma() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const config = { department: departmentCode.pharmacy };

  return (
    <div>
      <FacultyName name="Department of Pharmacy" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationPharmacy />
        <div className="rightSideContent m-4">
          <p className="heading4 text-center">Undergraduate Curriculum</p>
          <div className="deanMessage text-left">
            <div className="deanMessage text-left">
              <Curriculumn config={config} />
            </div>
          </div>
          {/* Mobile Responsive Start*/}
          <div
            id="mobileMenuButton"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <button>
              <VscAdd className="moreMenu" />
            </button>
          </div>
          {mobileMenuOpen && (
            <div id="mobileMenuContent">
              <button
                id="closeButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <VscChromeClose className="closeButtonIcon" />
              </button>
              <LeftSideNavigationPharmacy />
            </div>
          )}
          {/* Mobile Responsive End*/}
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default UnderGraduateCurriculumPharma;
