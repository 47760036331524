import React, { useState } from 'react';
import LeftSideNavigationLawDepartment from '../../component/leftSideNavigationDepartmentofLaw';
import FacultyName from '../../component/facultyName';
import Footer from '../../../../component/Footer';
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const ExploringLaw = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const deansMessage = "The Faculty of Law of  Eastern university is one of the premier law schools of the country that has always attracted the best legal minds both academically and ideologically, thereby creating an inspirational exchange centre of ideas. Particularly the Faculty of Law is known by its outstanding teaching staff most of whom have graduated from the best law schools of Bangladesh and abroad. They are socially involved, professionally active, competent and caring mentors."
    const deansMessagePart2 = "Our faculty offers legal education which is combination of both theory and practice. The goal is to equip graduates with a solid education in the fundamentals of the law and a sound understanding of its place in our society. EU's law curriculum reflects the changing requirement of the legal profession. Students are given extensive training on various forms of Legal Drafting and Trial Advocacy, in the Mock Court Room."
    const deansMessagePart3 = "Students graduating from the Department has shown excellent passing rate in the Bar Council Examination and Judicial Service Commission Examinations. Alumni of the Department of Law include judges, practicing lawyers in the District Court and the High Court, lecturers in various universities and job holders in various organizations."
    const deansMessagePart4 = "The Faculty enjoys world class computer labs and libraries rich with various text books and journals from around the world. Membership of the University in various on-line journals enables the students to reap their benefit and allows them to endeavour to learn the subject of their choosing. Through regular public lectures, seminars and workshops etc. attempts at refining the academic research and learning about the newest doctrines are constant. "
    const deansMessagePart5 = "Our campus provides a vibrant environment for the students to grow and evolve. The Department has always been very active in the Sports Club and the Social Welfare Club of the University. Eastern University Moot Court Society, exclusive to the Faculty of Law, has already made its mark by winning the Best Memorial Award in Henry Dunant Moot Court Competition, 2011. It also participated in the Price Media Moot Court Competition held in Delhi in 2012 among others."
    return (
        <div>
            <FacultyName name='Department of Law' />
            <div className='facultyContent' id='deanArts'>
                <LeftSideNavigationLawDepartment />
                <div className='rightSideContent mt-4'>

                    <p className='heading4 text-center'>Exploring Department of Law</p>
                    <div className='deanMessage text-left'>
                        <p className='allText'>
                            {deansMessage}
                        </p>
                        <p className='allText'>
                            {deansMessagePart2}
                        </p>
                        <p className='allText'>{deansMessagePart3}</p>
                        <p className='allText'>{deansMessagePart4}</p>
                        <p className='allText'>{deansMessagePart5}</p>
                    </div>
                    {/* Mobile Responsive Start*/}
                    <div
                        id="mobileMenuButton"
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                    >
                        <button>
                            <VscAdd className="moreMenu" />
                        </button>
                    </div>
                    {mobileMenuOpen && (
                        <div id="mobileMenuContent">
                            <button
                                id="closeButton"
                                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                            >
                                <VscChromeClose className="closeButtonIcon" />
                            </button>
                            <LeftSideNavigationLawDepartment />
                        </div>
                    )}
                    {/* Mobile Responsive End*/}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ExploringLaw;