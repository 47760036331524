import React, { useState } from 'react';
import LeftSideNavigationLawDepartment from '../../component/leftSideNavigationDepartmentofLaw';
import FacultyName from '../../component/facultyName';
import Footer from '../../../../component/Footer';
import { engFacultyTestImg, LLMCourseDistribution } from '../../../../static';
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const ProgramsLLMLawDepartment = () => {
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return (
        <div>
            <FacultyName name='Department of Law' facultyImg={engFacultyTestImg} />
            <div className='facultyContent' id='deanArts'>
                <LeftSideNavigationLawDepartment />
                <div className='rightSideContent m-4'>
                    <p className='heading3'>LL.M </p>


                    <p className='heading5'>Duration of the Programs</p>
                    <p className='allText'>
                        LL.M.(1year) – LL.M. one year program consists of three semesters and each semester lasts for four months.
                    </p>
                    <p className='heading5'>Admission Requirements</p>
                    <p className='allText'>
                        For LL.M. (1 Year) : Any one who has obtained LL.B. (Hons.) degree from this University or from any other recognized University in Bangladesh, or obtained an equivalent degree from any other foreign University is eligible for admission.
                    </p>
                    <p className='heading5'>
                        <a href={LLMCourseDistribution}>Download Course Distribution</a>
                    </p>
                </div>
                {/* Mobile Responsive Start*/}
                <div
                    id="mobileMenuButton"
                    onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                >
                    <button>
                        <VscAdd className="moreMenu" />
                    </button>
                </div>
                {mobileMenuOpen && (
                    <div id="mobileMenuContent">
                        <button
                            id="closeButton"
                            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                        >
                            <VscChromeClose className="closeButtonIcon" />
                        </button>
                        <LeftSideNavigationLawDepartment />
                    </div>
                )}
                {/* Mobile Responsive End*/}
            </div>
            <Footer />
        </div>
    );
};

export default ProgramsLLMLawDepartment;