import React, { useState } from "react";
import LeftSideNavigation from "../component/leftSideNavigationArts";
import FacultyName from "../component/facultyName";
import { facultyImgArts } from "../../../static";
import Footer from "../../../component/Footer";
import UpcomingEventsByDepartment from "../component/upcomingEventsByDepartment";
import { VscChromeClose } from "react-icons/vsc";
import { VscAdd } from "react-icons/vsc";
import { departmentCode } from "../../../util";

const ResearchEventsArts = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const config = { faculty: departmentCode.facultyOfArts };

  return (
    <div>
      <FacultyName name="Faculty of Arts" facultyImg={facultyImgArts} />
      <div className="facultyContent">
        <LeftSideNavigation />
        <div className="rightSideContent m-4">
          <p className="heading3">Events</p>
          <UpcomingEventsByDepartment config={config} />
        </div>
        {/* Mobile responsive start here */}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigation />
          </div>
        )}
        {/* Mobile responsive end here */}
      </div>
      <Footer />
    </div>
  );
};

export default ResearchEventsArts;
