import React from 'react';
import OtherCommitteesContent from './content/OtherCommitteesContent';
import Footer from '../../component/Footer';

const otherCommittees = () => {
    return (
        <div>
            <OtherCommitteesContent />
            <Footer />
        </div>
    );
};

export default otherCommittees;