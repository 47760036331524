import React from "react";
import ReactHtmlParser from "react-html-parser";
import moment from "moment/moment";

import Footer from "../../../component/Footer";
import { useParams } from "react-router-dom";
import { useFetchDetails } from "../../../hooks/useFetchDetails";
import LoadingBox from "../../../component/LoadingBox";
import MessageBox from "../../../component/MessageBox";
import { replace_string, server_django } from "../../../util";
import Spinner from "../../../UI/Spinner";

function FacultyMemberDetails() {
  // const [facultyMemberDetails, setfacultyMemberDetails] = useState([]);

  const { serial_id: id } = useParams();

  const {
    data: facultyMemberDetails,
    loading,
    error,
  } = useFetchDetails(`employee/facultyMembersDetails/${id}`);

  if (loading) return <Spinner />;

  if (error) return <MessageBox>{error}</MessageBox>;

  console.log(facultyMemberDetails);

  return (
    <seaction id="facultyMemberDetails">
      {facultyMemberDetails && (
        <FacultyInfo
          facultyMember={facultyMemberDetails}
          key={facultyMemberDetails.id}
        />
      )}

      {facultyMemberDetails && (
        <FacultyDetails facultyMember={facultyMemberDetails} />
      )}

      <Footer />
    </seaction>
  );
}

export default FacultyMemberDetails;

function FacultyInfo({ facultyMember }) {
  return (
    <div className="facultyMemberDetails">
      <div className="info">
        <div className="contact">
          <p className="heading6">{facultyMember.staff_name}</p>
          <p className="allText">
            {facultyMember.designation?.designation}
            {facultyMember.role?.length > 0 && <span> and</span>}
            {facultyMember.role?.length > 0 &&
              facultyMember.role
                ?.sort((a, b) => a.priority - b.priority)
                .map((item, index, arr) => {
                  const lastElement = index === arr.length - 1;
                  return (
                    <span>{` ${
                      lastElement ? item.role : `${item.role}, `
                    } `}</span>
                  );
                })}
          </p>
          <p className="allText">{facultyMember.department?.depname}</p>
          <p className="allText">
            {facultyMember.phone_number}{" "}
            <span>Ext: {facultyMember.extension}</span>
          </p>
          <p className="allText">{facultyMember.official_email}</p>
        </div>
        <img
          className="img-fuild"
          src={facultyMember.staff_picture?.replace(
            replace_string,
            server_django
          )}
          alt={facultyMember.staff_name}
        ></img>
      </div>
    </div>
  );
}

function FacultyDetails({ facultyMember }) {
  if (Object.keys(facultyMember).length === 0) return <LoadingBox />;

  return (
    <div className="details">
      <List />
      <TabContent facultyMember={facultyMember} />
    </div>
  );
}

function List() {
  return (
    <ul className="nav nav-tabs" role="tablist">
      <li className="nav-item">
        <a
          className="nav-link active heading5"
          href="#education"
          role="tab"
          data-toggle="tab"
        >
          Education
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link heading5"
          href="#Research_Interest"
          role="tab"
          data-toggle="tab"
        >
          Research Interest
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link heading5"
          href="#Teaching_Experiance"
          role="tab"
          data-toggle="tab"
        >
          Experience
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link heading5"
          href="#Administrative_Position"
          role="tab"
          data-toggle="tab"
        >
          Administrative Position
        </a>
      </li>
      <li className="nav-item">
        <a
          className="nav-link heading5"
          href="#seleted_publication"
          role="tab"
          data-toggle="tab"
        >
          Selected Publication
        </a>
      </li>
      {/* <li className="nav-item">
        <a
          className="nav-link heading5"
          href="#detailed_cv"
          role="tab"
          data-toggle="tab"
        >
          Detailed CV
        </a>
      </li> */}
    </ul>
  );
}

function TabContent({ facultyMember }) {
  const {
    educations,
    researchInterests,
    teachingExperiances,
    adminPositions,
    publications,
  } = facultyMember;

  return (
    <div className="tab-content">
      <div
        role="tabpanel"
        className="tab-pane fade active show  p-4"
        id="education"
      >
        {educations
          ?.sort((a, b) => b.academic_level.RANK - a.academic_level.RANK)
          .filter((item) => item.is_approved === true)
          .map((item) => (
            <p className="allText">
              {ReactHtmlParser(
                ` <strong> ${item.academic_level.NAME} </strong> in <em><u> ${item.subject}</u> </em> from  ${item.institute}  (${item.passing_year}) `
              )}
            </p>
          ))}
      </div>
      <div role="tabpanel" className="tab-pane fade p-4" id="Research_Interest">
        <ol>
          {researchInterests
            ?.filter((item) => item.is_approved === true)
            .map((item) => (
              <li className="allText">{item.topic}</li>
            ))}
        </ol>
      </div>
      <div
        role="tabpanel"
        className="tab-pane fade p-4"
        id="Teaching_Experiance"
      >
        <ol>
          {/* {facultyMember.current_promotions.length === 0 && (
            <li className="allText">
              <strong> {facultyMember.designation.designation} </strong> at{" "}
              <em>Eastern University</em> from{" "}
              {!facultyMember.current_promotions.length &&
                `${moment(facultyMember.join_date).format(
                  "MMM Do YYYY"
                )} to Continue`}
            </li>
          )} */}
          {teachingExperiances
            ?.sort((a, b) => a.designation.priority - b.designation.priority)
            .filter((item) => item.is_approved === true)
            .map((item) => (
              <li className="allText">
                <strong> {item.designation.designation} </strong> at{" "}
                <em>{item.institute}</em> from{" "}
                {moment(item.startDate).format("MMM Do YYYY")} to{" "}
                {new Date(item.todate).toISOString().slice(0, 10) &&
                item.todate < new Date().toISOString().slice(0, 10)
                  ? moment(item.todate).format("MMM Do YYYY")
                  : `Continue`}
              </li>
            ))}
        </ol>
      </div>
      <div
        role="tabpanel"
        className="tab-pane fade p-4"
        id="Administrative_Position"
      >
        <ul>
          {adminPositions
            ?.filter((item) => item.is_approved === true)
            .map((item) => (
              <li className="allText">
                <strong>{item.position} </strong> at {item.institute} From{" "}
                {item.timePriod}
              </li>
            ))}
        </ul>
      </div>
      <div
        role="tabpanel"
        className="tab-pane fade p-4"
        id="seleted_publication"
      >
        <ol>
          {publications
            ?.sort((a, b) => b.publicationYear - a.publicationYear)
            .filter((item) => item.is_approved === true)
            .map((item) => (
              <li className="allText">
                <strong>
                  <em>
                    {item.publicationLink === "" ? (
                      item.name
                    ) : (
                      <a href={item.publicationLink}>{item.name}</a>
                    )}{" "}
                  </em>
                </strong>
                published at <em> {item.journal}</em> on{" "}
                <strong>{item.publicationYear}</strong> Based on {item.topices}
              </li>
            ))}
        </ol>
      </div>
      {/* <div role="tabpanel" className="tab-pane fade" id="detailed_cv">
        <a href="#">
          <p className="heading5">Download Detailed CV</p>
        </a>
      </div> */}
    </div>
  );
}
