import React from 'react';
import Footer from '../../component/Footer';
import OnlinePaymentProcedureContent from './content/OnlinePaymentProcedureContent'

const onlinePaymentProcedure = () => {
    return (
        <div>
            <OnlinePaymentProcedureContent />
            <Footer />
        </div>
    );
};

export default onlinePaymentProcedure;