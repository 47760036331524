import React, { useState } from "react";
import LeftSideNavigationEnglish from "../../component/leftSideNavigationEnglish";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const ExploringEnglish = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const deansMessage =
    " Eastern University started in summer 2003 with four Faculties. The Faculty of Arts is one of them. It began its journey with M.A.ELT (English Language Teaching) program, and one semester later B.A (Hons.) in English was added. There are now three programs in the Faculty: a four year B.A. (Hons.) in English, a one-year M.A. ELL (English Language and Literature) and an M. A. ELT which offers a one-year M.A.ELT and a two-year M.A. ELT.";
  const deansMessagePart2 =
    "A well earned degree in English can open innumerable job opportunities at home and abroad. English is an international language and there is a great demand for competent users of the language. Graduates in English get preference in jobs ranging from teaching to media, banks, multi national companies etc. English graduates do quite well at the BCS Examinations and find it helpful to pursue MBA studies, both  academically and job wise, as this combination is best for top corporate jobs.";
  const deansMessagePart3 =
    "The English Program at Eastern follows a needs-based multi syllabus approach combining well-balanced language and literature courses in order to cater to the job needs of its graduates. Besides the foundation language courses at the undergraduate level are module-based, providing an intensive teaching-learning class environment. The Department has an English Language Club and holds extra class room activities such as poetry recitation, debate, drama, vocabulary/spelling contests to inspire and encourage learners to become adept users of the language.";
  return (
    <div>
      <FacultyName name="Department of English" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationEnglish />
        <div className="rightSideContent mt-4">
          <p className="heading4 text-center">
            Exploring Eastern University Department of English
          </p>
          <div className="deanMessage text-left">
            <p className="allText">{deansMessage}</p>
            <p className="allText">{deansMessagePart2}</p>
            <p className="allText">{deansMessagePart3}</p>
          </div>
          {/* Mobile Responsive Start*/}
          <div
            id="mobileMenuButton"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <button>
              <VscAdd className="moreMenu" />
            </button>
          </div>
          {mobileMenuOpen && (
            <div id="mobileMenuContent">
              <button
                id="closeButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <VscChromeClose className="closeButtonIcon" />
              </button>
              <LeftSideNavigationEnglish />
            </div>
          )}
          {/* Mobile Responsive End*/}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ExploringEnglish;
