import React from 'react';
import CampusSideBar from '../component/CampusSidebar';
import Footer from '../../component/Footer';
import { transportFacilitiesInfoAboutRouteImage, img1 } from '../../static';

const transportFacilities = () => {
    return (
        <>
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr />
                    <p className='heading2 text-center'>
                        Transport facilities
                    </p>
                    <hr />
                    <p className='allText'>
                        Transport facility is available for both staff and students of Eastern University.
                    </p>
                    <img className='img-fluid' src={transportFacilitiesInfoAboutRouteImage} alt='Image Loading...'/>
                    <img className='img-fluid' src={img1} alt='Image Loading...'/>
                </div>
                <CampusSideBar />
            </div>
            <Footer />
        </>
    );
};

export default transportFacilities;