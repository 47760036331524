import React from "react";
import IqacPresentationsContent from "./content/IqacPresentationsContent";
import Footer from "../../../component/Footer";

const iqacPresentations = () => {
  return (
    <div>
      <IqacPresentationsContent />
      <Footer />
    </div>
  );
};

export default iqacPresentations;
