import React from 'react';
import Footer from '../../component/Footer'
import InternationalAcademicCollaborationContent from './content/InternationalAcademicCollaborationContent';



const internationalAcademicCollaboration = () => {
    return (
        <div>
            <InternationalAcademicCollaborationContent/>
            <Footer />
        </div>
    );
};

export default internationalAcademicCollaboration;