import React from 'react';
import CampusSideBar from '../component/CampusSidebar';
import Footer from '../../component/Footer';

const PsychoSocialCounselling = () => {
    return (
        <>
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr />
                    <p className='heading2 text-center'>
                        PsyCho-Social Counselling
                </p>
                    <hr />
                    <p className='allText'>
                        Education is a cognitive process where sound mental health is one of the prime condition. Stable and sound mental health is very important for Education. Counselling ( Psycho Social) is an important tool for student to develop and maintain proper mental health.
                </p>
                    <p className='allText'>
                        Counselling service considers the dignity and worth of all individuals of the university and embrace human diversity within a warm, accepting and confidential environment.
                </p>
                    <p className='heading6'>
                        Counselling :
                </p>
                    <p className='allText'>
                        Counselling is an interactive process characterized by an unique relationship between counsellor and client that leads to change in the client behavior, beliefs, coping ability, decision-making knowledge & skills and level of emotional distress.
                </p>
                    <p className='heading6'>
                        Counselling and its importance for students:
                </p>
                    <p className='allText'>
                        <ul>
                            <li className='allText'>
                                Young Adulthood is a very crucial time of life. To make a right decision at this stage of life is very important. Counsellor can help students to make a right choice which is suitable and realistic for him/her.
                        </li>
                            <li className='allText'>
                                Stress , anxiety are common in everyone's life but sometimes the level of stress becomes so high we cannot live or lead or enjoy our normal life. Life becomes so hard and we face anger, depression, adjustment and others emotional problems. In these situations students not only face concentration and other educational mental health problems but also facing lots of personal difficulties. By taking professional help students can solve their issues.
                        </li>
                            <li className='allText'>
                                As a social being family, friends and associates are very important in our live and our well-being is closely related to how we maintain our relations with others. For making and maintaining more intimate relations and leading a joyful life one can take Psycho Social Counselling.
                        </li>
                            <li className='allText'>
                                Confidence and self-esteem are a very important tool for our glorious life. Proper use of the qualities helps us make a great contribution for nation.
                        </li>
                            <li className='allText'>
                                Its helps person to realize his or her own Responsibility.
                        </li>
                        </ul>
                    </p>
                    <p className='heading6'>
                        How the services will be provided:
                </p>
                    <p className='allText'>
                        <ul>
                            <li className='allText'>
                                The service is provided through individual counselling, peer counselling, Couple counselling, Family counselling and Group counselling.
                        </li>
                            <li className='allText'>
                                In counselling every single issue may take 4 to 8(hight12) sessions and each session takes 40 minutes to 1 hour.
                        </li>
                            <li className='allText'>
                                Some psychological Tests (Stress , Depression, Anxiety etc) are essential in this services.
                        </li>
                            <li className='allText'>
                                To arrange and facilitate workshops on psychological issues.
                        </li>
                            <li className='allText'>
                                The service is not only for the students but also for all employees of the university.
                        </li>
                        </ul>
                    </p>
                    <p className='heading6'>
                        Confidentiality:
                </p>
                    <p className='allText'>
                        <ul>
                            <li className='allText'>
                                Confidentiality is taken very seriously by professional Counsellors and psychotherapists and everything that is discussed is kept in the strictest confidence.
                        </li>
                            <li className='allText'>
                                Confidentiality is a professional right of a Psychologist. A safe, secure and confidential environment is a must for the service.
                        </li>
                            <li className='allText'>
                                Also note that counsellor can break confidentiality if client takes any unusual attitude like suicide attempt or any vandalism.
                        </li>
                        </ul>
                    </p>
                    <p className='allText'>
                        Counselling service considers the dignity and worth of all individuals of the university and embrace human diversity within a warm, accepting and confidential environment.
                </p>
                </div>
                <CampusSideBar />

            </div>
            <Footer />
        </>
    );
};

export default PsychoSocialCounselling;