import React, { Component } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faYoutube,
    faFacebook,
    faLinkedin,
    faInstagram
} from "@fortawesome/free-brands-svg-icons";




class SocialIcon extends Component {
    render() {
        return (



            <div id='topIcons' className='social-icons'>
                <a href="https://www.youtube.com/c/EasternUniversitybd?fbclid=IwAR3JecPNuPg_4VKQZ5wq1I6Lqgc77BezeIF3Jws4Tmx_SSWeuscq9oAjCtc" className='youtube'
                >
                    <FontAwesomeIcon icon={faYoutube} />
                </a>
                <a href="https://www.facebook.com/euedubd" className='facebook'
                >
                    <FontAwesomeIcon icon={faFacebook} />
                </a>
                <a href="https://www.linkedin.com/school/easternuniversityofficialpage" className='linkedin'
                >
                    <FontAwesomeIcon icon={faLinkedin} />
                </a>
                <a href="https://www.instagram.com/eastern_university_official" className='instagram'
                >
                    <FontAwesomeIcon icon={faInstagram} />
                </a>
                {/* <a href="#"
                >
                    <FontAwesomeIcon icon={faSearch} />
                </a> */}
            </div>



        );
    }
}

export default SocialIcon;