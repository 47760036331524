import React, { useState } from "react";
import FacultyName from "../component/facultyName";
import Footer from "../../../component/Footer";
import LeftSideNavigationEngineering from "../component/leftSideNavigationEngneering";
import { engFacultyTestImg } from "../../../static";
import DepartmentOfficials from "../component/DepartmentOfficials";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import { departmentCode } from "../../../util";

const ContactUsEngineering = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const config = { faculty: departmentCode.facultyOfEngineering };

  return (
    <div>
      <FacultyName
        name="Faculty of Engineering and Technology"
        facultyImg={engFacultyTestImg}
      />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationEngineering />
        <div className="rightSideContent m-4">
          <p className="heading3">Contact Us</p>
          <DepartmentOfficials config={config} />
        </div>
        {/* Mobile responsive start here */}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationEngineering />
          </div>
        )}
        {/* Mobile responsive end here */}
      </div>
      <Footer />
    </div>
  );
};

export default ContactUsEngineering;
