import React, { useState } from "react";
import LeftSideNavigationEnglish from "../../component/leftSideNavigationEnglish";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import DepartmentOfficials from "../../component/DepartmentOfficials";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import { departmentCode } from "../../../../util";

const OfficialsEnglish = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const config = { department: departmentCode.english };

  return (
    <div>
      <FacultyName name="Department of English" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationEnglish />
        <div className="rightSideContent mt-4">
          <p className="heading3 text-center">Officials</p>
          <DepartmentOfficials config={config} />
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationEnglish />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </div>
  );
};

export default OfficialsEnglish;
