import React from 'react';
import AcademicSidebar from '../../component/AcademicSideBar'

const RulesOfClassAttendanceContent = () => {
    return (
        <div className='content'>
            <div className='OtherPagecontent'>
                <hr></hr>
                <p className='heading2 primaryHeading text-center'>
                    Rules of Class Attendance</p>
                <hr></hr>

                <>
                    <p className="allText">
                        A student is expected to attend all classes in a course. All students are
                        required to be present in at least 80% of the classes including tutorial
                        classes held, to sit for the final examination. For example, if there are
                        26 classes and 13 tutorial /lab etc. of a course in a semester, the
                        percentage of attendance will be computed out of 39. If a student fails
                        to attend 80% classes of a course, s/he will not be allowed to sit for
                        the final exam. It is the responsibility of the students to keep course
                        teachers informed of his/her absences from classes in case of emergencies.
                        Absence in more than 20% of the total number of classes without any
                        application may lead to cancellation of registration of the student in
                        the semester.</p>
                    <p className="allText">
                        If a student remains absent in three consecutive classes, s/he must
                        submit an application to the course teacher and must obtain permission
                        to attend the next class. In the application, s/he must explain the exact
                        reason for remaining absent, not just that s/he was not in a position to
                        attend classes for reasons beyond control or had family problems. However,
                        just applying is not enough - the student must submit authentic documents
                        in support of his/her application. All medical documents submitted in
                        support of an application must be attested by Medical Officer of
                    Eastern University.</p>
                    <p className="allText">
                        If a student remains absent in 5 or more classes of a course in a semester,
                        s/he must apply to the Dean of the Faculty for obtaining permission to
                        continue the semester/ appear in the examination.
                </p>

                </>
            </div >
            <AcademicSidebar />
        </div >
    );
};

export default RulesOfClassAttendanceContent;