import React from "react";
import Wrapper from "../../component/Wrapper";
import AdmissionSidebar from "../component/AdmissionSidebar";

const AdmissionProcessForNative = () => {
  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">
            Admission Process for Native Students
          </p>
          <hr />

          <div className="details">
            <p className="allText">
              A candidate may take admission directly (without an admission
              test) or through an admission test and it would be
              physically/offline or Online.
            </p>
            <p className="heading5">Direct Admission</p>
            <p className="allText">
              Physical/Offline process: An applicant can take direct admission
              based on their academic result.
            </p>
            <ul className="list-group">
              <li className="list-group-item">
                <p className="allText">
                  Step-01: Collect Application Form from the Admission Office by
                  paying BDT 500/-
                </p>
              </li>

              <li className="list-group-item">
                <p className="allText">
                  Step-02: Submit the Application Form duly filled in with all
                  academic transcript and certificates and three (03) passport
                  size color photographs.
                </p>
              </li>
              <li className="list-group-item">
                <p className="allText">
                  Step-03: Deposit admission fees to the accounts section.
                </p>
              </li>
            </ul>

            <p className="heading5 mt-4">Online Direct Admission Process:</p>
            <ul className="list-group">
              <li className="list-group-item">
                <p className="allText">
                  Step-01: Please visit{" "}
                  <a href="https://www.easternuni.edu.bd/OnlineAdmission">
                    https://www.easternuni.edu.bd/OnlineAdmission
                  </a>
                </p>
              </li>

              <li className="list-group-item">
                <p className="allText">Step-02: Fill-up the Application Form</p>
              </li>
              <li className="list-group-item">
                <p className="allText">
                  Step-03: Pay BDT 500 to bKash no. 01795711361, and collect the
                  TrxID number
                </p>
              </li>
              <li className="list-group-item">
                <p className="allText">Step-04: Provide the TrxID number</p>
              </li>
              <li className="list-group-item">
                <p className="allText">Step-05: Submit the form online</p>
              </li>
              <li className="list-group-item">
                <p className="allText">
                  Step-06: Admission office will contact the candidates for
                  further direct admission guidelines.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <AdmissionSidebar />
      </div>
    </Wrapper>
  );
};

export default AdmissionProcessForNative;
