import React, { Component, useEffect, useState } from "react";
import Marquee from "react-double-marquee";
import axios from "axios";
import { server, server_django, auth } from "../util";
import { NavLink } from "react-router-dom";
import LoadingBox from "./LoadingBox";
import MessageBox from "./MessageBox";
import SpinnerMini from "../UI/SpinnerMini";

function TopNews() {
  const [topNews, setTopNews] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await axios.get(server_django + "/topnews", { auth });
        setLoading(false);
        setTopNews(res.data.results);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const topNewsList =
    topNews &&
    topNews.map((mylist) => {
      return (
        <li className="TopnewsLinks" key={mylist.id}>
          <NavLink
            exact
            to={
              mylist.detail !== "#" || mylist.picture_location
                ? `${"/topnews/" + mylist.id}`
                : "/"
            }
            className="QuickLinksStyle"
          >
            {/* exact to={'/breakingnews/' + mylist.serial_no} */}
            {mylist.headline}
            <span className="topnewsSaparator">
              <strong>|</strong>
            </span>
          </NavLink>
        </li>
      );
    });
  return (
    <div className="topNews">
      {loading ? (
        <SpinnerMini />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        <Marquee direction="left" speed={0.04}>
          {topNewsList}
        </Marquee>
      )}
    </div>
  );
}

export default TopNews;
