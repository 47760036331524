import React from "react";
import IqacSideBar from "../../../component/IqacSideBar";

const IqacOfficeContent = () => {
  return (
    <div className="content">
      <div className="OtherPagecontent">
        <hr />
        <p className="heading2 primaryHeading text-center">
          Office of the International Quality Assurance Cell
        </p>
        <hr />
        <p className="allText">Work in progress..</p>
      </div>
      <IqacSideBar />
    </div>
  );
};

export default IqacOfficeContent;
