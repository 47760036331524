import { useEffect, useState } from "react";
import { auth, server_django } from "../util";

export function useFetchDetails(url) {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const BASE_URL = `${server_django}/${url}`;

  useEffect(function () {
    const controller = new AbortController();
    async function fetchData() {
      try {
        setError("");
        setLoading(true);
        const res = await fetch(BASE_URL, {
          signal: controller.signal,
          headers: new Headers({
            Authorization: "Basic " + btoa(auth.username + ":" + auth.password),
            "Content-Type": "application/json",
          }),
        });

        if (!res.ok) {
          console.log(res);
          throw new Error("Something went wrong to fetching data");
        }
        const data = await res.json();

        if (data.Response === "False") {
          throw new Error("No Data Found");
        }

        setData(data);
        setError("");
      } catch (err) {
        if (err.name !== "AbortError") {
          setError(err.message);
        }
      } finally {
        setLoading(false);
      }
    }

    fetchData();

    return function () {
      controller.abort();
    };
  }, []);

  return { data, loading, error };
}

export default useFetchDetails;
