import React from 'react';
import CampusSideBar from '../component/CampusSidebar';
import Footer from '../../component/Footer';

const hostelFacilities = () => {
    return (
        <>
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr />
                    <p className='heading2 text-center'>
                        Hostel For Students
                    </p>
                    <hr />
                    <p className='allText'>
                        The University has arrangement of a female hostel located nearby the campus. Similar hostel accommodation is available for male students also. Full fledged and comfortable hostel facilities will be available for all students in the University campus under construction in Ashulia.
                    </p>
                </div>
                <CampusSideBar />
            </div>
            <Footer />
        </>
    );
};

export default hostelFacilities;