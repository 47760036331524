import React, { useState } from "react";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import { cgpaCalculation, civilCouseDistridution } from "../../../../static";
import LeftSideNavigationPharmacy from "../../component/leftSideNavigationPharmacy";

const BachelorOfPharmacy = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <FacultyName name="Department of Pharmacy" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationPharmacy />
        <div className="rightSideContent mt-4">
          <p className="heading3">Bachelor of Pharmacy (B. Pharm)</p>
          <p className="allText">
            The Department of Pharmacy offers a 4 years undergraduate Program of
            Bachelor of Pharmacy. To become a graduate in this field one has to
            complete 160 credit hours. It is utmost necessity to enhance the
            quality of higher education in Bangladesh for national and global
            context. Eastern University (EU) is one of the private universities
            trying to maintain quality education for creating the leader of the
            leaders since 2003. Department of Pharmacy has projected itself as
            one of the vital departments of the life science faculty. Eastern
            University is well equipped with necessary lab facilities for test,
            and research works. The academic programs of this University are
            continually being updated and improved to reflect the latest
            development in Pharmacy education. The reason for offering Bachelor
            of Pharmacy is to prepare students for employment as integrated
            members of the healthcare system. Pharmacists can find employment in
            pharmaceutical industries, Govt. jobs, research, academia, higher
            education and so on.
          </p>
          <p className="allText">
            The Bachelor of Pharmacy (Honours) programme in Eastern University
            has been designed for students to acquire sufficient understanding
            of the principles and techniques of the pharmaceutical sciences. It
            will provide students with a coordinated understanding,
            comprehensive knowledge and expertise in all disciplines of
            pharmacy. Lectures along with practical classes, seminars, in-plant
            training, interactive small group discussion and tutorials will be
            part of the Bachelor of Pharmacy (Hons.) programme.
          </p>
          <p className="allText">
            Apart from academic activities, the students of the Department
            regularly take part in different co-curricular and extra-curricular
            activities.
          </p>
          <p className="heading5">Assessment Strategy</p>
          <p className="allText">
            A student with 12 years formal pre-University education (i.e. S.S.C.
            + H.S.C. or its equivalent) must complete sufficient courses for
            graduation that is he/she must earn at total of 160 credits that
            includes General Education (suggested by UGC), Basic Science and
            Mathematics, Core Pharmaceutical science courses. The following
            uniform grading system approved by the UGC for all Universities is
            applied in evaluating the examinations of students. The minimum
            passing grade for graduation is CGPA 2.50. The overall performance
            of a student will be expressed in terms of Cumulative Grade Point
            Average (CGPA). A student can sit for any retake / improvement exam
            on payment of prescribed fees. Student’s performance evaluation in a
            course is shown in Figure. Students are continuously assessed
            through class attendance, participation, group discussion and Class
            tests.
          </p>
          <p className="heading5">Grading System</p>
          <p className="allText">
            The following uniform grading system approved by the UGC for all
            universities is applied in the examinations:
          </p>
          <p className="allText">Numerical Marks Letter Grade Grade Point </p>
          <p className="allText">80% and above A+ (A Plus) 4.00 </p>
          <p className="allText">75% to less than 80% A (A regular) 3.75 </p>
          <p className="allText">70% to less than 75% A- (A minus) 3.50 </p>
          <p className="allText">65% to less than 70% B+ (B Plus) 3.25 </p>
          <p className="allText">60% to less than 65% B (B regular) 3.00 </p>
          <p className="allText">55% to less than 60% B- (B minus) 2.75</p>
          <p className="allText">50% to less than 55% C+ (C Plus) 2.50 </p>
          <p className="allText">45% to less than 50% C (C regular) 2.25 </p>
          <p className="allText">40% to less than 45% D 2.00</p>
          <p className="allText"> less than 40% F 0.00 ,</p>
          <p className="allText"> I - Incomplete </p>
          <p className="allText"> W Withdrawn </p>
          <p className="allText">X Continuation </p>

          <p className="heading5">Course Withdrawal</p>
          <p className="allText">
            Students are not allowed to withdraw their courses once they
            register.
          </p>
          <p className="heading5">Incomplete (I) course</p>
          <p className="allText">
            An incomplete course indicates that the student started/register the
            courses in a semester but has not appeared in the final examination.
            If the total mark of the student excluding the final exam is
            ‘satisfactory’, the student will get a chance to sit for retake
            examination. The ‘satisfactory’ mark will depend on the circumstance
            and will be decided by the authority.
          </p>

          <p className="heading5">Retake</p>
          <p className="allText">
            Retake Examination will be applicable for the students who have got
            F/I/WH (Withheld) grade in the course. Retake exam means the student
            will be allowed to sit for the final examination once again after
            proper formalities. Normally such exams are taken within 2 weeks of
            next semester commencement or within the prescribed schedule
            declared by the authority.
          </p>
          <p className="heading5">Grade Improvement</p>
          <p className="allText">
            There is a provision for the students to improve their grades. If
            any grade of the student is below or equal to B+ (Plus), then the
            student may be permitted to sit for the grade improvement exam
            before the final exam of the next semester or within the prescribed
            schedule declared by the authority. Moreover, the student has the
            scope to improve overall CGPA by improving grades in the courses
            with below or equal to B+ (Plus) grade. However, this improvement
            exam must be attempted before starting the thesis/internship and the
            number of such grade Improvement exams will not be more than 10% of
            total courses of the program.
          </p>
          <p className="heading5">Dropout</p>
          <p className="allText">
            By dropout, we mean a student who did not start/register the courses
            for specific semester/s. In case of dropout from a semester, the
            student will be required to apply to the registrar for continuing
            study and attempt the courses or semester/s again after proper
            formalities if he/she returns.
          </p>
          <p className="heading5">
            Grade Point Average (GPA) and Cumulative Grade Point Average (CGPA)
          </p>
          <p className="allText">
            The process of Grade Point Average (GPA) and Cumulative Grade Point
            Average (CGPA) is almost same. GPA is used to denote students’
            performance of a specific semester whereas CGPA is used to denote
            performance of all the semesters as a whole. The process of
            calculation of GPA and CGPA is as follows.
          </p>
          <img className="img-fluid" src={cgpaCalculation} alt="CGPA" />
          <p className="heading5">Graduation Requirement</p>
          <p className="allText">
            The performance of a student will be evaluated in terms of semester
            grade point average (GPA) and cumulative grade point average (CGPA)
            which is the grade average for all the semesters. For the award of
            the B. Pharm. degree, a student shall have to earn all the credits
            offered by the Department of Pharmacy and MUST secure a minimum CGPA
            of 2.5.
          </p>
          {/* <p className="heading5">
            <a href={civilCouseDistridution}>
              Download Semester Wise Course Distribution
            </a>
          </p> */}
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationPharmacy />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </>
  );
};

export default BachelorOfPharmacy;
