import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { auth, replace_string, server, server_django } from "../util";
import ReactHtmlParser from "react-html-parser";
import Footer from "../component/Footer";
import { useParams } from "react-router-dom";
import LoadingBox from "./LoadingBox";
import MessageBox from "./MessageBox";
import Spinner from "../UI/Spinner";

function SingleNotice() {
  const [singleNotice, setSingleNotice] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { notice_id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          server_django + "/notice/" + notice_id,
          { auth }
        );

        setLoading(false);
        setSingleNotice(data);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchData();
  }, [notice_id]);

  const detailsNotice = singleNotice && (
    <div className="singlePost">
      <div className="card">
        <div className="card-body">
          <p className="heading3">{ReactHtmlParser(singleNotice.headline)}</p>
          <p className="allText">
            {ReactHtmlParser(singleNotice.details?.replace(/\r\n/g, "<br />"))}
          </p>
        </div>
        <img
          className="card-img-bottom img-fluid"
          src={singleNotice.pictureLocation?.replace(
            replace_string,
            server_django
          )}
          alt={singleNotice.headline}
        ></img>
      </div>
    </div>
  );

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        detailsNotice
      )}
      <Footer />
    </div>
  );
}

export default SingleNotice;
