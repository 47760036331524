import JournalLayout from "./JournalLayout";

function JournalEditorMessage() {
  return (
    <JournalLayout>
      <p className="heading4">Message from the Editor</p>
      <p className="allText">
        The Eastern University Journal is a half yearly Journal with Issues
        published in January and July every year. The Journal started its
        journey with the publication of its first volume in 2008 with a focus on
        promoting knowledge and research among the academicians and researchers
        of Eastern University in particular, and those of other universities and
        institutions around the world in general.
      </p>
      <p className="allText">
        The Editorial Board attaches top most importance in maintaining the
        highest publication standard of the journal. We have been lagging behind
        in bringing out some volumes due to the impact of COVID 19 and
        unavailability of articles of acceptable standard. Currently, we are
        working on publishing its Volume 10 (Issues 1&amp; 2) and hope to bring
        it out in a mouth or two. The next Volume (No:11) will be brought out by
        June, 2024 with the blindly reviewed papers presented in the EU National
        Conference held on February 17, 2024
      </p>
      <p className="allText">
        I am pleased to announce that the Editorial Board has completed all
        formalities to upload the online version of the Journal on the Eastern
        University website. From Vol. 10 onwards, all interested researchers and
        readers from any corner of the world will have an open access to all
        articles, papers and notes &amp; reviews published in our Journal. Our
        esteemed paper contributors will now have the opportunity of extensive
        citations in the research world.
      </p>
      <p className="allText">
        We solicit original articles, research papers, case studies, and book
        reviews on all areas of business studies engineering and technology,
        live sciences, Law art and social sciences from the researchers,
        academicians and practioners. The contributors are requested to strictly
        follow the “Guidelines for Contributors” provided in a separate section
        in the Journal.
      </p>
      <p className="allText">
        We hope this volume of the Journal will go a long way in bringing about
        the impetus that is required for transmitting and sharing knowledge in a
        multi-disciplinary environment.
      </p>
      <div className="mt-4">
        <p>Prof.Dr.M. Abbas Ali Khan</p>
        <p>Editor</p>
        <p>Eastern University Journal</p>
      </div>
    </JournalLayout>
  );
}

export default JournalEditorMessage;
