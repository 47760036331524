import Footer from "../../component/Footer";

function UseOfTerms() {
  return (
    <div className="page-container">
      <div className="container">
        <h1 className="text-center">Terms and Conditions of Use</h1>

        <p className="heading6">1. Terms</p>

        <p>
          By accessing this Website, accessible from
          https://www.easternuni.edu.bd/, you are agreeing to be bound by these
          Website Terms and Conditions of Use and agree that you are responsible
          for the agreement with any applicable local laws. If you disagree with
          any of these terms, you are prohibited from accessing this site. The
          materials contained in this Website are protected by copyright and
          trade mark law.
        </p>

        <p className="heading6">2. Use License</p>

        <p>
          Permission is granted to temporarily download one copy of the
          materials on Eastern University's Website for personal, non-commercial
          transitory viewing only. This is the grant of a license, not a
          transfer of title, and under this license you may not:
        </p>

        <ul>
          <li>modify or copy the materials;</li>
          <li>
            use the materials for any commercial purpose or for any public
            display;
          </li>
          <li>
            attempt to reverse engineer any software contained on Eastern
            University's Website;
          </li>
          <li>
            remove any copyright or other proprietary notations from the
            materials; or
          </li>
          <li>
            transferring the materials to another person or "mirror" the
            materials on any other server.
          </li>
        </ul>

        <p>
          This will let Eastern University to terminate upon violations of any
          of these restrictions. Upon termination, your viewing right will also
          be terminated and you should destroy any downloaded materials in your
          possession whether it is printed or electronic format. These Terms of
          Service has been created with the help of the{" "}
          <a href="https://www.termsofservicegenerator.net">
            Terms Of Service Generator
          </a>
          .
        </p>

        <p className="heading6">3. Disclaimer</p>

        <p>
          All the materials on Eastern University’s Website are provided "as
          is". Eastern University makes no warranties, may it be expressed or
          implied, therefore negates all other warranties. Furthermore, Eastern
          University does not make any representations concerning the accuracy
          or reliability of the use of the materials on its Website or otherwise
          relating to such materials or any sites linked to this Website.
        </p>

        <p className="heading6">4. Limitations</p>

        <p>
          Eastern University or its suppliers will not be hold accountable for
          any damages that will arise with the use or inability to use the
          materials on Eastern University’s Website, even if Eastern University
          or an authorize representative of this Website has been notified,
          orally or written, of the possibility of such damage. Some
          jurisdiction does not allow limitations on implied warranties or
          limitations of liability for incidental damages, these limitations may
          not apply to you.
        </p>

        <p className="heading6">5. Revisions and Errata</p>

        <p>
          The materials appearing on Eastern University’s Website may include
          technical, typographical, or photographic errors. Eastern University
          will not promise that any of the materials in this Website are
          accurate, complete, or current. Eastern University may change the
          materials contained on its Website at any time without notice. Eastern
          University does not make any commitment to update the materials.
        </p>

        <p className="headin4">6. Links</p>

        <p>
          Eastern University has not reviewed all of the sites linked to its
          Website and is not responsible for the contents of any such linked
          site. The presence of any link does not imply endorsement by Eastern
          University of the site. The use of any linked website is at the user’s
          own risk.
        </p>

        <p className="heading5">7. Site Terms of Use Modifications</p>

        <p>
          Eastern University may revise these Terms of Use for its Website at
          any time without prior notice. By using this Website, you are agreeing
          to be bound by the current version of these Terms and Conditions of
          Use.
        </p>

        <p className="heading6">8. Your Privacy</p>

        <p>Please read our Privacy Policy.</p>

        <p className="heading6">9. Governing Law</p>

        <p>
          Any claim related to Eastern University's Website shall be governed by
          the laws of bd without regards to its conflict of law provisions.
        </p>
      </div>
      <Footer/>
    </div>
  );
}

export default UseOfTerms;
