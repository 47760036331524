import React, { useEffect, useState } from "react";
import axios from "axios";
import { server_django, auth, replace_string } from "../util";
import ReactHtmlParser from "react-html-parser";

const SingleTopNews = (props) => {
  const [singleTopNew, setSingleTopNew] = useState();
  const id = window.location.pathname.split("/").pop();
  const fetchData = async () => {
    await axios
      .get(server_django + "/topnews/" + id, { auth })
      .then((res) => {
        setSingleTopNew(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const NewsList = singleTopNew && (
    <div className="singlePost" key={singleTopNew.id}>
      <div className="card ">
        <div className="card-body">
          <p className="heading2">{singleTopNew.headline}</p>
          {!singleTopNew.detail ? (
            ""
          ) : (
            <p className="allText">{ReactHtmlParser(singleTopNew.detail)}</p>
          )}

          {/* For external Links */}
          {!singleTopNew.link ? (
            ""
          ) : (
            <div className="rowc">
              <p className="allText">
                Registration Link: <a href={singleTopNew.link}>Click Here</a>
              </p>
            </div>
          )}

          {!singleTopNew.picture_location ? (
            ""
          ) : (
            <img
              className="img-fuild"
              src={singleTopNew.picture_location.replace(
                replace_string,
                server_django
              )}
              alt={singleTopNew.headline}
            />
          )}
        </div>
      </div>
    </div>
  );

  return <div>{NewsList}</div>;
};

export default SingleTopNews;
