import React from 'react';
import { NavLink } from 'react-router-dom';

const ResearchSideBar = () => {
    return (
        <div className='OtherpageSideBar'>
            <ul className="list-group list-group-flush">
                <li className="list-group-item">
                    <NavLink className="nav-link" exact to='/CRD'>
                        Centre for Research and Development
                    </NavLink>
                </li>

                {/* <li className="list-group-item">
                    <NavLink exact to='/researchGroupBusiness'>Department of Business Administration</NavLink>

                </li> */}
                <li className="list-group-item">
                    <NavLink exact to='/researchGroupCSE'>Department of Computer Science and Engineering</NavLink>
                </li>
                {/* <li className="list-group-item">
                    <NavLink exact to='/researchGroupEEE'>Department of Electrical and Electronic Engineering</NavLink>
                </li>
                <li className="list-group-item">
                    <NavLink exact to='/researchGroupEnglish'>Department of English</NavLink>
                </li>
                <li className="list-group-item">
                    <NavLink exact to='/researchGroupLawDepartment'>Department of Law</NavLink>
                </li> */}


            </ul>
        </div>
    );
};

export default ResearchSideBar;