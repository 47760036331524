import React, { Suspense, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ReactGa from "react-ga";

import singleNotice from "./SingleNotice";
import singleNews from "./SingleNews";
import singleTopNews from "./singleTopNews";
import singleUpcomingEvents from "./singleUpcomingEvents";
import singlePhotoAlbum from "./SinglePhotoAlbum";
import singleCourseDetails from "../pages/faculty/component/singleCourseDetails";
import history from "../pages/aboutUs/history";
import NotFound from "../pages/NotFound";
import PaymentInstraction from "../pages/OnlinePayment";

import PrivacyPolicy from "../pages/others/PrivacyPolicy";

import { GoogleTrackingID } from "../util";

import facultyMemberDetails from "../pages/faculty/component/facultyMemberDetails";

import career from "../pages/Career";
import loginPage from "../pages/login";

// About Us
import internationalAcademic from "../pages/aboutUs/internationalAcademicCollaboration";
import messageFromChairman from "../pages/aboutUs/messageFromChairman";
import boardOfTrustees from "../pages/aboutUs/boardOfTrustees";
import deansChairperson from "../pages/aboutUs/deansChairperson";
import membersofEUTrust from "../pages/aboutUs/membersofEUTrust";
import examController from "../pages/aboutUs/examController";
import governance from "../pages/aboutUs/governance";
import messageFromVC from "../pages/aboutUs/messageFromVC";
import academicCouncil from "../pages/aboutUs/academicCouncil";
import syndicate from "../pages/aboutUs/syndicate";
import treasurer from "../pages/aboutUs/treasurer";
import registrar from "../pages/aboutUs/registrar";
import Offices from "../pages/aboutUs/Offices";
import proctor from "../pages/aboutUs/proctor";
import accreditation from "../pages/aboutUs/accreditation";

import aboutEU from "../pages/aboutUs/aboutEU";
import formerChairmen from "../pages/aboutUs/formerChairman";
import allOtherOffices from "../pages/aboutUs/allOtherOffices";
import administration from "../pages/aboutUs/administration";
import otherCommittees from "../pages/aboutUs/otherCommittees";

// Academic Page
import academicRulesAndProcedure from "../pages/academic/academicRulesAndProcedures";
import academicStandingOfAStudent from "../pages/academic/academicStandingOfAStudent";
import codeOfConduct from "../pages/academic/codeOfConduct";
import testsAndExams from "../pages/academic/testsAndExams";
import rulesOfClassAttendance from "../pages/academic/rulesOfClassAttendance";
import onlinePaymentProcedure from "../pages/academic/onlinePaymentProcedure";
import registrationProcedure from "../pages/academic/registrationProcedure";
import degreeRequirments from "../pages/academic/degreeRequirments";

//IQAC
import iqacHome from "../pages/academic/IQAC/home";
import iqacMembers from "../pages/academic/IQAC/iqacMembers";
import iqacGoals from "../pages/academic/IQAC/iqacGoals";
import iqacResources from "../pages/academic/IQAC/iqacResources";
import iqacEventSchedule from "../pages/academic/IQAC/iqacEventSchedule";
import iqacPresentations from "../pages/academic/IQAC/iqacPresentations";
import iqacDocuments from "../pages/academic/IQAC/iqacDocuments";
import iqacPictureGallery from "../pages/academic/IQAC/iqacPictureGallery";
import iqacOffice from "../pages/academic/IQAC/iqacOffice";

// Admission page
import financialAidAndScholarship from "../pages/Admission/financialAidAndScholarship";
import admissionInformation from "../pages/Admission/admissionInformation";
import whyEasternUni from "../pages/Admission/whyEasternUni";
import admissionEligibility from "../pages/Admission/admissionEligibility";
import internationalStudents from "../pages/Admission/internationalStudents";
import feeStructureForForeignStudents from "../pages/Admission/feeStructureForForeignStudents";
import tutionFees from "../pages/Admission/tutionFees";
import euAdmissionForm from "../pages/Admission/euAdmissionForm";
import transferStudents from "../pages/Admission/transferStudents";
import creditTransferForm from "../pages/Admission/creditTransferForm";
import howToApply from "../pages/Admission/howToApply";
import admissionRequirements from "../pages/Admission/admissionRequirements";
import applyOnline from "../pages/Admission/applyOnline";
import paymentInformation from "../pages/Admission/paymentInformation";
import paymentProcedure from "../pages/Admission/paymentProcedure";
import undergraduateAdmissionEligibility from "../pages/Admission/undergraduateAdmissionEligibility";
import graduateAdmissionEligibility from "../pages/Admission/graduateAdmissionEligibility";
import underGraduateProgram from "../pages/Admission/underGraduateProgram";
import graduateProgram from "../pages/Admission/graduateProgram";
import AdmissionContact from "../pages/Admission/AdmissionContact";

//clubs
import AllClubsList from "../pages/campus/Clubs/AllClubsList";
import ClubHome from "../pages/campus/Clubs/ClubHome";

// campus
import careerServie from "../pages/campus/CSIO/careerServie";
import internationalOffice from "../pages/campus/CSIO/internationalOffice";
import psychoSocialCounselling from "../pages/campus/psychoSocialCounselling";
import hostelFacilities from "../pages/campus/hostelFacilities";
import transportFacilities from "../pages/campus/transportFacilities";
import studentactivities from "../pages/campus/Student Activities/studentactivities";
import aboutosa from "../pages/campus/Student Activities/aboutosa";
import coreValues from "../pages/campus/Student Activities/coreValues";

// Research
import CentreForResearchAndDevelopment from "../pages/research/CentreForResearchAndDevelopment";
import Journal from "../pages/research/Journal";
import Issues from "../pages/research/Issues";
import Issue from "../pages/research/Issue";
import Article from "../pages/research/Article";

// News and Updates
import news from "../pages/newsAndUpdates/News";
import upcomingEventsAll from "../pages/newsAndUpdates/UpcomingEvents";
import noticeAll from "../pages/newsAndUpdates/Notices";
import events from "../pages/newsAndUpdates/Events";

//Contact us
import ContactOffice from "../pages/contact/ContactOffice";
import contactDirectories from "../pages/contact/contactDirectories";
import quiz from "../pages/faculty/quiz/App";

// Faculty of Arts pages
//import facultyOfArtsHome from "../pages/faculty/facultyOfArts/facultyOfArts";
import deanMessageArts from "../pages/faculty/facultyOfArts/deansMessageArts";
import chairpersonMessageArts from "../pages/faculty/facultyOfArts/chairpersonMessageArts";
import programsBAEnglish from "../pages/faculty/facultyOfArts/programsBAEnglish";
import programsMAEnglish from "../pages/faculty/facultyOfArts/programsMAEnglish";
import admissionEnglish from "../pages/faculty/facultyOfArts/admissionEnglish";
import tutionFeesAndFinalcialAidsArts from "../pages/faculty/facultyOfArts/tutionFeesAndFinalcialAidsArts";
import researchHighlightsArts from "../pages/faculty/facultyOfArts/researchHighlightsArts";
import researchEventsArts from "../pages/faculty/facultyOfArts/researchEventsArts";
import researchInEnglish from "../pages/faculty/facultyOfArts/researchInEnglish";
import newsArts from "../pages/faculty/facultyOfArts/newsArts";
import noticeArts from "../pages/faculty/facultyOfArts/noticeArts";
import contactUsArts from "../pages/faculty/facultyOfArts/contactUsArts";

// Faculty of Engineering pages

import deanMessageEngineering from "../pages/faculty/facultyOfE&T/deanMessageEandT";
import messageFromCSEChairperson from "../pages/faculty/facultyOfE&T/messageFromCSEChairperson";
import messageFromEEEChairperson from "../pages/faculty/facultyOfE&T/messageFromEEEChairperson";
import bscinCSEProgram from "../pages/faculty/facultyOfE&T/bscinCSEProgram";
import bscinEEEProgram from "../pages/faculty/facultyOfE&T/bscinEEEProgram";
import admissionCSE from "../pages/faculty/facultyOfE&T/admissionCSE";
import admissionEEE from "../pages/faculty/facultyOfE&T/admissionEEE";
import tutionFeesAndFinalcialAidsEngineering from "../pages/faculty/facultyOfE&T/tutionFeesAndFinalcialAidsEngineering";
import researchHighlightsEngineering from "../pages/faculty/facultyOfE&T/researchHighlightsEngineering";
import researchEventsEngineering from "../pages/faculty/facultyOfE&T/researchEventsEngineering";
import researchInCSE from "../pages/faculty/facultyOfE&T/researchInCSE";
import researchInEEE from "../pages/faculty/facultyOfE&T/researchInEEE";
import newsEngineering from "../pages/faculty/facultyOfE&T/newsEngineering";
import noticeEngineering from "../pages/faculty/facultyOfE&T/noticeEngineering";
import contactUsEngineering from "../pages/faculty/facultyOfE&T/contactUsEngineering";

// Faculty of Business Administration pages

import deanMessageBusiness from "../pages/faculty/facultyOfBusinessAdministration/deanMessageBusiness";
import chairpersonMessageBusiness from "../pages/faculty/facultyOfBusinessAdministration/chairpersonMessageBusiness";
import programBBA from "../pages/faculty/facultyOfBusinessAdministration/programBBA";
import programMBARegular from "../pages/faculty/facultyOfBusinessAdministration/programMBARegular";
import programMBAExecutive from "../pages/faculty/facultyOfBusinessAdministration/programMBAExecutive";
import admissionBBA from "../pages/faculty/facultyOfBusinessAdministration/admissionBBA";
import admissionMBA from "../pages/faculty/facultyOfBusinessAdministration/admissionMBA";
import tutionFeesAndFinalcialAidsBusiness from "../pages/faculty/facultyOfBusinessAdministration/tutionFeesAndFinalcialAidsBusiness";
import researchHighlightsBusiness from "../pages/faculty/facultyOfBusinessAdministration/researchHighlightsBusiness";
import researchEventsBusiness from "../pages/faculty/facultyOfBusinessAdministration/researchEventsBusiness";
import researchInBusiness from "../pages/faculty/facultyOfBusinessAdministration/researchInBusiness";
import newsBusinessFaculty from "../pages/faculty/facultyOfBusinessAdministration/newsBusinessFaculty";
import noticeBusinessFaculty from "../pages/faculty/facultyOfBusinessAdministration/noticeBusinessFaculty";
import contactUsBusinessFaculty from "../pages/faculty/facultyOfBusinessAdministration/contactUsBusinessFaculty";
// Faculty of Law

import deanMessageLaw from "../pages/faculty/facultyOfLaw/deanMessageLaw";
import chairpersonMessageLaw from "../pages/faculty/facultyOfLaw/chairpersonMessageLaw";
import programLLB from "../pages/faculty/facultyOfLaw/programLLB";
import programLLM from "../pages/faculty/facultyOfLaw/programLLM";
import admissionLawFaculty from "../pages/faculty/facultyOfLaw/admissionLawFaculty";
import tutionFeesAndFinancialAidsLaw from "../pages/faculty/facultyOfLaw/tutionFeesAndFinancialAidsLaw";
import researchHighlightsLaw from "../pages/faculty/facultyOfLaw/researchHighlightsLaw";
import researchEventsLaw from "../pages/faculty/facultyOfLaw/researchEventsLaw";
import researchInLaw from "../pages/faculty/facultyOfLaw/researchInLaw";
import newsLaw from "../pages/faculty/facultyOfLaw/newsLaw";
import noticeLaw from "../pages/faculty/facultyOfLaw/noticeLaw";
import contactUsLaw from "../pages/faculty/facultyOfLaw/contactUsLaw";

// Department of English

import welcomeMessageEnglish from "../pages/faculty/departments/departmentOfEnglish/welcomeMessageEnglish";
import exploringEnglish from "../pages/faculty/departments/departmentOfEnglish/exploringEnglish";
import clubInformationEnglish from "../pages/faculty/departments/departmentOfEnglish/clubInformationEnglish";
import programsBAEnglishDepartment from "../pages/faculty/departments/departmentOfEnglish/programsBAEnglishDepartment";
import programsMAEnglishDepartment from "../pages/faculty/departments/departmentOfEnglish/programsMAEnglishDepartment";
import otherProgramsEnglish from "../pages/faculty/departments/departmentOfEnglish/otherProgramsEnglish";
import underGraduateAdmissionEnglish from "../pages/faculty/departments/departmentOfEnglish/underGraduateAdmissionEnglish";
import graduateAdmissionEnglish from "../pages/faculty/departments/departmentOfEnglish/graduateAdmissionEnglish";
import underGraduateCurriculumEnglish from "../pages/faculty/departments/departmentOfEnglish/underGraduateCurriculumEnglish";
import graduateCurriculumEnglish from "../pages/faculty/departments/departmentOfEnglish/graduateCurriculumEnglish";
import atAGlanceFacultyMembersEnglish from "../pages/faculty/departments/departmentOfEnglish/atAGlanceFacultyMembersEnglish";
import currentFacultyMembersEnglish from "../pages/faculty/departments/departmentOfEnglish/currentFacultyMembersEnglish";
import currentAdjunctFacultyMembersEnglish from "../pages/faculty/departments/departmentOfEnglish/currentAdjunctFacultyMembersEnglish";
import onLeaveFacultyMembersEnglish from "../pages/faculty/departments/departmentOfEnglish/onLeaveFacultyMembersEnglish";
import formarFacultyMembersEnglish from "../pages/faculty/departments/departmentOfEnglish/formarFacultyMembersEnglish";
import officialsEnglish from "../pages/faculty/departments/departmentOfEnglish/officialsEnglish";
import labFacilitiesEnglish from "../pages/faculty/departments/departmentOfEnglish/labFacilitiesEnglish";
import publicationsEnglish from "../pages/faculty/departments/departmentOfEnglish/publicationsEnglish";
import researchGroupEnglish from "../pages/faculty/departments/departmentOfEnglish/researchGroupEnglish";
import newsEnglish from "../pages/faculty/departments/departmentOfEnglish/newsEnglish";
import upcomingEventsEnglish from "../pages/faculty/departments/departmentOfEnglish/upcomingEventsEnglish";
import archiveEnglish from "../pages/faculty/departments/departmentOfEnglish/archiveEnglish";
import noticeEnglish from "../pages/faculty/departments/departmentOfEnglish/noticeEnglish";
import alumniEnglish from "../pages/faculty/departments/departmentOfEnglish/alumniEnglish";
import contuctUsEnglish from "../pages/faculty/departments/departmentOfEnglish/contuctUsEnglish";

// Department of Computer Science

import welcomeMessageCSE from "../pages/faculty/departments/departmentOfCSE/welcomeMessageCSE";
import exploringCSE from "../pages/faculty/departments/departmentOfCSE/exploringCSE";
import clubInformationCSE from "../pages/faculty/departments/departmentOfCSE/clubInformationCSE";
import bscinCSEDepartmentProgram from "../pages/faculty/departments/departmentOfCSE/bscinCSEDepartmentProgram";
import otherProgramCSE from "../pages/faculty/departments/departmentOfCSE/otherProgramCSE";
import admissionCSEDepartment from "../pages/faculty/departments/departmentOfCSE/admissionCSEDepartment";
import underGraduateCurriculumCSE from "../pages/faculty/departments/departmentOfCSE/underGraduateCurriculumCSE";
import contactUsCSE from "../pages/faculty/departments/departmentOfCSE/contactUsCSE";
import atAGlanceFacultyMembersCSE from "../pages/faculty/departments/departmentOfCSE/atAGlanceFacultyMembersCSE";
import currentFacultyMembersCSE from "../pages/faculty/departments/departmentOfCSE/currentFacultyMembersCSE";
import adjunctCurrentFacultyMembersCSE from "../pages/faculty/departments/departmentOfCSE/adjunctCurrentFacultyMembersCSE";
import onLeaveFacultyMembersCSE from "../pages/faculty/departments/departmentOfCSE/onLeaveFacultyMembersCSE";
import formerFacultyMembersCSE from "../pages/faculty/departments/departmentOfCSE/formerFacultyMembersCSE";
import officialsCSE from "../pages/faculty/departments/departmentOfCSE/officialsCSE";
import labFacilitiesCSE from "../pages/faculty/departments/departmentOfCSE/labFacilitiesCSE";
import publicationsCSE from "../pages/faculty/departments/departmentOfCSE/publicationsCSE";
import researchGroupCSE from "../pages/faculty/departments/departmentOfCSE/researchGroupCSE";
import newsCSE from "../pages/faculty/departments/departmentOfCSE/newsCSE";
import upcomingEventsCSE from "../pages/faculty/departments/departmentOfCSE/upcomingEventsCSE";
import archiveCSE from "../pages/faculty/departments/departmentOfCSE/archiveCSE";
import noticesCSE from "../pages/faculty/departments/departmentOfCSE/noticesCSE";
import alumniCSE from "../pages/faculty/departments/departmentOfCSE/alumniCSE";

// Department of EEE

import welcomeMessageEEE from "../pages/faculty/departments/departmentOfEEE/welcomeMessageEEE";
import exploringEEE from "../pages/faculty/departments/departmentOfEEE/exploringEEE";
import clubInformationEEE from "../pages/faculty/departments/departmentOfEEE/clubInformationEEE";
import bscinEEEDepartmentProgram from "../pages/faculty/departments/departmentOfEEE/bscinEEEDepartmentProgram";
import otherProgramsEEE from "../pages/faculty/departments/departmentOfEEE/otherProgramsEEE";
import admissionEEEDepartment from "../pages/faculty/departments/departmentOfEEE/admissionEEEDepartment";
import underGraduateCurriculumEEE from "../pages/faculty/departments/departmentOfEEE/underGraduateCurriculumEEE";
import atAGlanceFacultyMembersEEE from "../pages/faculty/departments/departmentOfEEE/atAGlanceFacultyMembersEEE";
import currentFacultyMembersEEE from "../pages/faculty/departments/departmentOfEEE/currentFacultyMembersEEE";
import adjunctCurrentFacultyMembersEEE from "../pages/faculty/departments/departmentOfEEE/adjunctCurrentFacultyMembersEEE";
import onLeaveFacultyMembersEEE from "../pages/faculty/departments/departmentOfEEE/onLeaveFacultyMembersEEE";
import formerFacultyMembersEEE from "../pages/faculty/departments/departmentOfEEE/formerFacultyMembersEEE";
import officialsEEE from "../pages/faculty/departments/departmentOfEEE/officialsEEE";
import labFacilitiesEEE from "../pages/faculty/departments/departmentOfEEE/labFacilitiesEEE";
import publicationsEEE from "../pages/faculty/departments/departmentOfEEE/publicationsEEE";
import researchGroupEEE from "../pages/faculty/departments/departmentOfEEE/researchGroupEEE";
import newsEEE from "../pages/faculty/departments/departmentOfEEE/newsEEE";
import upcomingEvents from "../pages/faculty/departments/departmentOfEEE/upcomingEvents";
import archiveEEE from "../pages/faculty/departments/departmentOfEEE/archiveEEE";
import noticesEEE from "../pages/faculty/departments/departmentOfEEE/noticesEEE";
import alumniEEE from "../pages/faculty/departments/departmentOfEEE/alumniEEE";
import contactUsEEE from "../pages/faculty/departments/departmentOfEEE/contactUsEEE";
import missionVision from "../pages/faculty/departments/departmentOfEEE/missionandvission";

// Department of Business Administration

import welcomeMessageBusinessDepartment from "../pages/faculty/departments/departmentOfBusinessAdministration/welcomeMessageBusinessDepartment";
import exploringBusinessDepartment from "../pages/faculty/departments/departmentOfBusinessAdministration/exploringBusinessDepartment";
import clubInformationBusinessDepartment from "../pages/faculty/departments/departmentOfBusinessAdministration/clubInformationBusinessDepartment";
import programsBBADepartment from "../pages/faculty/departments/departmentOfBusinessAdministration/programsBBADepartment";
import programsRegularMBADepartment from "../pages/faculty/departments/departmentOfBusinessAdministration/programsRegularMBADepartment";
import programsExecutiveMBADepartment from "../pages/faculty/departments/departmentOfBusinessAdministration/programsExecutiveMBADepartment";
import otherProgramsBusinessDepartment from "../pages/faculty/departments/departmentOfBusinessAdministration/otherProgramsBusinessDepartment";
import underGraduateAdmissionBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/underGraduateAdmissionBusiness";
import graduateAdmissionBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/graduateAdmissionBusiness";
import underGraduateCurriculumBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/underGraduateCurriculumBusiness";
import graduateCurriculumBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/graduateCurriculumBusiness";
import atAGlanceFacultyMembersBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/atAGlanceFacultyMembersBusiness";
import currentFacultyMembersBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/currentFacultyMembersBusiness";
import currentAdjunctFacultyMembersBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/currentAdjunctFacultyMembersBusiness";
import onLeaveFacultyMembersBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/onLeaveFacultyMembersBusiness";
import formarFacultyMembersBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/formarFacultyMembersBusiness";
import officialsBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/officialsBusiness";
import labFacilitiesBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/labFacilitiesBusiness";
import publicationsBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/publicationsBusiness";
import researchGroupBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/researchGroupBusiness";
import newsBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/newsBusiness";
import upcomingEventsBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/upcomingEventsBusiness";
import archiveBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/archiveBusiness";
import noticeBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/noticeBusiness";
import alumniBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/alumniBusiness";
import contuctUsBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/contuctUsBusiness";

//Department of Law

import welcomeMessageLawDepartment from "../pages/faculty/departments/departmentOfLaw/welcomeMessageLawDepartment";
import exploringLawDepartment from "../pages/faculty/departments/departmentOfLaw/exploringLawDepartment";
import clubInformationLawDepartment from "../pages/faculty/departments/departmentOfLaw/clubInformationLawDepartment";
import programsLLBLawDepartment from "../pages/faculty/departments/departmentOfLaw/programsLLBLawDepartment";
import programsLLMLawDepartment from "../pages/faculty/departments/departmentOfLaw/programsLLMLawDepartment";
import otherProgramsLawDepartment from "../pages/faculty/departments/departmentOfLaw/otherProgramsLawDepartment";
import underGraduateAdmissionLawDepartment from "../pages/faculty/departments/departmentOfLaw/underGraduateAdmissionLawDepartment";
import graduateAdmissionLawDepartment from "../pages/faculty/departments/departmentOfLaw/graduateAdmissionLawDepartment";
import underGraduateCurriculumLawDepartment from "../pages/faculty/departments/departmentOfLaw/underGraduateCurriculumLawDepartment";
import graduateCurriculumLawDepartment from "../pages/faculty/departments/departmentOfLaw/graduateCurriculumLawDepartment";
import atAGlanceFacultyMembersLawDepartment from "../pages/faculty/departments/departmentOfLaw/atAGlanceFacultyMembersLawDepartment";
import currentFacultyMembersLawDepartment from "../pages/faculty/departments/departmentOfLaw/currentFacultyMembersLawDepartment";
import currentAdjunctFacultyMembersLawDepartment from "../pages/faculty/departments/departmentOfLaw/currentAdjunctFacultyMembersLawDepartment";
import onLeaveFacultyMembersLawDepartment from "../pages/faculty/departments/departmentOfLaw/onLeaveFacultyMembersLawDepartment";
import formarFacultyMembersLawDepartment from "../pages/faculty/departments/departmentOfLaw/formarFacultyMembersLawDepartment";
import officialsLawDepartment from "../pages/faculty/departments/departmentOfLaw/officialsLawDepartment";
import labFacilitiesLawDepartment from "../pages/faculty/departments/departmentOfLaw/labFacilitiesLawDepartment";
import publicationsLawDepartment from "../pages/faculty/departments/departmentOfLaw/publicationsLawDepartment";
import researchGroupLawDepartment from "../pages/faculty/departments/departmentOfLaw/researchGroupLawDepartment";
import newsLawDepartment from "../pages/faculty/departments/departmentOfLaw/newsLawDepartment";
import upcomingEventsLawDepartment from "../pages/faculty/departments/departmentOfLaw/upcomingEventsLawDepartment";
import archiveLawDepartment from "../pages/faculty/departments/departmentOfLaw/archiveLawDepartment";
import noticeLawDepartment from "../pages/faculty/departments/departmentOfLaw/noticeLawDepartment";
import alumniLawDepartment from "../pages/faculty/departments/departmentOfLaw/alumniLawDepartment";
import contuctUsLawDepartment from "../pages/faculty/departments/departmentOfLaw/contuctUsLawDepartment";
import UseOfTerms from "../pages/others/UseOfTerms";

//Department of Civil

import { WelcomeMessageCivil } from "../pages/faculty/departments/departmentOfCivil/welcomeMessageCivil";
import { ExploringCivil } from "../pages/faculty/departments/departmentOfCivil/exploringCivil";
import { ClubInformationCivil } from "../pages/faculty/departments/departmentOfCivil/clubInformationCivil";
import BscInCivil from "../pages/faculty/departments/departmentOfCivil/bscInCivil";
import AdmissionCivilDepartment from "../pages/faculty/departments/departmentOfCivil/admissionCivilDepartment";
import AtAGlanceFacultyMembersCivil from "../pages/faculty/departments/departmentOfCivil/atAGlanceFacultyMembersCivil";
import CurrentFacultyMemberCivil from "../pages/faculty/departments/departmentOfCivil/currentFacultyMemberCivil";
import LoadingBox from "./LoadingBox";

//Department of Pharmacy
import { WelcomeMessagePharmacy } from "../pages/faculty/departments/departmentOfPharmacy/welcomeMessagePharmacy";
import AdmissionPharmacyDepartment from "../pages/faculty/departments/departmentOfPharmacy/admissionPharmacyDepartment";
import BachelorOfPharmacy from "../pages/faculty/departments/departmentOfPharmacy/bachelorOfPharmacy";
import LabFacilitiesPharmacy from "../pages/faculty/departments/departmentOfPharmacy/labFacilitiesPharmacy";
import ClubInformationPharmacy from "../pages/faculty/departments/departmentOfPharmacy/clubInformationPharmacy";
import AtAGlanceFacultyMembersPharmacy from "../pages/faculty/departments/departmentOfPharmacy/atAGlanceFacultyMembersPharmacy";
import CurrentFacultyMemberPharmacy from "../pages/faculty/departments/departmentOfPharmacy/currentFacultyMemberPharmacy";
import OnLeaveFacultyMembersPharmacy from "../pages/faculty/departments/departmentOfPharmacy/OnLeaveFacultyMembersPhamacy";
import AdmissionProcessForNative from "../pages/Admission/AdmissionProcessForNative";
import AdmissionProcessForInternational from "../pages/Admission/AdmissionProcessForInternational";
import AdmissionRequiredDocuments from "../pages/Admission/admissionRequiredDocuments";
import CreditTransferFacilities from "../pages/Admission/CreditTransferFacilities";
import OffcialsCivil from "../pages/faculty/departments/departmentOfCivil/officialsCivil";
import OfficialsPharmacy from "../pages/faculty/departments/departmentOfPharmacy/officialsPharmacy";
import AdjunctFacultyMembersPharmacy from "../pages/faculty/departments/departmentOfPharmacy/adjunctFacultyMembersPharmacy";
import NewsPharmacy from "../pages/faculty/departments/departmentOfPharmacy/newsPharmacy";
import NoticesPharmacy from "../pages/faculty/departments/departmentOfPharmacy/noticePharmacy";
import ProgramBBAIslamicFinance from "../pages/faculty/departments/departmentOfBusinessAdministration/programBBAIslamicFinance";
import ProgramMBAIslamicFinance from "../pages/faculty/departments/departmentOfBusinessAdministration/ProgramMBAIslamicFinance";
import ProgramBBAIslam from "../pages/faculty/facultyOfBusinessAdministration/programBBAIslam";
import ProgramMBAIslam from "../pages/faculty/facultyOfBusinessAdministration/ProgramMBAIslam";
import ConferenceBBA from "../pages/ConferenceBBA";
import ConferenceLaw from "../pages/ConferenceLaw";
import Conference from "../pages/Conference";
import UnderGraduateCurriculumPharma from "../pages/faculty/departments/departmentOfPharmacy/underGraduateCurriculumPharma";
import UnderGraduateCurriculumCivil from "../pages/faculty/departments/departmentOfCivil/underGraduateCurriculumCivil";
import JournalLayout from "../pages/research/JournalLayout";
import JournalAbout from "../pages/research/JournalAbout";
import JournalGuidLines from "../pages/research/JournalGuidLines";
import JournalEditorMessage from "../pages/research/JournalEditorMessage";
import JournalEditorialBoard from "../pages/research/JournalEditorialBoard";
import JournalAdvisoryBoard from "../pages/research/JournalAdvisoryBoard";
import AnnualReport from "../pages/faculty/departments/departmentOfCivil/annualReport";
import MissionandVissionBusiness from "../pages/faculty/departments/departmentOfBusinessAdministration/missionandvission";
import MissionAndVissionCSE from "../pages/faculty/departments/departmentOfCSE/missionAndVissionCSE";

// Faculty of Life Science

const faucltyOfLifeScience = React.lazy(() =>
  import("../pages/faculty/facultyOfLifeScience/facultyOfLifeScience")
);

const home = React.lazy(() => import("../pages/Home"));

const facultyOfArtsHome = React.lazy(() =>
  import("../pages/faculty/facultyOfArts/facultyOfArts")
);

const facultyOfEngineering = React.lazy(() =>
  import("../pages/faculty/facultyOfE&T/facultyOfEngineering")
);

const facultyOfBusinessAdministration = React.lazy(() =>
  import(
    "../pages/faculty/facultyOfBusinessAdministration/facultyOfBusinessAdministration"
  )
);

const facultyOfLaw = React.lazy(() =>
  import("../pages/faculty/facultyOfLaw/facultyOfLaw")
);

const departmentOfEnglish = React.lazy(() =>
  import("../pages/faculty/departments/departmentOfEnglish/departmentOfEnglish")
);

const departmentOfCSE = React.lazy(() =>
  import("../pages/faculty/departments/departmentOfCSE/departmentOfCSE")
);

const departmentOfEEE = React.lazy(() =>
  import("../pages/faculty/departments/departmentOfEEE/departmentOfEEE")
);

const DepartmentOfCivil = React.lazy(() =>
  import("../pages/faculty/departments/departmentOfCivil/departmentOfCivil")
);

const departmentOfBus = React.lazy(() =>
  import(
    "../pages/faculty/departments/departmentOfBusinessAdministration/departmentOfBusinessAdministration"
  )
);

const DepartmentOfPharmacy = React.lazy(() =>
  import(
    "../pages/faculty/departments/departmentOfPharmacy/departmentOfPharmacy"
  )
);

const departmentOfLaw = React.lazy(() =>
  import("../pages/faculty/departments/departmentOfLaw/departmentOfLaw")
);

function MyRoute() {
  useEffect(() => {
    ReactGa.initialize(GoogleTrackingID);

    ReactGa.pageview(window.location.pathname);
  }, []);
  return (
    <Suspense
      fallback={
        <div className="text-center">
          <LoadingBox />
        </div>
      }
    >
      <Switch>
        <Route exact path="/" component={home} />
        <Route exact path="/login" component={loginPage} />
        <Route exact path="/career" component={career}></Route>
        <Route exact path="/notice/:notice_id" component={singleNotice}></Route>
        <Route exact path="/news/:news_id" component={singleNews}></Route>
        <Route
          exact
          path="/upcomingEvents/:upcomingEvents_id"
          component={singleUpcomingEvents}
        ></Route>
        <Route
          exact
          path="/topnews/:topNews_id"
          component={singleTopNews}
        ></Route>
        <Route
          exact
          path="/facultyMemberDetails/:serial_id"
          component={facultyMemberDetails}
        ></Route>
        <Route exact path="/topphotoalbum/:id" component={singlePhotoAlbum} />
        <Route
          exact
          path="/courseDetails/:CourseID"
          component={singleCourseDetails}
        />
        {/* About Us */}
        <Route exact path="/history" component={history} />
        <Route exact path="/aboutEU" component={aboutEU} />
        <Route
          exact
          path="/internationalAcademicCollaboration"
          component={internationalAcademic}
        />
        <Route
          exact
          path="/messageFromChairman"
          component={messageFromChairman}
        />
        <Route exact path="/deanschairperson" component={deansChairperson} />
        <Route exact path="/boardOfTrustees" component={boardOfTrustees} />
        <Route
          exact
          path="/membersofEUTrust"
          component={membersofEUTrust}
        ></Route>
        <Route exact path="/examController" component={examController}></Route>
        <Route exact path="/governance" component={governance}></Route>
        <Route exact path="/messageFromVC" component={messageFromVC}></Route>
        <Route
          exact
          path="/academicCouncil"
          component={academicCouncil}
        ></Route>
        <Route exact path="/syndicate" component={syndicate}></Route>
        <Route exact path="/treasurer" component={treasurer}></Route>
        {/* <Route exact path='/registrar' component={registrar}></Route> */}
        <Route exact path="/offices" component={Offices}></Route>
        <Route exact path="/proctor" component={proctor}></Route>
        <Route exact path="/accreditation" component={accreditation}></Route>
        <Route exact path="/formerChairmen" component={formerChairmen}></Route>
        <Route
          exact
          path="/allOtherOffices"
          component={allOtherOffices}
        ></Route>
        <Route exact path="/administration" component={administration}></Route>
        <Route
          exact
          path="/otherCommittees"
          component={otherCommittees}
        ></Route>
        {/* Academic */}
        <Route
          exact
          path="/academicRulesAndProcedures"
          component={academicRulesAndProcedure}
        ></Route>
        <Route
          exact
          path="/academicStandingOfAStudent"
          component={academicStandingOfAStudent}
        ></Route>
        <Route exact path="/codeOfConduct" component={codeOfConduct}></Route>
        <Route exact path="/testsAndExams" component={testsAndExams}></Route>
        <Route
          exact
          path="/rulesOfClassAttendance"
          component={rulesOfClassAttendance}
        ></Route>
        <Route
          exact
          path="/onlinePaymentProcedure"
          component={onlinePaymentProcedure}
        ></Route>
        <Route
          exact
          path="/registrationProcedure"
          component={registrationProcedure}
        ></Route>
        <Route
          path="/academicCalender"
          component={() => {
            window.location.href =
              "http://webportal.easternuni.edu.bd/Calender/_AcademicCalendarSearch.aspx";
            return null;
          }}
        />
        {/* IQAC */}
        <Route exact path="/iqac" component={iqacHome}></Route>
        <Route exact path="/iqac" component={iqacHome}></Route>
        <Route exact path="/iqacMembers" component={iqacMembers}></Route>
        <Route exact path="/iqacGoals" component={iqacGoals}></Route>
        <Route exact path="/iqacResources" component={iqacResources}></Route>
        <Route
          exact
          path="/iqacEventSchedule"
          component={iqacEventSchedule}
        ></Route>
        <Route
          exact
          path="/iqacPresentations"
          component={iqacPresentations}
        ></Route>
        <Route exact path="/iqacDocuments" component={iqacDocuments}></Route>
        <Route
          exact
          path="/iqacPictureGallery"
          component={iqacPictureGallery}
        ></Route>
        <Route exact path="/iqacOffice" component={iqacOffice}></Route>
        <Route
          exact
          path="/degreeRequirments"
          component={degreeRequirments}
        ></Route>
        <Route
          path="/Ugcgovbd"
          component={() => {
            window.location.href = "http://www.ugc.gov.bd/en";
            return null;
          }}
        ></Route>
        <Route
          path="/heqepugcgovbd"
          component={() => {
            window.location.href = "http://www.heqep-ugc.gov.bd/";
            return null;
          }}
        ></Route>
        <Route
          path="/wwwmoedugovbdindexphp"
          component={() => {
            window.location.href = "http://www.moedu.gov.bd/index.php?";
            return null;
          }}
        ></Route>
        {/* Admission */}
        <Route
          exact
          path="/financialAidAndScholarship"
          component={financialAidAndScholarship}
        ></Route>
        <Route
          exact
          path="/admissionInformation"
          component={admissionInformation}
        ></Route>
        <Route exact path="/whyEasternUni" component={whyEasternUni}></Route>
        <Route
          exact
          path="/admissionEligibility"
          component={admissionEligibility}
        ></Route>
        <Route
          exact
          path="/internationalStudents"
          component={internationalStudents}
        ></Route>
        <Route
          exact
          path="/feeStructureForForeignStudents"
          component={feeStructureForForeignStudents}
        ></Route>
        <Route exact path="/tuitionFees" component={tutionFees}></Route>
        <Route
          exact
          path="/euAdmissionForm"
          component={euAdmissionForm}
        ></Route>
        <Route
          exact
          path="/transferStudents"
          component={transferStudents}
        ></Route>
        <Route
          exact
          path="/creditTransferForm"
          component={creditTransferForm}
        ></Route>
        <Route exact path="/howToApply" component={howToApply}></Route>
        <Route
          exact
          path="/admissionRequirements"
          component={admissionRequirements}
        ></Route>
        <Route exact path="/applyOnline" component={applyOnline}></Route>
        <Route
          exact
          path="/paymentInformation"
          component={paymentInformation}
        ></Route>
        <Route
          exact
          path="/paymentProcedure"
          component={paymentProcedure}
        ></Route>
        <Route
          exact
          path="/undergraduateAdmissionEligibility"
          component={undergraduateAdmissionEligibility}
        ></Route>
        <Route
          exact
          path="/graduateAdmissionEligibility"
          component={graduateAdmissionEligibility}
        ></Route>
        <Route
          exact
          path="/underGraduateProgram"
          component={underGraduateProgram}
        ></Route>
        <Route
          exact
          path="/graduateProgram"
          component={graduateProgram}
        ></Route>
        <Route
          path="/onlineAdmissionForm"
          component={() => {
            window.location.href =
              "https://webportal.easternuni.edu.bd/onlineadmission/index.aspx";
            return null;
          }}
        />
        <Route
          path="/OnlineAdmission"
          component={() => {
            window.location.href =
              "https://webportal.easternuni.edu.bd/onlineadmission/Admission.aspx";
            return null;
          }}
        ></Route>
        <Route path="/AdmissionContact" component={AdmissionContact} />
        <Route
          path="/admissionProcessForNativeStudents"
          component={AdmissionProcessForNative}
        />
        <Route
          path="/admissionProcessForInternationalStudents"
          component={AdmissionProcessForInternational}
        />
        <Route
          path="/admissionRequiredDocuments"
          component={AdmissionRequiredDocuments}
        />
        <Route
          path="/creditTransferFacilities"
          component={CreditTransferFacilities}
        />
        {/* Research */}
        <Route
          path="/Research"
          component={() => {
            window.location.href =
              "http://dspace.easternuni.edu.bd:8080/xmlui/";
            return null;
          }}
        ></Route>
        <Route exact path="/CRD" component={CentreForResearchAndDevelopment} />
        <Route exact path="/journal/about" component={JournalAbout} />
        <Route exact path="/journal/guidelines" component={JournalGuidLines} />
        <Route
          exact
          path="/journal/editor-message"
          component={JournalEditorMessage}
        />
        <Route
          exact
          path="/journal/editorial-board"
          component={JournalEditorialBoard}
        />
        <Route
          exact
          path="/journal/advisory-board"
          component={JournalAdvisoryBoard}
        />
        <Route exact path="/issues" component={Issues} />
        <Route exact path="/issue/:id" component={Issue} />
        <Route exact path="/article/:id" component={Article} />
        {/* Campus */}
        <Route
          path="/facebook"
          component={() => {
            window.location.href = "https://www.facebook.com/euedubd";
            return null;
          }}
        />
        <Route
          path="/youtube"
          component={() => {
            window.location.href =
              "https://www.youtube.com/c/EasternUniversitybd?fbclid=IwAR3JecPNuPg_4VKQZ5wq1I6Lqgc77BezeIF3Jws4Tmx_SSWeuscq9oAjCtc";
            return null;
          }}
        />
        <Route
          path="/studentPortal"
          component={() => {
            window.location.href =
              "https://webportal.easternuni.edu.bd/student/_login.aspx";
            return null;
          }}
        />
        <Route
          path="/library"
          component={() => {
            window.location.href = "http://library.easternuni.edu.bd/";
            return null;
          }}
        />
        <Route
          path="/transportSchedule"
          component={() => {
            window.location.href =
              "https://webportal.easternuni.edu.bd/Transport/_transportSchedule.aspx";
            return null;
          }}
        />
        <Route path="/careerServie" component={careerServie}></Route>
        <Route
          path="/internationalOffice"
          component={internationalOffice}
        ></Route>
        <Route
          path="/CVCoverLetter"
          component={() => {
            window.location.href = "https://owl.purdue.edu/";
            return null;
          }}
        ></Route>
        <Route
          path="/jobinterviewquestions"
          component={() => {
            window.location.href = "https://www.jobinterviewquestions.com/";
            return null;
          }}
        ></Route>
        <Route
          path="/bdjobs"
          component={() => {
            window.location.href = "https://bdjobs.com/";
            return null;
          }}
        ></Route>
        <Route
          path="/loosemonkies"
          component={() => {
            window.location.href = "http://ww38.bd.loosemonkies.com/";
            return null;
          }}
        ></Route>
        <Route
          path="/myperfectresume"
          component={() => {
            window.location.href = "https://www.myperfectresume.com/";
            return null;
          }}
        ></Route>
        <Route
          path="/careeronestop"
          component={() => {
            window.location.href = "http://www.careeronestop.org/";
            return null;
          }}
        ></Route>
        <Route
          path="/mynextmove"
          component={() => {
            window.location.href = "https://www.mynextmove.org/";
            return null;
          }}
        ></Route>
        <Route
          path="/businessballs"
          component={() => {
            window.location.href = "https://www.businessballs.com/";
            return null;
          }}
        ></Route>
        <Route
          path="/careeradvice"
          component={() => {
            window.location.href = "https://www.monster.co.uk/career-advice/";
            return null;
          }}
        ></Route>
        <Route
          exact
          path="/psychoSocialCounselling"
          component={psychoSocialCounselling}
        ></Route>
        <Route
          exact
          path="/hostelFacilities"
          component={hostelFacilities}
        ></Route>
        <Route
          exact
          path="/transportFacilities"
          component={transportFacilities}
        ></Route>
        <Route
          exact
          path="/studentactivities"
          component={studentactivities}
        ></Route>
        <Route exact path="/aboutosa" component={aboutosa}></Route>
        <Route exact path="/coreValues" component={coreValues}></Route>
        {/* Clubs */}
        <Route path="/clubsAndExtraCurricular" component={AllClubsList}></Route>
        <Route path="/ClubHome" component={ClubHome}></Route>
        {/* News and Updates */}
        <Route exact path="/newsPage" component={news}></Route>
        <Route
          exact
          path="/upcomingEventsPage"
          component={upcomingEventsAll}
        ></Route>
        <Route exact path="/noticePage" component={noticeAll}></Route>
        <Route exact path="/events" component={events} />
        {/* Contact Information */}
        <Route exact path="/office/:officeId" component={ContactOffice}></Route>
        <Route exact path="/examControllerOffice">
          <Redirect to="/office/6" />
        </Route>
        <Route
          exact
          path="/contactDirectories"
          component={contactDirectories}
        ></Route>
        {/* Faculty of Arts */}
        <Route
          exact
          path="/facultyOfArts"
          component={facultyOfArtsHome}
        ></Route>
        <Route
          exact
          path="/deansMessageArts"
          component={deanMessageArts}
        ></Route>
        <Route
          exact
          path="/programsBAEnglish"
          component={programsBAEnglish}
        ></Route>
        <Route
          exact
          path="/chairpersonMessageArts"
          component={chairpersonMessageArts}
        ></Route>
        <Route
          exact
          path="/programsMAEnglish"
          component={programsMAEnglish}
        ></Route>
        <Route
          exact
          path="/admissionEnglish"
          component={admissionEnglish}
        ></Route>
        2
        <Route
          exact
          path="/tutionFeesAndFinalcialAidsArts"
          component={tutionFeesAndFinalcialAidsArts}
        ></Route>
        <Route
          exact
          path="/researchHighlightsArts"
          component={researchHighlightsArts}
        ></Route>
        <Route
          exact
          path="/researchEventsArts"
          component={researchEventsArts}
        ></Route>
        <Route
          exact
          path="/researchInEnglish"
          component={researchInEnglish}
        ></Route>
        <Route exact path="/newsArts" component={newsArts}></Route>
        <Route exact path="/noticeArts" component={noticeArts}></Route>
        <Route exact path="/contactUsArts" component={contactUsArts}></Route>
        {/* Faculty of E&T */}
        <Route
          exact
          path="/facultyOfEngineering"
          component={facultyOfEngineering}
        ></Route>
        <Route
          exact
          path="/deanMessageEandT"
          component={deanMessageEngineering}
        ></Route>
        <Route
          exact
          path="/messageFromCSEChairperson"
          component={messageFromCSEChairperson}
        ></Route>
        <Route
          exact
          path="/messageFromEEEChairperson"
          component={messageFromEEEChairperson}
        ></Route>
        <Route
          exact
          path="/bscinCSEProgram"
          component={bscinCSEProgram}
        ></Route>
        <Route
          exact
          path="/bscinEEEProgram"
          component={bscinEEEProgram}
        ></Route>
        <Route exact path="/admissionCSE" component={admissionCSE}></Route>
        <Route exact path="/admissionEEE" component={admissionEEE}></Route>
        <Route
          exact
          path="/tutionFeesAndFinalcialAidsEngineering"
          component={tutionFeesAndFinalcialAidsEngineering}
        ></Route>
        <Route
          exact
          path="/researchHighlightsEngineering"
          component={researchHighlightsEngineering}
        ></Route>
        <Route
          exact
          path="/researchEventsEngineering"
          component={researchEventsEngineering}
        ></Route>
        <Route exact path="/researchInCSE" component={researchInCSE}></Route>
        <Route exact path="/researchInEEE" component={researchInEEE}></Route>
        <Route
          exact
          path="/newsEngineering"
          component={newsEngineering}
        ></Route>
        <Route
          exact
          path="/noticeEngineering"
          component={noticeEngineering}
        ></Route>
        <Route
          exact
          path="/contactUsEngineering"
          component={contactUsEngineering}
        ></Route>
        {/* Faculty of Business */}
        <Route
          exact
          path="/facultyOfBusinessAdministration"
          component={facultyOfBusinessAdministration}
        ></Route>
        <Route
          exact
          path="/deanMessageBusiness"
          component={deanMessageBusiness}
        ></Route>
        <Route
          exact
          path="/chairpersonMessageBusiness"
          component={chairpersonMessageBusiness}
        ></Route>
        <Route exact path="/programBBA" component={programBBA}></Route>
        <Route
          exact
          path="/programMBARegular"
          component={programMBARegular}
        ></Route>
        <Route
          exact
          path="/programMBAExecutive"
          component={programMBAExecutive}
        ></Route>
        <Route exact path="/admissionBBA" component={admissionBBA}></Route>
        <Route exact path="/admissionMBA" component={admissionMBA}></Route>
        <Route
          exact
          path="/tutionFeesAndFinalcialAidsBusiness"
          component={tutionFeesAndFinalcialAidsBusiness}
        ></Route>
        <Route
          exact
          path="/researchHighlightsBusiness"
          component={researchHighlightsBusiness}
        ></Route>
        <Route
          exact
          path="/researchEventsBusiness"
          component={researchEventsBusiness}
        ></Route>
        <Route
          exact
          path="/researchInBusiness"
          component={researchInBusiness}
        ></Route>
        <Route
          exact
          path="/newsBusinessFaculty"
          component={newsBusinessFaculty}
        ></Route>
        <Route
          exact
          path="/noticeBusinessFaculty"
          component={noticeBusinessFaculty}
        ></Route>
        <Route
          exact
          path="/contactUsBusinessFaculty"
          component={contactUsBusinessFaculty}
        ></Route>
        <Route exact path="/programBBAIslam" component={ProgramBBAIslam} />
        <Route exact path="/programMBAIslam" component={ProgramMBAIslam} />
        {/* Faculty of Law */}
        <Route exact path="/facultyOfLaw" component={facultyOfLaw}></Route>
        <Route exact path="/deanMessageLaw" component={deanMessageLaw}></Route>
        <Route
          exact
          path="/chairpersonMessageLaw"
          component={chairpersonMessageLaw}
        ></Route>
        <Route exact path="/programLLB" component={programLLB}></Route>
        <Route exact path="/programLLM" component={programLLM}></Route>
        <Route
          exact
          path="/admissionLawFaculty"
          component={admissionLawFaculty}
        ></Route>
        <Route
          exact
          path="/tutionFeesAndFinancialAidsLaw"
          component={tutionFeesAndFinancialAidsLaw}
        ></Route>
        <Route
          exact
          path="/researchHighlightsLaw"
          component={researchHighlightsLaw}
        ></Route>
        <Route
          exact
          path="/researchEventsLaw"
          component={researchEventsLaw}
        ></Route>
        <Route exact path="/researchInLaw" component={researchInLaw}></Route>
        <Route exact path="/newsLaw" component={newsLaw}></Route>
        <Route exact path="/noticeLaw" component={noticeLaw}></Route>
        <Route exact path="/contactUsLaw" component={contactUsLaw}></Route>
        {/* Faculty of Life Science */}
        <Route
          exact
          path="/facultyOfLifeScience"
          component={faucltyOfLifeScience}
        />
        {/* Department of English */}
        <Route
          exact
          path="/departmentOfEnglish"
          component={departmentOfEnglish}
        ></Route>
        <Route
          exact
          path="/welcomeMessageEnglish"
          component={welcomeMessageEnglish}
        ></Route>
        <Route
          exact
          path="/exploringEnglish"
          component={exploringEnglish}
        ></Route>
        <Route
          exact
          path="/clubInformationEnglish"
          component={clubInformationEnglish}
        ></Route>
        <Route
          exact
          path="/programsBAEnglishDepartment"
          component={programsBAEnglishDepartment}
        ></Route>
        <Route
          exact
          path="/programsMAEnglishDepartment"
          component={programsMAEnglishDepartment}
        ></Route>
        <Route
          exact
          path="/otherProgramsEnglish"
          component={otherProgramsEnglish}
        ></Route>
        <Route
          exact
          path="/underGraduateAdmissionEnglish"
          component={underGraduateAdmissionEnglish}
        ></Route>
        <Route
          exact
          path="/graduateAdmissionEnglish"
          component={graduateAdmissionEnglish}
        ></Route>
        <Route
          exact
          path="/underGraduateCurriculumEnglish"
          component={underGraduateCurriculumEnglish}
        ></Route>
        <Route
          exact
          path="/graduateCurriculumEnglish"
          component={graduateCurriculumEnglish}
        ></Route>
        <Route
          exact
          path="/atAGlanceFacultyMembersEnglish"
          component={atAGlanceFacultyMembersEnglish}
        ></Route>
        <Route
          exact
          path="/currentFacultyMembersEnglish"
          component={currentFacultyMembersEnglish}
        ></Route>
        <Route
          exact
          path="/currentAdjunctFacultyMembersEnglish"
          component={currentAdjunctFacultyMembersEnglish}
        ></Route>
        <Route
          exact
          path="/onLeaveFacultyMembersEnglish"
          component={onLeaveFacultyMembersEnglish}
        ></Route>
        <Route
          exact
          path="/formarFacultyMembersEnglish"
          component={formarFacultyMembersEnglish}
        ></Route>
        <Route
          exact
          path="/officialsEnglish"
          component={officialsEnglish}
        ></Route>
        <Route
          exact
          path="/labFacilitiesEnglish"
          component={labFacilitiesEnglish}
        ></Route>
        <Route
          exact
          path="/publicationsEnglish"
          component={publicationsEnglish}
        ></Route>
        <Route
          exact
          path="/researchGroupEnglish"
          component={researchGroupEnglish}
        ></Route>
        <Route exact path="/newsEnglish" component={newsEnglish}></Route>
        <Route
          exact
          path="/upcomingEventsEnglish"
          component={upcomingEventsEnglish}
        ></Route>
        <Route exact path="/archiveEnglish" component={archiveEnglish}></Route>
        <Route exact path="/noticeEnglish" component={noticeEnglish}></Route>
        <Route exact path="/alumniEnglish" component={alumniEnglish}></Route>
        <Route
          exact
          path="/contuctUsEnglish"
          component={contuctUsEnglish}
        ></Route>
        {/* Department of CSE */}
        <Route
          exact
          path="/departmentOfCSE"
          component={departmentOfCSE}
        ></Route>
        <Route
          exact
          path="/welcomeMessageCSE"
          component={welcomeMessageCSE}
        ></Route>
        <Route exact path="/exploringCSE" component={exploringCSE}></Route>
        <Route
          exact
          path="/clubInformationCSE"
          component={clubInformationCSE}
        ></Route>
        <Route
          exact
          path="/bscinCSEDepartmentProgram"
          component={bscinCSEDepartmentProgram}
        ></Route>
        <Route
          exact
          path="/otherProgramCSE"
          component={otherProgramCSE}
        ></Route>
        <Route
          exact
          path="/admissionCSEDepartment"
          component={admissionCSEDepartment}
        ></Route>
        <Route
          exact
          path="/underGraduateCurriculumCSE"
          component={underGraduateCurriculumCSE}
        ></Route>
        <Route exact path="/contactUsCSE" component={contactUsCSE}></Route>
        <Route
          exact
          path="/atAGlanceFacultyMembersCSE"
          component={atAGlanceFacultyMembersCSE}
        ></Route>
        <Route
          exact
          path="/currentFacultyMembersCSE"
          component={currentFacultyMembersCSE}
        ></Route>
        <Route
          exact
          path="/adjunctCurrentFacultyMembersCSE"
          component={adjunctCurrentFacultyMembersCSE}
        ></Route>
        <Route
          exact
          path="/onLeaveFacultyMembersCSE"
          component={onLeaveFacultyMembersCSE}
        ></Route>
        <Route
          exact
          path="/formerFacultyMembersCSE"
          component={formerFacultyMembersCSE}
        ></Route>
        <Route exact path="/officialsCSE" component={officialsCSE}></Route>
        <Route
          exact
          path="/labFacilitiesCSE"
          component={labFacilitiesCSE}
        ></Route>
        <Route
          exact
          path="/publicationsCSE"
          component={publicationsCSE}
        ></Route>
        <Route
          exact
          path="/researchGroupCSE"
          component={researchGroupCSE}
        ></Route>
        <Route exact path="/newsCSE" component={newsCSE}></Route>
        <Route
          exact
          path="/upcomingEventsCSE"
          component={upcomingEventsCSE}
        ></Route>
        <Route exact path="/archiveCSE" component={archiveCSE}></Route>
        <Route exact path="/noticesCSE" component={noticesCSE}></Route>
        <Route exact path="/alumniCSE" component={alumniCSE}></Route>
        <Route
          exact
          path="/missionVisionCSE"
          component={MissionAndVissionCSE}
        ></Route>
        {/* Department of Civil */}
        <Route exact path="/departmentOfCivil" component={DepartmentOfCivil} />
        <Route
          exact
          path="/welcomeMessageCivil"
          component={WelcomeMessageCivil}
        />
        <Route exact path="/exploringCivil" component={ExploringCivil} />
        <Route
          exact
          path="/clubInformationCivil"
          component={ClubInformationCivil}
        />
        <Route exact path="/bscinCivil" component={BscInCivil} />
        <Route
          exact
          path="/admissionCivilDepartment"
          component={AdmissionCivilDepartment}
        />
        <Route
          exact
          path="/atAGlanceFacultyMembersCivil"
          component={AtAGlanceFacultyMembersCivil}
        />
        <Route
          exact
          path="/currentFacultyMembersCivil"
          component={CurrentFacultyMemberCivil}
        />
        <Route exact path="/officialsCivil" component={OffcialsCivil} />
        <Route exact path="/AnnualReport" component={AnnualReport} />
        <Route
          exact
          path="/underGraduateCurriculumCivil"
          component={UnderGraduateCurriculumCivil}
        />
        {/* Department of EEE */}
        <Route
          exact
          path="/departmentOfEEE"
          component={departmentOfEEE}
        ></Route>
        <Route
          exact
          path="/welcomeMessageEEE"
          component={welcomeMessageEEE}
        ></Route>
        <Route exact path="/exploringEEE" component={exploringEEE}></Route>
        <Route
          exact
          path="/clubInformationEEE"
          component={clubInformationEEE}
        ></Route>
        <Route
          exact
          path="/otherProgramsEEE"
          component={otherProgramsEEE}
        ></Route>
        <Route
          exact
          path="/bscinEEEDepartmentProgram"
          component={bscinEEEDepartmentProgram}
        ></Route>
        <Route
          exact
          path="/admissionEEEDepartment"
          component={admissionEEEDepartment}
        ></Route>
        <Route
          exact
          path="/underGraduateCurriculumEEE"
          component={underGraduateCurriculumEEE}
        ></Route>
        <Route
          exact
          path="/atAGlanceFacultyMembersEEE"
          component={atAGlanceFacultyMembersEEE}
        ></Route>
        <Route
          exact
          path="/currentFacultyMembersEEE"
          component={currentFacultyMembersEEE}
        ></Route>
        <Route
          exact
          path="/adjunctCurrentFacultyMembersEEE"
          component={adjunctCurrentFacultyMembersEEE}
        ></Route>
        <Route
          exact
          path="/onLeaveFacultyMembersEEE"
          component={onLeaveFacultyMembersEEE}
        ></Route>
        <Route
          exact
          path="/formerFacultyMembersEEE"
          component={formerFacultyMembersEEE}
        ></Route>
        <Route exact path="/officialsEEE" component={officialsEEE}></Route>
        <Route
          exact
          path="/labFacilitiesEEE"
          component={labFacilitiesEEE}
        ></Route>
        <Route
          exact
          path="/publicationsEEE"
          component={publicationsEEE}
        ></Route>
        <Route
          exact
          path="/researchGroupEEE"
          component={researchGroupEEE}
        ></Route>
        <Route exact path="/newsEEE" component={newsEEE}></Route>
        <Route exact path="/upcomingEvents" component={upcomingEvents}></Route>
        <Route exact path="/archiveEEE" component={archiveEEE}></Route>
        <Route exact path="/noticesEEE" component={noticesEEE}></Route>
        <Route exact path="/alumniEEE" component={alumniEEE}></Route>
        <Route exact path="/contactUsEEE" component={contactUsEEE}></Route>
        <Route exact path="/missionVisionEEE" component={missionVision}></Route>
        {/* Department of Business */}
        <Route
          exact
          path="/departmentOfBusinessAdministration"
          component={departmentOfBus}
        ></Route>
        <Route
          exact
          path="/welcomeMessageBusinessDepartment"
          component={welcomeMessageBusinessDepartment}
        ></Route>
        <Route
          exact
          path="/exploringBusinessDepartment"
          component={exploringBusinessDepartment}
        ></Route>
        <Route
          exact
          path="/clubInformationBusinessDepartment"
          component={clubInformationBusinessDepartment}
        ></Route>
        <Route
          exact
          path="/programsBBADepartment"
          component={programsBBADepartment}
        ></Route>
        <Route
          exact
          path="/programsRegularMBADepartment"
          component={programsRegularMBADepartment}
        ></Route>
        <Route
          exact
          path="/programsExecutiveMBADepartment"
          component={programsExecutiveMBADepartment}
        ></Route>
        <Route
          exact
          path="/otherProgramsBusinessDepartment"
          component={otherProgramsBusinessDepartment}
        ></Route>
        <Route
          exact
          path="/underGraduateAdmissionBusiness"
          component={underGraduateAdmissionBusiness}
        ></Route>
        <Route
          exact
          path="/graduateAdmissionBusiness"
          component={graduateAdmissionBusiness}
        ></Route>
        <Route
          exact
          path="/underGraduateCurriculumBusiness"
          component={underGraduateCurriculumBusiness}
        ></Route>
        <Route
          exact
          path="/graduateCurriculumBusiness"
          component={graduateCurriculumBusiness}
        ></Route>
        <Route
          exact
          path="/atAGlanceFacultyMembersBusiness"
          component={atAGlanceFacultyMembersBusiness}
        ></Route>
        <Route
          exact
          path="/currentFacultyMembersBusiness"
          component={currentFacultyMembersBusiness}
        ></Route>
        <Route
          exact
          path="/currentAdjunctFacultyMembersBusiness"
          component={currentAdjunctFacultyMembersBusiness}
        ></Route>
        <Route
          exact
          path="/onLeaveFacultyMembersBusiness"
          component={onLeaveFacultyMembersBusiness}
        ></Route>
        <Route
          exact
          path="/formarFacultyMembersBusiness"
          component={formarFacultyMembersBusiness}
        ></Route>
        <Route
          exact
          path="/officialsBusiness"
          component={officialsBusiness}
        ></Route>
        <Route
          exact
          path="/labFacilitiesBusiness"
          component={labFacilitiesBusiness}
        ></Route>
        <Route
          exact
          path="/publicationsBusiness"
          component={publicationsBusiness}
        ></Route>
        <Route
          exact
          path="/researchGroupBusiness"
          component={researchGroupBusiness}
        ></Route>
        <Route exact path="/newsBusiness" component={newsBusiness}></Route>
        <Route
          exact
          path="/upcomingEventsBusiness"
          component={upcomingEventsBusiness}
        ></Route>
        <Route
          exact
          path="/archiveBusiness"
          component={archiveBusiness}
        ></Route>
        <Route exact path="/noticeBusiness" component={noticeBusiness}></Route>
        <Route exact path="/alumniBusiness" component={alumniBusiness}></Route>
        <Route
          exact
          path="/contuctUsBusiness"
          component={contuctUsBusiness}
        ></Route>
        <Route
          exact
          path="/programsBBAonIslamicDepartment"
          component={ProgramBBAIslamicFinance}
        />
        <Route
          exact
          path="/programsMBAonIslamicDepartment"
          component={ProgramMBAIslamicFinance}
        />
        <Route
          exact
          path="/missionVisionBusiness"
          component={MissionandVissionBusiness}
        ></Route>
        {/* Department of Law*/}
        <Route
          exact
          path="/departmentOfLaw"
          component={departmentOfLaw}
        ></Route>
        <Route
          exact
          path="/welcomeMessageLawDepartment"
          component={welcomeMessageLawDepartment}
        ></Route>
        <Route
          exact
          path="/exploringLawDepartment"
          component={exploringLawDepartment}
        ></Route>
        <Route
          exact
          path="/clubInformationLawDepartment"
          component={clubInformationLawDepartment}
        ></Route>
        <Route
          exact
          path="/programsLLBLawDepartment"
          component={programsLLBLawDepartment}
        ></Route>
        <Route
          exact
          path="/programsLLMLawDepartment"
          component={programsLLMLawDepartment}
        ></Route>
        <Route
          exact
          path="/otherProgramsLawDepartment"
          component={otherProgramsLawDepartment}
        ></Route>
        <Route
          exact
          path="/underGraduateAdmissionLawDepartment"
          component={underGraduateAdmissionLawDepartment}
        ></Route>
        <Route
          exact
          path="/graduateAdmissionLawDepartment"
          component={graduateAdmissionLawDepartment}
        ></Route>
        <Route
          exact
          path="/underGraduateCurriculumLawDepartment"
          component={underGraduateCurriculumLawDepartment}
        ></Route>
        <Route
          exact
          path="/graduateCurriculumLawDepartment"
          component={graduateCurriculumLawDepartment}
        ></Route>
        <Route
          exact
          path="/atAGlanceFacultyMembersLawDepartment"
          component={atAGlanceFacultyMembersLawDepartment}
        ></Route>
        <Route
          exact
          path="/currentFacultyMembersLawDepartment"
          component={currentFacultyMembersLawDepartment}
        ></Route>
        <Route
          exact
          path="/currentAdjunctFacultyMembersLawDepartment"
          component={currentAdjunctFacultyMembersLawDepartment}
        ></Route>
        <Route
          exact
          path="/onLeaveFacultyMembersLawDepartment"
          component={onLeaveFacultyMembersLawDepartment}
        ></Route>
        <Route
          exact
          path="/formarFacultyMembersLawDepartment"
          component={formarFacultyMembersLawDepartment}
        ></Route>
        <Route
          exact
          path="/officialsLawDepartment"
          component={officialsLawDepartment}
        ></Route>
        <Route
          exact
          path="/labFacilitiesLawDepartment"
          component={labFacilitiesLawDepartment}
        ></Route>
        <Route
          exact
          path="/publicationsLawDepartment"
          component={publicationsLawDepartment}
        ></Route>
        <Route
          exact
          path="/researchGroupLawDepartment"
          component={researchGroupLawDepartment}
        ></Route>
        <Route
          exact
          path="/newsLawDepartment"
          component={newsLawDepartment}
        ></Route>
        <Route
          exact
          path="/upcomingEventsLawDepartment"
          component={upcomingEventsLawDepartment}
        ></Route>
        <Route
          exact
          path="/archiveLawDepartment"
          component={archiveLawDepartment}
        ></Route>
        <Route
          exact
          path="/noticeLawDepartment"
          component={noticeLawDepartment}
        ></Route>
        <Route
          exact
          path="/alumniLawDepartment"
          component={alumniLawDepartment}
        ></Route>
        <Route
          exact
          path="/contuctUsLawDepartment"
          component={contuctUsLawDepartment}
        ></Route>
        {/* Department of Pharmacy */}
        <Route
          exact
          path="/departmentOfPharmacy"
          component={DepartmentOfPharmacy}
        />
        <Route
          exact
          path="/welcomeMessagePharmacy"
          component={WelcomeMessagePharmacy}
        />
        <Route
          exact
          path="/admissionPharmacyDepartment"
          component={AdmissionPharmacyDepartment}
        />
        <Route
          exact
          path="/bachelorOfPharmacy"
          component={BachelorOfPharmacy}
        />
        <Route
          exact
          path="/labFacilitiesPharmacy"
          component={LabFacilitiesPharmacy}
        />
        <Route
          exact
          path="/clubInformationPharmacy"
          component={ClubInformationPharmacy}
        />
        <Route
          exact
          path="/atAGlanceFacultyMembersPharmacy"
          component={AtAGlanceFacultyMembersPharmacy}
        />
        <Route
          exact
          path="/currentFacultyMembersPharmacy"
          component={CurrentFacultyMemberPharmacy}
        />
        <Route
          exact
          path="/onLeaveFacultyMembersPharmacy"
          component={OnLeaveFacultyMembersPharmacy}
        />
        <Route
          exact
          path="/adjunctFacultyMembersPharmacy"
          component={AdjunctFacultyMembersPharmacy}
        />
        <Route exact path="/newsPharmacy" component={NewsPharmacy} />
        <Route exact path="/noticePharmacy" component={NoticesPharmacy} />
        <Route exact path="/officialsPharmacy" component={OfficialsPharmacy} />
        <Route
          exact
          path="/underGraduateCurriculumPharma"
          component={UnderGraduateCurriculumPharma}
        />
        {/* Quiz */}
        <Route exact path="/io" component={quiz} />
        {/* Payment Instraction */}
        <Route
          exact
          path="/OnlinePaymentInstruction"
          component={PaymentInstraction}
        ></Route>
        {/* All external Links goes here */}
        <Route
          exact
          path="/SpeechWeavers"
          component={() => {
            window.location.href = "https://forms.gle/3FjCSWiXiAc9sAJX9";
            return null;
          }}
        />
        <Route
          exact
          path="/formDownload"
          component={() => {
            window.location.href = "/assets/Forms/Police_Information_Form.pdf";
            return null;
          }}
        />
        <Route
          exact
          path="/convocation"
          component={() => {
            window.location.href =
              "https://webportal.easternuni.edu.bd/convocation/_ConvChkInfo.aspx";
            return null;
          }}
        />
        <Route
          exact
          path="/alumniDirectory"
          component={() => {
            window.location.href =
              "https://webportal.easternuni.edu.bd/Online_Alumni/_AlumniSearch.aspx";
            return null;
          }}
        />
        <Route
          exact
          path="/webmail"
          component={() => {
            window.location.href =
              "https://www.google.com/a/easternuni.edu.bd/ServiceLogin?service=mail&passive=true&rm=false&continue=http://mail.google.com/a/easternuni.edu.bd/&ltmpl=default&ltmplcache=2";
            return null;
          }}
        />
        <Route exact path="/conference" component={Conference}></Route>
        <Route exact path="/conferenceLaw" component={ConferenceLaw}></Route>
        <Route exact path="/conferenceBBA" component={ConferenceBBA}></Route>
        <Route exact path="/privacypolicy" component={PrivacyPolicy} />
        <Route exact path="/useofterms" component={UseOfTerms} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
}

export default MyRoute;
