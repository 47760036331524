import React from "react";

import { NavLink, Link } from "react-router-dom";
import { useCourses } from "../../../features/useCourses";
import LoadingBox from "../../../component/LoadingBox";
import MessageBox from "../../../component/MessageBox";

function Curriculumn({ config }) {
  const { courses, isLoading, error } = useCourses(config);

  if (isLoading) return <LoadingBox />;
  if (error) return <MessageBox>{error.message}</MessageBox>;

  if (!courses.length) return <p className="heading5">There is no course.</p>;

  const CourseCurriculumn = courses.map((item) => {
    return (
      <tr key={item.id}>
        <td>
          <p className="allText text-left">
            <NavLink exact to={"/courseDetails/" + item.id}>
              {item.COURSECODE}
            </NavLink>
          </p>
        </td>
        <td>
          <p className="allText text-left">
            <NavLink exact to={"/courseDetails/" + item.id}>
              {item.COURSENAME}
            </NavLink>
          </p>
        </td>
        <td>
          <p className="allText text-center">{item.CREDITHOURS}</p>
        </td>
        <td>
          <p className="allText text-left">
            {/* {item.courses_details?.prerequisite.map((item) => (
              <NavLink exact to={"/courseDetails/" + item.id}>
                {item.COURSECODE}
              </NavLink>
            ))} */}

            {item.courses_details?.prerequisite.map((item, index, arr) => {
              const lastElement = index === arr.length - 1;
              return (
                <span>
                  <Link exact to={"/courseDetails/" + item.id}>
                    {` ${
                      lastElement ? item.COURSECODE : `${item.COURSECODE}, `
                    } `}
                  </Link>
                </span>
              );
            })}
          </p>
        </td>
      </tr>
    );
  });
  return (
    <table className="table table-responsive">
      <thead>
        <tr>
          <th>
            <p className="allText text-left">Course Code</p>
          </th>
          <th>
            <p className="allText text-left">Course Title</p>
          </th>
          <th>
            <p className="allText text-left">Credit Hours</p>
          </th>
          <th>
            <p className="allText text-left">Prerequisite</p>
          </th>
        </tr>
      </thead>
      <tbody>{CourseCurriculumn}</tbody>
    </table>
  );
}

export default Curriculumn;
