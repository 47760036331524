import React from "react";

import moment from "moment/moment";
import { Link } from "react-router-dom";

import LeftSideBar from "../component/NewsAndUpdatesSideBar";
import ReactHtmlParser from "react-html-parser";
import Footer from "../../component/Footer";
import LoadingBox from "../../component/LoadingBox";
import MessageBox from "../../component/MessageBox";

import useNoticeArchive from "../../features/useNoticeArchive";
import Pagination from "../../UI/Pagination";
import Spinner from "../../UI/Spinner";

function Notices() {
  const { notices, isLoading, error, count } = useNoticeArchive();

  if (isLoading) return <Spinner />;

  if (error) return <MessageBox>{error.message}</MessageBox>;

  const NoticeList = notices.map((mylist) => {
    return (
      <li class="list-group-item" key={mylist.id} style={{ width: "100%" }}>
        <p className="card-title heading6 text-left">
          <Link exact to={"/notice/" + mylist.id}>
            {ReactHtmlParser(mylist.headline)}
          </Link>
        </p>
        <h4 className="card-subtitle mb-2 text-muted text-left">
          Publish Date: {moment(mylist.noticeDate, "YYYY/MM/DD").format("LL")}
        </h4>
      </li>
    );
  });

  return (
    <>
      <div className="content">
        <div className="OtherPagecontent">
          <hr></hr>
          <p className="heading2 text-center primaryHeading">Notice</p>
          <hr></hr>
          <div id="NoticeAll">
            <div className="card noticeCard">
              {isLoading ? (
                <LoadingBox></LoadingBox>
              ) : error ? (
                <MessageBox variant="danger">{error}</MessageBox>
              ) : (
                <ul class="list-group list-group-flush">{NoticeList}</ul>
              )}
            </div>
          </div>
          <Pagination count={count} />
        </div>
        <LeftSideBar />
      </div>
      <Footer />
    </>
  );
}

export default Notices;
