import React, { useState } from "react";
import FacultyName from "../../component/facultyName";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import Footer from "../../../../component/Footer";
import { LeftSideNavigationCivil } from "../../component/leftSideNavigationCivil";

export const ExploringCivil = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <FacultyName name="Department of Civil Engineering" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationCivil />
        <div className="rightSideContent mt-4">
          <p className="heading4 text-left">
            Exploring Eastern University Civil Engineering
          </p>
          <div className="deanMessage text-left">
            <p className="allText">
              The Department of Civil Engineering offers a 4 years undergraduate
              Program of Bachelor of Science in Civil Engineering. To become a
              graduate in this field one has to complete 160 credit hours. It is
              utmost necessity to enhance the quality of higher education in
              Bangladesh for national and global context. Eastern University
              (EU) is one of the private universities trying to maintain quality
              education for creating the leader of the leaders since 2003.
              Almost all the physical infrastructure development of the country
              are the products of Civil Engineering. Construction, operation and
              maintenance of buildings, bridges, roads, railways, airports,
              embankment, river training, irrigation, canals, sea and river
              ports, power houses, transmission towers, dams and barrages etc.
              require the knowledge and technology of Civil Engineering.
              Experienced and learned teachers teach the courses based on
              Outcome Based Education (OBE) system. Eastern University is well
              equipped with necessary lab facilities for test, and research
              works. The academic programs of this University are continually
              being updated and improved to reflect the latest development in
              Engineering education.
            </p>

            <p className="heading5">Vision</p>
            <p className="allText">
              To be a globally renowned Civil Engineering Department for
              producing leader of the leaders through quality education.
            </p>
            <p className="heading5">Mission</p>
            <p className="allText">
              To advance civil engineering knowledge and learning through
              essential soft skills for leadership at globally private and
              public sector.
            </p>
            <p className="heading5">Objectives</p>
            <ul>
              <li>
                <p className="allText">
                  Graduates are competent, innovative and entrepreneurial in
                  acquiring and applying knowledge towards solving Civil
                  Engineering problems.
                </p>
              </li>
              <li>
                <p className="allText">
                  Graduates possess leadership qualities, able to work, manage
                  in diverse teams and serve the society in multi-disciplinary
                  environment.
                </p>
              </li>
              <li>
                <p className="allText">
                  Graduates demonstrate professionalism and uphold ethical
                  values with emphasis on sustainable development.
                </p>
              </li>
              <li>
                <p className="allText">
                  Graduates are able to communicate effectively, possess strong
                  self-confidence and recognize the need for life-long learning.
                </p>
              </li>
            </ul>
          </div>
          {/* Mobile Responsive Start*/}
          <div
            id="mobileMenuButton"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <button>
              <VscAdd className="moreMenu" />
            </button>
          </div>
          {mobileMenuOpen && (
            <div id="mobileMenuContent">
              <button
                id="closeButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <VscChromeClose className="closeButtonIcon" />
              </button>
              <LeftSideNavigationCivil />
            </div>
          )}
          {/* Mobile Responsive End*/}
        </div>
      </div>
      <Footer />
    </>
  );
};
