import React, { Component } from 'react';
import HistoryContent from './content/HistoryContent';


import Footer from '../../component/Footer'

class history extends Component {
    render() {
        return (
            <div>
                <HistoryContent />
                <Footer />
            </div>
        );
    }
}

export default history;