import { Link } from "react-router-dom";
import Wrapper from "../component/Wrapper";
import { conferenceLaw, conferenceLawInstrection } from "../static";

function ConferenceLaw() {
  return (
    <Wrapper>
      <div className="content conference">
        <div className="OtherPagecontent ">
          <p className="heading1">
            South Asian Postgraduate Law Conference, 2023
          </p>

          <img
            src={conferenceLaw}
            alt="Business Conference 2024"
            className="img-fluid"
          />
        </div>
        <div className="OtherpageSideBar">
          <ul className="list-group list-group-flush">
            <li className="list-group-item">
              <a href={conferenceLawInstrection}>Download Instraction</a>
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
}

export default ConferenceLaw;
