import { useQuery } from "@tanstack/react-query";
import { getACourse } from "../services/apis";
export function useCourse(id) {
  const {
    data: course,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["course", id],
    queryFn: () => getACourse(id),
    retry: 3,
  });
  return { course, isLoading, error };
}
