import React, { Component, useEffect, useState } from "react";
import axios from "axios";
import { server, server_django, auth, replace_string } from "../util";
import Footer from "../component/Footer";
import ReactHtmlParser from "react-html-parser";
import { useParams } from "react-router-dom";
import LoadingBox from "./LoadingBox";
import MessageBox from "./MessageBox";
import Spinner from "../UI/Spinner";

function SingleNews() {
  const [singleNews, setSingleNews] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { news_id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(
          server_django + "/allNews/" + news_id,
          {
            auth,
          }
        );

        setLoading(false);
        setSingleNews(data);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const detailsNews = singleNews && (
    <div className="singlePost">
      <div className="card">
        <div className="card-body">
          <p className="heading3">{singleNews.headline}</p>
          <p className="allText">
            {ReactHtmlParser(singleNews.details?.replace(/\r\n/g, "<br />"))}
          </p>
        </div>
        <img
          className="card-img-bottom img-fluid"
          src={singleNews.pictureLocation?.replace(
            replace_string,
            server_django
          )}
          alt={singleNews.headline}
        ></img>
      </div>
    </div>
  );
  return (
    <div>
      {loading ? (
        <Spinner />
      ) : error ? (
        <MessageBox variant="danger">{error}</MessageBox>
      ) : (
        detailsNews
      )}
      <Footer />
    </div>
  );
}

export default SingleNews;
