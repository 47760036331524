import React from 'react';
import StudentActivitiesSideBar from '../../component/StudentActivitiesSideBar';
import Footer from '../../../component/Footer';

const aboutosa = () => {
    return (
        <>
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr />
                    <p className='heading2 text-center'>
                        About the OSA
                    </p>
                    <hr />
                    <p className='allText'>
                        The Office of Student Affairs (OSA) is committed to facilitating the growth, development, achievement of students at Eastern University (EU) and their future endeavor. OSA works closely with the top administration, students and external groups to support EU students to be successful and effective leaders in the emerging global community.
                    </p>
                    <p className='allText'>
                        The mission of the OSA is to promote student learning, life skills, and personal growth by providing the best and most innovative programs, facilities and services.
                    </p>
                    <p className='allText'>
                        The OSA at Eastern University envisions a healthy culture of learning, reflecting the profound opportunities available for students, meant for the academic and personal well-being of its students.
                    </p>
                </div>
                <StudentActivitiesSideBar />
            </div>
            <Footer />
        </>
    );
};

export default aboutosa;