import { useQuery } from "@tanstack/react-query";
import { getNews } from "../services/apis";
export function useNews(config = {}) {
  const { department, faculty } = config;
  const {
    data: news,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["news", department, faculty],
    queryFn: () => getNews({ department, faculty }),
    retry: 3,
  });
  return { news, isLoading, error };
}
