import React from "react";
import { NavLink } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";

export const LeftSideNavigationCivil = () => {
  return (
    <div id="facultyLeftSideMenu" className="sidemenu">
      <Menu vertical className="sidemenuBar">
        <Dropdown text="Home" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <Dropdown.Item>
              <NavLink exact to="/departmentOfCivil">
                Civil Home
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/">
                EU Home
              </NavLink>
            </Dropdown.Item>

            <Dropdown text="About" className="link item sideMenuBarDropdown">
              <Dropdown.Menu>
                <NavLink exact to="/welcomeMessageCivil">
                  <Dropdown.Item>Welcome Message</Dropdown.Item>
                </NavLink>
                <NavLink exact to="/exploringCivil">
                  <Dropdown.Item>Exploring Civil</Dropdown.Item>
                </NavLink>
                <NavLink exact to="/clubInformationCivil">
                  <Dropdown.Item>Clubs Information</Dropdown.Item>
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="Academic" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            {/* Programs */}
            <Dropdown text="Programs" className="link item sideMenuBarDropdown">
              <Dropdown.Menu>
                <Dropdown text="Undergraduate" className="link item">
                  <Dropdown.Menu>
                    <NavLink exact to="/bscinCivil">
                      <Dropdown.Item>B.Sc in Civil</Dropdown.Item>
                    </NavLink>
                  </Dropdown.Menu>
                </Dropdown>
              </Dropdown.Menu>
            </Dropdown>

            {/* Admission */}
            <Dropdown
              text="Admission"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <NavLink exact to="/admissionCivilDepartment">
                  <Dropdown.Item>Undergraduate</Dropdown.Item>
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
            {/* Curriculum */}
            <Dropdown
              text="Curriculum"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <NavLink exact to="/underGraduateCurriculumCivil">
                  <Dropdown.Item>Undergraduate</Dropdown.Item>
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="People" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            {/* Faculty Members */}
            {/* <Dropdown
              text="Faculty Members"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <NavLink exact to="/atAGlanceFacultyMembersCivil">
                  <Dropdown.Item>At A Glance</Dropdown.Item>
                </NavLink>
                <NavLink exact to="/currentFacultyMembersCivil">
                  <Dropdown.Item>Current Faculty</Dropdown.Item>
                </NavLink>
                {/* 
                <Dropdown.Item>
                  <NavLink exact to="/adjunctCurrentFacultyMembersCivil">
                    Adjunct Faculty
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/onLeaveFacultyMembersCivil">
                    On Leave
                  </NavLink>
                </Dropdown.Item> 
              </Dropdown.Menu>
            </Dropdown> */}
            <Dropdown.Item>
              <NavLink exact to="/atAGlanceFacultyMembersCivil">
                Faculty Members
              </NavLink>
            </Dropdown.Item>
            {/* Officials */}
            <Dropdown.Item>
              <NavLink exact to="/officialsCivil">
                Officials
              </NavLink>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* Lab */}
        {/* <Menu.Item className="sideBarSingleItem">
          <NavLink exact to="/labFacilitiesCivil">
            Lab Facilities
          </NavLink>
        </Menu.Item> */}

        {/* <Dropdown text="Research" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <Dropdown.Item>
            <NavLink exact to='/publicationsCSE'>Publications</NavLink> 
              <a
                href="http://dspace.easternuni.edu.bd:8080/xmlui/"
                target="_blank"
                rel="noreferrer"
              >
                Publications
              </a>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/researchGroupCivil">
                Research Groups
              </NavLink>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown> */}

        {/* Announcement */}
        <Dropdown text="Announcement" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <Dropdown.Item>
              <NavLink exact to="/newsPage">
                News
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/upcomingEventsPage">
                Upcoming Events
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/noticePage" tabindex="-1">
                Notice
              </NavLink>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item className="sideBarSingleItem">
          {/* <NavLink exact to='/alumniCSE'>Alumni</NavLink> */}
          <a
            href="http://webportal.easternuni.edu.bd/Online_Alumni/_AlumniSearch.aspx"
            target="_blank"
          >
            Alumni
          </a>
        </Menu.Item>
        {/* <Menu.Item className="sideBarSingleItemLast">
          <NavLink exact to="/contactUsCivil">
            Contact Us
          </NavLink>
        </Menu.Item> */}
        <Menu.Item className="sideBarSingleItemLast">
          <NavLink exact to="/AnnualReport">
            Annual Report
          </NavLink>
        </Menu.Item>
      </Menu>
    </div>
  );
};
