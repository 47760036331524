import JournalLayout from "./JournalLayout";

function JournalGuidLines() {
  return (
    <JournalLayout>
      <p className="heading4">Guidelines for Contributors </p>
      <p className="allText">
        All papers submitted for publication in the journal should be printed,
        double-spaced on one side of A4 papers with ample margin (an inch and a
        half on the left-hand side and one inch on the top, bottom and right
        sides). Their hard copies should be sent in duplicate together with a
        soft copy in MS word. The author(s) will be informed of the status of
        the article at the end of the peer review process, which may take a
        while. The author of an accepted article may be asked to make revisions
        if required. Manuscripts not accepted for publication are not returned,
        but the reasons for non-acceptance are communicated. The authors will
        receive two copies of the journal that carries the article. Book
        reviewers will receive one copy of the journal.
      </p>
      <p className="allText">
        References must follow the author-date system both for in-text
        references and end references as noted below:
      </p>
      <p className="heading6 mt-4"> In-text Citation:</p>
      <ul className="px-4">
        <li className="allText">
          Citation of reference literature within the body of an article should
          only indicate the last name of the author and the year of publication
          in brackets. Example: Arrangers, order fulfillers, assemblers &
          customizers, etc. are also important specialized service providers
          (Bower sox and Cooper, 2001).
        </li>
        <li className="allText">
          However, when something is quoted from a source, the exact page number
          must be provided in addition to above information. Example: The
          primary and secondary channel participants add values to a product.
          The values they add are called "Service Outputs" (Bucklin, 1966 and
          1972, pp. 18-31; Etgar, 1974,. pp. 95-97; Coughlan, et al., 2006, pp.
          19-20, 43-59).
        </li>
        <li className="allText">
          If the reference list contains more than one work of an author done in
          the same year, they are to be distinguished by suffixing a, b, c etc.
          to the year, for example Johnson 2004 a), (Johnson 2004 b), etc.
        </li>
        <li className="allText">
          When two or more references are cited, separate these with a
          semicolon. Example: (Marx 1967; Weber, 1964), etc.
        </li>
      </ul>
      <p className="heading6 mt-2"> References:</p>
      <p className="allText">
        References cited in an article should be listed with details following
        the format given below:
      </p>

      <ul className="px-4">
        <li className="allText">
          In case of reference(s)from journal article(s), the name of the
          author(s), with the last name first and then first name and the middle
          initial, and then year of publication, the title of the paper within
          inverted commas, the name of the journal in italic with the volume
          number, month and year, and finally, the page number(s).
        </li>
        <p className="allText mt-4">
          Example: Bhaduri, P.D and Goldsmith, A.D (2005), "Growth and Equity in
          Developing Countries: A Reinterpretation of the Peruvian Experience"
          Dhaka University Journal of Business Studies, Vol. XXVI, No 2, June
          2005, p.123.
        </p>
        <li className="allText">
          In case of reference(s) from book(s) or monograph(s), the name of the
          author(s), with last name and then first name and middle initial and
          then year of publication, the title of the book/ monograph in italics,
          name of the publisher and place of publication and finally, the page
          number(s).
        </li>
        <p className="allText mt-4">
          Example: Kennedy, S.I. (2000), The Economic Thought of a Humanist,
          Chicago University Press, Chicago, pp.32-32.
        </p>
        <li className="allText">
          In case of reference(s) from conference or seminar papers, the name of
          the author(s), with last name and then first name and middle initial
          and then year of publication, the title of the conference/ seminar in
          italics, the organizer, the place and date of the conference/ seminar
          and page number(s).
        </li>
        <p className="allText mt-4">
          Example: Parvez, M (1999), " Human Rights and Ethnic Minorities in
          Bangladesh: an Overview", paper presented at a seminar on Ethnic
          minorities and Developments organized by Institute of Applied
          Anthropology, Bangladesh at the Institute of Modern Languages,
          University of Dhaka, 22-23 July, 1999,p.5.
        </p>
        <li className="allText">
          For references to the internet materials use the URL of the Website
          and the date viewed. Example: Smith A. (1999), "Select committee
          report on social care in the community",
          http://www.dhas.gov.uk/reports/report015285viewed on January 30, 2016.
        </li>
      </ul>
      <p className="allText">
        For further examples, please see the articles published in the previous
        issues of The Eastern University Journal.
      </p>
      <p className="allText">
        For details on the rules, please go to the American Psychological
        Association (APA) style for Arts, Business, Social Sciences & Law and
        Engr. IEEE Citation style for Engineering & Computer Sciences."
      </p>
    </JournalLayout>
  );
}

export default JournalGuidLines;
