import React, { Component } from 'react';
import AdmissionSidebar from '../../component/AdmissionSidebar';

class GraduateAdmissionEligibilityContent extends Component {
    render() {
        return (
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr/>
                    <p className='heading2 primaryHeading'>
                        Requirements:
                    </p>
                    <hr/>
                    <div className='container'>
                        <ol start="1" className='allText'>
                            <li>The candidates applying for Master programs must have minimum GPA 2.00 or equivalent results at the Bachelor level and must fulfill the admission requirement for Bachelor program as per UGC’s set criteria.</li>
                            <li>A relevant undergraduate degree from a recognized university with a GPA of 2.00 (in a scale of 4.0) or 40% marks in graduation level out of 1000 marks.</li>
                            <li>At least 2 years of job experience with Bachelor degree or 4-year Bachelor (Honors) degree required for EMBA.</li>
                        </ol>
                    </div>
                </div>
                <AdmissionSidebar/>
            </div>
        );
    }
}

export default GraduateAdmissionEligibilityContent;