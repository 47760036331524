import React, { useState } from "react";
import LeftSideNavigationBusinessDepartment from "../../component/leftSideNavigationDepartmentofBusiness";
import FacultyName from "../../component/facultyName";
import { engFacultyTestImg } from "../../../../static";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import Message from "../../../../component/Message";
import { departmentCode } from "../../../../util";

const WelcomeMessageBusinessDepartment = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName
        name="Department of Business Administration"
        facultyImg={engFacultyTestImg}
      />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationBusinessDepartment />
        <div className="rightSideContent mt-4">
          <p className="heading4 text-left">
            Welcome to Department of Business Administration
          </p>
          <Message
            messageFrom="Chairperson"
            department={departmentCode.bba}
            header={true}
          />
          {/* Mobile Responsive Start*/}
          <div
            id="mobileMenuButton"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <button>
              <VscAdd className="moreMenu" />
            </button>
          </div>
          {mobileMenuOpen && (
            <div id="mobileMenuContent">
              <button
                id="closeButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <VscChromeClose className="closeButtonIcon" />
              </button>
              <LeftSideNavigationBusinessDepartment />
            </div>
          )}
          {/* Mobile Responsive End*/}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default WelcomeMessageBusinessDepartment;
