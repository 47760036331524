import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";

import Spinner from "../../UI/Spinner";
import useIssues from "../../features/useIssues";
import styled from "styled-components";
import JournalLayout from "./JournalLayout";
import { replace_string, server_django } from "../../util";

const Heading = styled.h1`
  font-size: 2.2rem;
  text-transform: uppercase;
  font-weight: 600;
  color: #424242;
  margin: 2rem 0;
`;
const SubTitle = styled.p`
  font-size: 1.2rem;
  font-weight: 600;
  text-transform: capitalize;
  color: #727272;
`;
const Title = styled.h2`
  font-size: 1.6rem !important;
  font-family: "open sans";
  font-weight: 500;
  text-transform: capitalize;
  text-align: justify;
  cursor: pointer;
  color: #496989;
  &:hover {
    color: #6196a6;
  }
`;
const Image = styled.img`
  max-width: 20rem;
`;

const Article = styled.article`
  margin: 3rem 0;
`;

function Issue() {
  const { id } = useParams();
  const { issues, isLoading } = useIssues();
  if (isLoading) return <Spinner />;
  const issue = issues.find((item) => item.id === Number(id));

  return (
    <JournalLayout>
      <Heading>{`${issue.journal.volume} - ${issue.issue}`}</Heading>
      <Image
        className="img-fluid"
        src={issue.cover_image.replace(replace_string, server_django)}
        alt={issue.issue}
      />
      <ul>
        {issue.articles.map((article) => (
          <ArticlesList key={article.id} article={article} />
        ))}
      </ul>
    </JournalLayout>
  );
}

export default Issue;

function ArticlesList({ article }) {
  return (
    <Article>
      <Link to={`/article/${article.id}`}>
        <Title>{article.title}</Title>
      </Link>
      <SubTitle>{article.writer}</SubTitle>
    </Article>
  );
}
