import { replace_string, server_django } from "../../util";

function OfficeEmployeeList({ employees }) {
  const OfficeList = employees.map((employee) => {
    return (
      <tr key={employee.Sl} className="contactInfo">
        <td className="info">
          <p className="heading5">{employee.staff_name}</p>
          {
            <p className="allText">
              {employee.designation.designation}
              <span>{employee.section && ` (${employee.section.name})`}</span>
              {employee.role.length !== 0 && (
                <span>{employee.role.map((item) => `, ${item.role}`)}</span>
              )}
            </p>
          }
          {/* {employee.role.length !== 0 && (
            <p className="allText">{employee.role.map((item) => item.role)}</p>
          )} */}
          <p className="allText">{`${employee.phone_number} Ext: ${employee.extension}`}</p>
          <p className="allText">{employee.official_email}</p>
        </td>
        <td className="contactImg">
          <img
            className="img-fluid"
            src={employee.staff_picture?.replace(replace_string, server_django)}
            alt={employee.staff_name}
          ></img>{" "}
        </td>
      </tr>
    );
  });

  return OfficeList;
}

export default OfficeEmployeeList;
