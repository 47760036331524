import React, { useState } from "react";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import LeftSideNavigationPharmacy from "../../component/leftSideNavigationPharmacy";

const LabFacilitiesPharmacy = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName name="Department of Pharmacy" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationPharmacy />
        <div className="rightSideContent mt-4">
          <p className="heading3 text-center">Lab Facilities</p>
          <div class="">
            <p className="heading5">
              Importance of Labs in the Faculty of Life Science at Eastern
              University (EU)
            </p>
            <p className="allText">
              The Laboratories of the Pharmacy Department at Eastern University
              (EU) are adorned with cutting-edge equipment and instruments to
              promote excellence in teaching, learning, and research. EU
              laboratories play a critical role in assisting faculty and
              students in gaining practical experience for higher education or
              careers in industry as well as in research areas both home and
              abroad. Eastern University is committed to developing and
              maintaining a cutting-edge laboratory. EU has built one of the
              best lab facilities for pharmacy undergraduate students. All EU
              laboratories are well-spaced and meet safety standards, with
              strict adherence to good laboratory practice. The following
              section describes the Pharmacy departments' laboratory
              information:
            </p>
            <p className="heading5">1. Pharmacognosy & Phytochemistry Lab </p>
            <p className="allText">
              Pharmacognosy & Medicinal Chemistry Laboratory is equipped with
              all the modern instruments such as fume hood, thermostatic water
              bath, dryer, distillation set as well as glassware essential for
              laboratory course works.
            </p>
            <p className="heading5">2. Pharmaceutical Analysis Lab </p>
            <p className="allText">
              Being one of the crucial laboratories for the department,
              Pharmaceutical Analysis, Pharmaceutical Technology & Bio-
              pharmaceutics Lab has instruments for major pharmaceutical
              experiments such as dissolution tester, disintegration machine,
              viscometer, UV visible spectrophotometer etc.
            </p>
            <p className="heading5"> 3. Physiology & Pharmacology Lab</p>
            <p className="allText">
              Physiology & Pharmacology Lab has modern facilities such as
              microscope, centrifuge machine, anatomical models, dissecting box
              etc to conduct physiology and pharmacology laboratory course.
            </p>
            <p className="heading5">
              4. Organic Pharmacy & Medicinal chemistry Lab{" "}
            </p>
            <p className="allText">
              Organic Pharmacy & Medicinal chemistry Lab consists of all modern
              equipment like analytical, bunsen burner, heating mantle, vacuum
              pump, hot soxhlet apparatus, water bath, hot air oven, UV
              cabinate.
            </p>
            <p className="heading5">
              5. Pharmaceutical Technology & Cosmetology Lab
            </p>
            <p className="allText">
              Pharmaceutical technology & cosmetology lab is equiped with
              prescription balance, blender, cap selector, capsule filling
              machine, capsule polishing machine, coating machine, dissolution
              tester, distillation unit, glassware, double cone blender, drum
              mixer, friability tester, glassware, granulating sieve set,
              grinding mill, hardness tester, homogenizer, mechanical stirrer,
              hot air oven, pestle and mortar, refrigerator, suppository moulds,
              tablet coating pan, tablet disintegration machine, tablet machine,
              top loading balance, viscometer.
            </p>
            <p className="heading5">6. Pharmaceutical Microbiology Lab</p>
            <p className="allText">
              Microbiology Lab is well equipped with autoclave, incubator,
              centrifuge machine, laminar air flow and other necessary
              equipment’s for conducting microbiology operations.
            </p>
            <p className="heading5">
              7. Biopharmaceutics & Pharmacokinetics Lab
            </p>
            <p className="allText">
              Biopharmaceutics & pharmacokinetics lab is well equipped with
              dissolution tester, friability tester, hardness tester, tablet
              disintegration machine, polari meter, tensile tester.
            </p>
            <p className="heading5">8. Inorganic & Physical Pharmacy Lab </p>
            <p className="allText">
              The inorganic & physical pharmacy lab contains all the necessary
              equipment like analytical balance, deionization water system,
              drying oven, fume hood, titration apparatus, water bath,
              desiccator, hot plate, digital rotator, extraction device, melting
              point apparatus, sonication bath, electric suction pump, soxhlet
              apparatus, solvent distillation unit.
            </p>
            <p className="heading5">9. Research Lab </p>
            <p className="allText">
              The research lab contains all modern equipment like analytical
              balance, ultrasonic cleaner, burette, pipette and glassware and
              TLC.
            </p>
            <p className="heading5">10. Computer Lab </p>
            <p className="allText">
              The Easter University authority has facilitated a well decorated
              computer lab for the pharmacy department when multiple numbers of
              computers are being allocated for student purposes.
            </p>
            <p className="heading5">11. Pharmaceutical Technology Lab-2 </p>
            <p className="allText">
              The Pharmaceutical Technology Lab-2 is in the development process
            </p>
            <p className="heading5">Animal House </p>
            <p className="allText">
              The animal house contains Swiss albino mice along with different
              necessary equipment’s like baby scale, hot plate, animal cage,
              magnetic stir, centrifuge machine etc.
            </p>
            <p className="heading5">Medicinal Garden </p>
            <p className="allText">
              Medicinal garden of Eastern University consists of more than 100
              species of medicinal plants. These plants are planted for
              phytomedicinal research purposes.
            </p>
          </div>
          {/* Mobile Responsive Start*/}
          <div
            id="mobileMenuButton"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <button>
              <VscAdd className="moreMenu" />
            </button>
          </div>
          {mobileMenuOpen && (
            <div id="mobileMenuContent">
              <button
                id="closeButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <VscChromeClose className="closeButtonIcon" />
              </button>
              <LeftSideNavigationPharmacy />
            </div>
          )}
          {/* Mobile Responsive End*/}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LabFacilitiesPharmacy;
