import React from 'react';
import AcademicRulesAndProceduresContent from './content/AcademicRulesAndProceduresContent';
import Footer from '../../component/Footer'

const academicRulesAndProcedures = () => {
    return (
        <div>
            <AcademicRulesAndProceduresContent />
            <Footer />
        </div>
    );
};

export default academicRulesAndProcedures;