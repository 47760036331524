import React, { useState } from "react";
import LeftSideNavigationBusinessDepartment from "../../component/leftSideNavigationDepartmentofBusiness";
import FacultyName from "../../component/facultyName";
import { engFacultyTestImg, MBACourseDistridution } from "../../../../static";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const ProgramsRegularMBADepartment = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName
        name="Department of Business Administration"
        facultyImg={engFacultyTestImg}
      />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationBusinessDepartment />
        <div className="rightSideContent m-4">
          <p className="heading3">
            MBA (Masters of Business Administration) - Regular
          </p>


          <p className="allText">
            The regular MBA program is a multi-disciplinary professional
            graduate study designed to meet the needs of the students who want
            to develop their career as successful managers, executives, and
            professionals of local and multinational organizations and as
            entrepreneurs. The program offers a unique opportunity for the
            prospective and practicing managers and professionals to upgrade
            their professional knowledge, expertise and improve their decision
            making and problem solving skills in different management areas of
            private and public sector and not-for-profit organizations.
          </p>
          <p className="heading5">Objectives</p>
          <p className="allText">
            <ul>
              <li>
                To develop the competence necessary for executives in an
                increasingly complex and globalized business world.
              </li>
              <li>
                To enhance critical thinking and develop communication,
                interpersonal and leadership skills.
              </li>
              <li>
                To enhance the real life exposure and practical orientation of
                the students.
              </li>
              <li>
                To enhance the ability of the students to identify business
                opportunities and resolve managerial problems.
              </li>
              <li>
                To develop students' understanding of the functional areas of
                business and develop a general perspective of their
                interrelationships.
              </li>
              <li>
                To promote the intellectual growth of students who would like to
                pursue higher studies and research leading to Ph.D and to groom
                them to be academics in pursuance of a teaching profession at
                University level in their area of specialization.
              </li>
            </ul>
          </p>
          <p className="heading5">Features of the Regular MBA Program</p>
          <p className="allText">
            The MBA (Regular) is a two-year (six semesters) program. A full-time
            student with any other Master's Degree should be able to complete
            the program in 16 to 20 months. A part time student will however
            take a longer time depending upon the number of courses he enrolls
            in every semester. A student with a 4-year BBA degree should be able
            to complete in 12 to 20 months as he may get exemption in some
            courses. A maximum of 24 credit hours may be exempted. Equivalence
            Committee of the University decides on course waiver based on
            previous results.
          </p>
          <p className="allText">
            Students are required to complete 16 foundation and functional
            courses, one capstone/integrated course and any four courses chosen
            from the area of concentration. In addition to completion of these
            21 courses of 63 credit hours (3 credit hours for each course), a
            student is required to undergo an Internship Program, or write a
            thesis or undertake a Specialized Business Project of 3 credit
            hours. The total number of credit hours that are required to
            complete the regular MBA program at Eastern University is 66.
          </p>
          <p className="allText">
            A student is required to take at least two courses per semester. A
            student must complete the program within a maximum period of 6 (six)
            years from the initial date of registration.
          </p>
          <p className="allText">
            The regular MBA is a comprehensive program with appropriate emphasis
            on functional, cross-functional and support areas of modern business
            and organizational management. In addition to course work, carefully
            selected and designed project works, assignments, case studies, etc.
            serve the purpose of cross-functional integration of professional
            knowledge and skills of students in the context of real life
            business situations. Project works, case assignments, etc. are
            carefully designed to integrate the learning in individual courses
            to the real life practices.
          </p>
          <p className="heading5">Admission Requirement</p>
          <p className="allText">
            Candidates must have at least second division or a minimum GPA of
            2.50 in both SSC/Dakhil and HSC/Alim/Diploma from Technical
            Education Board or Minimum GPA of 2.00 in one of those exam with
            total GPA of 6.00 in both the exams are eligible for admission. The
            GCE Students must have passed five subjects in O-level and two
            subjects in A-level.
          </p>
          <p className="heading5">Degree Requirement</p>
          <p className="allText">
            To qualify for the MBA degree, a student has to pass all the
            required courses individually, pass the internship or the project
            with a minimum “C” grade, earn a minimum Cumulative Grade Point
            Average (CGPA) of 2.50 and maintain a record of good conduct.
          </p>
          <p className='heading5'>
            <a href={MBACourseDistridution}>Download Course Distribution</a>
          </p>
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationBusinessDepartment />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </div>
  );
};

export default ProgramsRegularMBADepartment;
