import React from "react";

import Wrapper from "../../component/Wrapper";

import OtherpageSideBar from "../component/OtherpageSideBar";
import MessageFromChairmanContent from "./content/messageFromChairmanContent";

function messageFromChairman() {
  return (
    <Wrapper>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          {/* <Message messageFrom="Chairman" textStyle="text-center" /> */}
          <MessageFromChairmanContent />
        </div>
        <OtherpageSideBar />
      </div>
    </Wrapper>
  );
}

export default messageFromChairman;
