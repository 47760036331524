import React, { Component } from "react";

import Wrapper from "../../component/Wrapper";
import Message from "../../component/Message";
import OtherpageSideBar from "../component/OtherpageSideBar";

class messageFromVC extends Component {
  render() {
    return (
      <Wrapper>
        <div className="content">
          <div className="OtherPagecontent">
            <hr />
            <Message messageFrom="Vice Chancellor" textStyle="text-center" />
          </div>
          <OtherpageSideBar />
        </div>
      </Wrapper>
    );
  }
}

export default messageFromVC;
