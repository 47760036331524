import React from "react";
import { NavLink } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";

const LeftSideNavigationArts = () => {
  return (
    <div id="facultyLeftSideMenu" className="sidemenu">
      <Menu vertical className="sidemenuBar mt-4">
        <Dropdown text="Home" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <NavLink exact to="/facultyOfArts">
              <Dropdown.Item>About</Dropdown.Item>
            </NavLink>
            <NavLink exact to="/deansMessageArts">
              <Dropdown.Item>Dean's Message</Dropdown.Item>
            </NavLink>

            <NavLink exact to="/chairpersonMessageArts">
              <Dropdown.Item>Chairperson's Message</Dropdown.Item>
            </NavLink>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="Departments" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <NavLink exact to="/departmentOfEnglish">
              <Dropdown.Item>Department of English</Dropdown.Item>
            </NavLink>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown
          text="Programs"
          className="link item sideMenuBarDropdown"
          direction="right"
        >
          <Dropdown.Menu>
            <Dropdown text="Undergraduate" className="link item">
              <Dropdown.Menu>
                <NavLink exact to="/programsBAEnglish">
                  <Dropdown.Item>B.A.(Hons.) in English</Dropdown.Item>
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown text="Graduate" className="link item">
              <Dropdown.Menu>
                <NavLink exact to="/programsMAEnglish">
                  <Dropdown.Item>
                    M.A. ELL(English Language & Literature)
                  </Dropdown.Item>
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="Admission" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <Dropdown
              text="Admission Information"
              pointing="bottom"
              className="link item"
            >
              <Dropdown.Menu>
                <NavLink exact to="/admissionEnglish">
                  <Dropdown.Item>Admission in English</Dropdown.Item>
                </NavLink>
              </Dropdown.Menu>
            </Dropdown>
            <NavLink exact to="/tutionFeesAndFinalcialAidsArts">
              <Dropdown.Item>Tution Fees and Finalcial Aids</Dropdown.Item>
            </NavLink>
          </Dropdown.Menu>
        </Dropdown>
        {/* <Dropdown text='Research' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                            <NavLink exact to='/researchHighlightsArts' >Research Highlights</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/researchEventsArts'>Events</NavLink>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink exact to='/researchInEnglish'>Research in English</NavLink>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown> */}
        <Dropdown
          text="News and Events"
          className="link item sideMenuBarDropdown"
        >
          <Dropdown.Menu>
            <NavLink exact to="/newsArts">
              <Dropdown.Item>News</Dropdown.Item>
            </NavLink>
            <NavLink exact to="/researchEventsArts">
              <Dropdown.Item>Events</Dropdown.Item>
            </NavLink>
            <NavLink exact to="/noticeArts">
              <Dropdown.Item>Notice</Dropdown.Item>
            </NavLink>
          </Dropdown.Menu>
        </Dropdown>

        <Menu.Item className="sideBarSingleItemLast">
          <NavLink exact to="/contactUsArts">
            Contact Us
          </NavLink>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default LeftSideNavigationArts;
