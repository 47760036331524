import React from "react";
import { NavLink } from "react-router-dom";
import IqacSideBar from "../../../component/IqacSideBar";

const IqacResourcesContent = () => {
  return (
    <div className="content">
      <div className="OtherPagecontent">
        <hr />
        <p className="heading2 primaryHeading text-center">Resources</p>
        <hr />
        <p className="allText">
          Resource materials and web links in relation to IQAC
        </p>
        <p className="allText">
          <ul>
            <li>
              <NavLink exact to="/Ugcgovbd">
                www.ugc.gov.bd/
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/heqepugcgovbd">
                www.heqep-ugc.gov.bd/
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/wwwmoedugovbdindexphp">
                www.moedu.gov.bd/index.php?
              </NavLink>
            </li>
          </ul>
        </p>
      </div>
      <IqacSideBar />
    </div>
  );
};

export default IqacResourcesContent;
