import React from "react";
import Footer from "../../component/Footer";
import ResearchSideBar from "../component/ResearchSideBar";
import { deanImgBusness, deanImgEngineering, CRDSecretary } from "../../static";

const CentreForResearchAndDevelopment = () => {
  return (
    <div>
      <div className="content">
        <div className="OtherPagecontent">
          <hr />
          <p className="heading2 primaryHeading text-center">
            Centre for Research and Development (CRD)
          </p>
          <hr />
          <div clasName="history">
            <p className="heading4 primaryHeading">Introductory</p>

            <blockquote>
              "Starving research and development is like eating the seed
              corn”-Mitt Romney
            </blockquote>
            <p className="allText">
              {" "}
              Welcome to the Centre for Research and Development (CRD)! CRD is
              the knowledge creation catalyst of Eastern University. It
              coordinates research activities of the faculties, handles internal
              and external research funds and thereby promotes the development
              of newer knowledge for the service of the nation and the humanity
              as a whole. The areas of research include arts, natural and social
              sciences like business and law. CRD is open to collaboration for
              research with any person or institution from within the country
              and without.
            </p>
            <p className="allText">
              Every year EU allocates funds for research and development
              purposes, which CDR distributes among the faculties by
              periodically inviting applications from them. It encourages them
              to take this opportunity to conduct research in the areas of their
              interests. In the process of their investigations, they are
              required to involve qualified students in capacity of research
              assistants (RAs) and thereby to prepare them for higher studies
              and future academic careers. The researchers may involve academics
              from local and foreign institutions in their projects. They are
              also encouraged to get research funding from outside sources as
              well.{" "}
            </p>
            <p className="allText">
              CRD facilitates the faculties to attend conferences locally and
              internationally and disseminate their research findings. It
              encourages them to publish both in the Eastern University Journal
              and other national and international journals. It prefers them to
              publish in indexed journals like ISI and Scopus journals. It has a
              policy of conferment of awards on those who publish in high impact
              journals.
            </p>
            <p className="allText">
              From time to time, CRD organizes workshops, dialogues, colloquiums
              and conferences on both fundamental and applied research topics.
              It offers partnership with other institutions in this regard.
            </p>
            <p className="allText">
              CRD publishes the Eastern University Journal twice a year that
              carries quality research papers accepted after strict blind
              review. Everybody is welcome to contribute to the journal.
            </p>
            <p className="heading4 mt-4">CRD Members</p>
            <table className="table officeImg">
              <tbody>
                <tr className="contactInfo">
                  <td className="info">
                    <p className="card-text heading5">
                      Professor Dr. Md Abbas Ali Khan
                    </p>
                    <p className="card-text allText">Director</p>
                  </td>
                  <td className="contactImg">
                    <img
                      className="img-fluid"
                      src={deanImgBusness}
                      alt="CRD Director"
                    ></img>{" "}
                  </td>
                </tr>
                <tr className="contactInfo">
                  <td className="info">
                    <p className="card-title heading5">
                      Professor Dr Md Mahfuzur Rahman
                    </p>
                    <p className="card-text allText">Member</p>
                  </td>
                  <td className="contactImg">
                    <img
                      className="img-fluid"
                      src={deanImgEngineering}
                      alt="E&T dean comes here!"
                    ></img>{" "}
                  </td>
                </tr>
              </tbody>
            </table>
            <hr />
            <p className="heading4 mt-4">CRD Secretariat</p>
            <table className="table officeImg">
              <tbody>
                <tr className="contactInfo">
                  <td className="info">
                    <p className="card-title heading5">Ms Rokeya Hasan</p>
                    <p className="card-text allText">Secretary</p>
                  </td>
                  <td className="contactImg">
                    <img
                      className="img-fluid"
                      src={CRDSecretary}
                      alt="CRD Secretary  comes here!"
                    ></img>{" "}
                  </td>
                </tr>
              </tbody>
            </table>
            <p className="heading4 mt-4">Contact Us</p>
            <p className="allText">Centre for Research and Development (CRD)</p>
            <p className="allText">Eastern University Building, 7th Floor</p>
            <p className="allText">Road 6, Block B, Ashulia Model Town,</p>
            <p className="allText">Savar, Dhaka-1345</p>
          </div>
        </div>
        <ResearchSideBar />
      </div>
      <Footer />
    </div>
  );
};

export default CentreForResearchAndDevelopment;
