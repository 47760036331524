import React, { useState } from "react";
import LeftSideNavigationEnglish from "../../component/leftSideNavigationEnglish";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import CurrentFaculty from "../../component/FacultyMembersByDepartment";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";
import { departmentCode, employeeType } from "../../../../util";

const AtAGlanceFacultyMembersEnglish = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const config = { department: departmentCode.english };

  const status = {
    full: employeeType.full,
    part: employeeType.part,
    acting: employeeType.acting,
    contractual: employeeType.contractual,
    leave: employeeType.leave,
  };

  return (
    <div>
      <FacultyName name="Department of English" />
      <div className="facultyContent">
        <LeftSideNavigationEnglish />
        <div className="rightSideContent mt-4">
          <p className="heading3">At a Glance</p>

          <CurrentFaculty
            adjunct={status.part}
            config={config}
            statusID={status.full}
            contractual={status.contractual}
          />

          {/* <CurrentFaculty
            heading="Adjunct Faculty Members"
            config={config}
            statusID={status.part}
          /> */}

          <CurrentFaculty
            heading="On Leave Faculty Members"
            config={config}
            statusID={status.leave}
          />
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationEnglish />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </div>
  );
};

export default AtAGlanceFacultyMembersEnglish;
