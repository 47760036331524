import React, { Component } from 'react';
import AdmissionSidebar from '../../component/AdmissionSidebar';

class UndergraduateAdmissionEligibilityContent extends Component {
    render() {
        return (
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr/>
                    <p className='heading2 primaryHeading'>
                        Requirements:
                    </p>
                    <hr/>
                    <div className='container'>
                        <ol start="1" className='allText'>
                            <li>Minimum GPA of 2.5 each in SSC and HSC or equivalent public examinations.</li>
                            <li>Candidates with GPA 2.00 in any examination must have minimum GPA 6.00 combined.</li>
                            <li>In case of the ward of Freedom Fighter, at least GPA 5.00 
                                combined in SSC/Dakhil, HSC/Alim or Equivalent Examination is 
                                required. Proof of Certificate must be submitted in this 
                                connection.</li>
                            <li>‘0’ Level in 5 (five) subjects and ‘A’ Level in 2 (two) major 
                                subjects with minimum ‘B’ Grade in four subjects and ‘C’ Grade 
                                in three subjects.</li>
                            <li>For EEE/CSE programs, students must have had Physics and 
                                Mathematics in HSC/A-Level or equivalent level with fulfilling the 
                                above criteria.</li>
                            <li>Acceptable score in EU Admission Test.</li>
                        </ol>
                    </div>
                </div>
                <AdmissionSidebar/>
            </div>
        );
    }
}

export default UndergraduateAdmissionEligibilityContent;