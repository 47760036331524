import React, { Component } from "react";
import Wrapper from "../../component/Wrapper";
import { NavLink } from "react-router-dom";
import AdmissionSidebar from "../component/AdmissionSidebar";

class admissionInformation extends Component {
  render() {
    return (
      <Wrapper>
        <div className="content">
          <div className="OtherPagecontent">
            <hr />
            <p className="heading2 primaryHeading text-center">
              Admission Information
            </p>
            <hr />
            {/* <p className='heading6'>Admission Schedule</p>
                    <p className="allText">
                        Eastern University admits students every semester. Admission schedule of
                        the semester showing deadlines for admission form submission, admission
                        test, admission fee payment, registration, withdrawal, class commencement
                        etc. is available on the website of the University. The admission schedule
                        may also be known from the Admission Office and from frequent admission
                        announcements published by the University in the leading newspapers.
                        The students seeking admission should visit Website of Eastern University or
                        contact the Admission Office for admission form and further information.
                    </p>
                    <p className='heading6'>There are three intakes at EU</p>
                    <ul>
                        <li className="allText">Spring (January – April)</li>
                        <li className="allText">Summer (May – August)</li>
                        <li className="allText">Fall (September – December)</li>
                    </ul>

                    <p className='heading6'>Admission Test: </p>
                    <p className="allText">
                    One hour Admission Test consisting of English Grammar, General Knowledge, Paragraph 
                    writing. Analytical Ability (for BBA), Physics, Chemistry & Mathematics (for CSE & EEE), 
                    Writing Skill (for LLB), Reading comprehension and Composition (for English).
                    </p>

                    <p className='heading6'>Provisional Admission</p>
                    <p className="allText">
                        Students who have appeared at the last required examination for admission
                        may apply for provisional admission. Every student must submit the required
                        certificates and other documents at the time of admission. Applicants
                        seeking admission who are not able to produce one or more documents at the
                        time of admission may be admitted provisionally. All provisionally admitted
                        students are required to submit appropriate documents within a given
                        deadline as a prerequisite for continued enrollment.
                    </p>
                    <p className='heading6'>Non-degree Students</p>
                    <p className="allText">
                        Student of another university or equivalent educational institution may
                        register in courses as non-degree student on payment of requisite fees for
                        such students. The total number of courses s/he may register in shall not
                        exceed 50 percent of the courses required for the degree.
                    </p>
                    <p className='allText'>Course Waiver/Exemption for Graduate Students</p>
                    <p className="allText">
                        A graduate student with undergraduate degree from Eastern University may
                        get waiver or exemption of courses to a maximum of 50% of the total credits
                        required for the degree. A graduate student with undergraduate degree from
                        other universities may get waiver or exemption of courses to a maximum of
                        35% of the total credits required for the degree. In exceptional cases, the
                        limit may be raised to 40% by the appropriate authority. Transcripts and
                        other supporting documents must be submitted with the application for
                        exemption/waiver.
                    <p />
                        <p className='allText'>
                            Student may apply for exemption from courses of other universities in
                            which s/he has at least B – grades and the course contents match at least
                            up to 85% with the contents of the corresponding course at EU.
                        </p>
                        <p className='heading6'>Student Identification Card</p>
                        <p className="allText">
                            A new student receives, upon admission, an identification (ID) card with a
                            unique number. This card is used for entering and staying in the campus,
                            attending classes, using the library, internet and computer services, etc.
                            The students must take good care of the ID and must report of its loss,
                            if any, to the Registrar’s Office immediately so that the card can be
                            cancelled to prevent its misuse. The Registrar’s Office will issue a
                            duplicate ID Card upon application and payment of fees by the student.
                            A student is liable to pay for any loss incurred by the University for the
                            missing the card.
                        </p>
                        <p className='heading6'>Orientation</p>
                        <p className="allText">
                            All new students must participate in the orientation program at the
                            beginning of the semester. Students are encouraged to bring along their
                            parents / guardians to the orientation ceremony. The orientation program
                            acquaints the students with the academic system of the University, academic
                            rules, educational opportunities, facilities and services available at
                            the University.
                        </p>
                        <p className='heading6'>Admission Office :</p>
                        <p className="allText">
                            The Admission Office is located on Campus Building of the University & at
                            the following addresses :
                        </p>
                        <p className='heading6'>Eastern University</p>
                        <p className="allText">
                            Road 6, Block B, Ashulia Model Town,
                            Akran, Savar Dhaka.<br /> Phone: 09602666651 Cell: 01844169651, 01844169659<br /> 01844169660, 01741300002<br />E-mail: admission@easternuni.edu.bd
                        </p>
                    </p> */}
            <p className="heading4"> ADMISSION SCHEDULE FOR SPRING 2025</p>
            <p className="allText">
              <strong>Admission Last Date:</strong> Admission is now open for
              Spring 2025 semester. Please call to know the last date of
              Admission.
            </p>{" "}
            <p className="allText">
              <strong>Class Start (Tentative Date) </strong> : 5th January 2025
            </p>
            <p className="allText">
              <strong>Orientation (Tentative Date) </strong> : Will be informed
              later
            </p>
            <p className="heading4">Intake/ Semester </p>
            <p className="allText">
              There are two (02) intakes/ Semester at Eastern University. Each
              semester duration is six (06) months.
            </p>
            <p className="allText">Spring (January – June)</p>
            <p className="allText">Summer (July – December)</p>
            <p className="heading4">Programs</p>
            <div className="row">
              <div className="col-md-6 col-sm-12">
                <p className="heading5">Undergraduate Programs</p>
                <ul>
                  <li>
                    <p className="allText">
                      <NavLink exact to="/programsBAEnglishDepartment">
                        B.A. (Hons.) in English
                      </NavLink>
                    </p>
                  </li>
                  <li>
                    <p className="allText">
                      <NavLink exact to="/programsBBADepartment">
                        BBA (Bachelor of Business Administration)
                      </NavLink>
                    </p>
                  </li>
                  <li>
                    <p className="allText text-left">
                      <NavLink exact to="/programsBBAonIslamicDepartment">
                        BBA in Islamic Finance,Banking and Insurance (Bachelor
                        of Business Administration)
                      </NavLink>
                    </p>
                  </li>
                  <li>
                    <p className="allText">
                      <NavLink exact to="/bscinCivil">
                        B.Sc. in CE (Civil Engineering)
                      </NavLink>
                    </p>
                  </li>
                  <li>
                    <p className="allText">
                      <NavLink exact to="/bscinCSEDepartmentProgram">
                        B.Sc. in CSE (Computer Science and Engineering)
                      </NavLink>
                    </p>
                  </li>
                  <li>
                    <p className="allText">
                      <NavLink exact to="/bscinEEEDepartmentProgram">
                        B.Sc. in EEE (Electrical and Electronic Engineering)
                      </NavLink>
                    </p>
                  </li>
                  <li>
                    <p className="allText">
                      <NavLink exact to="/programsLLBLawDepartment">
                        LL.B (Hons.)
                      </NavLink>
                    </p>
                  </li>
                  <li>
                    <p className="allText">
                      <NavLink exact to="/bachelorOfPharmacy">
                        B. Pharm. (Bachelor of Pharmacy)
                      </NavLink>
                    </p>
                  </li>
                </ul>
              </div>
              <div className="col-md-6 col-sm-12">
                <p className="heading5">Graduate Programs</p>
                <ul>
                  <li>
                    <p className="allText">
                      <NavLink exact to="/programsRegularMBADepartment">
                        MBA (Master of Business Administration)
                      </NavLink>
                    </p>
                    <p className="allText text-left">
                      <NavLink exact to="/programsMBAonIslamicDepartment">
                        MBA in Islamic Finance, Banking and Insurance (Master of
                        Business Administration)
                      </NavLink>
                    </p>
                    <p className="allText">
                      <NavLink exact to="/programsExecutiveMBADepartment">
                        EMBA (Executive Master of Business Administration)
                      </NavLink>
                    </p>
                  </li>
                  <li>
                    <p className="allText">
                      <NavLink exact to="/programsMAEnglishDepartment">
                        M.A. ELL (English Language and Literature)
                      </NavLink>
                    </p>
                  </li>
                  <li>
                    <p className="allText">
                      <NavLink exact to="/programsLLMLawDepartment">
                        LL.M
                      </NavLink>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
            <p className="heading4">Admission Procedure</p>
            <p className="allText">
              A candidate may take admission directly (without an admission test
              except LLB program) or through an admission test and it would be
              physically/ offline or Online.
            </p>
            <p className="heading4">Direct Admission</p>
            <p className="allText">
              {" "}
              Physical/ Offline process: An applicant can take direct admission
              based on their academic result.{" "}
            </p>
            <p className="allText">
              <strong>Step-01: </strong>Collect Application Form from the
              Admission Office by paying BDT 500/-
            </p>
            <p className="allText">
              <strong>Step-02:</strong> Submit the Application Form duly filled
              in with all academic transcript and certificates and three (03)
              passport size color photographs.
            </p>
            <p className="allText">
              <strong> Step-03: </strong>Deposit admission fees to the accounts
              section.
            </p>
            <p className="allText">Online Direct Admission Process:</p>
            <p className="allText">
              <strong>Step-01: </strong>Please visit
              https://www.easternuni.edu.bd/OnlineAdmission
            </p>
            <p className="allText">
              <strong>Step-02:</strong> Fill-up the Application Form
            </p>
            <p className="allText">
              <strong> Step-03: </strong>Pay BDT 500 to bKash no. 01795711361,
              and collect the TrxID number
            </p>
            <p className="allText">
              <strong>Step-04: </strong>Provide the TrxID number
            </p>
            <p className="allText">
              <strong>Step-05:</strong> Submit the form online
            </p>
            <p className="allText">
              <strong> Step-06: </strong>Admission office will contact the
              candidates for further direct admission guidelines.
            </p>
          </div>
          <AdmissionSidebar />
        </div>
      </Wrapper>
    );
  }
}

export default admissionInformation;
