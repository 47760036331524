import React from "react";
import {
  IQACDocumentsCourseSpecification,
  IQACDocumentsCourseSpecification2,
  IQACDocumentsFieldExperienceSpecification,
  IQACDocumentsIQACtotalFund,
  IQACDocumentsPrivateUniversityAct2010,
  IQACDocumentsTeacherPerformanceform,
  IQACDocumentsSAReport,
} from "../../../../static";
import IqacSideBar from "../../../component/IqacSideBar";

const IqacDocumentsContent = () => {
  return (
    <div className="content">
      <div className="OtherPagecontent">
        <hr />
        <p className="heading2 primaryHeading text-center">Documents</p>
        <hr />
        <p className="allText">
          <ul>
            <li>
              <a href={IQACDocumentsCourseSpecification}>
                Course Specification-1
              </a>
            </li>
            <li>
              <a href={IQACDocumentsCourseSpecification2}>
                Course Specification-2
              </a>
            </li>
            <li>
              <a href={IQACDocumentsFieldExperienceSpecification}>
                Field Experience Specification
              </a>
            </li>
            <li>
              <a href={IQACDocumentsIQACtotalFund}> IQAC Total Fund</a>
            </li>
            <li>
              <a href={IQACDocumentsPrivateUniversityAct2010}>
                Private University Act-2010
              </a>
            </li>
            <li>
              <a href={IQACDocumentsTeacherPerformanceform}>
                Teacher Performance form
              </a>
            </li>
            <li>
              <a href={IQACDocumentsSAReport}> Khulna University- SA Report</a>
            </li>
          </ul>
        </p>
      </div>
      <IqacSideBar />
    </div>
  );
};

export default IqacDocumentsContent;
