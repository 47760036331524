import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { getNoticeArchive } from "../services/apis";
import { PAGE_SIZE } from "../util";

function useNoticeArchive() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const page = Number(searchParams.get("page")) || 1;
  const queryClient = useQueryClient();

  const {
    data: { data: notices, count } = {},
    isLoading,
    error,
  } = useQuery({
    queryKey: ["noticeArchive", page],
    queryFn: () => getNoticeArchive({ page }),
  });

  //pre-fetching
  const pageCount = Math.ceil(count / PAGE_SIZE);
  if (page < pageCount)
    queryClient.prefetchQuery({
      queryKey: ["noticeArchive", page + 1],
      queryFn: () => getNoticeArchive({ page: page + 1 }),
    });
  if (page > 1)
    queryClient.prefetchQuery({
      queryKey: ["noticeArchive", page - 1],
      queryFn: () => getNoticeArchive({ page: page - 1 }),
    });

  return { notices, isLoading, error, count };
}

export default useNoticeArchive;
