import React, { Component } from 'react';
import FeeStructureForForeignStudentsContent from './Content/FeeStructureForForeignStudentsContent';
import Footer from '../../component/Footer';

class feeStructureForForeignStudents extends Component {
    render() {
        return (
            <div>
                <FeeStructureForForeignStudentsContent/>
                <Footer/>
            </div>
        );
    }
}

export default feeStructureForForeignStudents;