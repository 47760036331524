import React from "react";
import IqacOfficeContent from "./content/IqacOfficeContent";
import Footer from "../../../component/Footer";

const iqacOffice = () => {
  return (
    <div>
      <IqacOfficeContent />
      <Footer />
    </div>
  );
};

export default iqacOffice;
