import React from 'react';
import StudentActivitiesSideBar from '../../component/StudentActivitiesSideBar';
import Footer from '../../../component/Footer';

const studentactivities = () => {
    return (
        <>
            <div className='content'>
                <div className='OtherPagecontent'>
                    <hr />
                    <p className='heading2 text-center'>
                        Student Activities
                </p>
                    <hr />
                    <p className='allText'>
                        The overall activities of OSA are divided into two major areas of operations:
                    </p>
                    <p className='heading5'>
                        Campus Activities
                    </p>
                    <p className='allText'>
                        <ul>
                            <li className='allText'>Student Orientation</li>
                            <li className='allText'>Students’ Discipline</li>
                            <li className='allText'>Student Services and Facilities</li>
                            <li className='allText'>Event Organization & Festival Celebration</li>
                            <li className='allText'>Student Organizations and Clubs activities</li>
                            <li className='allText'>Extra-curricular activities and competitions</li>
                        </ul>
                    </p>
                    <p className='heading5'>
                        External Activities
                    </p>
                    <p className='allText'>
                        <ul>
                            <li className='allText'>National & International Competitions</li>
                            <li className='allText'>Student Exchange Programs</li>
                            <li className='allText'> Community Service</li>
                            <li className='allText'>Event Participation</li>
                            <li className='allText'>Collaboration and Liaison with External Organizations</li>
                        </ul>
                    </p>
                </div>
                <StudentActivitiesSideBar />
            </div>
            <Footer />
        </>
    );
};

export default studentactivities;