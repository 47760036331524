import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class OtherpageSideBar extends Component {
  render() {
    return (
      <div className="OtherpageSideBar">
        <ul className="list-group list-group-flush">
          <li className="nav-item dropdown list-group-item">
            <NavLink className="nav-link" exact to="/aboutEu">
              EU Profile
            </NavLink>
          </li>
          <li className="nav-item dropdown list-group-item">
            <a
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Trustees of EU
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              {/* <li className="list-group-item">
                <NavLink
                  eaxct
                  to="/messageFromChairman"
                  className="dropdown-item"
                >
                  Message from Chairman
                </NavLink>
              </li> */}
              <li className="list-group-item">
                <NavLink eaxct to="/boardOfTrustees" className="dropdown-item">
                  Board of Trustees
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink eaxct to="/formerChairmen" className="dropdown-item">
                  Former Chairmen of EU
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink eaxct to="/membersofEUTrust" className="dropdown-item">
                  Members of EU Foundation
                </NavLink>
              </li>
            </div>
          </li>
          <li className="nav-item dropdown list-group-item">
            <a
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Executive Leaders
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li className="list-group-item">
                <NavLink eaxct to="/messageFromVC" className="dropdown-item">
                  Message from the VC
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink eaxct to="/treasurer" className="dropdown-item">
                  Treasurer
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink eaxct to="/deanschairperson" className="dropdown-item">
                  Deans & Chairpersons
                </NavLink>
              </li>
              {/* <li className='list-group-item'>
                                <NavLink eaxct to='/administration' className="dropdown-item">Administration</NavLink>
                            </li> */}
              <li className="list-group-item">
                <NavLink eaxct to="/proctor" className="dropdown-item">
                  Proctors
                </NavLink>
              </li>
            </div>
          </li>

          <li className="nav-item dropdown list-group-item">
            <a
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Authorities
            </a>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              <li className="list-group-item">
                <NavLink eaxct to="/governance" className="dropdown-item">
                  Governance
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink eaxct to="/syndicate" className="dropdown-item">
                  Syndicate
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink eaxct to="/academicCouncil" className="dropdown-item">
                  Academic Council
                </NavLink>
              </li>
              <li className="list-group-item">
                <NavLink eaxct to="/otherCommittees" className="dropdown-item">
                  Other Committees
                </NavLink>
              </li>
            </div>
          </li>
          <li className="list-group-item">
            <NavLink className="nav-link" exact to="/allOtherOffices">
              Offices
            </NavLink>
          </li>
        </ul>
      </div>
    );
  }
}

export default OtherpageSideBar;
