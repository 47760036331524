import React from "react";
import { NavLink } from "react-router-dom";
import { Dropdown, Menu } from "semantic-ui-react";

const leftSideNavigationDepartmentofBusiness = () => {
  return (
    <div id="facultyLeftSideMenu" className="sidemenu">
      <Menu vertical className="sidemenuBar">
        <Dropdown
          floating
          text="Home"
          className="link item sideMenuBarDropdown"
        >
          <Dropdown.Menu>
            <Dropdown.Item>
              <NavLink exact to="/departmentOfBusinessAdministration">
                Business Home
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/">
                EU Home
              </NavLink>
            </Dropdown.Item>

            <Dropdown text="About" className="link item sideMenuBarDropdown">
              <Dropdown.Menu>
                <Dropdown.Item>
                  <NavLink exact to="/welcomeMessageBusinessDepartment">
                    Welcome Message
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/exploringBusinessDepartment">
                    Exploring Business EU
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/clubInformationBusinessDepartment">
                    Clubs Information
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item className="sideBarSingleItem">
          <NavLink exact to="/missionVisionBusiness">
            Vision and Mission
          </NavLink>
        </Menu.Item>
        <Dropdown
          text="Academic"
          className="link item sideMenuBarDropdown vertical-dropdown"
        >
          <Dropdown.Menu className="vertical-menu">
            {/* Programs */}
            <Dropdown
              text="Programs"
              className="link item sideMenuBarDropdown vertical-dropdown"
            >
              <Dropdown.Menu>
                <Dropdown
                  text="Undergraduate"
                  className="link item vertical-dropdown"
                >
                  <Dropdown.Menu className="vertical-menu">
                    <NavLink exact to="/programsBBADepartment">
                      <Dropdown.Item>BBA</Dropdown.Item>
                    </NavLink>
                    <NavLink exact to="/programsBBAonIslamicDepartment">
                      <Dropdown.Item>
                        BBA in Islamic Finance,Banking and Insurance
                      </Dropdown.Item>
                    </NavLink>
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown
                  text="Graduate"
                  className="link item vertical-dropdown"
                >
                  <Dropdown.Menu className="vertical-menu">
                    <NavLink exact to="/programsRegularMBADepartment">
                      <Dropdown.Item>Regular-MBA</Dropdown.Item>
                    </NavLink>
                    <NavLink exact to="/programsExecutiveMBADepartment">
                      <Dropdown.Item>Executive-MBA</Dropdown.Item>
                    </NavLink>
                    <NavLink exact to="/programsMBAonIslamicDepartment">
                      <Dropdown.Item>
                        MBA in Islamic Finance,Banking and Insurance
                      </Dropdown.Item>
                    </NavLink>
                  </Dropdown.Menu>
                </Dropdown>
              </Dropdown.Menu>
            </Dropdown>

            {/* Admission */}
            <Dropdown
              text="Admission"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <Dropdown.Item>
                  <NavLink exact to="/underGraduateAdmissionBusiness">
                    Undergraduate
                  </NavLink>
                </Dropdown.Item>

                <Dropdown.Item>
                  <NavLink exact to="/graduateAdmissionBusiness">
                    Graduate
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* Curriculum */}
            <Dropdown
              text="Curriculum"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <Dropdown.Item>
                  <NavLink exact to="/underGraduateCurriculumBusiness">
                    Undergraduate
                  </NavLink>
                </Dropdown.Item>

                <Dropdown.Item>
                  <NavLink exact to="/graduateCurriculumBusiness">
                    Graduate
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown text="People" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            {/* Faculty Members */}
            {/* <Dropdown
              text="Faculty Members"
              className="link item sideMenuBarDropdown"
            >
              <Dropdown.Menu>
                <Dropdown.Item>
                  <NavLink exact to="/atAGlanceFacultyMembersBusiness">
                    At A Glance
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/currentFacultyMembersBusiness">
                    Current Faculty{" "}
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/currentAdjunctFacultyMembersBusiness">
                    Adjunct Faculty
                  </NavLink>
                </Dropdown.Item>
                <Dropdown.Item>
                  <NavLink exact to="/onLeaveFacultyMembersBusiness">
                    On Leave
                  </NavLink>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
            <Dropdown.Item>
              <NavLink exact to="/atAGlanceFacultyMembersBusiness">
                Faculty Members
              </NavLink>
            </Dropdown.Item>
            {/* Officials */}
            <Dropdown.Item>
              <NavLink exact to="/officialsBusiness">
                Officials
              </NavLink>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        {/* Lab */}
        <Menu.Item className="sideBarSingleItem">
          <NavLink exact to="/labFacilitiesBusiness">
            Lab Facilities
          </NavLink>
        </Menu.Item>

        {/* <Dropdown text='Research' className='link item sideMenuBarDropdown'>
                    <Dropdown.Menu>
                        <Dropdown.Item>
                          <NavLink exact to='/publicationsBusiness' tabindex="-1">Publications</NavLink> 
                            <a href='http://dspace.easternuni.edu.bd:8080/xmlui/' target='_blank'>Publications</a>
                        </Dropdown.Item>
                        <Dropdown.Item>
                            <NavLink tabindex="-1" exact to='/researchGroupBusiness'>Research Groups</NavLink>
                        </Dropdown.Item>

                    </Dropdown.Menu>
                </Dropdown> */}

        {/* Announcement */}
        <Dropdown text="Announcement" className="link item sideMenuBarDropdown">
          <Dropdown.Menu>
            <Dropdown.Item>
              <NavLink tabindex="-1" exact to="/newsBusiness">
                News
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink tabindex="-1" exact to="/upcomingEventsBusiness">
                Upcoming Events
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item>
              <NavLink exact to="/noticeBusiness" tabindex="-1">
                Notice
              </NavLink>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Menu.Item className="sideBarSingleItem">
          {/* <NavLink exact to='/alumniBusiness'>Alumni</NavLink> */}
          <a
            href="http://webportal.easternuni.edu.bd/Online_Alumni/_AlumniSearch.aspx"
            target="_blank"
          >
            Alumni
          </a>
        </Menu.Item>
        <Menu.Item className="sideBarSingleItemLast">
          <NavLink exact to="/contuctUsBusiness">
            Contact Us
          </NavLink>
        </Menu.Item>
      </Menu>
    </div>
  );
};

export default leftSideNavigationDepartmentofBusiness;
