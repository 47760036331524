import React, { useState } from "react";
import LeftSideNavigationEEE from "../../component/leftSideNavigationEEE";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import { EEECourseDistridution } from "../../../../static";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const BscinEEEDepartmentProgram = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <div>
      <FacultyName name="Department of Electrical and Electronic Engineering" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationEEE />
        <div className="rightSideContent mt-4">
          <p className="heading3">
            B.Sc. in EEE (Electrical and Electronic Engineering)
          </p>

          <p className="allText">
            The Department of Electrical and Electronic Engineering offers
            program leading to the degree of B.Sc. in Electrical and Electronic
            Engineering (EEE). The credit hours required for completion of the
            B. Sc. (EEE) program is 154. The program includes courses from
            General areas namely, English, Physics, Chemistry, Economics,
            Accounting, Arts, and from Engineering disciplines namely,
            Electrical, Electronics, Telecommunication, Industrial Derives,
            Control System and Power Systems Engineering as major area, and also
            Computer and Mechanical Engineering as allied Engineering. The
            program requires some of the courses as core courses and it has left
            option for the students to choose a few courses as electives for
            specialization.
          </p>
          <p className="allText">
            The curriculum for the B.Sc. (EEE) has been designed to make the
            program goal-oriented to fulfill the needs of the age.
          </p>
          <p className="heading5">Entry Requirement</p>
          <p className="allText">
            Candidates with a minimum GPA of 3.00 in both SSC/Dakhil and
            HSC/Alim/Diploma in Engineering from Technical Education Board. The
            GCE students must have passed five subjects in O-Level and two
            subjects in A-Level. Students must be from science background in SSS
            and HSC or equivalent.
          </p>

          <p className="heading5">Duration of the program</p>
          <p className="allText">
            The duration for B. Sc. in Electrical and Electronic Engineering
            program is four years divided into 8 semesters.
          </p>
          <p className="heading5">Graduation Requirement</p>
          <p className="allText">
            To obtain BSEEE degree a student will have to complete 154.0 cr.
            hrs. with a minimum CGPA of 2.5.
          </p>
          <p className="heading5">
            <a href={EEECourseDistridution}>Download Course Distribution</a>
          </p>
          <p className="heading5">The Program Educational Objectives (PEOs):</p>
          <p className="allText">
            The purpose of PEOs is to prepare graduates to possess the ability
            to:
          </p>

          <p className="allText">
            <strong>PEO-1:</strong> : Offer top-notch education and hands-on
            practical training to graduates within a well-equipped setting,
            fostering the cultivation of exemplary professionalism aligned with
            engineering standards and ethical principles.
          </p>
          <p className="allText">
            <strong>PEO-2:</strong> Improve the capacity to address intricate
            engineering challenges and sustainable solutions by imparting
            specialized and advanced knowledge through research, innovation, and
            the utilization of contemporary tools that enables graduates to
            autonomously navigate the broader landscape of technological
            advancements.
          </p>
          <p className="allText">
            <strong>PEO-3: </strong>Enhance effective communication, leadership,
            and managerial skills through collaborative teamwork and individual
            tasks within various engineering educational activities and project
            assignments.
          </p>

          <p className="heading5"> Program Outcomes: </p>
          <p className="allText">
            Program outcomes are mainly focused on developing the students in
            terms of skills, knowledge and behavior during their graduation
            period. There are 12 POs for the engineering students which are
            listed below.
          </p>

          <table className="table table-striped ">
            <thead className="thead-light">
              <tr>
                <th>POs</th>
                <th>Knowledge</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>PO-1</th>
                <th>Engineering Knowledge</th>
                <td>
                  Apply knowledge of mathematics, science, engineering
                  fundamentals and an engineering specialization to the solution
                  of complex engineering problems.
                </td>
              </tr>
              <tr>
                <th>PO-2</th>
                <th>Problem Analysis</th>
                <td>
                  Identify, formulate, research literature and analyze complex
                  engineering problems searching substantiated conclusions using
                  first principles of mathematics, natural sciences and
                  engineering sciences.
                </td>
              </tr>
              <tr>
                <th>PO-3</th>
                <th>Design/ development of solutions</th>
                <td>
                  Design solutions for complex engineering problems and design
                  systems, components and processes that meet specified needs
                  with appropriate consideration for public health and safety,
                  cultural, societal and environmental considerations.
                </td>
              </tr>
              <tr>
                <th>PO-4</th>
                <th>Investigation</th>
                <td>
                  Conduct investigations of complex problems using research
                  based knowledge and research methods including design of
                  experiments, analysis and interpretation of data and synthesis
                  of information to provide valid conclusions.
                </td>
              </tr>
              <tr>
                <th>PO-5</th>
                <th>Modern Tool Usage</th>
                <td>
                  Create, select and apply appropriate techniques, resources and
                  modern engineering and IT tools, including prediction and
                  modeling to complex engineering activities with an
                  understanding of the limitations.
                </td>
              </tr>
              <tr>
                <th>PO-6</th>
                <th>The Engineer and Society</th>
                <td>
                  Apply reasoning informed by contextual knowledge to assess the
                  societal, health, safety, legal and cultural issues and the
                  consequent responsibilities relevant to professional
                  engineering practice.
                </td>
              </tr>
              <tr>
                <th>PO-7</th>
                <th>Environment and Sustainability</th>
                <td>
                  Understand the impact of professional engineering solutions in
                  societal and environmental contexts and demonstrate knowledge
                  of and need for sustainable development.
                </td>
              </tr>
              <tr>
                <th>PO-8</th>
                <th>Ethics</th>
                <td>
                  Apply ethical principles and commit to professional ethics and
                  responsibilities and norms of engineering practice.
                </td>
              </tr>
              <tr>
                <th>PO-9</th>
                <th>Individual and Teamwork</th>
                <td>
                  Function effectively as an individual, and as a member or
                  leader in diverse teams and in multi-disciplinary settings.
                </td>
              </tr>
              <tr>
                <th>PO-10</th>
                <th>Communication</th>
                <td>
                  Communicate effectively on complex engineering activities with
                  the engineering community and with society at large, such as
                  being able to comprehend and write effective reports and
                  design documentation, make effective presentations and give
                  and receive clear instructions.
                </td>
              </tr>
              <tr>
                <th>PO-11</th>
                <th>Project Management and Finance</th>
                <td>
                  Demonstrate knowledge and understanding of engineering and
                  management principles and apply these to one’s own work, as a
                  member and leader in a team, to manage projects and
                  multidisciplinary environments.
                </td>
              </tr>
              <tr>
                <th>PO-12</th>
                <th>Lifelong learning</th>
                <td>
                  Understand the impact of professional engineering solutions in
                  societal and environmental contexts and demonstrate knowledge
                  of and need for sustainable development.
                </td>
              </tr>
            </tbody>
          </table>
          <p className="allText">
            The relationship between PEOs and POs is given in following Table:
          </p>
          <table className="table table-bordered">
            <tr>
              <th>PEO\PO</th>
              <th>PO-1</th>
              <th>PO-2</th>
              <th>PO-3</th>
              <th>PO-4</th>
              <th>PO-5</th>
              <th>PO-6</th>
              <th>PO-7</th>
              <th>PO-8</th>
              <th>PO-9</th>
              <th>PO-10</th>
              <th>PO-11</th>
              <th>PO-12</th>
            </tr>
            <tr>
              <th>PEO</th>
              <td>&#10003;</td>
              <td>&#10003;</td>
              <td>&#10003;</td>
              <td></td>
              <td></td>
              <td>&#10003;</td>
              <td></td>
              <td>&#10003;</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>PEO</th>
              <td></td>
              <td></td>
              <td></td>
              <td>&#10003;</td>
              <td>&#10003;</td>
              <td></td>
              <td>&#10003;</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>&#10003;</td>
            </tr>
            <tr>
              <th>PEO</th>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td>&#10003;</td>
              <td>&#10003;</td>
              <td>&#10003;</td>
              <td></td>
            </tr>
          </table>
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationEEE />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </div>
  );
};

export default BscinEEEDepartmentProgram;
