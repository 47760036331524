import React from 'react';
import AcademicSideBar from '../../component/AcademicSideBar';

const DegreeRequirmentsContent = () => {
    return (
        <div className='content'>
            <div className='OtherPagecontent'>
                <hr />
                <p className='heading2 text-center'>
                    Degree Requirements
                </p>
                <hr />
                <p className='allText'>
                    <ol>
                        <li>
                            <p className='allText'>
                                In order to earn the degree, a student must:
                            </p>
                            <ol type="a">
                                <li>
                                    <p className='allText'>
                                        successfully complete all the courses and credits required for the degree he/she is applying for
                                    </p>
                                </li>
                                <li>
                                    <p className='allText'>
                                        earn the  CGPA (2.5) required for the degree
                                    </p>
                                </li>
                                <li>
                                    <p className='allText'>
                                        have no disciplinary charges or actions against him/her and
                                    </p>
                                </li>
                                <li>
                                    <p className='allText'>
                                        clear all dues and liabilities to the university
                                    </p>
                                </li>
                            </ol>
                        </li>
                        <li>
                            <p className='allText'>
                                An undergraduate student must complete the degree requirement within six years from the date of his/her first admission. A student of a one-year master's degree program must complete the degree requirements within four years from the date of his/her first admission. A student of two-year master's degree program must complete the degree requirements within six years from the date of his/her first admission.
                            </p>
                        </li>
                        <li>
                            <p className='allText'>
                                Students are responsible for fulfilling all the requirements of their respective degree programs within the deadline. They should consult their Academic Advisors in planning their course schedule and be familiar with EU policies and procedures related to registration for courses and graduation requirements for their degrees.
                            </p>
                        </li>
                        <li>
                            <p className='allText'>
                                If a student has completed the requirements for the degree, he/she should apply in the prescribed form to the Registrar's Office for the award of the degree and pay requisite fees for provisional/final certificate, transcript and for attendance in convocation.
                            </p>
                        </li>
                    </ol>
                </p>

            </div>
            <AcademicSideBar />
        </div>
    );
};

export default DegreeRequirmentsContent;