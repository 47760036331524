import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { QuizDetails } from "./components/QuizDetails";
import { Schedule } from "./components/Schedule";
import { Gallery } from "./components/gallery";
import { Registration } from "./components/Registration";
import { Prize } from "./components/Prize";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import LeftSideNavigationCSE from '../component/leftSideNavigationCSE'
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const App = () => {
  const [landingPageData, setLandingPageData] = useState({});
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
       {/* Mobile Responsive Start*/}
       <div
                id="mobileMenuButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
                <button>
                    <VscAdd className="moreMenu" />
                </button>
            </div>
            {mobileMenuOpen && (
                <div id="mobileMenuContent">
                    <button
                        id="closeButton"
                        onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                    >
                        <VscChromeClose className="closeButtonIcon" />
                    </button>
                    <LeftSideNavigationCSE />
                </div>
            )}
            {/* Mobile Responsive End*/}
      <div className="facultySliderandHeader">
                <LeftSideNavigationCSE />
                <div className="facultySlider">
                <Header data={landingPageData.Header} />
                </div>
            </div>
      <QuizDetails data={landingPageData.QuizDetails} />
      <Prize data={landingPageData.Prize} />
      <Schedule data={landingPageData.Schedule} />
      <Registration />
      <Gallery />
      <Contact data={landingPageData.Contact} />
    </div>
  );
};

export default App;
