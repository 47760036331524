import React from "react";

import ReactHtmlParser from "react-html-parser";
import Footer from "../../../component/Footer";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom";
import { useCourse } from "../../../features/useCourse";
import LoadingBox from "../../../component/LoadingBox";
import MessageBox from "../../../component/MessageBox";
import styled from "styled-components";
import { getNumberWithOrdinal } from "../../../util";

const Stylediv = styled.div`
  display: grid;
  font-size: 18px;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  row-gap: 2rem;
  column-gap: 1rem;
  margin: 4rem 0;
`;

const FlexDiv = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const SpecialDiv = styled.div`
  grid-column: 1/6;
`;

function SingleCourseDetails() {
  const { CourseID } = useParams();
  const { course, isLoading, error } = useCourse(CourseID);
  if (isLoading) return <LoadingBox />;
  if (error) return <MessageBox>{error.message}</MessageBox>;

  const {
    COURSENAME,
    IS_GED,
    courses_details,
    CREDITHOURS,
    PROGRAM,
    COURSECODE,
  } = course;

  return (
    <>
      <div className="singleCourse">
        <p className="heading4 my-4">{ReactHtmlParser(COURSENAME)}</p>
        <Stylediv>
          <FlexDiv>
            <p className="heading6">Course Code : </p>
            <p className="allText">{COURSECODE}</p>
          </FlexDiv>
          <FlexDiv>
            <p className="heading6">Course Type : </p>
            <p className="allText">{!IS_GED ? "Core" : "GED"}</p>
          </FlexDiv>

          <FlexDiv>
            <p className="heading6">Credit Hour : </p>
            <p className="allText">{CREDITHOURS}</p>
          </FlexDiv>

          <FlexDiv>
            <p className="heading6">Year : </p>
            <p className="allText">
              {getNumberWithOrdinal(courses_details?.year)}
            </p>
          </FlexDiv>
          <FlexDiv>
            <p className="heading6">Semester : </p>
            <p className="allText">
              {getNumberWithOrdinal(courses_details?.semester)}
            </p>
          </FlexDiv>
          <FlexDiv>
            <p className="heading6">Prerequisite : </p>
            <p className="allText">
              {courses_details?.prerequisite?.length > 0
                ? courses_details.prerequisite?.map((item, index, arr) => {
                    const lastElement = index === arr.length - 1;
                    return (
                      <span>
                        <Link exact to={"/courseDetails/" + item.id}>{` ${
                          lastElement ? item.COURSECODE : `${item.COURSECODE}, `
                        } `}</Link>
                      </span>
                    );
                  })
                : "None"}
            </p>
          </FlexDiv>
          <SpecialDiv>
            <FlexDiv>
              <p className="heading6">Teach in : </p>

              <p className="allText">
                {PROGRAM.map((item, index, arr) => {
                  const lastElement = index === arr.length - 1;
                  return (
                    <span>
                      {` ${lastElement ? `${item.name}. ` : `${item.name}, `} `}
                    </span>
                  );
                })}
              </p>
            </FlexDiv>
          </SpecialDiv>
        </Stylediv>
        <div className="course-outline">
          <p className="heading5 text-left">Course outline</p>
          <p className="allText">
            {ReactHtmlParser(
              course.courses_details?.content.replace(/\r\n/g, "<br />")
            )}
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SingleCourseDetails;
