import { useState } from "react";
import LeftSideNavigationCSE from "../../component/leftSideNavigationCSE";
import FacultyName from "../../component/facultyName";
import { VscAdd, VscChromeClose } from "react-icons/vsc";
import Footer from "../../../../component/Footer";

function MissionAndVissionCSE() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  return (
    <div>
      <FacultyName name="Department of Computer Science and Engineering" />
      <div className="facultyContent">
        <LeftSideNavigationCSE />
        <div className="rightSideContent mt-4">
          <p className="heading3 text-center">
            Departmental Vision and Mission
          </p>
          <div>
            <p className="heading5">Vision of Dept. of CSE</p>
            <p className="allText">
              The vision of Department of CSE is to build a center of excellence
              for producing quality graduates to compete in the national and the
              international arena in the field of Computer Science and
              Engineering through ensuring outcome based curriculum, necessary
              analytical skills and professional diversity.
            </p>
            <p className="heading5">Mission of Dept. of CSE</p>
            <p className="allText">
              <strong>Statement-1:</strong> To provide quality education and
              exposure to the latest tools and technologies in the area of
              Computer Science and Engineering.
            </p>
            <p className="allText">
              <strong>Statement-2:</strong> To promote research based
              projects/activities in the emerging areas of Computer Science and
              Engineering.
            </p>
            <p className="allText">
              <strong>Statement-3:</strong> To ensure engaging students with
              real life situations by collaboration with the industry.
            </p>
            <p className="allText">
              <strong>Statement-4:</strong> To ensure a spirited, congenial and
              inclusive environment where students, faculty and staff can
              flourish together.
            </p>
          </div>
        </div>
        {/* Mobile Responsive Start*/}
        <div
          id="mobileMenuButton"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <button>
            <VscAdd className="moreMenu" />
          </button>
        </div>
        {mobileMenuOpen && (
          <div id="mobileMenuContent">
            <button
              id="closeButton"
              onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            >
              <VscChromeClose className="closeButtonIcon" />
            </button>
            <LeftSideNavigationCSE />
          </div>
        )}
        {/* Mobile Responsive End*/}
      </div>
      <Footer />
    </div>
  );
}

export default MissionAndVissionCSE;
