import { useQuery } from "@tanstack/react-query";
import { getOffices } from "../services/apis";
export function useOffice() {
  const {
    data: office,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["offices"],
    queryFn: () => getOffices(),
  });

  return { office, isLoading, error };
}
