import React from "react";

import MessageBox from "../../../component/MessageBox";
import { useFetch } from "../../../hooks/useFetch";
import { replace_string, server_django } from "../../../util";
import Spinner from "../../../UI/Spinner";

function DepartmentOfficials({ config }) {
  const { data, loading, error } = useFetch("employee/departmentOfficials");
  if (loading) return <Spinner />;
  if (error) return <MessageBox>{error}</MessageBox>;

  if (!data?.length) return null;
  const officialsMember = config.department
    ? data?.filter((item) =>
        item.department?.some((item) => item.depid === config.department)
      )
    : config.faculty
    ? data?.filter(
        (item) => item.department?.collegecode.collegecode === config.faculty
      )
    : [];

  return (
    <ul className="officialsContact">
      {officialsMember.map((list, index) => (
        <Officials key={index} list={list} />
      ))}
    </ul>
  );
}

export default DepartmentOfficials;

function Officials({ list }) {
  return (
    <li key={list.id}>
      <div>
        <p className="heading6">{list.staff_name}</p>
        <p className="allText">
          {list.role?.length > 0 &&
            list.role.map((item) => <span>{`${item.role}, `}</span>)}
          {list.designation.designation}
        </p>

        <p className="allText">
          {list.phone_number} Ext: {list.extension}
        </p>
        <p className="allText">{list.official_email}</p>
      </div>

      <img
        className="img-fluid"
        src={list.staff_picture.replace(replace_string, server_django)}
        alt={list.staff_name}
      />
    </li>
  );
}
