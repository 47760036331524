import React, { useState } from "react";
import LeftSideNavigationCSE from "../../component/leftSideNavigationCSE";
import FacultyName from "../../component/facultyName";
import Footer from "../../../../component/Footer";
import { VscAdd } from "react-icons/vsc";
import { VscChromeClose } from "react-icons/vsc";

const ExploringCSE = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  return (
    <>
      <FacultyName name="Department of Computer Science and Engineering" />
      <div className="facultyContent" id="deanArts">
        <LeftSideNavigationCSE />
        <div className="rightSideContent mt-4">
          <p className="heading4 text-left">
            Exploring Eastern University Computer Science and Engineering{" "}
          </p>
          <div className="deanMessage text-left">
            <p className="allText">
              The Department of Computer Science and Engineering offers programs
              leading to the degrees of B.Sc. in Computer Science and
              Engineering [B.Sc. (CSE)]. The duration of B.Sc. in CSE is four
              years and is divided into 8 semesters. To obtain B.Sc. in CSE,
              students need to complete 155 credit hours with a minimum CGPA of
              2.50. Our main task is to prepare students for careers in Software
              and Hardware industries or to pursue advanced graduate studies at
              home and abroad. Also a part of our mission is to ensure a
              comprehensive and client-oriented teaching environment that
              emphasizes depth, originality and critical thinking of computer
              science and related professional values.
            </p>
          </div>
          {/* Mobile Responsive Start*/}
          <div
            id="mobileMenuButton"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <button>
              <VscAdd className="moreMenu" />
            </button>
          </div>
          {mobileMenuOpen && (
            <div id="mobileMenuContent">
              <button
                id="closeButton"
                onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
              >
                <VscChromeClose className="closeButtonIcon" />
              </button>
              <LeftSideNavigationCSE />
            </div>
          )}
          {/* Mobile Responsive End*/}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ExploringCSE;
