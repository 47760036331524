import React, { Component } from 'react';
import TutionFeesContent from './Content/TutionFeesContent';
import Footer from '../../component/Footer';
import AdmissionSidebar from '\../component/AdmissionSidebar';


class tutionFees extends Component {
    render() {
        return (
            <div>
                <div className='content'>
                    <TutionFeesContent />
                    <AdmissionSidebar />
                </div>
                <Footer />
            </div>
        );
    }
}

export default tutionFees;